import { useMediaQuery } from '@mui/material'
import { ReactNode, RefObject } from 'react'
import styled from '@emotion/styled'
import { useColors } from './Colors'
import { Container, ContainerProps, Pusher } from './layout/Layout'
import { isMobile } from 'react-device-detect'
import { ReactComponent as Logo } from './icons/beework-logo-blue-yellow.svg'

const NarrowNoPaddingContainer = styled(Container)`
  @media (max-width: 370px) {
    padding: 0 !important;
  }
`

type SignInLayoutProps = {
  topChildren?: ReactNode
  bottomChildren?: ReactNode
  containerRef?: RefObject<HTMLDivElement>
} & ContainerProps

export default function SignInLayout({
  children,
  topChildren,
  bottomChildren,
  style,
  containerRef,
  ...containerProps
}: SignInLayoutProps) {
  const colors = useColors()
  const narrow = useMediaQuery('(max-width: 850px') || isMobile

  return (
    <NarrowNoPaddingContainer
      divRef={containerRef}
      hAlign="center"
      vAlign={narrow ? 'bottom' : 'center'}
      style={{
        overflowY: 'scroll',
        position: 'relative',
        flex: 1,
        minHeight: narrow ? 800 : 500,
        height: '100%',
        padding:
          isMobile && !narrow ? '300px 0 0 0' : narrow ? '70px 0 0 0' : 30,
        backgroundSize: narrow ? `129.55px 155.46px` : 'cover',
        backgroundColor: colors.logInBackground,
        ...style,
      }}
      horizontal
      {...containerProps}
    >
      <Container
        style={{
          flex: narrow ? '1 1 800px' : '1 0 800px',
          maxHeight: '100%',
          maxWidth: narrow ? '100%' : 490,
          filter: `drop-shadow(5px 8px 20px ${colors.logInDropshadow})`,
        }}
      >
        {topChildren ? (
          topChildren
        ) : (
          <Container horizontal vAlign="bottom" style={{ marginBottom: 20 }}>
            <Logo
              style={{ marginLeft: isMobile ? 40 : undefined }}
              height={isMobile ? 85 : 57}
              width={isMobile ? 330 : 220}
            />
            <Pusher />
          </Container>
        )}
        <Container
          flex="1"
          hAlign="center"
          style={{
            paddingTop: 50,
            borderRadius: narrow ? '15px 15px 0 0' : 15,
            backgroundColor: colors.foreground,
            overflowY: isMobile ? 'auto' : undefined,
          }}
        >
          <Container
            style={{
              paddingBottom: narrow ? 0 : 40,
              paddingLeft: narrow ? 50 : 10,
              paddingRight: narrow ? 50 : 10,
              width: narrow ? '100%' : 390,
            }}
            flex="1"
          >
            {children}
          </Container>
        </Container>
        {bottomChildren}
      </Container>
    </NarrowNoPaddingContainer>
  )
}
