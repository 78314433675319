import { useFirestoreDoc } from '@iwikal/reactfire'
import firebase from 'firebase/app'
import { Modal } from '@mui/material'
import {
  PostDocument,
  PostEventUserStatus,
  PostUserDocument,
} from 'connect-shared/types'
import User from 'connect-shared/User'
import { RefObject, Suspense, SuspenseList, useEffect } from 'react'
import { useScrollLimitQuery } from '../../../chat/useScrollLimitQuery'
import { useColors } from '../../../Colors'
import { firestore } from '../../../firestore'
import { useCurrentUserId } from '../../../hooks/auth'
import { Container, Spacer } from '../../../layout/Layout'
import PlayippSpinner from '../../../playippSpinner/PlayippSpinner'
import StatusAvatar from '../../../StatusAvatar'
import { BodyText } from '../../../Text'
import OptionButton from './OptionButton'
import ModalCloseButton from '../../../ModalCloseButton'
import { useDateFns, useTranslate } from '../../../hooks/Internationalization'

function AttendeeItem({ userId }: { userId: string }) {
  const colors = useColors()
  const user = new User(
    useFirestoreDoc(firestore().collection('users').doc(userId)).read()
  )

  return (
    <Container horizontal vAlign="center">
      <StatusAvatar userId={userId} size={50} />
      <Spacer size={10} />
      <Container
        flex={1}
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Container>
          <BodyText
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {user.fullName}
          </BodyText>
          <BodyText
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: 12,
              color: colors.conversationTime,
            }}
          >
            {user.jobTitle}
          </BodyText>
        </Container>
      </Container>
    </Container>
  )
}

function AttendeeList({
  title,
  respondentCount,
  totalRespondentCount,
  attendees,
}: {
  title: string
  respondentCount: number
  totalRespondentCount: number

  attendees: firebase.firestore.QueryDocumentSnapshot<PostUserDocument>[]
}) {
  const colors = useColors()
  return (
    <Container>
      <BodyText>
        <span style={{ fontWeight: 600 }}>{title}</span>{' '}
        <span
          style={{ color: colors.inactiveLikeButton }}
        >{`${respondentCount}/${totalRespondentCount}`}</span>
      </BodyText>
      <Spacer size={10} />
      <SuspenseList revealOrder="forwards">
        {attendees.map((snap) => {
          return (
            <>
              <AttendeeItem key={snap.id} userId={snap.id} />
              <Spacer size={20} />{' '}
            </>
          )
        })}
      </SuspenseList>
    </Container>
  )
}

type AttendeesModalProps = {
  onRequestClose: () => void
  setAttending: (status: PostEventUserStatus) => Promise<void>
  postSnap: firebase.firestore.DocumentSnapshot<PostDocument>
}

export default function AttendeesModal({
  onRequestClose,
  postSnap,
  setAttending,
}: AttendeesModalProps) {
  const colors = useColors()
  const t = useTranslate()

  const currentUserId = useCurrentUserId()
  const postRef = postSnap.ref
  const { format } = useDateFns()

  const post = postSnap.data()
  const event = post?.event
  if (!event) {
    throw new Error('Post without event in AttendeesModal')
  }

  const respondentCount = Object.values(event.attendingCounters).reduce(
    (acc, curr) => acc + curr,
    0
  )

  const query = postRef.collection('users').orderBy('attending', 'desc')
  const { snap, scrollRef, loadMore, hasMore } = useScrollLimitQuery(query, {
    initialLimit: 20,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadMore, [])

  const userSnap = useFirestoreDoc(
    postRef.collection('users').doc(currentUserId)
  )
  const attendingStatus = userSnap.read().data()?.attending

  const yesAttendees = snap.docs.filter(
    (snap) => snap.data().attending === 'yes'
  )
  const noAttendees = snap.docs.filter((snap) => snap.data().attending === 'no')
  const maybeAttendees = snap.docs.filter(
    (snap) => snap.data().attending === 'maybe'
  )

  return (
    <Modal
      open
      onClose={onRequestClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container
        style={{
          maxHeight: 'calc(100% - 100px)',
          height: 850,
          width: 480,
          position: 'relative',
        }}
      >
        <ModalCloseButton onClick={onRequestClose} />
        <Container
          style={{
            height: '100%',
            overflow: 'hidden',
            borderRadius: 10,
          }}
        >
          <Container
            style={{
              height: '100%',
              overflow: 'auto',
              backgroundColor: colors.webChatModalBackground,
              padding: 30,
            }}
            divRef={scrollRef as RefObject<HTMLDivElement>}
          >
            <Container horizontal>
              <Container>
                <BodyText
                  style={{
                    color: colors.danger,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {format(event.fromDate.toDate(), 'EEEE d LLL - kk:mm')}
                </BodyText>
                <BodyText
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    wordBreak: 'break-word',
                  }}
                >
                  {event.title}
                </BodyText>
                {event.location && (
                  <BodyText style={{ fontSize: 14 }}>{event.location}</BodyText>
                )}
              </Container>
            </Container>
            <Spacer size={20} />
            <Container>
              <BodyText style={{ fontSize: 16 }}>
                {t('Are you attending?')}
              </BodyText>
              <Spacer size={10} />
              <Container horizontal>
                <OptionButton
                  selected={attendingStatus === 'yes'}
                  onClick={() => setAttending('yes')}
                >
                  {t('Yes')}
                </OptionButton>
                <Spacer size={16} />
                <OptionButton
                  selected={attendingStatus === 'no'}
                  onClick={() => setAttending('no')}
                >
                  {t('No')}
                </OptionButton>
                <Spacer size={16} />
                <OptionButton
                  selected={attendingStatus === 'maybe'}
                  onClick={() => setAttending('maybe')}
                >
                  {t('Maybe')}
                </OptionButton>
              </Container>
            </Container>
            <Spacer size={30} />
            <BodyText style={{ fontWeight: 600, fontSize: 14 }}>
              {t('Number of respondents')}{' '}
              <span style={{ color: colors.chatTime }}>{respondentCount}</span>
            </BodyText>
            <Spacer size={10} />
            <Suspense fallback={null}>
              {!!yesAttendees.length && (
                <AttendeeList
                  title={t('Yes')}
                  attendees={yesAttendees}
                  totalRespondentCount={respondentCount}
                  respondentCount={event.attendingCounters.yes}
                />
              )}
              {!!noAttendees.length && (
                <AttendeeList
                  title={t('No')}
                  totalRespondentCount={respondentCount}
                  respondentCount={event.attendingCounters.no}
                  attendees={noAttendees}
                />
              )}
              {!!maybeAttendees.length && (
                <AttendeeList
                  title={t('Maybe')}
                  totalRespondentCount={respondentCount}
                  respondentCount={event.attendingCounters.maybe}
                  attendees={maybeAttendees}
                />
              )}
            </Suspense>
            {hasMore && (
              <Container flex={1} style={{ marginTop: 25 }} hAlign="center">
                <PlayippSpinner size={100} color={colors.beeworkBlue} />
              </Container>
            )}
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}
