import { ReactNode, useReducer, useCallback } from 'react'
import Card from './Card'
import { Container, Pusher, Spacer } from './layout/Layout'
import { BodyText } from './Text'
import BlockButton from './BlockButton'
import { ReactComponent as DropdownIcon } from './icons/ico-chevron-down.svg'
import { useColors } from './Colors'
import IconButton from './IconButton'
import Styled from '@emotion/styled'

const HoverableWrapper = Styled.div<{ hoverEnabled?: boolean }>`
border: 2px solid transparent; 
margin: -2px;
border-radius: 10px; 
    &:hover{
        border: ${(props) => (props.hoverEnabled ? '2px solid #4B9FCC' : '')} ; 
    }
`

function Dot() {
  const colors = useColors()
  return (
    <div
      style={{
        margin: '0 8px',
        borderRadius: 4 / 2,
        width: 4,
        height: 4,
        backgroundColor: colors.unavailable,
      }}
    />
  )
}

export default function DropdownCard({
  text,
  count,
  children,
  shouldHover,
  buttonText,
  onClick,
  defaultExpanded,
}: {
  text: string
  count?: number
  children: ReactNode
  shouldHover?: boolean
  buttonText?: string
  onClick?: () => void
  defaultExpanded?: boolean
}) {
  const [expanded, toggleExpanded] = useReducer(
    (state) => !state,
    defaultExpanded || false
  )

  const colors = useColors()

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick && onClick()
      e.stopPropagation()
    },
    [onClick]
  )

  return (
    <HoverableWrapper hoverEnabled={shouldHover}>
      <Card style={{ padding: 0 }}>
        <Container
          onClick={useCallback(toggleExpanded, [toggleExpanded])}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            cursor: 'pointer',
            paddingTop: '30px',
            paddingBottom: expanded ? 20 : 30,
            borderBottom: expanded ? `1px solid ${colors.selected}` : '',
          }}
          horizontal
          vAlign="center"
        >
          <Spacer size={30} />
          <BodyText style={{ fontSize: 20 }}>{text}</BodyText>
          {count !== undefined && count > 0 && (
            <>
              <Dot />
              <BodyText style={{ fontSize: 20, color: colors.unavailable }}>
                {count}
              </BodyText>
            </>
          )}
          <Pusher />
          {buttonText && (
            <BlockButton
              onClick={handleButtonClick}
              style={{
                width: 131,
                fontSize: 14,
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              {buttonText}
            </BlockButton>
          )}
          <Spacer size={34} />
          <IconButton
            Icon={DropdownIcon}
            onClick={useCallback((e) => {
              toggleExpanded()
              e.stopPropagation()
            }, [])}
            size={24}
            fill={colors.pastEvent}
            style={{
              position: 'relative',
            }}
          />
          <Spacer size={30} />
        </Container>
        {expanded && children}
      </Card>
    </HoverableWrapper>
  )
}
