import { SuspenseList } from 'react'
import { Poll, PollResult, PollVotes } from 'connect-shared/types'
import { DocumentSnapshot } from 'connect-shared/firestore'
import { Resource } from '../../resource'
import { useColors } from '../../Colors'
import ClickableOpacity from '../../ClickableOpacity'
import { Container } from '../../layout/Layout'
import { BodyText, CaptionText } from '../../Text'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material'
import { firestore } from '../../firestore'
import firebase from 'firebase/app'
import { usePostPollResultRef, usePostPollVotesRef } from '../../hooks/feeds'
import { showErrorToast } from '../../Toast'
import { useTranslate } from '../../hooks/Internationalization'

type ResultResource = Resource<DocumentSnapshot<PollResult>>
type VotesResource = Resource<DocumentSnapshot<PollVotes>>

function hasVoted(votesResource: VotesResource): boolean {
  const votes = votesResource.read().data()?.votes
  if (!votes) return false
  for (const vote of Object.values(votes)) {
    if (vote) return true
  }
  return false
}

interface PollProps {
  feedId: string
  postId: string
  votesResource: VotesResource
  poll: Poll
  resultResource: ResultResource
}

export default function PollView(props: PollProps) {
  const t = useTranslate()
  const votesRef = usePostPollVotesRef(props.feedId, props.postId)
  const resultRef = usePostPollResultRef(props.feedId, props.postId)

  async function vote(index: number) {
    function inc(i: number): number {
      return firebase.firestore.FieldValue.increment(i) as any
    }

    function del(): never {
      return firebase.firestore.FieldValue.delete() as never
    }

    try {
      const snap = props.votesResource.read()

      const { votes = {}, generation = 0 } = snap.data() || {}

      let totalVotersDiff = 0
      const votesDiff = { [index]: 0 }

      if (!props.poll.allowMultiple) {
        for (const key of Object.keys(votes)) {
          const option = parseInt(key, 10)
          if (option !== index) {
            votesDiff[option] = (votesDiff[option] ?? 0) - 1
          }
        }
      }

      if (votes[index]) {
        votesDiff[index] -= 1
        if (Object.keys(votes).length === 1) {
          totalVotersDiff -= 1
        }
      } else {
        votesDiff[index] += 1
        if (Object.keys(votes).length === 0) {
          totalVotersDiff += 1
        }
      }

      const resultData: PollResult = {
        totalVoters: inc(totalVotersDiff),
        votes: Object.fromEntries(
          Object.entries(votesDiff).map(([index, diff]) => {
            return [index, inc(diff)]
          })
        ),
      }

      const votesData: PollVotes = {
        votes:
          totalVotersDiff === -1
            ? del()
            : Object.fromEntries(
                Object.entries(votesDiff).flatMap(([index, diff]) => {
                  if (diff < 0) return [[index, del()]]
                  if (diff > 0) return [[index, true as const]]
                  return []
                })
              ),
        generation: generation + 1,
      }

      await firestore()
        .batch()
        .set(resultRef, resultData, { merge: true })
        .set(votesRef, votesData, { merge: true })
        .commit()
    } catch (e) {
      showErrorToast(t('Failed to register your vote.'))
      throw e
    }
  }

  if (!props.poll.ordering) {
    throw [props.feedId, props.postId]
  }

  return (
    <Container>
      {Boolean(props.poll.title) && (
        <CaptionText
          style={{ fontWeight: 600, fontSize: 16, marginBottom: 10 }}
        >
          {props.poll.title}
        </CaptionText>
      )}
      <SuspenseList>
        {props.poll.ordering.map((id, i) => {
          const option = props.poll.options[id]
          return (
            <Option
              key={id}
              index={i}
              multiple={props.poll.allowMultiple}
              votesResource={props.votesResource}
              resultResource={props.resultResource}
              title={option.title}
              vote={vote}
            />
          )
        })}
      </SuspenseList>
    </Container>
  )
}

function Option(props: {
  index: number
  multiple: boolean
  votesResource: VotesResource
  resultResource: ResultResource
  title: string
  vote: (i: number) => void
}) {
  const { title, resultResource, votesResource, index, vote, multiple } = props

  const myVotes = votesResource.read().data()?.votes
  const selected = !!myVotes?.[index]

  const statistics = (() => {
    if (!hasVoted(votesResource)) return null

    const data = resultResource.read().data()
    if (!data) return { percentage: '0%', votes: 0 }

    const { totalVoters, votes: { [index]: votes = 0 } = {} } = data

    const percentage = Math.round((votes / totalVoters) * 100)

    return { percentage: `${percentage}%`, votes }
  })()

  const Icon = multiple
    ? selected
      ? CheckBox
      : CheckBoxOutlineBlank
    : selected
    ? RadioButtonChecked
    : RadioButtonUnchecked
  const colors = useColors()
  const t = useTranslate()

  return (
    <ClickableOpacity
      onClick={() => vote(index)}
      hover
      style={{
        borderRadius: 4,
        padding: 10,
        minHeight: 57,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon
        width={20}
        height={20}
        htmlColor={colors.label}
        style={{ marginRight: 10 }}
      />
      <Container horizontal flex={1}>
        <Container flex={1}>
          <CaptionText
            style={{
              display: 'flex',
              alignSelf: 'start',
              textAlign: 'start',
              wordBreak: 'break-word',
            }}
          >
            {title}
          </CaptionText>
          {statistics && <Bar percentage={statistics.percentage} />}
        </Container>
        {hasVoted(votesResource) && (
          <BodyText
            style={{
              marginLeft: 30,
              width: 120,
              alignSelf: 'flex-end',
              fontWeight: 600,
              fontSize: 14,
              textAlign: 'end',
              color: colors.mediumHeading,
            }}
          >
            {t(
              '%{smart_count} Vote • %{percentage} |||| %{smart_count} Votes • %{percentage}',
              {
                smart_count: statistics?.votes,
                percentage: statistics?.percentage,
              }
            )}
          </BodyText>
        )}
      </Container>
    </ClickableOpacity>
  )
}

function Bar(props: { percentage: string }) {
  const colors = useColors()

  return (
    <div
      style={{
        background: colors.border,
        borderRadius: 3,
        height: 6,
        marginTop: 5,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: props.percentage,
          background: colors.beeworkBlue,
          borderRadius: 3,
          height: 6,
        }}
      />
    </div>
  )
}
