import { DialogContent, DialogOverlay } from '@reach/dialog'
import { useCallback, useState } from 'react'
import { useColors } from './Colors'
import IconButton from './IconButton'
import { Container, Spacer } from './layout/Layout'
import { BodyText, CaptionText } from './Text'
import Toggle from './Toggle'
import { ReactComponent as CloseIcon } from './icons/closeModal.svg'
import BlockButton from './BlockButton'
import ClickableOpacity from './ClickableOpacity'
import { css } from '@emotion/react'
import useIsMounted from 'ismounted'
import { useTranslate } from './hooks/Internationalization'

type ConfirmDeleteModalProps = {
  title: string
  text: string
  toggleText?: string
  onRequestClose: () => void
  onConfirm: () => Promise<any> | void
  loading?: boolean
  toggle?: boolean
  buttonText?: string
}

export default function ConfirmDeleteModal({
  title,
  text,
  toggleText,
  onRequestClose,
  onConfirm,
  loading,
  toggle,
  buttonText,
}: ConfirmDeleteModalProps) {
  const [toggled, setToggled] = useState(false)
  const colors = useColors()
  const t = useTranslate()

  const confirmDisabled = !toggle ? false : !toggled || loading
  const [confirmInProgress, setConfirmInProgress] = useState(false)
  const isMounted = useIsMounted()

  const handleConfirm = useCallback(async () => {
    if (confirmInProgress) return
    setConfirmInProgress(true)
    try {
      await onConfirm()
      if (isMounted.current) {
        onRequestClose()
      }
    } finally {
      if (isMounted.current) {
        setConfirmInProgress(false)
      }
    }
  }, [confirmInProgress, isMounted, onConfirm, onRequestClose])

  return (
    <DialogOverlay
      aria-label=" "
      onDismiss={onRequestClose}
      style={{
        zIndex: 21,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.webChatTransparentBackground,
      }}
    >
      <DialogContent
        aria-label=" "
        style={{
          position: 'relative',
          width: 410,
          backgroundColor: colors.webChatModalBackground,
          borderRadius: 10,
          padding: 30,
        }}
      >
        <Container>
          <CaptionText style={{ fontSize: 20, fontWeight: 600 }}>
            {title}
          </CaptionText>
          <BodyText style={{ marginTop: 20 }}>{text}</BodyText>
        </Container>
        <Container horizontal style={{ marginTop: 20 }}>
          <BodyText>{toggleText}</BodyText>
          <Spacer size={10} />
          {toggle && <Toggle value={toggled} onChange={setToggled} />}
        </Container>
        <Container horizontal hAlign="right" style={{ marginTop: 20 }}>
          <ClickableOpacity
            css={css`
              color: ${colors.label};

              &:hover {
                color: ${colors.danger};
              }
            `}
            style={{ fontWeight: 600, marginRight: 20 }}
            onClick={onRequestClose}
          >
            {t('Cancel')}
          </ClickableOpacity>
          <BlockButton
            onClick={handleConfirm}
            disabled={confirmDisabled || confirmInProgress}
            danger
            loading={confirmInProgress}
          >
            {buttonText || t('Delete')}
          </BlockButton>
        </Container>
        <IconButton
          onClick={onRequestClose}
          size={40}
          Icon={CloseIcon}
          style={{
            position: 'absolute',
            top: -40,
            right: -40,
          }}
        />
      </DialogContent>
    </DialogOverlay>
  )
}
