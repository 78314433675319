import { useFirestoreCollection } from '@iwikal/reactfire'
import { PostNode } from 'connect-shared/datamodel'
import { memo } from 'react'
import { ModelDocument } from '../../firestore'
import CommentItem from './CommentItem'
import { Container } from '../../layout/Layout'

const CommentsList = ({ post }: { post: ModelDocument<PostNode> }) => {
  const commentsQuery = post
    .collection('comments')
    .where('parent', '==', null)
    .orderBy('createdDate', 'desc')

  const commentsResource = useFirestoreCollection(commentsQuery)

  return (
    <Container style={{ padding: '15px 10px 0' }}>
      {commentsResource.read().docs.map((commentSnap) => {
        return (
          <CommentItem
            key={commentSnap.id}
            commentSnap={commentSnap}
            postRef={post}
          />
        )
      })}
    </Container>
  )
}

export default memo(CommentsList)
