import { useColors } from '../Colors'
import UserListItem from './UserListItem'
import useUserSearch from '../hooks/useUserSearch'

interface FoundUsersListProps {
  usersToFilter?: string[]
  searchTerm: string
  addUser: (userId: string) => void
  selectedUsers: Set<string>
}

export default function FoundUsersList({
  searchTerm,
  addUser,
  selectedUsers,
  usersToFilter,
}: FoundUsersListProps) {
  const colors = useColors()
  const trimmed = searchTerm.trimStart().toLowerCase()
  const searchResult = useUserSearch(
    trimmed,
    new Set([...selectedUsers, ...(usersToFilter || [])])
  )

  return (
    <div style={{ position: 'relative', flex: 1 }}>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          backgroundColor: colors.foreground,
          zIndex: 2,
        }}
      >
        {searchResult.length > 0 && (
          <div style={{ borderTop: `5px solid ${colors.active}` }}>
            {searchResult.map((snap, index) => {
              return (
                <div
                  key={snap.id}
                  style={{ display: 'flex', alignSelf: 'stretch' }}
                >
                  <UserListItem
                    clicked={() => addUser(snap.id)}
                    userId={snap.id}
                    avatarSize={40}
                    backgroundColor={
                      index % 2
                        ? colors.webChatModalBackground
                        : colors.foreground
                    }
                    style={{
                      padding: `5px 11px 5px 11px`,
                    }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
