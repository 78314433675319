import { useFirestoreDoc } from '@iwikal/reactfire'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import PageLayout from '../layout/PageLayout'
import { emitTutorialEvent } from '../Tutorials'
import CombinedFeed from './feed/CombinedFeed'
import CombinedPersonalFeeds from './feed/CombinedPersonalFeeds'
import Feed from './feed/Feed'
import FeedMenu from './FeedMenu'
import useFeatureFlag from '../hooks/useFeatureFlag'

export function useCombined() {
  const organization = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const doc = useMemo(() => {
    return organization.collection('combinedFeeds').doc(currentUserId)
  }, [currentUserId, organization])

  return useFirestoreDoc(doc)
}

export default function Feeds() {
  const history = useHistory()

  useEffect(() => {
    emitTutorialEvent('feed_viewed')
  }, [])

  const combined = useCombined()

  const selectFeed = React.useCallback(
    (feedId: string) => history.push('/feeds/' + feedId + '/posts'),
    [history]
  )

  const match = useRouteMatch<{ feedId: string }>('/feeds/:feedId/')

  const feedId = match?.params.feedId

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)

  return (
    <PageLayout
      leftSideContent={
        <FeedMenu selectFeed={selectFeed} combinedFeedRes={combined} />
      }
    >
      <React.Suspense fallback={null}>
        {feedId === 'personal' && hasFeaturePersonalFeeds ? (
          <CombinedPersonalFeeds />
        ) : !feedId || feedId === 'all' ? (
          <CombinedFeed dashboard={false} />
        ) : (
          <Feed feedId={feedId} />
        )}
      </React.Suspense>
    </PageLayout>
  )
}
