import { KeyboardEvent, useCallback, useState } from 'react'
import BlockButton from './BlockButton'
import { Container, Pusher, Spacer } from './layout/Layout'
import { BodyText, CaptionText } from './Text'
import TextInput from './TextInput'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import SignInLayout from './SignInLayout'
import LinkText from './LinkText'
import { showErrorToast, showSuccessToast } from './Toast'
import { useTranslate } from './hooks/Internationalization'

export default function ResetPassword({ oobCode }: { oobCode: string }) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState<string | undefined>()

  const t = useTranslate()
  const history = useHistory()

  const resetPassword = useCallback(async () => {
    if (!oobCode) {
      setError('Something went wrong, please try again later')
      return
    }

    if (password !== confirmPassword) {
      setError('Passwords does not match')
      return
    }
    const auth = firebase.auth()

    auth.confirmPasswordReset(oobCode, password).then(
      () => {
        showSuccessToast(t('Your new password has been saved'), 15000)
        history.push('/sign-in')
      },
      (e: Error) => {
        showErrorToast(e.message, 15000)
        setError(e.message)
      }
    )
  }, [password, confirmPassword, history, oobCode, t])

  return (
    <SignInLayout>
      <Container vAlign="center" flex={1}>
        <Pusher />
        <CaptionText strong style={{ fontSize: 26 }}>
          {t('New password')}
        </CaptionText>
        <Spacer size={20} />
        <TextInput
          type="password"
          onKeyDown={useCallback(
            (e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                resetPassword()
              }
            },
            [resetPassword]
          )}
          error={!!error}
          onChangeText={setPassword}
          value={password}
          placeholder={t('Set a password')}
        />
        <TextInput
          type="password"
          onKeyDown={useCallback(
            (e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                resetPassword()
              }
            },
            [resetPassword]
          )}
          error={error}
          onChangeText={setConfirmPassword}
          value={confirmPassword}
          placeholder={t('Repeat password')}
        />
        <Spacer size={20} />
        <BlockButton onClick={resetPassword}>{t('Reset password')}</BlockButton>
        <Pusher />
        <Container horizontal hAlign="center">
          <BodyText>{t('Found your password?')}</BodyText>
          <LinkText
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              textDecoration: 'none',
              marginLeft: 4,
            }}
            to="/"
          >
            {t('Sign in')}
          </LinkText>
        </Container>
        <Spacer size={30} />
      </Container>
    </SignInLayout>
  )
}
