import { useFirestoreDoc } from '@iwikal/reactfire'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import ErrorBoundary from '../../chat/ErrorBoundary'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { useFeeds } from '../../hooks/feeds'
import useCurrentUser from '../../hooks/useCurrentUser'
import { Container } from '../../layout/Layout'
import FeedAbout from './FeedAbout'
import FeedMembers from './FeedMembers'
import FeedPosts from './FeedPosts'
import FeedSettings from './FeedSettings'
import FeedSettingsCard from './FeedSettingsCard'
import PostDetailsInline from '../PostDetailsInline'
import { useTranslate } from '../../hooks/Internationalization'

export function useFeedInteractivityDisabled(feedId: string) {
  const organizationDoc = useOrganizationDoc()
  const feedRef = organizationDoc.collection('feeds').doc(feedId)
  const feedResource = useFirestoreDoc(feedRef)

  return useMemo(() => {
    return feedResource.read().data()?.disableInteractions || false
  }, [feedResource])
}

type FeedProps = {
  feedId: string
}

function Feed({ feedId }: FeedProps) {
  const feedDoc = useFeeds().doc(feedId)
  const feedResource = useFirestoreDoc(feedDoc)
  const feedSnap = feedResource.read()

  const match = useRouteMatch<{ route: string }>('/feeds/:feedId/:route')
  const route = match?.params.route

  const currentUserId = useCurrentUserId()
  const currentUser = useCurrentUser()
  const isAdmin =
    feedSnap.data()?.admins?.[currentUserId] === true ||
    currentUser?.organizationAdmin ||
    false
  const nonPostRoutes = ['about', 'settings', 'members']

  if (route && !nonPostRoutes.includes(route) && route !== 'posts') {
    return <PostDetailsInline feedId={feedId} postId={route} />
  }

  return (
    <Container style={{ marginTop: 30 }}>
      <FeedSettingsCard
        isAdmin={isAdmin}
        postId={route}
        feedSnap={feedResource.read()}
      />
      {route && !nonPostRoutes.includes(route) && (
        <>
          <FeedPosts feedSnap={feedSnap} />
        </>
      )}
      {route === 'about' && <FeedAbout feedSnap={feedSnap} />}
      {isAdmin && route === 'settings' && <FeedSettings feedSnap={feedSnap} />}
      {route === 'members' && <FeedMembers feedSnap={feedSnap} />}
    </Container>
  )
}

export function FeedError() {
  const t = useTranslate()
  return <pre>{t('Feed not found')}</pre>
}

export default function FeedErrorBoundary(props: FeedProps) {
  return (
    <ErrorBoundary resetKey={props.feedId} fallback={<FeedError />}>
      <Feed {...props} />
    </ErrorBoundary>
  )
}
