import firebase from 'firebase/app'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useOrganizationId } from './auth'
import { useDateFns } from './Internationalization'

export default function useUsageUpdater() {
  const history = useHistory()

  const orgId = useOrganizationId()

  const [path, setPath] = useState(history.location.pathname)
  const prevPath = useRef('')

  useEffect(() => {
    return history.listen(({ pathname }, action) => {
      if (action === 'PUSH') {
        setPath(pathname)
      }
    })
  }, [history])

  const { format } = useDateFns()

  useEffect(() => {
    if (path === prevPath.current) return
    prevPath.current = path
    const ref = firebase
      .database()
      .ref('organizations')
      .child(orgId)
      .child('usage')
      .child('month')
      .child(format(new Date(), 'yyyy-MM'))

    const fields = [
      ['homePageViews', '/home'],
      ['homePageViews', '/'],
      ['chatPageViews', '/chat'],
      ['feedsPageViews', '/feeds'],
      ['wikiPageViews', '/wiki'],
      ['peoplePageViews', '/people'],
      ['settingsPageViews', '/settings'],
      ['profilePageViews', '/users'],
    ]

    const field = fields.find(
      ([_, p]) => path.startsWith(p + '/') || path === p
    )

    if (field) {
      ref.child(field[0]).set(firebase.database.ServerValue.increment(1))
    }

    ref.child('totalPageViews').set(firebase.database.ServerValue.increment(1))
  }, [format, orgId, path])
}
