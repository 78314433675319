import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { userFullName } from 'connect-shared/User'
import {
  Suspense,
  SuspenseList,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Link } from 'react-router-dom'
import { useScrollLimitQuery } from '../../chat/useScrollLimitQuery'
import { useColors } from '../../Colors'
import { useOrganizationDoc } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import useUser from '../../hooks/useUser'
import { Container, ContainerProps } from '../../layout/Layout'
import Spinner from '../../Spinner'
import StatusAvatar from '../../StatusAvatar'

type FeedPostLikedByUserProps = {
  userId: string
}

function FeedPostLikedByUser({ userId }: FeedPostLikedByUserProps) {
  const colors = useColors()
  const user = useUser(userId)

  if (!user) {
    console.error(`User with id: ${userId} not found`)
    return null
  }

  return (
    <Link
      to={`/users/${user.id}`}
      style={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 17,
      }}
    >
      <StatusAvatar userId={userId} size={50} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          overflow: 'hidden',
          paddingLeft: 10,
        }}
      >
        <div
          style={{
            textAlign: 'left',
            lineHeight: '19px',
            fontSize: 14,
            color: colors.text,
          }}
        >
          {userFullName(user)}
        </div>
        <div
          style={{
            lineHeight: '14px',
            fontSize: 10,
            color: colors.label,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {user.jobTitle}
        </div>
      </div>
    </Link>
  )
}

type FeedPostLikedByProps = {
  feedId: string
  postId: string
} & ContainerProps

export default function FeedPostLikedBy({
  feedId,
  postId,
  style,
  ...rest
}: FeedPostLikedByProps) {
  const colors = useColors()
  const t = useTranslate()

  const organizationRef = useOrganizationDoc()

  const postRef = useMemo(() => {
    return organizationRef
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
  }, [feedId, organizationRef, postId])

  const usersQuery = useMemo(
    () => postRef.collection('users').where('likes', '>', 0),
    [postRef]
  )

  const post = useFirestoreDoc(postRef)
  useFirestoreCollection(usersQuery.limit(10))

  const {
    snap: { docs: snaps },
    hasMore,
    loadMore,
  } = useScrollLimitQuery(usersQuery, { initialLimit: 10 })

  const divRef = useRef<HTMLDivElement>(null)
  const handleScroll = useCallback(() => {
    const div = divRef.current
    if (!div) return
    const distanceToBottom = div.scrollHeight - div.scrollTop - div.clientHeight
    if (distanceToBottom < 50) {
      loadMore()
    }
  }, [loadMore])

  useEffect(() => {
    if (snaps.length > 0) {
      handleScroll()
    }
  }, [handleScroll, snaps.length])

  return (
    <Container
      style={{
        width: '100%',
        height: 500,
        backgroundColor: colors.background,
        overflowY: 'auto',
        ...style,
      }}
      divRef={divRef}
      onScroll={handleScroll}
      {...rest}
    >
      <Container style={{ marginTop: 30, marginLeft: 30, marginRight: 30 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
          }}
        >
          <Container
            style={{
              color: '#222',
              fontSize: 20,
              flex: '1 0 0',
              width: '100%',
              fontWeight: 600,
            }}
          >
            {t('Liked by')}
          </Container>
          <Container
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: colors.label,
              paddingRight: 30,
            }}
          >
            {post.read().data()?.likeCount || 0}
          </Container>
        </div>
        <SuspenseList revealOrder={'together'}>
          {snaps.map((snap) => {
            return (
              <Suspense fallback={null} key={snap.id}>
                <FeedPostLikedByUser userId={snap.id} />
              </Suspense>
            )
          })}
        </SuspenseList>
        {hasMore && <Spinner />}
      </Container>
    </Container>
  )
}
