import Spinner from './Spinner'

export default function FullscreenSpinner() {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner size={50} />
    </div>
  )
}
