import Styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import {
  CSSProperties,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useSubscriptions } from '../hooks/feeds'
import { useDateFns, useTranslate } from '../hooks/Internationalization'
import useCurrentUser from '../hooks/useCurrentUser'
import HoverableContainer from '../HoverableContainer'
import { Container, Spacer } from '../layout/Layout'
import OutlineButton from '../OutlineButton'
import RoundAvatar from '../RoundAvatar'
import { BodyText } from '../Text'
import { showErrorToast, showSuccessToast } from '../Toast'

const ClickableHoverText = Styled(ClickableOpacity)`
  color: #828282;
  &:hover{
    color: #4B9FCC;
  }
`

function OpenCloseButton({
  open,
  toggleOpen,
  style,
}: {
  open: boolean
  toggleOpen: () => void
  style?: CSSProperties
}) {
  const t = useTranslate()
  const handleToggleOpen = useCallback(
    (e) => {
      e.stopPropagation()
      toggleOpen()
    },
    [toggleOpen]
  )
  return (
    <Container style={{ alignSelf: 'start', ...style }}>
      <ClickableHoverText
        style={{ fontSize: 14, fontWeight: 600 }}
        onClick={handleToggleOpen}
      >
        {open ? t('Less info') : t('More info')}
      </ClickableHoverText>
    </Container>
  )
}

type DiscoverFeedsItemProps = {
  feedSnap: firebase.firestore.QueryDocumentSnapshot<FeedDocument>
  style?: CSSProperties
  onFeedAdded: (id: string) => void
}

export default function DiscoverFeedsItem({
  feedSnap,
  style,
  onFeedAdded: onFeedAdd,
}: DiscoverFeedsItemProps) {
  const subscriptions = useSubscriptions()

  const [open, toggleOpen] = useReducer((open) => !open, false)
  const [loading, setLoading] = useState(false)
  const colors = useColors()
  const t = useTranslate()

  const feed = feedSnap.data()
  const feedRef = useOrganizationDoc().collection('feeds').doc(feedSnap.id)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )

  const history = useHistory()

  const navigateToFeed = useCallback(() => {
    history.push(`/feeds/${feedSnap.id}`)
  }, [history, feedSnap.id])

  const currentUserId = useCurrentUserId()

  const followFeed = useCallback(async () => {
    onFeedAdd(feedSnap.id)
    setLoading(true)
    const subscribe = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('addCombinedFeedSubscriptions')
    await subscribe({
      combinedFeedIds: [currentUserId],
      feedIds: [feedSnap.id],
    }).catch((error) => {
      showErrorToast('Something went wrong, please try again later')
      setLoading(false)
      throw error
    })
    setLoading(false)
    showSuccessToast(`You now follow ${feed.name} `)
  }, [currentUserId, feed.name, feedSnap.id, onFeedAdd])

  const latestPostRef = useOrganizationDoc()
    .collection('feeds')
    .doc(feedSnap.id)
    .collection('posts')
    .orderBy('createdDate', 'desc')
    .limit(1)

  const latestPostResource = useFirestoreCollection(latestPostRef)

  const latestPost = latestPostResource.read().docs[0]

  const { formatDistance } = useDateFns()
  const latestPostCreatedDate: string | undefined = useMemo(() => {
    if (latestPost) {
      return t('Posted %{dateTime} ago', {
        dateTime: formatDistance(
          new Date(),
          latestPost.data().createdDate.toDate()
        ),
      })
    }
  }, [formatDistance, latestPost, t])

  const memberCountText = followers.read().data()?.ids.length || 0
  const postCountText = feedSnap.data().postCount || 0

  const statisticsText = useMemo(() => {
    if (latestPostCreatedDate) {
      return t(
        '%{memberCount} Followers • %{postCount} Posts • %{latestPostCreatedDate}',
        {
          memberCount: memberCountText,
          postCount: postCountText,
          latestPostCreatedDate,
        }
      )
    } else {
      return t('%{memberCount} Followers • %{postCount} Posts', {
        memberCount: memberCountText,
        postCount: postCountText,
      })
    }
  }, [latestPostCreatedDate, memberCountText, postCountText, t])

  const organizationDoc = useOrganizationDoc()
  const isSubscribed = subscriptions.read().has(feedSnap.id)

  const currentUser = useCurrentUser()

  const isAdmin =
    feedSnap.data()?.admins?.[currentUserId] === true ||
    currentUser?.organizationAdmin ||
    false

  const removeFromFeed = useCallback(async () => {
    setLoading(true)
    if (isAdmin) {
      await feedSnap.ref.update(
        ['admins', currentUserId].join('.'),
        firebase.firestore.FieldValue.delete()
      )
    }

    await organizationDoc
      .collection('combinedFeeds')
      .doc(currentUserId)
      .update(
        ['subscriptions', feedSnap.id].join('.'),
        firebase.firestore.FieldValue.delete()
      )
      .catch((e) => {
        showErrorToast(t('Something went wrong, please try again later'))
        setLoading(false)
        throw e
      })
    showSuccessToast(t('You have successfully been removed from the feed'))
    setLoading(false)
  }, [currentUserId, feedSnap.id, feedSnap.ref, isAdmin, organizationDoc, t])

  return (
    <HoverableContainer
      backgroundColor={colors.foreground}
      hoverColor={colors.selected}
      vAlign="center"
      flex={1}
      style={{
        display: 'flex',
        padding: 20,
        width: '100%',
        borderRadius: 5,
        boxShadow: `0 1px 3px ${colors.announcementShadow}`,
        ...style,
      }}
    >
      <Container horizontal>
        <ClickableOpacity onClick={navigateToFeed}>
          <RoundAvatar size={50} avatar={feed.avatar} name={feed.name} />
        </ClickableOpacity>
        <Spacer size={16} />
        <Container
          vAlign="center"
          flex={1}
          style={{
            display: 'flex',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Container>
            <ClickableOpacity
              style={{
                display: 'flex',
              }}
              onClick={navigateToFeed}
            >
              <BodyText
                style={{
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {feed.name}
              </BodyText>
            </ClickableOpacity>
            <BodyText style={{ color: colors.labelStrong }}>
              {statisticsText}
            </BodyText>

            {feed.description && (
              <OpenCloseButton open={open} toggleOpen={toggleOpen} />
            )}
          </Container>
        </Container>
        <Container vAlign="center">
          {isSubscribed ? (
            <OutlineButton danger disabled={loading} onClick={removeFromFeed}>
              {t('Unfollow feed')}
            </OutlineButton>
          ) : (
            <OutlineButton disabled={loading} onClick={followFeed}>
              {t('Follow feed')}
            </OutlineButton>
          )}
        </Container>
      </Container>
      {open && (
        <Container hAlign="left" style={{ marginTop: 20 }}>
          <BodyText>{feed.description}</BodyText>
        </Container>
      )}
    </HoverableContainer>
  )
}
