import styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import 'firebase/auth'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { CommentDocument } from 'connect-shared/types'
import { useColors } from '../../Colors'
import { firestore } from '../../firestore'
import { useCurrentUserId } from '../../hooks/auth'
import { Resource } from '../../resource'
import { ReactComponent as LikeIcon } from '../../icons/ico-like.svg'

function useLikeCount(
  commentSnap: QueryDocumentSnapshot<CommentDocument>
): Resource<number> {
  const commentRef = firestore().doc(commentSnap.ref.path)
  const commentUsersRef = commentRef.collection('users')

  const commentUsersRes = useFirestoreCollection(commentUsersRef)

  return {
    read: () =>
      commentUsersRes
        .read()
        .docs.reduce((acc, val) => acc + val.data().likes, 0),
  }
}

const StyledCommentLikeCounter = styled.div`
  font-size: 12px;
  user-select: none;
  svg {
    margin-top: 2px;
  }
`

type CommentLikeCounterProps = {
  commentSnap: QueryDocumentSnapshot<CommentDocument>
}

export default function CommentLikeCounter({
  commentSnap,
}: CommentLikeCounterProps) {
  const currentUserId = useCurrentUserId()
  const commentRef = firestore().doc(commentSnap.ref.path)
  const commentUsersRef = commentRef.collection('users')
  const likeCountResource = useLikeCount(commentSnap)

  const energized =
    (useFirestoreDoc(commentUsersRef.doc(currentUserId)).read().data()?.likes ||
      0) > 0

  const energizeComment = () => {
    const likes = energized ? 0 : 1
    commentSnap.ref
      .collection('users')
      .doc(currentUserId)
      .set({ likes }, { merge: true })
  }

  return (
    <StyledCommentLikeCounter>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginRight: '5px',
            marginLeft: '5px',
            marginTop: '0px',
            color: useColors().text,
          }}
        >
          {likeCountResource.read()}
        </div>
        <div onClick={energizeComment} style={{ cursor: 'pointer' }}>
          <LikeIcon width={16} height={16} filled={energized} />
        </div>
      </div>
    </StyledCommentLikeCounter>
  )
}
