import {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import BlockButton from './BlockButton'
import { useTranslate } from './hooks/Internationalization'
import { Container, Spacer } from './layout/Layout'
import { BodyText, CaptionText, NoteText } from './Text'

type ImageUploadFieldProps = {
  label?: string
  accept?: string
  subLabel?: string
  value?: File | Blob | string
  onChange?: (file: File | undefined) => void
  style?: CSSProperties
  imgStyle?: CSSProperties
  error?: string
  placerholder?: ReactNode
  disabled?: boolean
}

export default function ImageUploadField({
  disabled,
  style,
  error,
  label,
  subLabel,
  accept,
  value,
  onChange,
  imgStyle,
  placerholder,
  ...containerProps
}: ImageUploadFieldProps) {
  const t = useTranslate()
  const url = useMemo(() => {
    if (!value) {
      return
    }

    if (typeof value === 'string') {
      return value
    }

    return URL.createObjectURL(value)
  }, [value])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files ? e.target.files[0] : undefined
      onChange?.(file)
    },
    [onChange]
  )

  // TODO make it look nice
  return (
    <Container
      style={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'initial',
        position: 'relative',
        ...style,
      }}
      {...containerProps}
    >
      {label && (
        <>
          <CaptionText>{label}</CaptionText>
          <Spacer size={10} />
        </>
      )}
      {subLabel && (
        <>
          <BodyText>{subLabel}</BodyText>
          <Spacer size={10} />
        </>
      )}
      <label style={{ alignSelf: 'flex-start' }}>
        <input
          style={{
            position: 'absolute',
            top: -9999,
            opacity: 0,
          }}
          onChange={handleChange}
          type="file"
          value=""
          accept={accept || 'image/*'}
          onClick={useCallback((e) => {
            if (e.taget) {
              e.taget.value = null
            }
          }, [])}
        />
        <BlockButton style={{ pointerEvents: 'none' }}>
          {t('Choose File')}
        </BlockButton>
      </label>
      {error && (
        <>
          <Spacer size={10} />
          <NoteText danger>{error}</NoteText>
        </>
      )}
      <Spacer size={10} />
      {url ? <img src={url} style={imgStyle} /> : placerholder}
    </Container>
  )
}
