import { CSSProperties, HTMLAttributes, SVGProps, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslate } from './hooks/Internationalization'
import IconHoverWrapper from './IconHoverWrapper'
import { ReactComponent as ArrowIcon } from './icons/ico-chevron-left.svg'
import InlineButton from './InlineButton'

export function BackIcon({ style, ...props }: SVGProps<SVGSVGElement>) {
  return <ArrowIcon style={{ ...style }} {...props} />
}

type InlineBackButtonProps = {
  flex?: CSSProperties['flex']
  text?: boolean
  big?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function InlineBackButton({
  flex = '0 0 auto',
  style,
  text,
  big,
  ...divProps
}: InlineBackButtonProps) {
  const t = useTranslate()
  const history = useHistory()
  const handleClick = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <IconHoverWrapper>
      <InlineButton
        big={big}
        onClick={handleClick}
        flex={flex}
        text={text ? t('Back') : undefined}
        Icon={BackIcon}
        style={{
          flex,
          ...style,
        }}
        {...divProps}
      />
    </IconHoverWrapper>
  )
}
