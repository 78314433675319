import { CSSProperties, HTMLAttributes } from 'react'
import CopyButton from './CopyButton'
import { Container, Spacer } from './layout/Layout'
import { CaptionText } from './Text'

type CopyRowProps = {
  flex?: CSSProperties['flex']
  /** Value to copy to clipboard */
  value: string
  /** Text to display as label */
  label: string
} & HTMLAttributes<HTMLDivElement>

export default function CopyRow({
  flex = '0 0 auto',
  style,
  value,
  label,
  ...divProps
}: CopyRowProps) {
  return (
    <Container
      horizontal
      vAlign="center"
      title={value}
      style={{
        flex,
        ...style,
      }}
      {...divProps}
    >
      <CopyButton content={value} />
      <Spacer size={35} />
      <CaptionText strong>{label}</CaptionText>
      <Spacer size={7} />
      <CaptionText
        flex="0 1 auto"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </CaptionText>
    </Container>
  )
}
