import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import 'firebase/auth'
import { CSSProperties, ReactElement, Suspense, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ClickableOpacity, { ClickableOpacityProps } from './ClickableOpacity'
import { useColors } from './Colors'
import IconHoverWrapper from './IconHoverWrapper'
import SidebarAvatar from './dashboardWidgets/sidebarAvatar/SidebarAvatar'
import { useTranslate } from './hooks/Internationalization'
import { useCurrentUserId, useOrganizationDoc } from './hooks/auth'
import useCurrentUser from './hooks/useCurrentUser'
import useFeatureFlag from './hooks/useFeatureFlag'
import { useIsWorkplaceAdmin } from './hooks/useIsWorkplaceAdmin'
import useOrganizationTheme from './hooks/useOrganizationColors'
import { useUserlimit } from './hooks/useUserlimit'
import { ReactComponent as ChatIcon } from './icons/ico-chat.svg'
import { ReactComponent as ContactsIcon } from './icons/ico-contacts.svg'
import { ReactComponent as EventsIcon } from './icons/ico-event.svg'
import { ReactComponent as FeedsIcon } from './icons/ico-feeds.svg'
import { ReactComponent as HomeIcon } from './icons/ico-home.svg'
import { ReactComponent as SettingsIcon } from './icons/ico-settings.svg'
import { ReactComponent as WikiIcon } from './icons/ico-wiki.svg'
import { Pusher } from './layout/Layout'
import { navigationBorder, navigationHeight, navigationPadding } from './style'

type NavigationButtonProps = {
  Icon?: any
  IconComponent?: (props: {
    active: boolean
    fill: string
    style: { height: number; width: number }
  }) => ReactElement
  unreadIndicator?: boolean
  text?: string
  to: string
  onClick?: () => void
} & ClickableOpacityProps

function NavigationButton({
  Icon,
  IconComponent,
  text,
  to,
  onClick,
  unreadIndicator,
  ...props
}: NavigationButtonProps) {
  const { pathname } = useLocation()
  const active = pathname.startsWith(to)
  const colors = useColors()

  const [isHovered, setIsHovered] = useState(false)
  return (
    <IconHoverWrapper>
      <ClickableOpacity
        hover
        hoverColor={colors.backgroundHighlight}
        {...props}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link
          to={to}
          onClick={onClick}
          style={{
            height: 66,
            width: 76,
            padding: 10,
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontWeight: 600,
            position: 'relative',
          }}
        >
          {Icon && (
            <div>
              <Icon
                filled={active || isHovered}
                style={{ height: 28, width: 28 }}
                fill={active ? colors.text : colors.beeworkBlue}
              />
            </div>
          )}
          {IconComponent && (
            <IconComponent
              style={{ height: 28, width: 28 }}
              fill={active ? colors.text : colors.beeworkBlue}
              active={active}
            />
          )}
          {unreadIndicator && <UnreadIndicator style={{ top: 6, right: 18 }} />}
          <span
            style={{
              marginTop: 4,
              fontSize: 10,
              color: active ? colors.text : colors.label,
            }}
          >
            {text}
          </span>
        </Link>
      </ClickableOpacity>
    </IconHoverWrapper>
  )
}

const StyledNav = styled.nav`
  ::-webkit-scrollbar {
    display: none;
  }
`

function UnreadIndicator({ style }: { style?: CSSProperties }) {
  const dotSize = 10
  const colors = useColors()

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: -dotSize / 2,
        width: dotSize,
        height: dotSize,
        borderRadius: dotSize / 2,
        backgroundColor: colors.danger,
        ...style,
      }}
    />
  )
}

function ChatBadge() {
  const query = useOrganizationDoc()
    .collection('userRooms')
    .where('userId', '==', useCurrentUserId())
    .where('important', '==', true)
    .limit(1)

  const snap = useFirestoreCollection(query).read()

  if (snap.size === 0) return null

  return <UnreadIndicator />
}

function Chat(props: {
  active: boolean
  fill: string
  style: { height: number; width: number }
  hovered?: boolean
}) {
  return (
    <div
      style={{
        position: 'relative',
        height: 31,
        width: 28,
      }}
    >
      <ChatIcon
        width={28}
        height={28}
        filled={props.active || props.hovered}
        fill={props.fill}
        style={props.style}
      />
      <Suspense fallback={null}>
        <ChatBadge />
      </Suspense>
    </div>
  )
}

function FullNavigation() {
  const organizationDoc = useOrganizationDoc()
  const orgResource = useFirestoreDoc(organizationDoc)
  const chatEnabled = orgResource.read().data()?.chatEnabled ?? true

  const wikiEnabled = useFeatureFlag('wiki', false)
  const legacyEventsEnabled = useFeatureFlag('legacyEvents', false)
  const currentUser = useCurrentUser()
  const theSplittening = useFeatureFlag('theSplittening', false)
  const peopleEnabled = useFeatureFlag('peopleEnabled', true)

  const t = useTranslate()
  const isWorkplaceAdmin = useIsWorkplaceAdmin()

  const combinedFeedDoc = organizationDoc
    .collection('combinedFeeds')
    .doc(useCurrentUserId())
  const hasUnreadPost = !!useFirestoreDoc(combinedFeedDoc).read().data()
    ?.firstUnseenPost

  const [chatHovered, setChatHovered] = useState(false)

  return (
    <>
      <NavigationButton
        Icon={HomeIcon}
        text={t('Home')}
        to="/home"
        id="navigate_home"
      />
      {chatEnabled && (
        <div
          onMouseEnter={() => setChatHovered(true)}
          onMouseLeave={() => setChatHovered(false)}
        >
          <NavigationButton
            IconComponent={(props) => <Chat hovered={chatHovered} {...props} />}
            text={t('Chat')}
            to="/chat"
          />
        </div>
      )}
      {legacyEventsEnabled && (
        <NavigationButton
          Icon={EventsIcon}
          text={t('Events')}
          to="/events"
          id="navigate_events"
        />
      )}
      <NavigationButton
        unreadIndicator={hasUnreadPost}
        Icon={FeedsIcon}
        text={t('Feeds')}
        to="/feeds"
        id="navigate_feeds"
      />
      {wikiEnabled && (
        <NavigationButton
          Icon={WikiIcon}
          text={t('Pages')}
          to="/wiki"
          id="navigate_wiki"
        />
      )}
      {peopleEnabled && (
        <NavigationButton
          Icon={ContactsIcon}
          text={t('People')}
          to="/people"
          id="navigate_people"
        />
      )}
      {theSplittening &&
        (currentUser?.organizationAdmin || isWorkplaceAdmin) && (
          <>
            <NavigationButton
              Icon={SettingsIcon}
              text={t('Settings')}
              to="/settings"
              id="navigate_settings"
            />
          </>
        )}
    </>
  )
}

function LockedOutNavigation() {
  const theSplittening = useFeatureFlag('theSplittening', false)
  const currentUser = useCurrentUser()
  const isWorkplaceAdmin = useIsWorkplaceAdmin()
  const { exceeded } = useUserlimit()
  const t = useTranslate()
  if (
    theSplittening &&
    (currentUser?.organizationAdmin || (isWorkplaceAdmin && !exceeded))
  ) {
    return (
      <NavigationButton
        Icon={SettingsIcon}
        text={t('Settings')}
        to="/settings"
        id="navigate_settings"
      />
    )
  } else {
    return null
  }
}

export default function Navigation() {
  const { primary, logo } = useOrganizationTheme()
  const colors = useColors()
  const { exceeded } = useUserlimit()
  return (
    <StyledNav
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
        boxSizing: 'content-box',
        backgroundColor: colors.foreground,
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        paddingTop: navigationPadding,
        borderBottomWidth: navigationBorder,
        borderBottomStyle: 'solid',
        borderBottomColor: primary,
        alignItems: 'center',
        height: navigationHeight,
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 3,
      }}
      className="navigation-bar"
    >
      <div
        style={{ flex: '1 0 0px' }}
        css={css`
          display: none !important;
          background-color: #f0f !important;
          margin: 100px;
          padding: 100px;
        `}
      >
        {logo && (
          <Link to={'/home'}>
            <img alt="logo" src={logo} style={{ marginLeft: 30, height: 30 }} />
          </Link>
        )}
      </div>
      {exceeded ? <LockedOutNavigation /> : <FullNavigation />}
      <div style={{ display: 'flex', flex: '1 0 0px' }}>
        <Pusher />
        <SidebarAvatar />
        <div style={{ width: 25 }} />
      </div>
    </StyledNav>
  )
}
