import { useFirestoreDoc } from '@iwikal/reactfire'
import { projectDomain } from 'connect-shared/ci'
import { colors } from 'connect-shared/constants'
import {
  isSamlProviderId,
  isSamlProviderMeta,
  SamlProviderId,
  SamlProviderMeta,
} from 'connect-shared/types'
import firebase from 'firebase/app'
import produce from 'immer'
import useIsMounted from 'ismounted'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import BlockButton from '../BlockButton'
import Card from '../Card'
import {
  createSsoProvider,
  deleteSsoProvider,
  getSsoProvider,
  updateSsoProvider,
} from '../cloud-functions'
import CopyRow from '../CopyRow'
import FileDropzone from '../FileDropzone'
import { useOrganizationDoc } from '../hooks/auth'
import { Container, Spacer } from '../layout/Layout'
import PageLayoutLegacy from '../layout/PageLayoutLegacy'
import PlayippSpinner from '../playippSpinner/PlayippSpinner'
import { BodyText, CaptionText } from '../Text'
import TextareaInput from '../TextareaInput'
import TextInput from '../TextInput'
import { isMobile } from 'react-device-detect'
import { useTranslate } from '../hooks/Internationalization'

function wrapCertificate(certificate: string) {
  if (!certificate) return ''
  const head = '-----BEGIN CERTIFICATE-----'
  const tail = '-----END CERTIFICATE-----'
  if (certificate.startsWith(head)) {
    return certificate
  } else {
    return head + '\n' + certificate + '\n' + tail
  }
}

type SamlXmlImportProps = {
  onImport: Dispatch<SetStateAction<SamlProviderMeta>>
}

function SamlXmlImport({ onImport }: SamlXmlImportProps) {
  const handleFiles = useCallback(
    async (files: FileList | null) => {
      const file = files?.[0]
      if (!file) return
      const xml = await file.text()
      const tree = new DOMParser().parseFromString(xml, 'application/xml')
      const entityDescriptor = tree.querySelector('EntityDescriptor')
      const entityId = entityDescriptor?.getAttribute('entityID') || ''
      const rawCertificate =
        entityDescriptor?.querySelector('X509Certificate')?.innerHTML || ''
      const certificate = wrapCertificate(rawCertificate)

      const url =
        entityDescriptor
          ?.querySelector('SingleSignOnService')
          ?.getAttribute('Location') || ''

      onImport(
        produce((value) => {
          value.entityId = entityId
          value.certificate = certificate
          value.url = url
        })
      )
    },
    [onImport]
  )

  return (
    <div>
      <FileDropzone onFiles={handleFiles} />
    </div>
  )
}

type SamlIdpStepProps = {
  value: SamlProviderMeta
  onChange: Dispatch<SetStateAction<SamlProviderMeta>>
}

function SamlIdpCard({ value, onChange }: SamlIdpStepProps) {
  const t = useTranslate()
  const handleImport = useCallback(
    (value: SetStateAction<SamlProviderMeta>) => {
      onChange(value)
    },
    [onChange]
  )

  return (
    <Card>
      <CaptionText strong>{t('Identity provider details')}</CaptionText>
      <Spacer size={10} />
      <SamlXmlImport onImport={handleImport} />
      <Spacer size={40} />
      <TextInput
        noErrorPlaceholder
        placeholder={t('SSO URL')}
        value={value.url}
        onChangeText={useCallback(
          (text) => {
            onChange(
              produce((value) => {
                value.url = text
              })
            )
          },
          [onChange]
        )}
      />
      <Spacer size={40} />
      <TextInput
        noErrorPlaceholder
        placeholder={t('Entity ID')}
        value={value.entityId}
        onChangeText={useCallback(
          (text) => {
            onChange(
              produce((value) => {
                value.entityId = text
              })
            )
          },
          [onChange]
        )}
      />
      <Spacer size={35} />
      <TextareaInput
        label={t('Certificate')}
        placeholder={t(
          '-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----'
        )}
        onChangeText={useCallback(
          (text) => {
            onChange(
              produce((value) => {
                value.certificate = text
              })
            )
          },
          [onChange]
        )}
        value={value.certificate}
      />
    </Card>
  )
}

function SamlSpCard() {
  const t = useTranslate()
  return (
    <Card>
      <CaptionText strong>{t('Service provider details')}</CaptionText>
      <Spacer size={38} />
      <CopyRow
        label={t('ACS URL')}
        value={`https://${projectDomain(projectId)}/__/auth/handler`}
      />
      <Spacer size={26} />
      <CopyRow label={t('Entity ID')} value="playipp-connect" />
    </Card>
  )
}

const projectId = (firebase.app().options as any).projectId as string

type SamlSaveStepProps = {
  providerMeta: SamlProviderMeta
  onRequestConfigRefresh: () => void
}

function SamlSaveCard({
  providerMeta,
  onRequestConfigRefresh,
}: SamlSaveStepProps) {
  const t = useTranslate()
  const organizationDoc = useOrganizationDoc()
  const [providerIdName, setProviderIdName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const handleClick = useCallback(async () => {
    setSubmitting(true)
    try {
      const providerId = 'saml.' + providerIdName
      if (isSamlProviderId(providerId)) {
        await createSsoProvider({ providerId, providerMeta })
        onRequestConfigRefresh()
      } else {
        throw new Error('Invalid config provided')
      }
    } catch (err) {
      console.error(err)
    }
    setSubmitting(false)
  }, [onRequestConfigRefresh, providerIdName, providerMeta])

  const handleChangeText = useCallback((text) => {
    setProviderIdName(text.toLowerCase().replace(/[^\d[a-z]_-]/g))
  }, [])
  const organizationName =
    useFirestoreDoc(organizationDoc).read().data()?.name || ''
  useEffect(() => {
    handleChangeText(organizationName.replace(/ /g, '-'))
  }, [handleChangeText, organizationName])

  return (
    <Card>
      <CaptionText strong>{t('Sign in ID')}</CaptionText>
      <Spacer size={20} />
      <BodyText>
        <strong>{t('The sign in ID cannot be edited after saving!')}</strong>
      </BodyText>
      <Spacer size={10} />
      <TextInput value={providerIdName} onChangeText={handleChangeText} />
      <Spacer size={20} />
      <BodyText>
        {t(
          'Users in your organization will need the ID to sign in. They can manually input the ID, or visit the sign in URL directly.'
        )}
      </BodyText>
      <Spacer size={20} />
      <CopyRow label={t('Sign in ID')} value={'saml.' + providerIdName} />
      <Spacer size={20} />
      <CopyRow
        label={t('Sign in URL')}
        value={window.location.origin + '/sso/saml.' + providerIdName}
      />
      <Spacer size={20} />
      <BlockButton
        style={{
          width: isMobile ? 320 : 142,
        }}
        disabled={submitting}
        onClick={handleClick}
      >
        {t('Save')}
      </BlockButton>
    </Card>
  )
}

type SamlCreateProps = { onRequestConfigRefresh: () => void }
function SamlCreate({ onRequestConfigRefresh }: SamlCreateProps) {
  const [providerMeta, setProviderMeta] = useState<SamlProviderMeta>({
    type: 'saml',
    certificate: '',
    entityId: '',
    url: '',
  })

  return (
    <Container flex={1}>
      <SamlIdpCard value={providerMeta} onChange={setProviderMeta} />
      <Spacer size={20} />
      <SamlSpCard />
      <Spacer size={20} />
      <AttributeMappingCard />
      <Spacer size={20} />
      <SamlSaveCard
        providerMeta={providerMeta}
        onRequestConfigRefresh={onRequestConfigRefresh}
      />
    </Container>
  )
}

type AttributeMappingProps = {
  attributeKey: string
  description: string
  required?: boolean
}

function AttributeMapping({
  attributeKey,
  description,
  required,
}: AttributeMappingProps) {
  const t = useTranslate()
  return (
    <Container horizontal>
      <CaptionText strong>{attributeKey}</CaptionText>
      <CaptionText strong>:</CaptionText>
      <Spacer size={10} />
      <CaptionText>{description}</CaptionText>
      {required && (
        <>
          <Spacer size={5} />
          <CaptionText strong>{t('(required)')}</CaptionText>
        </>
      )}
    </Container>
  )
}

function AttributeMappingCard() {
  const t = useTranslate()
  return (
    <Card>
      <CaptionText strong>{t('Attribute mapping')}</CaptionText>
      <Spacer size={10} />
      <BodyText>
        {t(
          'Configure your IDP to include the following attributes to automatically populate the user profile when signing in the first time.'
        )}
      </BodyText>
      <Spacer size={10} />
      <AttributeMapping
        attributeKey={'email'}
        description={t('E-mail')}
        required
      />
      <Spacer size={5} />
      <AttributeMapping
        attributeKey={'given_name'}
        description={t('Given name')}
      />
      <Spacer size={5} />
      <AttributeMapping
        attributeKey={'family_name'}
        description={t('Family name')}
      />
      <Spacer size={5} />
      <AttributeMapping attributeKey={'phone'} description={t('Phone no.')} />
      <Spacer size={5} />
      <AttributeMapping attributeKey={'title'} description={t('Title')} />
      <Spacer size={5} />
      <AttributeMapping attributeKey={'avatar'} description={t('Avatar URL')} />
    </Card>
  )
}

type SamlUpdateProps = {
  currentMeta: SamlProviderMeta
  providerId: SamlProviderId
  onRequestConfigRefresh: () => void
}
function SamlEdit({
  currentMeta,
  providerId,
  onRequestConfigRefresh,
}: SamlUpdateProps) {
  const t = useTranslate()
  const [meta, setMeta] = useState(currentMeta)

  const saveProvider = useCallback(async () => {
    await updateSsoProvider({
      providerId,
      providerMeta: meta,
    })
  }, [meta, providerId])

  const [deleteConfirmText, setDeleteConfirmText] = useState('')
  const deleteConfirmed = deleteConfirmText === providerId

  const deleteProvider = useCallback(async () => {
    if (!confirm(t('Are you sure? Current users may be lost!'))) return
    if (!deleteConfirmed) return
    await deleteSsoProvider(providerId)
    onRequestConfigRefresh()
  }, [t, deleteConfirmed, providerId, onRequestConfigRefresh])

  return (
    <Container flex={1}>
      <SamlIdpCard value={meta} onChange={setMeta} />
      <Spacer size={20} />
      <SamlSpCard />
      <Spacer size={20} />
      <AttributeMappingCard />
      <Spacer size={20} />
      <Card>
        <CopyRow label={t('Sign in ID')} value={providerId} />
        <Spacer size={20} />
        <CopyRow
          label={t('Sign in URL')}
          value={window.location.origin + '/sso/' + providerId}
        />
        <Spacer size={20} />
        <BlockButton onClick={saveProvider}>{t('Save')}</BlockButton>
      </Card>
      <Spacer size={20} />
      <Card style={{ border: `2px dashed ${colors.danger}` }}>
        <CaptionText strong>
          {t('This action is permanent and non-reversible')}
        </CaptionText>
        <Spacer size={20} />
        <CaptionText>
          {t(
            'SAML Users will not able to sign in after deleting the SAML configuration. Creating a new SAML configuration may not restore previous users.'
          )}
        </CaptionText>
        <Spacer size={20} />
        <TextInput
          placeholder={providerId}
          label={t('Confirm deletion by typing your sign in ID')}
          value={deleteConfirmText}
          onChangeText={setDeleteConfirmText}
        />
        <Spacer size={20} />
        <BlockButton
          danger
          onClick={deleteProvider}
          disabled={!deleteConfirmed}
        >
          {t('Delete')}
        </BlockButton>
      </Card>
    </Container>
  )
}

function SamlConfig() {
  // TODO update on delete
  const [currentConfig, setCurrentConfig] = useState<
    | {
        providerId: SamlProviderId
        meta: SamlProviderMeta
      }
    | null
    | 'loading'
    | 'shouldFetch'
  >('shouldFetch')

  const isMounted = useIsMounted()

  useEffect(() => {
    async function effect() {
      if (currentConfig === 'shouldFetch') {
        setCurrentConfig('loading')
        const config = await getSsoProvider('saml')
        if (!isMounted.current) {
          return
        }
        if (config) {
          const { meta, providerId } = config

          if (!isSamlProviderMeta(meta) || !isSamlProviderId(providerId)) {
            throw new Error('Invalid provider config')
          }
          setCurrentConfig({
            meta,
            providerId,
          })
        } else {
          setCurrentConfig(null)
        }
      }
    }
    effect()
  }, [currentConfig, isMounted])

  const triggerConfigFetch = useCallback(() => {
    setCurrentConfig('shouldFetch')
  }, [])

  if (currentConfig === 'loading' || currentConfig === 'shouldFetch') {
    return <PlayippSpinner size="50px" />
  } else if (currentConfig === null) {
    return <SamlCreate onRequestConfigRefresh={triggerConfigFetch} />
  } else {
    return (
      <SamlEdit
        currentMeta={currentConfig.meta}
        providerId={currentConfig.providerId}
        onRequestConfigRefresh={triggerConfigFetch}
      />
    )
  }
}

export default function Sso() {
  const history = useHistory()
  const t = useTranslate()
  const goBack = useCallback(() => {
    history.push('/settings')
  }, [history])

  return (
    <PageLayoutLegacy
      wide
      text={t('SAML configuration')}
      backButton
      flex={1}
      backButtonOnClick={goBack}
    >
      <Container flex={1} hAlign="center">
        <SamlConfig />
      </Container>
    </PageLayoutLegacy>
  )
}
