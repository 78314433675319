import { TextField } from '@mui/material'
import { DocumentSnapshot } from 'connect-shared/firestore'
import { FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import { Crop } from 'react-image-crop'
import { useHistory } from 'react-router'
import uuid from 'uuid'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import { useOrganizationDoc } from '../../hooks/auth'
import { Container, Spacer } from '../../layout/Layout'
import OutlineButton from '../../OutlineButton'
import PlayippSpinner from '../../playippSpinner/PlayippSpinner'
import RoundAvatar from '../../RoundAvatar'
import { BodyText } from '../../Text'
import { showErrorToast, showSuccessToast } from '../../Toast'
import ToggleBlock from '../../ToggleBlock'
import { cropImage, fileToUpload, Upload } from '../../crop-utils'
import CropImageModal from '../CropImageModal'
import { css } from '@emotion/react'
import { useTranslate } from '../../hooks/Internationalization'

export default function FeedSettings({
  feedSnap,
}: {
  feedSnap: DocumentSnapshot<FeedDocument>
}) {
  const colors = useColors()
  const t = useTranslate()
  const feed = feedSnap.data()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [name, setName] = useState(feed?.name || '')
  const [description, setDescription] = useState(feed?.description || '')
  const [avatar, setAvatar] = useState<Upload | undefined>()
  const [tempAvatarUrl, setTempAvatarUrl] = useState<string | undefined>(
    feedSnap.data()?.avatar
  )

  const [inviteOnly, setInviteOnly] = useState(feed?.inviteOnly || false)
  const toggleInviteOnly = useCallback(() => {
    setInviteOnly(!inviteOnly)
  }, [inviteOnly])

  const [anyoneMayPost, setAnyoneMayPost] = useState(
    feed?.anyoneMayPost || false
  )
  const toggleAnyoneMayPost = useCallback(() => {
    setAnyoneMayPost((anyoneMayPost) => !anyoneMayPost)
  }, [])

  const [disableInteractions, setDisableInteractions] = useState(
    feed?.disableInteractions
  )
  const toggleDisableInteractivity = useCallback(() => {
    setDisableInteractions((disableInteractivity) => !disableInteractivity)
  }, [])

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const openDeleteModal = useCallback(() => {
    setDeleteModalOpen(true)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false)
  }, [])

  const [cropModalOpen, setCropModalOpen] = useState(false)

  const avatarSize = 200

  const changesMade = useMemo(() => {
    return (
      feed?.name !== name ||
      feed.description !== description ||
      feed.anyoneMayPost !== anyoneMayPost ||
      feed.inviteOnly !== inviteOnly ||
      avatar !== undefined ||
      feed?.disableInteractions !== disableInteractions
    )
  }, [
    anyoneMayPost,
    avatar,
    description,
    disableInteractions,
    feed?.anyoneMayPost,
    feed?.description,
    feed?.disableInteractions,
    feed?.inviteOnly,
    feed?.name,
    inviteOnly,
    name,
  ])

  const descriptionLengthLimit = 300

  const handleDescriptionChange = useCallback((value: string) => {
    if (value.length >= descriptionLengthLimit + 1) {
      return
    }
    setDescription(value)
  }, [])

  const inputRef = useRef<HTMLInputElement>(null)

  const onUploadPress = useCallback(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.click()
  }, [])

  const onImageSelect = useCallback(
    async (files: FileList | null) => {
      if (files?.length === 0) {
        return
      }
      if (files === null) {
        showErrorToast(t('Something went wrong, try again'))
        console.error('files is null')
        return
      }
      if (files.length > 1) {
        showErrorToast(t('Something went wrong, try again'))
        console.error(`files length is ${files.length}`)
        return
      }

      const avatar = files?.[0]

      const acceptedImages = ['jpg', 'jpeg', 'png']

      const isValidImage = acceptedImages.some((acceptedImage) => {
        const formatedImageType = `image/${acceptedImage}`
        return formatedImageType === avatar.type
      })

      if (!isValidImage) {
        console.error(`${avatar.type} is not a valid image type`)
        showErrorToast(t('This file is not supported'))
        return
      }

      if (avatar) {
        const upload = await fileToUpload(avatar)
        setAvatar(upload)
        setTempAvatarUrl(URL.createObjectURL(upload.file))
        setCropModalOpen(true)
      }
    },
    [t]
  )

  const organizationDoc = useOrganizationDoc()

  const restore = useCallback(() => {
    setName(feed?.name || '')
    setDescription(feed?.description || '')
    setInviteOnly(feed?.inviteOnly || false)
    setAnyoneMayPost(feed?.anyoneMayPost || false)
    setAvatar(undefined)
    setTempAvatarUrl(feed?.avatar)
  }, [
    feed?.anyoneMayPost,
    feed?.avatar,
    feed?.description,
    feed?.inviteOnly,
    feed?.name,
  ])

  const targetWidth = 300
  const targetHeight = 300
  const [crop, setCrop] = useState<Crop>({
    aspect: targetWidth / targetHeight,
  })

  const imageRef = useRef<HTMLImageElement>()

  const handleCropSubmit = useCallback(async () => {
    if (!imageRef.current || !avatar) return
    const blob = await cropImage(
      avatar,
      crop,
      imageRef.current,
      targetWidth,
      targetHeight
    )
    const upload = await fileToUpload(blob as File)
    setAvatar(upload)
    setTempAvatarUrl(URL.createObjectURL(upload.file))
    setCropModalOpen(false)
  }, [avatar, crop])

  const submit = useCallback(async () => {
    async function uploadAvatar(avatar: Upload) {
      if (!imageRef.current) {
        throw new Error('Missing imageRef')
      }

      const avatarRef = firebase
        .storage()
        .ref(
          `${organizationDoc.path}/feeds/${feedSnap.id}/avatars/${uuid.v4()}`
        )

      const uploadTask = await avatarRef.put(avatar.file, {
        cacheControl: 'private, max-age=604800, immutable',
      })

      return uploadTask.ref.getDownloadURL()
    }

    setLoading(true)

    try {
      if (!name.trim()) {
        setError(true)
        return
      }
      setError(false)
      const updateData: FeedDocument = {
        inviteOnly,
        name,
        description,
        anyoneMayPost,
        disableInteractions: Boolean(disableInteractions),
      }

      if (avatar) {
        updateData.avatar = await uploadAvatar(avatar).catch((e) => {
          showErrorToast(t('Something went wrong, try again'))
          throw e
        })
      }

      await feedSnap.ref.update(updateData)
      showSuccessToast('Feed successfully updated!')
      setAvatar(undefined)
    } catch (err) {
      showErrorToast(t('Something went wrong, try again'))
      throw err
    } finally {
      setLoading(false)
    }
  }, [
    anyoneMayPost,
    avatar,
    description,
    disableInteractions,
    feedSnap.id,
    feedSnap.ref,
    inviteOnly,
    name,
    organizationDoc.path,
    t,
  ])

  const history = useHistory()

  const deleteFeed = useCallback(async () => {
    history.push('/feeds')
    await feedSnap.ref.delete().catch((e) => {
      showErrorToast(t('Something went wrong please try again later'))
      throw e
    })
    showSuccessToast(t('feed successfully deleted'))
  }, [feedSnap.ref, history, t])

  const renderAvatar = tempAvatarUrl ? (
    <Container>
      <RoundAvatar size={avatarSize} avatar={tempAvatarUrl} />
    </Container>
  ) : (
    <Suspense
      fallback={
        <div
          style={{
            backgroundColor: colors.version,
            height: 200,
            width: 200,
            borderRadius: avatarSize / 2,
          }}
        />
      }
    >
      <RoundAvatar size={avatarSize} name={name} />
    </Suspense>
  )

  const isWithinLimit = useMemo(() => {
    return description.length < descriptionLengthLimit
  }, [description.length])

  const submitDisabled = loading || name.trim() === ''
  return (
    <Container hAlign="right" style={{ marginTop: 30 }}>
      <Container
        horizontal
        vAlign="center"
        style={{
          width: '100%',
          boxShadow: `0 1px 3px ${colors.announcementShadow}`,
          borderRadius: changesMade ? '5px 5px 0 0' : 5,
          backgroundColor: colors.foreground,
          padding: 20,
        }}
      >
        <Container flex={1} hAlign="center">
          {renderAvatar} <Spacer size={20} />
          <OutlineButton onClick={onUploadPress}>
            {t('Upload new feed image')}
          </OutlineButton>
          {/*
          Invisible input that handles file input. 
          Had to do this due to inputs being a nightmare to style
          */}
          <input
            ref={inputRef}
            onChange={(e) => onImageSelect(e.target.files)}
            id="file-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            style={{
              display: 'none',
            }}
          />
        </Container>
        <Spacer size={20} />
        <Container flex={1}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={t('Feed name')}
            variant="outlined"
          />
          {error ? (
            <BodyText style={{ fontSize: 14, color: colors.danger }}>
              {t('The feed must have a name!')}
            </BodyText>
          ) : (
            <Spacer size={21} />
          )}
          <Spacer size={9} />
          <TextField
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            multiline
            label={t('Description')}
            rows={6}
            variant="outlined"
          />
          <BodyText
            style={{
              alignSelf: 'flex-end',
              fontSize: 14,
              color: isWithinLimit ? colors.labelDim : colors.warning,
            }}
          >{`${description?.length} / ${descriptionLengthLimit}`}</BodyText>
          <Spacer size={9} />
          <ToggleBlock
            value={inviteOnly}
            onChange={toggleInviteOnly}
            title={t('Invite only')}
            text={t('No one can start following your feed without an invite.')}
          />
          <Spacer size={10} />
          <ToggleBlock
            value={anyoneMayPost}
            onChange={toggleAnyoneMayPost}
            title={t('Anyone may post')}
            text={t(
              'Allow all followers to post to the feed, not just admins.'
            )}
          />
          <Spacer size={10} />
          <ToggleBlock
            value={disableInteractions}
            onChange={toggleDisableInteractivity}
            title={t('Disable interactivity')}
            text={t('Disable likes and comments for this feed.')}
          />
        </Container>
      </Container>
      {changesMade && (
        <Container
          hAlign="right"
          horizontal
          style={{
            backgroundColor: colors.foreground,
            width: '100%',
            padding: 20,
            borderTop: `1px solid ${colors.border}`,
            borderRadius: '0 0 5px 5px',
            boxShadow: `0 1px 3px ${colors.announcementShadow}`,
          }}
        >
          <ClickableOpacity
            css={css`
              color: ${colors.labelDim};
              &:hover {
                color: ${colors.cancelHover};
              }
            `}
            style={{ fontWeight: 600 }}
            onClick={restore}
          >
            {t('Cancel')}
          </ClickableOpacity>
          <Spacer size={20} />
          <OutlineButton
            disabled={submitDisabled}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 143,
            }}
            onClick={submit}
          >
            {loading ? (
              <PlayippSpinner color={colors.active} size={16} />
            ) : (
              t('Save settings')
            )}
          </OutlineButton>
        </Container>
      )}
      {!changesMade && (
        <>
          <Spacer size={20} />
          <OutlineButton
            onClick={openDeleteModal}
            style={{ display: 'inline-block' }}
            danger
          >
            {t('Delete feed')}
          </OutlineButton>
        </>
      )}
      <Spacer size={50} />
      {deleteModalOpen && (
        <ConfirmDeleteModal
          onRequestClose={closeDeleteModal}
          onConfirm={deleteFeed}
          title={t('Delete %{feed?.name}?', { feedName: feed?.name || '' })}
          text={t('Are you sure you want to delete %{feed?.name}?', {
            feedName: feed?.name || '',
          })}
        />
      )}
      {cropModalOpen && avatar && (
        <CropImageModal
          onSubmit={handleCropSubmit}
          crop={crop}
          setCrop={setCrop}
          imageRef={imageRef}
          avatar={avatar}
          onRequestClose={() => setCropModalOpen(false)}
        />
      )}
    </Container>
  )
}
