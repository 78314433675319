import { Checkbox, FormControlLabel, Input } from '@mui/material'
import firebase from 'firebase/app'
import { Poll, PollOption, PollResult } from 'connect-shared/types'
import produce, { Draft, nothing } from 'immer'
import {
  Dispatch,
  Reducer,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useColors } from '../Colors'
import IconButton from '../IconButton'
import { ReactComponent as RemoveIcon } from '../icons/ico-not-going.svg'
import { Container, Pusher } from '../layout/Layout'
import { Resource } from '../resource'
import { BodyText, CaptionText, NoteText } from '../Text'
import TextInput, { StyledInputWrapper } from '../TextInput'
import uuid from 'uuid'
import OutlineButton from '../OutlineButton'
import { useTranslate } from '../hooks/Internationalization'

type SetPoll = Dispatch<Recipe<Poll | undefined>>

type PollOptionBlockProps = {
  optionId: string
  disabled: boolean
  onTextChange: (text: string) => void
  option: PollOption
  onRemove: () => void
  index: number
  maxTitleLength: number
}

function PollOptionBlock({
  optionId,
  option,
  onRemove,
  onTextChange,
  disabled,
  index,
  maxTitleLength,
}: PollOptionBlockProps) {
  const [hovered, setHovered] = useState(false)
  const [isFreshOption, setIsFreshOption] = useState(true)
  const colors = useColors()

  const handleTextChange = useCallback(
    (text: string) => {
      setIsFreshOption(false)
      onTextChange(text)
    },
    [onTextChange]
  )
  return (
    <Container vAlign="center" key={optionId}>
      <StyledInputWrapper
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        focusColor={disabled ? 'none' : colors.active}
        borderColor={colors.border}
        size={16}
        style={{
          display: 'flex',
          marginBottom: 5,
          flex: 1,
          flexDirection: 'row',
          opacity: disabled ? 0.4 : 1,
          cursor: 'text',
          width: '100%',
          fontSize: 16,
          color: colors.labelDim,
          borderRadius: 16 / 3,
          borderWidth: 1,
          borderStyle: 'solid',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <TextInput
          containerStyle={{ border: 0 }}
          noErrorPlaceholder
          style={{ flex: 1 }}
          disabled={disabled}
          onChangeText={handleTextChange}
          value={option.title}
          placeholder={`Option ${index + 1}`}
        />
        {hovered && (
          <Container vAlign="center">
            <IconButton
              style={{
                marginRight: 20,
                cursor: disabled ? 'default' : 'pointer',
                opacity: disabled ? 0.4 : 1,
              }}
              fill={colors.danger}
              disabled={disabled}
              onClick={() => onRemove()}
              size={30}
              Icon={RemoveIcon}
            />
          </Container>
        )}
      </StyledInputWrapper>
      <NoteText
        style={{
          alignSelf: 'flex-end',
          marginBottom: 10,
          color:
            !option.title.trim() && !isFreshOption
              ? colors.warning
              : colors.labelDim,
        }}
      >
        {`${option.title.length} / ${maxTitleLength}`}
      </NoteText>
    </Container>
  )
}

interface PollEditorProps {
  setPoll: SetPoll
  poll: Poll
  initialPoll: Poll | undefined
  pollResultResource:
    | Resource<firebase.firestore.DocumentSnapshot<PollResult>>
    | undefined
}

function* pollOptions(poll: Poll): Generator<[string, PollOption]> {
  for (const optionId of poll.ordering) {
    const option = poll.options[optionId]
    if (!option) throw new Error('invalid poll')
    yield [optionId, option]
  }
}

export type Recipe<S> =
  | ((draft: Draft<S>) => void)
  | ((draft: Draft<S>) => S)
  | ((draft: Draft<S>) => typeof nothing)

export function useStateHistory<S>(
  initializer: (() => S) | S
): [S, Dispatch<Recipe<S>>, (newBase: S) => void] {
  type R = Reducer<
    { state: S; history: Recipe<S>[] },
    Recipe<S> | { newBase: S }
  >

  const [{ state }, dispatch] = useReducer<R, (() => S) | S>(
    (prev, action) => {
      if (typeof action === 'function') {
        const state = produce(prev.state, action as () => void)
        if (state === prev.state) return prev

        return { state, history: [...prev.history, action] }
      } else {
        let state = action.newBase
        for (const action of prev.history) {
          try {
            state = produce(state, action as () => void)
          } catch (e) {
            console.error('failed to rebase edit', e)
          }
        }
        return { state, history: prev.history }
      }
    },
    initializer,
    (initializer) => {
      const state =
        typeof initializer === 'function'
          ? (initializer as () => S)()
          : initializer
      return { state, history: [] }
    }
  )

  const rebase = useCallback((newBase: S) => {
    dispatch({ newBase })
  }, [])

  return [state, dispatch, rebase]
}

export default function PollEditor(props: PollEditorProps) {
  const colors = useColors()
  const t = useTranslate()

  const disabled = pollDisabled(props.pollResultResource?.read().data())
  const poll = disabled ? props.initialPoll : props.poll

  const onTextChange = useCallback(
    (text: string, id: string) => {
      const title = text.trimStart().slice(0, maxTitleLength)

      props.setPoll((draft) => {
        if (!draft) return
        const { options, ordering } = draft

        if (!draft.ordering.includes(id)) {
          ordering.push(id)
        }
        options[id] = { title }
      })
    },
    [props]
  )

  const removeOption = useCallback(
    (optionId: string) => {
      props.setPoll((draft) => {
        if (!draft) {
          return
        }
        delete draft.options[optionId]
        const index = draft.ordering.indexOf(optionId)
        if (index >= 0) draft.ordering.splice(index, 1)
      })
    },
    [props]
  )

  const addOption = useCallback(() => {
    props.setPoll((draft) => {
      if (!draft) {
        return
      }
      const { options, ordering } = draft
      const optionId = uuid.v4()
      ordering.push(optionId)
      options[optionId] = { title: '' }
    })
  }, [props])

  const onTitleChange = useCallback(
    (value) => {
      props.setPoll((draft) => {
        if (draft) {
          draft.title = value
        }
      })
    },
    [props]
  )

  if (!poll) return null
  const maxTitleLength = 150
  const maxOptions = 10
  const options = [...pollOptions(poll)]

  return (
    <Container
      style={{
        padding: 20,
        border: '1px solid',
        borderColor: colors.inputBorder,
        borderRadius: 5,
      }}
    >
      <Header setPoll={props.setPoll} disabled={disabled} />
      <PollTitleInput title={props.poll.title || ''} onChange={onTitleChange} />
      <AllowMultipleToggle
        disabled={disabled}
        setPoll={props.setPoll}
        value={poll.allowMultiple}
      />

      {options.map(([optionId, option], i) => {
        return (
          <PollOptionBlock
            key={optionId}
            optionId={optionId}
            disabled={disabled}
            onTextChange={(text) => onTextChange(text, optionId)}
            option={option}
            onRemove={() => removeOption(optionId)}
            index={i}
            maxTitleLength={maxTitleLength}
          />
        )
      })}
      {options.length < maxOptions && (
        <Container hAlign="left">
          <OutlineButton
            disabled={disabled}
            onClick={addOption}
            style={{ display: 'inline-block', justifySelf: 'flex-start' }}
          >
            {t('Add option')}
          </OutlineButton>
        </Container>
      )}
    </Container>
  )
}

function PollTitleInput({
  title,
  onChange,
}: {
  title: string
  onChange: (value: string) => void
}) {
  const colors = useColors()
  const t = useTranslate()

  const titleLimit = 100

  const titleWithinLimit = useMemo(() => {
    return title.length < titleLimit
  }, [title.length])

  return (
    <Container>
      <Input
        style={{ width: '100%', marginRight: 40, marginTop: 10 }}
        placeholder={t('Poll title')}
        value={title}
        onChange={(e) => {
          if (e.target.value.length <= titleLimit) {
            onChange(e.target.value)
          }
        }}
      />
      <BodyText
        style={{
          alignSelf: 'flex-end',
          fontSize: 11,
          color: titleWithinLimit ? colors.textDimHard : colors.warning,
        }}
      >{`${title.length} / ${titleLimit}`}</BodyText>
    </Container>
  )
}

function Header(props: { setPoll: SetPoll; disabled: boolean }) {
  const colors = useColors()
  const t = useTranslate()

  return (
    <Container horizontal vAlign="center">
      {(() => {
        const [poll, cant] = t(
          "Poll| can't be changed after voting has started"
        ).split('|')
        return (
          <CaptionText strong>
            {poll}
            {props.disabled && (
              <CaptionText style={{ color: colors.label }}>{cant}</CaptionText>
            )}
          </CaptionText>
        )
      })()}
      {!props.disabled && (
        <>
          <Pusher />
          <IconButton
            fill={colors.danger}
            size={30}
            Icon={RemoveIcon}
            onClick={() => props.setPoll(() => nothing)}
          />
        </>
      )}
    </Container>
  )
}

function AllowMultipleToggle(props: {
  value: boolean
  setPoll: SetPoll
  disabled: boolean
}) {
  const colors = useColors()
  const t = useTranslate()

  return (
    <FormControlLabel
      disabled={props.disabled}
      style={{ marginTop: 10, marginBottom: 5 }}
      label={
        <BodyText
          style={{
            fontSize: 15,
            color: colors.label,
            fontWeight: 600,
          }}
        >
          {t('Allow multiple choices')}
        </BodyText>
      }
      control={<Checkbox color="default" />}
      value={props.value}
      onChange={() =>
        props.setPoll((draft) => {
          if (draft) draft.allowMultiple = !draft.allowMultiple
        })
      }
    />
  )
}

export function pollDisabled(result: PollResult | undefined): boolean {
  const { totalVoters = 0 } = result ?? {}
  return totalVoters > 0
}
