import { Alert, Box, Button, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslate } from './hooks/Internationalization'
import useAccountInfo, { isAccount } from './hooks/useAccountInfo'

type UserLimitReachedProps = {}

export default function UserLimitReached({}: UserLimitReachedProps) {
  const isAccountUser = isAccount(useAccountInfo())
  const history = useHistory()

  const navigateSwitchUser = useCallback(() => {
    // TODO For some reason this setTimeout is needed :(
    setTimeout(() => {
      history.push('/switch-user')
    }, 1)
  }, [history])

  const signOut = useCallback(() => {
    history.push('/sign-out')
  }, [history])
  const t = useTranslate()

  return (
    <Box
      sx={{ mt: 3 }}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Alert severity="error" sx={{ p: 2 }}>
        <Typography fontSize={16} color="text.primary">
          <strong>User limit reached</strong> <br />
          Contact your administrator to resolve this issue.
        </Typography>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
          {isAccountUser && (
            <Button onClick={navigateSwitchUser} sx={{ mr: 2 }} variant="text">
              {t('Switch user')}
            </Button>
          )}
          <Button onClick={signOut} variant="outlined">
            Sign out
          </Button>
        </Box>
      </Alert>
    </Box>
  )
}
