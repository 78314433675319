import { Modal } from '@mui/material'
import { DocumentSnapshot } from 'connect-shared/firestore'
import { FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React, { useCallback, useState } from 'react'
import UserSelectInput from '../../chat/UserSelectInput'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import HelpHover from '../../HelpHover'
import { Container, Pusher, Spacer } from '../../layout/Layout'
import OutlineButton from '../../OutlineButton'
import { CaptionText } from '../../Text'
import { showErrorToast, showSuccessToast } from '../../Toast'
import ModalCloseButton from '../../ModalCloseButton'
import { useTranslate } from '../../hooks/Internationalization'

type InviteFollowersModalProps = {
  open: boolean
  onRequestClose?: () => void
  feedSnap: DocumentSnapshot<FeedDocument>
  admins?: string[]
}

export default function InviteAdminsModal({
  open,
  onRequestClose,
  feedSnap,
  admins,
}: InviteFollowersModalProps) {
  const [loading, setLoading] = useState(false)
  const [newAdmins, setNewAdmins] = useState(new Set<string>())
  const [searchTerm, setSearchTerm] = useState('')

  const colors = useColors()
  const t = useTranslate()

  const handleSubmit = useCallback(async () => {
    setLoading(true)

    const subscribe = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('addCombinedFeedSubscriptions')

    const followPromises = [...newAdmins].map((followerId) => {
      return subscribe({
        combinedFeedIds: [followerId],
        feedIds: [feedSnap.id],
      })
    })

    try {
      await Promise.all(followPromises)

      const updateData: Partial<FeedDocument> = {
        admins: Object.fromEntries(
          [...newAdmins, ...(admins || [])].map((id) => [id, true])
        ),
      }
      await feedSnap.ref.update(updateData)
    } catch (e) {
      showErrorToast(t('Something went wrong, please try again later'))
      setLoading(false)
      throw e
    }
    showSuccessToast(t('Followers successfully updated!'))
    setNewAdmins(new Set())
    setLoading(false)
    onRequestClose?.()
  }, [newAdmins, t, onRequestClose, feedSnap.id, feedSnap.ref, admins])

  const handleOnChangeUsers = useCallback((users: Set<string>) => {
    setSearchTerm('')
    setNewAdmins(users)
  }, [])

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClose={onRequestClose}
      open={open}
    >
      <Container
        style={{
          position: 'relative',
          width: 600,
          borderRadius: 10,
          padding: 30,
          backgroundColor: colors.webChatModalBackground,
        }}
      >
        <Container horizontal vAlign="center">
          <CaptionText>Add admins</CaptionText>
          <Spacer size={10} />
          <HelpHover
            tooltipPlacement="top-start"
            text={t(
              'Add people by searching and selecting their name as they appear in the list.'
            )}
          />
        </Container>
        <UserSelectInput
          usersToFilter={admins}
          placeholder={t('Search for people')}
          text={searchTerm}
          onChangeText={setSearchTerm}
          users={newAdmins}
          onChangeUsers={handleOnChangeUsers}
        />
        <Spacer size={40} />
        <Container horizontal>
          <Pusher />
          <ClickableOpacity
            style={{ color: colors.labelDim, fontWeight: 600 }}
            onClick={onRequestClose}
          >
            {t('Cancel')}
          </ClickableOpacity>
          <Spacer size={10} />
          <OutlineButton
            disabled={loading || newAdmins.size === 0}
            onClick={handleSubmit}
            style={{ display: 'inline-block' }}
          >
            {t('Add admins')}
          </OutlineButton>
        </Container>
        <ModalCloseButton onClick={onRequestClose} />
      </Container>
    </Modal>
  )
}
