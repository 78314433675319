import { useCallback } from 'react'
import { useColors } from './Colors'
import { useSpring, animated, config } from 'react-spring'

interface ToggleProps {
  style?: React.CSSProperties
  toggleStyle?: React.CSSProperties
  onChange?: (value: boolean) => void
  value?: boolean
  className?: string
}

export default function Toggle({
  onChange,
  style,
  toggleStyle,
  value,
  className,
}: ToggleProps) {
  const colors = useColors()

  const animation = useSpring({
    config: config.default,
    position: 'relative',
    left: value ? 10 : 2,
  })

  const dotStyle: React.CSSProperties = {
    ...animation,
    display: 'flex',
    width: 12,
    height: 12,
    borderRadius:
      toggleStyle && toggleStyle.borderRadius
        ? toggleStyle.borderRadius
        : 12 / 2,
    backgroundColor: colors.foreground,
    ...toggleStyle,
  }

  const handleChange = useCallback(() => {
    onChange?.(!value)
  }, [onChange, value])

  return (
    <div
      onClick={handleChange}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 14 / 2,
        width: 24,
        height: 14,
        backgroundColor: value ? colors.brand : colors.faint,
        ...style,
      }}
      className={className}
    >
      <animated.div style={dotStyle} />
    </div>
  )
}
