import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { InvitationDocument, UserDocument } from 'connect-shared/types'
import User, { userFullName } from 'connect-shared/User'
import { Suspense, SuspenseList, useCallback, useState } from 'react'
import Card from '../Card'
import { resendInvitationEmail } from '../cloud-functions'
import { useColors } from '../Colors'
import ConfirmDeleteModal from '../ConfirmDeleteModal'
import { firestore } from '../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useDateFns, useTranslate } from '../hooks/Internationalization'
import useEllipsis from '../hooks/useEllipsis'
import HoverableContainer from '../HoverableContainer'
import { Container, Spacer } from '../layout/Layout'
import PageLayout from '../layout/PageLayout'
import LinkText from '../LinkText'
import OutlineButton from '../OutlineButton'
import PopoutMenu, { PopoutMenuOption } from '../PopoutMenu'
import { NoteText } from '../Text'
import { showErrorToast, showSuccessToast } from '../Toast'
import TableInfoRow from '../wiki/TableInfoRow'
import InviteUsersModal from './InviteUsersModal'
import ManageUsersMenu from './ManageUsersMenu'

function inviterRef(invitation: InvitationDocument | undefined) {
  if (!invitation) return firestore().collection('empty').doc('empty')
  const { inviter, invitee } = invitation
  return firestore()
    .collection('users')
    .doc(inviter ?? invitee)
}

type UserRowProps = {
  userSnap: QueryDocumentSnapshot<UserDocument>
}

function UserRow({ userSnap }: UserRowProps) {
  const [loading, setLoading] = useState(false)
  const [alreadySent, setAlreadySent] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false)
  }, [])

  const openDeleteModal = useCallback(() => {
    setDeleteModalOpen(true)
  }, [])

  const colors = useColors()
  const t = useTranslate()

  const query = firestore().collection('invitations').doc(userSnap.id)
  const invitation = useFirestoreDoc(query).read().data()

  const inviterResource = useFirestoreDoc(inviterRef(invitation))

  const currentUserId = useCurrentUserId()
  const user = new User(userSnap)
  const { fullName, organizationAdmin } = user
  const ellipsedName = useEllipsis(user.fullName)

  const toggleAdmin = useCallback(async () => {
    if (userSnap.id === currentUserId) {
      throw new Error('Unable to demote self')
    }
    try {
      await userSnap.ref.update({
        organizationAdmin: !organizationAdmin,
      })
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
      return
    }
    const toastMessage = organizationAdmin
      ? t('%{fullName} is no longer an admin', { fullName })
      : t('%{fullName} is now admin', { fullName })
    showSuccessToast(toastMessage)
  }, [userSnap.id, userSnap.ref, currentUserId, organizationAdmin, fullName, t])

  const deleteUser = useCallback(() => {
    try {
      userSnap.ref.delete()
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
      return
    }
    showSuccessToast(t('User deleted'))
  }, [t, userSnap.ref])

  const resendEmail = useCallback(async () => {
    try {
      setLoading(true)
      await resendInvitationEmail(userSnap.id)
      showSuccessToast(
        t('A new invitation has been sent to %{email}', {
          email: userSnap.data().email,
        })
      )
      setAlreadySent(true)
    } catch (err) {
      showErrorToast(t('Failed to send email'))
      console.error(err)
    }
    setLoading(false)
  }, [t, userSnap])

  const { email } = userSnap.data()

  const sendDisabled = alreadySent || loading
  const options: PopoutMenuOption[] = [
    {
      text: !sendDisabled
        ? t('Resend invitation')
        : loading
        ? t('Sending invitation')
        : t('Invitation sent'),
      onClick: resendEmail,
      disabled: sendDisabled,
    },
    {
      text: organizationAdmin ? t('Demote admin') : t('Promote to admin'),
      onClick: toggleAdmin,
    },
    {
      text: t('Delete user'),
      onClick: openDeleteModal,
      danger: true,
    },
  ]

  const { format } = useDateFns()

  return (
    <HoverableContainer
      hoverColor={colors.selected}
      style={{
        padding: '13px 0',
        borderBottom: `1px solid ${colors.selected}`,
      }}
    >
      <Container horizontal flex={1}>
        <Spacer size={15} />
        <TableInfoRow>
          <LinkText to={'/users/' + user.id}>{email}</LinkText>
        </TableInfoRow>
        <TableInfoRow text={userFullName(inviterResource.read().data())} />
        <TableInfoRow
          text={
            invitation
              ? format(invitation.timestamp.toDate(), 'yyyy-MM-dd')
              : ''
          }
        />
        <TableInfoRow text={invitation ? invitation.code : ''} />
        <Container vAlign="center">
          <PopoutMenu options={options} />
        </Container>
      </Container>
      {deleteModalOpen && (
        <ConfirmDeleteModal
          onConfirm={deleteUser}
          onRequestClose={closeDeleteModal}
          title={t('Delete %{ellipsedName}?', { ellipsedName })}
          text={t('The user will be permanently removed.')}
        />
      )}
    </HoverableContainer>
  )
}

export default function Invitations() {
  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  const openInviteModal = useCallback(() => {
    setInviteModalOpen(true)
  }, [])

  const closeInviteModal = useCallback(() => {
    setInviteModalOpen(false)
  }, [])

  const colors = useColors()
  const t = useTranslate()

  const organizationDoc = useOrganizationDoc()
  const usersRef = firestore()
    .collection('users')
    .where('organization', '==', organizationDoc)
    .where('hidden', '==', false)
    .where('invitePending', '==', true)

  const usersResource = useFirestoreCollection(usersRef)

  return (
    <PageLayout style={{ marginTop: 30 }} leftSideContent={<ManageUsersMenu />}>
      <Container hAlign="right">
        <OutlineButton
          onClick={openInviteModal}
          style={{ display: 'inline-flex' }}
        >
          {t('Invite users')}
        </OutlineButton>
      </Container>
      <Spacer size={10} />
      <Card>
        <NoteText style={{ fontSize: 16, fontWeight: 600 }}>
          {t('Pending invites')}{' '}
          <span style={{ color: colors.label }}>
            {usersResource.read().docs.length}
          </span>
        </NoteText>
        <Spacer size={20} />
        <Container
          style={{
            borderBottom: `1px solid ${colors.inputBorder}`,
            paddingBottom: 8,
          }}
          flex={1}
          horizontal
        >
          <Spacer size={15} />
          <TableInfoRow text={t('Name')} strong style={{ flex: 1 }} />
          <TableInfoRow text={t('Invite sent by')} strong />
          <TableInfoRow text={t('Date')} strong />
          <TableInfoRow text={t('Code')} strong />
          <Spacer size={50} />
        </Container>
        <SuspenseList revealOrder="forwards">
          {usersResource.read().docs.map((userSnap) => {
            return (
              <Suspense key={userSnap.id} fallback={null}>
                <UserRow userSnap={userSnap} key={userSnap.id} />
              </Suspense>
            )
          })}
        </SuspenseList>
      </Card>
      {inviteModalOpen && <InviteUsersModal close={closeInviteModal} />}
    </PageLayout>
  )
}
