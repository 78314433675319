import React, {
  CSSProperties,
  HTMLAttributes,
  HtmlHTMLAttributes,
  useMemo,
} from 'react'

interface PusherProps extends HtmlHTMLAttributes<HTMLDivElement> {
  /** Relative size to other pushers (`flex-grow`) */
  weight?: number
}

/**
 * Creates empty space between components by growing to fill the parent element
 * (`flex: 1`)
 */
export function Pusher({ weight = 1, style, ...divProps }: PusherProps) {
  return (
    <div
      style={{
        flexGrow: weight,
        ...style,
      }}
      {...divProps}
    />
  )
}

interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  /** Size in pixels (width or height depending on flex direction */
  size?: number
}

/**
 * A fixed size element to create empty space between components
 */
export function Spacer({ size = 1, style, ...divProps }: SpacerProps) {
  return (
    <div
      style={{
        flexBasis: size,
        flexGrow: 0,
        flexShrink: 0,
        minHeight: 0.1,
        minWidth: 0.1,
        ...style,
      }}
      {...divProps}
    />
  )
}

export type ContainerProps = HtmlHTMLAttributes<HTMLDivElement> & {
  /** Render children horizontally (´flex-direction: row´) */
  horizontal?: boolean
  /** Align items vertically (justifyContent/alignItems) */
  vAlign?: 'top' | 'center' | 'bottom'
  /** Align items horizontally (justifyContent/alignItems) */
  hAlign?: 'left' | 'center' | 'right'
  divRef?: React.RefObject<HTMLDivElement>
  flex?: CSSProperties['flex']
}

/**
 * Wrapper to layout other components. Defaults to horizontal alignment of
 * children (`flex-direction: column`).
 */
export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  function Container(
    {
      flex = '0 0 auto',
      horizontal,
      style,
      hAlign,
      vAlign,
      divRef,
      ...divProps
    },
    ref
  ) {
    const justifyContent: CSSProperties['justifyContent'] = useMemo(() => {
      if (horizontal) {
        if (hAlign === 'left') {
          return 'flex-start'
        } else if (hAlign === 'center') {
          return 'center'
        } else if (hAlign === 'right') {
          return 'flex-end'
        }
      } else {
        if (vAlign === 'top') {
          return 'flex-start'
        } else if (vAlign === 'center') {
          return 'center'
        } else if (vAlign === 'bottom') {
          return 'flex-end'
        }
      }
    }, [hAlign, horizontal, vAlign])

    const alignItems: CSSProperties['alignItems'] = useMemo(() => {
      if (!horizontal) {
        if (hAlign === 'left') {
          return 'flex-start'
        } else if (hAlign === 'center') {
          return 'center'
        } else if (hAlign === 'right') {
          return 'flex-end'
        }
      } else {
        if (vAlign === 'top') {
          return 'flex-start'
        } else if (vAlign === 'center') {
          return 'center'
        } else if (vAlign === 'bottom') {
          return 'flex-end'
        }
      }
    }, [hAlign, horizontal, vAlign])

    return (
      <div
        ref={divRef || ref}
        style={{
          flex: flex,
          display: 'flex',
          flexDirection: horizontal ? 'row' : 'column',
          alignItems,
          justifyContent,
          ...style,
        }}
        {...divProps}
      />
    )
  }
)
