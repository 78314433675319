import styled from '@emotion/styled'
import { CSSProperties, ReactNode, useState } from 'react'

const CustomScrollDiv = styled.div<{ isActive: boolean }>`
  ::-webkit-scrollbar {
    width: 6px;
    background: #ededed;
  }

  ::-webkit-scrollbar-thumb {
    background: #d1d1d1;
  }
`

export default function HoverableScrollContainer({
  style,
  children,
}: {
  children?: ReactNode
  style?: CSSProperties
}) {
  const [hovered, setHovered] = useState(false)
  return (
    <CustomScrollDiv
      isActive={hovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        overflowY: hovered ? 'scroll' : 'hidden',
        paddingRight: hovered ? 3 : 9,
        scrollbarWidth: hovered ? 'thin' : 'none',
        ...style,
      }}
    >
      {children}
    </CustomScrollDiv>
  )
}
