import { Modal, TextField } from '@mui/material'
import { FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/storage'
import React, {
  Suspense,
  useCallback,
  useReducer,
  useRef,
  useState,
} from 'react'
import { Crop } from 'react-image-crop'
import { useHistory } from 'react-router'
import uuid from 'uuid'
import BlockButton from '../BlockButton'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { Container, Spacer } from '../layout/Layout'
import OutlineButton from '../OutlineButton'
import PlayippSpinner from '../playippSpinner/PlayippSpinner'
import RoundAvatar from '../RoundAvatar'
import { BodyText, CaptionText } from '../Text'
import { showErrorToast, showSuccessToast } from '../Toast'
import ToggleBlock from '../ToggleBlock'
import { cropImage, fileToUpload, Upload } from '../crop-utils'
import CreateNewFeedModalCropAvatar from './CreateNewFeedModalCropAvatar'
import { css } from '@emotion/react'
import ModalCloseButton from '../ModalCloseButton'
import { useTranslate } from '../hooks/Internationalization'

type CreateNewFeedModalProps = {
  open: boolean
  close?: () => void
}

export default function CreateNewFeedModal({
  open,
  close,
}: CreateNewFeedModalProps) {
  const [tempAvatarUrl, setTempAvatarUrl] = useState<string | undefined>()
  const [state, setState] = useState<'createFeed' | 'cropAvatar'>('createFeed')
  const [avatar, setAvatar] = useState<Upload | undefined>(undefined)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [inviteOnly, toggleInviteOnly] = useReducer(
    (inviteOnly) => !inviteOnly,
    false
  )
  const [anyoneMayPost, toggleAnyoneMayPost] = useReducer(
    (anyoneMayPost) => !anyoneMayPost,
    false
  )
  const [disableInteractions, toggleDisableInteractions] = useReducer(
    (disableInteractivity) => !disableInteractivity,
    false
  )

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const colors = useColors()
  const t = useTranslate()

  const descriptionLengthLimit = 300

  const organizationDoc = useOrganizationDoc()
  const currentUserId = useCurrentUserId()
  const history = useHistory()

  const targetWidth = 300
  const targetHeight = 300
  const [crop, setCrop] = useState<Crop>({
    aspect: targetWidth / targetHeight,
  })

  const imageRef = useRef<HTMLImageElement>()

  const submit = useCallback(async () => {
    const feedRef = organizationDoc.collection('feeds').doc()

    async function uploadAvatar(avatar: Upload) {
      if (!imageRef.current) {
        throw new Error('Missing imageRef')
      }

      const avatarRef = firebase
        .storage()
        .ref(`${organizationDoc.path}/feeds/${feedRef.id}/avatars/${uuid.v4()}`)

      const uploadTask = await avatarRef.put(avatar.file, {
        cacheControl: 'private, max-age=604800, immutable',
      })
      return await uploadTask.ref.getDownloadURL()
    }

    setLoading(true)

    try {
      if (!name.trim()) {
        setError(true)
        return
      }

      setError(false)

      const newFeedData: FeedDocument = {
        inviteOnly,
        name,
        description,
        anyoneMayPost,
        disableInteractions,
        admins: {
          [currentUserId]: true,
        },
      }

      if (avatar) {
        newFeedData.avatar = await uploadAvatar(avatar)
      }
      await feedRef.set(newFeedData)

      const subscribe = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('addCombinedFeedSubscriptions')

      await subscribe({
        combinedFeedIds: [currentUserId],
        feedIds: [feedRef.id],
      })
      showSuccessToast('Feed successfully created!')
      close?.()
      setName('')
      setDescription('')
      setAvatar(undefined)

      if (inviteOnly) {
        toggleInviteOnly()
      }

      if (anyoneMayPost) {
        toggleAnyoneMayPost()
      }

      history.push(`/feeds/${feedRef.id}/members`)
    } catch (err) {
      setLoading(false)
      showErrorToast(t('Something went wrong, please try again'))
      throw err
    } finally {
      setLoading(false)
    }
  }, [
    anyoneMayPost,
    avatar,
    close,
    currentUserId,
    description,
    disableInteractions,
    history,
    inviteOnly,
    name,
    organizationDoc,
    t,
  ])

  const inputRef = useRef<HTMLInputElement>(null)

  const onUploadPress = useCallback(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.click()
  }, [])

  const onImageSelect = useCallback(
    async (files: FileList | null) => {
      if (files === null) {
        showErrorToast(t('Something went wrong, try again'))
        console.error('files is null')
        return
      }
      if (files.length !== 1) {
        showErrorToast(t('Something went wrong, try again'))
        console.error(`files length is ${files.length}`)
        return
      }

      const avatar = files?.[0]

      const isValidImage = ['image/jpeg', 'image/png'].includes(avatar.type)

      if (!isValidImage) {
        console.error(`${avatar.type} is not a valid image type`)
        showErrorToast(t('This file is not supported'))
        return
      }

      if (avatar) {
        const upload = await fileToUpload(avatar)
        setAvatar(upload)
        setState('cropAvatar')
      }
    },
    [t]
  )

  const handleDescriptionChange = useCallback((value: string) => {
    if (value.length >= descriptionLengthLimit + 1) {
      return
    }
    setDescription(value)
  }, [])

  const onCropSubmit = useCallback(async () => {
    if (!imageRef.current || !avatar) return
    const blob = await cropImage(
      avatar,
      crop,
      imageRef.current,
      targetWidth,
      targetHeight
    )
    const upload = await fileToUpload(blob as File)
    setAvatar(upload)
    setTempAvatarUrl(URL.createObjectURL(upload.file))
    setState('createFeed')
  }, [avatar, crop])

  const onCropCancel = useCallback(() => {
    setAvatar(undefined)
    setTempAvatarUrl(undefined)
    setState('createFeed')
  }, [])

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClose={close}
      open={open}
    >
      <Container
        style={{
          position: 'relative',
          width: 460,
          borderRadius: 10,
          padding: 30,
          backgroundColor: colors.webChatModalBackground,
        }}
      >
        <ModalCloseButton
          onClick={close}
          style={{ position: 'absolute', zIndex: 10, top: -40, right: -40 }}
        />
        <CaptionText style={{ fontWeight: 600, fontSize: 20 }}>
          {t('Create new feed')}
        </CaptionText>
        <Spacer size={20} />
        {state === 'createFeed' && (
          <>
            <Container horizontal>
              {avatar && tempAvatarUrl ? (
                <RoundAvatar size={60} avatar={tempAvatarUrl} />
              ) : (
                <Suspense fallback={<PlayippSpinner size={60} />}>
                  <RoundAvatar size={60} name={name} />
                </Suspense>
              )}
              <Spacer size={20} />
              <OutlineButton
                onClick={onUploadPress}
                style={{ fontWeight: 600, fontSize: 13, padding: '3px 10px' }}
              >
                {t('Upload feed image')}
              </OutlineButton>
              {/*
                Invisible input that handles file input.
                Had to do this due to inputs being a nightmare to style
              */}
              <input
                ref={inputRef}
                onChange={(e) => onImageSelect(e.target.files)}
                id="file-input"
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{
                  display: 'none',
                }}
              />
            </Container>
            <Spacer size={20} />
            <Container>
              <TextField
                value={name}
                onChange={(event) => setName(event.target.value)}
                label={t('Feed name')}
                variant="outlined"
                error={error}
                id="feed_name"
              />
              {error ? (
                <BodyText
                  style={{
                    color: colors.danger,
                    fontSize: 14,
                    margin: 0,
                  }}
                >
                  {t('You need to enter a name')}
                </BodyText>
              ) : (
                <Spacer size={21} />
              )}
              <Spacer size={9} />
              <TextField
                value={description}
                onChange={(event) =>
                  handleDescriptionChange(event.target.value)
                }
                label={t('Feed description')}
                multiline
                rows={4}
                variant="outlined"
              />
              <Container hAlign="right">
                <BodyText
                  style={{
                    color:
                      description.length < descriptionLengthLimit
                        ? colors.labelDim
                        : colors.warning,
                  }}
                >{`${description.length} / ${descriptionLengthLimit}`}</BodyText>
              </Container>
              <Spacer size={9} />
              <ToggleBlock
                value={inviteOnly}
                onChange={toggleInviteOnly}
                title={t('Invite only')}
                text={t(
                  'No one can start following your feed without an invite.'
                )}
              />
              <Spacer size={10} />
              <ToggleBlock
                value={anyoneMayPost}
                onChange={toggleAnyoneMayPost}
                title={t('Anyone may post')}
                text={t(
                  'Allow all followers to post to the feed, not just admins.'
                )}
              />
              <Spacer size={10} />
              <ToggleBlock
                value={disableInteractions}
                onChange={toggleDisableInteractions}
                title={t('Disable interactivity')}
                text={t('Disable likes and comments for this feed')}
              />
            </Container>
            <Spacer size={20} />
            <Container horizontal vAlign="center" hAlign="right">
              <ClickableOpacity
                css={css`
                  color: ${colors.label};

                  &:hover {
                    color: ${colors.cancelHover};
                  }
                `}
                onClick={close}
                style={{ fontWeight: 600 }}
              >
                {t('Cancel')}
              </ClickableOpacity>
              <Spacer size={20} />
              <BlockButton
                style={{ width: 92 }}
                disabled={loading}
                onClick={submit}
                id="create_feed"
              >
                {loading ? (
                  <PlayippSpinner color={colors.foreground} size={16} />
                ) : (
                  t('Create')
                )}
              </BlockButton>
            </Container>
          </>
        )}
        {state === 'cropAvatar' && avatar && (
          <CreateNewFeedModalCropAvatar
            imageRef={imageRef}
            crop={crop}
            setCrop={setCrop}
            avatar={avatar}
            onSubmit={onCropSubmit}
            onCancel={onCropCancel}
          />
        )}
      </Container>
    </Modal>
  )
}
