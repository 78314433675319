import { Modal, TextField } from '@mui/material'
import { Container, Pusher, Spacer } from './layout/Layout'
import { useColors } from './Colors'
import { BodyText, CaptionText } from './Text'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'
import ClickableOpacity from './ClickableOpacity'
import useCurrentUser from './hooks/useCurrentUser'
import BlockButton from './BlockButton'
import { ReactComponent as CloseIcon } from './icons/closeModal.svg'
import IconButton from './IconButton'
import { Vacation } from 'connect-shared/types'
import produce from 'immer'
import { useCurrentUserDoc } from './hooks/auth'
import { showErrorToast, showSuccessToast } from './Toast'
import firebase from 'firebase/app'
import OutlineButton from './OutlineButton'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslate } from './hooks/Internationalization'

type PickerProps = {
  value: Date
  onChange: (date: Date) => void
  label: string
}
function Picker({ value, onChange, label }: PickerProps) {
  return (
    <DatePicker
      disableMaskedInput
      renderInput={(props) => <TextField style={{ width: 200 }} {...props} />}
      inputFormat="dd MMM yyyy"
      label={label}
      value={value}
      onChange={useCallback(
        (date: Date | null) => {
          if (date) onChange(date)
        },
        [onChange]
      )}
    />
  )
}

type PlannedLeaveModalProps = {
  onClose: () => void
  initialVacation?: number
}

export default function PlannedLeaveModal({
  onClose,
  initialVacation,
}: PlannedLeaveModalProps) {
  const t = useTranslate()
  const currentUser = useCurrentUser()
  const vacationToEdit = useMemo(() => {
    const vacations = currentUser?.vacations
    if (!vacations || initialVacation === undefined) {
      return undefined
    }
    return vacations[initialVacation]
  }, [currentUser?.vacations, initialVacation])

  const [startDate, setStartDate] = useState(
    vacationToEdit?.start || new Date()
  )
  const [endDate, setEndDate] = useState(vacationToEdit?.end || new Date())
  const [status, setStatus] = useState(vacationToEdit?.name || '')
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  const handleStartChange = useCallback(
    (date: Date) => {
      if (isAfter(date, endDate)) {
        setStartDate(date)
        setEndDate(date)
      } else {
        setStartDate(date)
      }
    },
    [endDate]
  )

  const handleEndChange = useCallback(
    (date: Date) => {
      if (isBefore(date, startDate)) {
        setEndDate(date)
        setStartDate(date)
      } else {
        setEndDate(date)
      }
    },
    [startDate]
  )

  const currentUserDoc = useCurrentUserDoc()

  const save = useCallback(async () => {
    if (!status.trim()) {
      setHasErrors(true)
      return
    }
    setHasErrors(false)
    setLoading(true)

    try {
      const vacation: Vacation = {
        start: startOfDay(startDate),
        end: endOfDay(endDate),
        name: status.trim(),
      }

      const vacations = produce(
        currentUser?.vacations,
        (vacations: Vacation[]) => {
          if (initialVacation !== undefined) {
            vacations[initialVacation] = vacation
          } else {
            vacations.push(vacation)
          }
        }
      )

      await currentUserDoc.update({ vacations })

      if (initialVacation === undefined) {
        firebase.analytics().logEvent('vacation_added')
        showSuccessToast(t('Vacation added'))
      } else {
        firebase.analytics().logEvent('vacation_edited')
        showSuccessToast(t('Vacation edited'))
      }

      setLoading(false)
      onClose()
    } catch (err) {
      console.error(err)
      setLoading(false)
      showErrorToast(t('Something went wrong please try again later'))
      onClose()
    }
  }, [
    currentUser?.vacations,
    currentUserDoc,
    endDate,
    initialVacation,
    onClose,
    startDate,
    status,
    t,
  ])

  const remove = useCallback(
    async (index: number) => {
      setLoading(true)
      try {
        const nextVacations = produce(currentUser?.vacations, (vacations) => {
          vacations && vacations.splice(index, 1)
        })
        await currentUserDoc.update({
          vacations: nextVacations,
        })
        onClose()
        setLoading(false)
      } catch (err) {
        console.error(err)
        showErrorToast(t('Something went wrong please try again later'))
      }
    },
    [currentUser?.vacations, currentUserDoc, onClose, t]
  )

  const colors = useColors()
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open
      onClose={onClose}
    >
      <Container
        style={{
          position: 'relative',
          backgroundColor: colors.background,
          borderRadius: 10,
          padding: 30,
        }}
      >
        <CaptionText style={{ fontSize: 20, fontWeight: 600 }}>
          {t('New planned leave')}
        </CaptionText>
        <Container style={{ marginTop: 30 }} horizontal vAlign="center">
          <Picker
            label={t('From')}
            value={startDate}
            onChange={handleStartChange}
          />
          <Spacer size={10} />
          <BodyText style={{ fontSize: 14 }}>-</BodyText>
          <Spacer size={10} />
          <Picker label={t('To')} value={endDate} onChange={handleEndChange} />
        </Container>
        <Spacer size={20} />
        <TextField
          error={hasErrors}
          variant="outlined"
          placeholder={t('Add a status text')}
          autoFocus
          value={status}
          onChange={useCallback(
            (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setStatus(e.target.value)
            },
            []
          )}
          style={{ width: 519 }}
        />
        <Spacer size={8} />
        <Container horizontal>
          {initialVacation !== undefined && (
            <OutlineButton
              disabled={loading}
              onClick={() => remove(initialVacation)}
              danger
            >
              {t('Remove planned leave')}
            </OutlineButton>
          )}
          <Pusher />
          <ClickableOpacity
            onClick={onClose}
            style={{ marginRight: 20, fontWeight: 600, color: colors.labelDim }}
          >
            <BodyText>{t('Cancel')}</BodyText>
          </ClickableOpacity>
          <BlockButton disabled={loading} onClick={save}>
            {t('Save')}
          </BlockButton>
        </Container>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', top: -40, right: -40 }}
          size={40}
          Icon={CloseIcon}
        />
      </Container>
    </Modal>
  )
}
