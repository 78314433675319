function coloredBackground(theme: any, background: string) {
  return {
    ...theme,
    background,
    foreground: background,
    text: theme.textInverted,
    labelDim: theme.textInverted,
  }
}

export function accentBackground(theme: any) {
  return coloredBackground(theme, theme.accent)
}

export function darkTheme(theme: any) {
  return {
    ...theme,
    background: '#151515',
    backgroundHighlight: '#222222',
    foreground: '#000000',
    text: theme.textInverted,
    textInverted: theme.text,
    labelTopic: '#dddddd',
  }
}
