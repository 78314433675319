import { ReactComponent as CloseIcon } from './icons/closeModal.svg'
import { Container, ContainerProps } from './layout/Layout'
import IconHoverWrapper from './IconHoverWrapper'
import ClickableOpacity from './ClickableOpacity'

export default function ModalCloseButton({
  style,
  ...containerProps
}: ContainerProps) {
  return (
    <Container
      style={{
        position: 'absolute',
        bottom: '100%',
        left: '100%',
        cursor: 'pointer',
        zIndex: 10,
        ...style,
      }}
      {...containerProps}
    >
      <ClickableOpacity>
        <IconHoverWrapper>
          <CloseIcon height={40} />
        </IconHoverWrapper>
      </ClickableOpacity>
    </Container>
  )
}
