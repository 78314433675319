import React from 'react'
import { useColors } from '../Colors'
import { useTranslate } from '../hooks/Internationalization'
import { ReactComponent as EmptyIllustration } from '../icons/Empty.svg'
import { Container, Spacer } from '../layout/Layout'
import { BodyText } from '../Text'

export default function EmptyFeed({ isAdmin }: { isAdmin: boolean }) {
  const colors = useColors()
  const t = useTranslate()

  return (
    <Container hAlign="center" style={{ marginTop: 30 }}>
      <EmptyIllustration width={132} height={132} />
      <Spacer size={15} />
      <BodyText style={{ color: colors.labelStrong, fontSize: 14 }}>
        {t('This feed has no posts yet.')}
      </BodyText>
      {!isAdmin && (
        <>
          <Spacer size={20} />
          <BodyText
            style={{ color: colors.labelStrong, fontSize: 14, fontWeight: 300 }}
          >
            {t('Check back later')}
          </BodyText>
        </>
      )}
    </Container>
  )
}
