import 'firebase/firestore'
import firebase from 'firebase/app'
import * as React from 'react'
import { firestore } from '../firestore'
import { UserDocument } from 'connect-shared/types'
import { useIdTokenResult } from './useFeatureFlag'
import { useAccountId } from './useAccountSnap'
import { Resource, useFirestoreCollection } from '@iwikal/reactfire'

type LoggedInResult = {
  organizationId: string
  userId: string
}

type LoggedOutResult = {
  organizationId: undefined
  userId: undefined
}

export function useAuthData(assertLoggedIn: true): LoggedInResult
export function useAuthData(
  assertLoggedIn: false
): LoggedInResult | LoggedOutResult
export function useAuthData(assertLoggedIn: boolean) {
  const tokenResult = useIdTokenResult().read()

  if (tokenResult) {
    const { claims } = tokenResult
    if (claims.organization && claims.user_id) {
      return {
        userId: claims.user_id,
        organizationId: claims.organization,
      }
    }
  }

  if (assertLoggedIn) {
    throw new Error('Called useAuthData with assertLoggedIn while logged out')
  }
  return {
    userId: undefined,
    organizationId: undefined,
  }
}

/**
 * Do not use if logged out, will throw error.
 */
export function useCurrentUserId() {
  const { userId } = useAuthData(true)
  React.useDebugValue(userId)
  return userId
}

/**
 * Do not use if logged out, will throw error.
 */
export function useCurrentUserDoc() {
  const id = useCurrentUserId()

  function createRef() {
    return firebase
      .firestore()
      .collection('users')
      .doc(id) as firebase.firestore.DocumentReference<UserDocument>
  }

  const [state, setState] = React.useState(createRef)

  if (id !== state.id) {
    const next = createRef()
    setState(next)
    return next
  }

  return state
}

/**
 * Do not use if logged out, will throw error.
 */
export function useOrganizationId() {
  const { organizationId } = useAuthData(true)
  React.useDebugValue(organizationId)
  return organizationId
}

/**
 * Do not use if logged out, will throw error.
 */
export function useOrganizationDoc() {
  const organizationId = useOrganizationId()

  function createRef() {
    return firestore().collection('organizations').doc(organizationId)
  }

  const [state, setState] = React.useState(createRef)

  if (organizationId !== state.id) {
    const next = createRef()
    setState(next)
    return next
  }

  return state
}

export function useOrganizationUserDoc() {
  const organizationId = useOrganizationId()
  const currentUserId = useCurrentUserId()

  return React.useMemo(() => {
    return firestore()
      .collection('organizations')
      .doc(organizationId)
      .collection('users')
      .doc(currentUserId)
  }, [currentUserId, organizationId])
}

export function useHasMultipleUsers(): Resource<boolean> {
  const accountId = useAccountId()
  const usersQuery = React.useMemo(() => {
    if (accountId) {
      return firestore()
        .collection('users')
        .where('accountId', '==', accountId)
        .where('invitePending', '==', false)
        .limit(2)
    } else {
      return firestore().collection('empty')
    }
  }, [accountId])
  const users = useFirestoreCollection(usersQuery)
  return {
    read: () => users.read().size > 1,
  }
}
