import React from 'react'

interface ErrorBoundaryProps {
  fallback: React.ReactNode
  children?: React.ReactNode
  resetKey?: unknown
}

interface State {
  hasError: boolean
  error: any
  resetKey: unknown
}

const defaultState: State = {
  hasError: false,
  error: undefined,
  resetKey: undefined,
}

const errorContext = React.createContext<any>(undefined)
const clearErrorContext = React.createContext(() => {})

export function useError() {
  return React.useContext(errorContext)
}

export function useClearError() {
  return React.useContext(clearErrorContext)
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = defaultState

  static getDerivedStateFromError(error: Error): Partial<State> {
    console.error(error)
    return {
      error,
      hasError: true,
    }
  }

  static getDerivedStateFromProps(
    nextProps: Readonly<ErrorBoundaryProps>,
    prevState: State
  ): Partial<State> | null {
    const { resetKey } = nextProps
    if (resetKey === prevState.resetKey) {
      return null
    } else {
      return {
        ...defaultState,
        resetKey,
      }
    }
  }

  clearError = () => {
    this.setState({
      ...defaultState,
      resetKey: this.state.resetKey,
    })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <errorContext.Provider value={this.state.error}>
          <clearErrorContext.Provider value={this.clearError}>
            {this.props.fallback}
          </clearErrorContext.Provider>
        </errorContext.Provider>
      )
    } else {
      return this.props.children
    }
  }
}
