import assertNever from 'assert-never'
import firebase from 'firebase/app'
import useIsMounted from 'ismounted'
import { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { switchUserHeroSupport } from './cloud-functions'
import FullscreenSpinner from './FullscreenSpinner'
import { useTranslate } from './hooks/Internationalization'
import ResetPassword from './ResetPassword'
import { showErrorToast, showSuccessToast } from './Toast'

function AccountVerify({ oobCode }: { oobCode: string }) {
  const t = useTranslate()
  const [state, setState] = useState<
    'initial' | 'loading' | 'error' | 'success'
  >('initial')
  const isMounted = useIsMounted()
  useEffect(() => {
    if (state !== 'initial') return
    setState('loading')
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(async () => {
        if (isMounted.current) {
          showSuccessToast(t('Email address verified!'))
          setState('success')
          await firebase.auth().currentUser?.reload()
          await firebase.auth().currentUser?.getIdToken(true)
        }
      })
      .catch((e: Error) => {
        if (isMounted.current) {
          showErrorToast(e.message, 15000)
          setState('error')
        }
      })
  }, [oobCode, state, isMounted, t])

  if (state === 'success' || state === 'error') {
    return <Redirect to="/" />
  }

  if (state === 'loading' || state === 'initial') {
    // TODO better loading state
    return <FullscreenSpinner />
  }

  assertNever(state)
}

function SignIn() {
  const t = useTranslate()
  const [state, setState] = useState<
    'initial' | 'loading' | 'error' | 'success'
  >('initial')
  const isMounted = useIsMounted()
  const search = useLocation().search
  useEffect(() => {
    if (state !== 'initial') return
    setState('loading')
    const query = new URLSearchParams(search)
    const email = query.get('email') || ''

    firebase
      .auth()
      .signInWithEmailLink(email)
      .then(async () => {
        if (isMounted.current) {
          showSuccessToast(t('Account created!'))
          setState('success')
          await firebase.auth().currentUser?.reload()
          await firebase.auth().currentUser?.getIdToken(true)
        }
      })
      .catch((e: Error) => {
        if (isMounted.current) {
          showErrorToast(e.message, 15000)
          setState('error')
        }
      })
  }, [state, isMounted, search, t])

  if (state === 'success' || state === 'error') {
    return <Redirect to="/" />
  }

  if (state === 'loading' || state === 'initial') {
    // TODO better loading state
    return <FullscreenSpinner />
  }

  assertNever(state)
}

function ExternalSignupEmail({
  oobCode,
  switchUserToken,
}: {
  oobCode: string
  switchUserToken: string
}) {
  const history = useHistory()

  useEffect(() => {
    async function effect() {
      if (firebase.auth().currentUser) {
        await firebase.auth().signOut()
      }

      await firebase.auth().applyActionCode(oobCode)
      showSuccessToast('E-mail verified!')
      await firebase.auth().signInWithCustomToken(switchUserToken)
    }

    effect()
      .catch((err) => {
        showErrorToast(err.message)
        console.error(err)
      })
      .then(() => {
        history.push('/')
      })
  }, [history, oobCode, switchUserToken])

  return <FullscreenSpinner />
}

function HeroSwitchUser({ secret }: { secret: string }) {
  const history = useHistory()

  useEffect(() => {
    async function effect() {
      if (firebase.auth().currentUser) {
        await firebase.auth().signOut()
      }
      const token = await switchUserHeroSupport(secret)
      await firebase.auth().signInWithCustomToken(token)
    }

    effect()
      .catch((err) => {
        showErrorToast(err.message)
        console.error(err)
      })
      .then(() => {
        history.push('/')
      })
  }, [history, secret])

  return <FullscreenSpinner />
}

export default function AccountActions() {
  const t = useTranslate()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const oobCode = query.get('oobCode')
  const mode = query.get('mode')
  const switchUserToken = query.get('switchUserToken')
  const secret = query.get('secret')

  if (mode === 'resetPassword' && oobCode) {
    return <ResetPassword oobCode={oobCode} />
  }

  if (mode === 'heroSupportSwitch' && secret) {
    return <HeroSwitchUser secret={secret} />
  }

  if (mode === 'verifyEmail' && switchUserToken && oobCode) {
    return (
      <ExternalSignupEmail
        oobCode={oobCode}
        switchUserToken={switchUserToken}
      />
    )
  }

  if (mode === 'verifyEmail' && oobCode) {
    return <AccountVerify oobCode={oobCode} />
  }

  if (mode === 'signIn' && oobCode) {
    return <SignIn />
  }

  showErrorToast(t('Invalid email link, please try again.'), 10000)
  return <Redirect to="/" />
}
