import { CSSProperties } from 'react'
import { ReactComponent as Spinner } from './playippSpinner.svg'
import styled from '@emotion/styled'

const StyledPlayippSpinner = styled.div<{ color: string }>`
  .loadingCircle {
    position: relative;
    /* min-width: 22px; */
    /* width:auto; */
  }
  .loadingCircle:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .loadingCircleCircular {
    animation: loadingCircleRotate 3s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .loadingCirclePath {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: loadingCircleDash 2s ease-in-out infinite;
    stroke-linecap: round;
    /* stroke: #fff; */
    stroke: ${(p) => (p.color ? p.color : '#4B9FCC')};
  }

  @keyframes loadingCircleRotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loadingCircleDash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -20;
    }
    100% {
      stroke-dasharray: 10, 200;
      stroke-dashoffset: -124;
    }
  }
`
type PlayippSpinnerProps = {
  color?: string
  size?: CSSProperties['width']
}

const PlayippSpinner = ({
  color = '#4B9FCC',
  size = 'auto',
}: PlayippSpinnerProps) => {
  return (
    <StyledPlayippSpinner color={color}>
      <div className="loadingCircle" style={{ width: size }}>
        <Spinner />
      </div>
    </StyledPlayippSpinner>
  )
}

export default PlayippSpinner
