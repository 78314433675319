import { CSSProperties, HTMLAttributes } from 'react'
import { useColors } from './Colors'
import { Container } from './layout/Layout'

type InlineButtonProps = {
  flex?: CSSProperties['flex']
  Icon?: any
  text?: string
  iconSize?: number
  big?: boolean
} & HTMLAttributes<HTMLSpanElement>

export default function InlineButton({
  flex = '0 0 auto',
  style,
  Icon,
  text,
  onClick,
  iconSize = 25,
  big,
  ...spanProps
}: InlineButtonProps) {
  const colors = useColors()
  return (
    <Container
      flex={flex}
      horizontal
      hAlign="center"
      vAlign="center"
      onClick={onClick}
      style={{
        fontSize: big ? 32 : 16,
        color: colors.accent,
        fontWeight: 600,
        cursor: 'pointer',
        ...style,
      }}
      {...spanProps}
    >
      {Icon && (
        <Icon
          height={big ? iconSize * 3 : iconSize}
          width={big ? iconSize * 3 : iconSize}
          fillColor={colors.active}
        />
      )}
      {!!Icon && !!text && <div style={{ width: big ? 15 : 6 }} />}
      {text}
    </Container>
  )
}
