import { isSsoProviderId } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'
import BlockButton from './BlockButton'
import { ssoAuth } from './cloud-functions'
import { Container, Spacer } from './layout/Layout'
import LinkText from './LinkText'
import LoadingOverlay from './LoadingOverlay'
import TextInput from './TextInput'
import { showErrorToast } from './Toast'
import { useColors } from './Colors'
import SignUpLayout from './SignUpLayout'
import { BodyText } from './Text'
import { useTranslate } from './hooks/Internationalization'

function stripId(input?: string): string {
  return input?.toLowerCase().replace(/[^a-z\d_.-]/g, '') || ''
}

export default function SsoAuth() {
  const params = useParams<{ providerId?: string }>()
  const [storedProviderId, setStoredProviderId] = useLocalStorage(
    'SsoAuth-providerId',
    ''
  )
  const paramsId = stripId(params.providerId)
  const storedId = stripId(storedProviderId)

  const colors = useColors()
  const t = useTranslate()

  const [providerId, setProviderId] = useState(paramsId || storedId || '')
  const [error, setError] = useState('')

  const history = useHistory()
  useEffect(() => {
    if (!paramsId && storedId) {
      history.replace(`/sso/${storedId}`)
    }
  }, [history, paramsId, storedId])

  useEffect(() => {
    if (paramsId && !storedId) {
      setStoredProviderId(paramsId)
    }
  }, [paramsId, setStoredProviderId, storedId])

  const provider: firebase.auth.AuthProvider | null = useMemo(() => {
    if (providerId?.startsWith('saml.')) {
      return new firebase.auth.SAMLAuthProvider(providerId)
    }
    return null
  }, [providerId])

  const handleClick = useCallback(async () => {
    if (!provider) {
      setError(t('Provider not found'))
      return
    }

    setLoading(true)
    let token: string
    try {
      await firebase.auth().signInWithPopup(provider)
    } catch (err) {
      setError(err.message)
      setLoading(false)
      return
    }

    try {
      token = await ssoAuth()
    } catch (err) {
      await firebase.auth().signOut()
      showErrorToast(err.message)
      setLoading(false)
      return
    }

    try {
      await firebase.auth().signInWithCustomToken(token)
    } catch (_) {
      setError(t('Unknown error'))
      setLoading(false)
      return
    }
  }, [provider, t])

  const handleChange = useCallback(
    (value: string) => {
      const id = value.toLowerCase().replace(/[^a-z\d_.-]/g, '')
      setProviderId(id)
      setStoredProviderId(id)
      history.replace(`/sso/${id}`)
    },
    [history, setStoredProviderId]
  )

  const valid = useMemo(() => {
    return isSsoProviderId(providerId)
  }, [providerId])

  const [loading, setLoading] = useState(false)

  const fontSize = isMobile ? 32 : 16

  return (
    <SignUpLayout
      backgroundColor={colors.beeworkGoldLight}
      footer={
        <Container style={{ marginTop: 24 }} hAlign="center">
          <BodyText
            style={{
              fontSize,
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t('Use password instead?')}
          </BodyText>
          <LinkText
            to="/sign-in"
            style={{
              fontSize,
              marginLeft: 5,
              color: colors.beeworkBlue,
              cursor: 'pointer',
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t('Sign in')}
          </LinkText>
        </Container>
      }
      caption={t('SSO sign in')}
    >
      <Container style={{ paddingTop: isMobile ? 30 : undefined }}>
        {loading && <LoadingOverlay />}
        <TextInput
          size={isMobile ? 40 : undefined}
          disabled={loading}
          onChangeText={handleChange}
          value={providerId}
          placeholder={t('Organization SSO key')}
          error={error}
        />
        {isMobile && <Spacer size={20} />}
        <Container hAlign="center">
          <BlockButton
            style={{
              fontSize: isMobile ? 32 : 16,
              padding: isMobile ? 40 : undefined,
              pointerEvents: valid ? 'auto' : 'fill',
              backgroundColor: colors.beeworkGold,
              color: colors.beeworkBlue,
              borderRadius: 27,
            }}
            disabled={!valid || loading}
            onClick={handleClick}
          >
            {t('Continue')}
          </BlockButton>
        </Container>
      </Container>
    </SignUpLayout>
  )
}
