import { HTMLAttributes } from 'react'
import Avatar from './Avatar'

type RoundUserAvatarProps = {
  name?: string
  avatar?: string
  size?: number
  onClick?: () => any
  style?: React.CSSProperties
  type?: 'group' | 'user'
} & HTMLAttributes<HTMLDivElement>

export default function RoundAvatar({
  name = '',
  avatar = '',
  size = 62,
  onClick,
  style,
  type = 'user',
  ...rest
}: RoundUserAvatarProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        alignContent: 'center',
        width: size,
        height: size,
        flexShrink: 0,
        borderRadius: type === 'user' ? size / 2 : size / 8,
        overflow: 'hidden',
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      <Avatar name={name} avatar={avatar} />
    </div>
  )
}
