import {
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  SuspenseList,
  useCallback,
} from 'react'
import { useWritableFeeds } from '../hooks/feeds'
import { useTranslate } from '../hooks/Internationalization'
import useCurrentUser from '../hooks/useCurrentUser'
import { Container } from '../layout/Layout'
import FeedListItem from './feed/FeedListItem'
import { FeedPostEditCardState } from './FeedPostEditCard'
import useFeatureFlag from '../hooks/useFeatureFlag'

export default function PostCreateCardSelectFeedState({
  header,
  onSelectFeed: selectFeed,
  setState,
}: {
  header: ReactNode
  onSelectFeed: Dispatch<SetStateAction<string | undefined>>
  setState: Dispatch<SetStateAction<FeedPostEditCardState>>
}) {
  const writableFeeds = useWritableFeeds()
  const t = useTranslate()

  const handleOnClick = useCallback(
    (id) => {
      selectFeed?.(id)
      setState('open')
    },
    [selectFeed, setState]
  )
  const currentUser = useCurrentUser()
  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)
  return (
    <Container>
      {header}
      <Container
        style={{
          maxHeight: 450,
          overflowY: 'auto',
          padding: '20px 0',
        }}
      >
        <SuspenseList>
          {[
            hasFeaturePersonalFeeds ? (
              <Suspense key="personal">
                <FeedListItem
                  avatar={currentUser?.avatar}
                  feedId={`personal_` + currentUser?.id}
                  name={t('Your feed')}
                  onClick={handleOnClick}
                />
              </Suspense>
            ) : (
              <></>
            ),
            ...writableFeeds.read().map((feedSnap) => {
              const feed = feedSnap.data()
              return (
                <Suspense fallback={null} key={feedSnap.id}>
                  <FeedListItem
                    onClick={handleOnClick}
                    avatar={feed?.avatar}
                    name={feed?.name}
                    feedId={feedSnap.id}
                  />
                </Suspense>
              )
            }),
          ]}
        </SuspenseList>
      </Container>
    </Container>
  )
}
