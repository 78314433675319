import { useEffect, useState } from 'react'
import { readCurrentUserId, readOrganization } from '../chat/auth'

export function usePranks(userId?: string) {
  const [data, setData] = useState<any>({})

  useEffect(() => {
    try {
      return readOrganization()
        .collection('users')
        .doc(userId || readCurrentUserId())
        .collection('extra')
        .doc('pranks')
        .onSnapshot(
          (snap) => {
            setData(snap.data() || {})
          },
          () => {}
        )
    } catch (_) {
      _
    }
  }, [userId])

  return data
}
