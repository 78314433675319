import Styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { privateRoomId } from 'connect-shared/new-chat'
import getStatus from 'connect-shared/statuses'
import { UserDocument } from 'connect-shared/types'
import User from 'connect-shared/User'
import { memo, useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Card from '../Card'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { firestore } from '../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import IconButton from '../IconButton'
import { ReactComponent as MailIcon } from '../icons/ico-send-email.svg'
import { ReactComponent as ChatIcon } from '../icons/ico-chat.svg'
import { Container, Spacer } from '../layout/Layout'
import PopoutMenu, { PopoutMenuOption } from '../PopoutMenu'
import StatusAvatar from '../StatusAvatar'
import { BodyText } from '../Text'
import { showSuccessToast } from '../Toast'
import { UserCardPopover } from '../user-profile/UserCard'
import TableInfoRow from '../wiki/TableInfoRow'
import ConfirmDeleteModal from '../ConfirmDeleteModal'
import { usePopoverHover } from '../PopoverWrapper'
import { language, useTranslate } from '../hooks/Internationalization'

export const HoverableContainer = Styled.div`
  background-color: transparent;

  &:hover {
      background-color: #EDEDED;
  }
`

function userRef(userId: string) {
  return firestore().collection('users').doc(userId)
}

interface UserCardItemViewProps {
  card: boolean
  userId: string
  email: string
  admin: boolean
  status: ReturnType<typeof getStatus>
  fullName: string
  jobTitle: string
  statusText?: string
  workplace: string
}

function UserCardItemView({
  card,
  userId,
  email,
  admin,
  status,
  fullName,
  jobTitle,
  statusText,
  workplace,
}: UserCardItemViewProps) {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const colors = useColors()
  const t = useTranslate()

  const currentUserId = useCurrentUserId()
  const history = useHistory()

  const hasWorkplaces = !useFirestoreCollection(
    useOrganizationDoc().collection('workplaces').limit(1)
  ).read().empty

  const startChat = useCallback(() => {
    const roomId = privateRoomId(currentUserId, userId)
    history.push(`/chat/rooms/${roomId}`)
  }, [history, currentUserId, userId])

  const navigateToProfile = useCallback(() => {
    history.push(`/users/${userId}`)
  }, [history, userId])

  const toggleAdmin = useCallback(async () => {
    if (currentUserId === userId) {
      throw new Error(t('Unable to demote self'))
    }
    await userRef(userId).update({
      organizationAdmin: !admin,
    })
    const toastMessage = admin
      ? t('%{fullName} is no longer an admin', { fullName })
      : t('%{fullName} is now admin', { fullName })
    showSuccessToast(toastMessage)
  }, [currentUserId, userId, admin, t, fullName])

  const deleteUser = useCallback(() => {
    userRef(userId).delete()
  }, [userId])

  const contextMenuOptions: PopoutMenuOption[] = [
    {
      text: t('View profile'),
      onClick: useCallback(() => {
        history.push(`/users/${userId}`)
      }, [userId, history]),
    },
  ]

  if (currentUserId !== userId) {
    const adminOptions = [
      {
        text: admin ? t('Demote admin') : t('Promote to admin'),
        onClick: toggleAdmin,
      },
      {
        text: t('Delete user'),
        onClick: () => setConfirmDeleteModalOpen(true),
        danger: true,
      },
    ]
    contextMenuOptions.push(...adminOptions)
  }

  const chatEnabled =
    useFirestoreDoc(useOrganizationDoc()).read().data()?.chatEnabled ?? true

  const { anchorProps, popOverProps } = usePopoverHover()

  if (card) {
    return (
      <>
        <Card
          style={{
            position: 'relative',
            padding: 0,
            width: 260,
            marginBottom: 20,
          }}
        >
          <ClickableOpacity
            onClick={navigateToProfile}
            style={{
              padding: 30,
              width: '100%',
              height: '100%',
            }}
          >
            <Container hAlign="center">
              <StatusAvatar size={80} userId={userId} />
              <Spacer size={6} />
              <BodyText
                style={{
                  maxWidth: 200,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {fullName}
              </BodyText>
              <BodyText
                style={{
                  maxWidth: 200,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  height: 21,
                  fontSize: 14,
                  color: colors.labelDim,
                }}
              >
                {jobTitle}
              </BodyText>
              <Spacer size={15} />
              <BodyText
                style={{
                  color: status.color,
                }}
              >
                {status.name[language]}
              </BodyText>
              <Spacer size={5} />
              <BodyText
                style={{
                  height: 21,
                  fontStyle: 'italic',
                  color: colors.chatRead,
                }}
              >
                {!!statusText && `"${statusText}"`}
              </BodyText>
            </Container>
          </ClickableOpacity>
          <Container
            horizontal
            style={{
              borderTop: `1px solid ${colors.inputBorder}`,
            }}
          >
            {chatEnabled && (
              <IconButton
                onClick={startChat}
                style={{
                  height: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: colors.selected,
                  flex: 1,
                }}
                Icon={ChatIcon}
                size={20}
                fill={colors.textDimHard}
              />
            )}
            <div
              style={{
                height: '100%',
                width: 1,
                backgroundColor: colors.inputBorder,
              }}
            />
            <a
              href={`mailto://${email}`}
              style={{
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colors.selected,
                flex: 1,
                cursor: 'pointer',
              }}
            >
              <MailIcon
                style={{
                  width: 20,
                  height: 20,
                }}
                fill={colors.textDimHard}
              />
            </a>
          </Container>
          <Container style={{ position: 'absolute', top: 0, right: 0 }}>
            <PopoutMenu options={contextMenuOptions} />
          </Container>
        </Card>
        <Spacer size={24} />
        {confirmDeleteModalOpen && (
          <ConfirmDeleteModal
            title={t('Delete %{fullName}?', { fullName })}
            text={t('Are you sure you want to delete %{fullName}?', {
              fullName,
            })}
            onRequestClose={() => setConfirmDeleteModalOpen(false)}
            onConfirm={deleteUser}
          />
        )}
      </>
    )
  }

  return (
    <HoverableContainer
      style={{
        padding: '12px 0 12px 0',
        borderBottomColor: colors.selected,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
      }}
    >
      <Container
        horizontal
        style={{
          position: 'relative',
        }}
      >
        <UserCardPopover userId={userId} {...popOverProps} />
        <Link
          to={`/users/${userId}`}
          style={{ textDecoration: 'none' }}
          {...anchorProps}
        >
          <StatusAvatar userId={userId} size={46} style={{ marginLeft: 10 }} />
        </Link>
        <Spacer size={15} />
        <TableInfoRow style={{ flex: 1.5 }} text={fullName} />
        <TableInfoRow text={jobTitle || '-'} />
        {hasWorkplaces && <TableInfoRow text={workplace || '-'} />}
        <Container vAlign="center">
          <PopoutMenu options={contextMenuOptions} />
        </Container>
      </Container>
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          title={t('Delete %{fullName}?', { fullName })}
          text={t('Are you sure you want to delete %{fullName}?', { fullName })}
          onRequestClose={() => setConfirmDeleteModalOpen(false)}
          onConfirm={deleteUser}
        />
      )}
    </HoverableContainer>
  )
}

const MemoizedView = memo(UserCardItemView)

type UserItemProps = {
  userSnap: QueryDocumentSnapshot<UserDocument>
  card?: boolean
}
export default function UserCardItem({ userSnap, card }: UserItemProps) {
  const user = new User(userSnap)
  const status = getStatus(user)

  return (
    <MemoizedView
      card={!!card}
      userId={user.id}
      email={user.email}
      admin={!!user.organizationAdmin}
      status={status}
      fullName={user.fullName}
      jobTitle={user.jobTitle || '-'}
      workplace={user.workplace ?? ''}
    />
  )
}
