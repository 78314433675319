import React, { ReactNode } from 'react'
import ClickableOpacity, {
  ClickableOpacityProps,
} from '../../../ClickableOpacity'
import { ReactComponent as CheckIcon } from '../../../icons/ico-check.svg'
import { useColors } from '../../../Colors'
import { Container } from '../../../layout/Layout'

type OptionButtonProps = {
  selected?: boolean
  disabled?: boolean
  onClick?: () => void
  children?: ReactNode
} & ClickableOpacityProps

export default function OptionButton({
  selected,
  disabled,
  onClick,
  children,
  style,
}: OptionButtonProps) {
  const colors = useColors()
  return (
    <ClickableOpacity
      hover
      hoverColor={colors.border}
      style={{
        cursor: 'pointer',
        display: 'flex',
        fontSize: 12,
        fontWeight: 600,
        color: selected ? colors.textInverted : colors.text,
        opacity: disabled ? 0.2 : 1,
        alignItems: 'center',
        borderRadius: 10,
        ...style,
      }}
      onClick={disabled ? undefined : onClick}
    >
      <Container
        horizontal
        vAlign="center"
        style={{
          border: `1px solid ${selected ? colors.active : colors.chatTime}`,
          padding: selected ? '2px 7px' : '2px 10px',
          borderRadius: 10,
          backgroundColor: selected ? colors.active : 'transparent',
        }}
      >
        {selected && (
          <CheckIcon
            style={{ marginRight: 2 }}
            height={15}
            width={15}
            fillColor={colors.textInverted}
          />
        )}
        {children}
      </Container>
    </ClickableOpacity>
  )
}
