import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { PopoverProps } from '@mui/material'
import { PostDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import { Suspense, useCallback, useState } from 'react'
import Card from './Card'
import { useColors } from './Colors'
import { useCurrentUserId, useOrganizationDoc } from './hooks/auth'
import { useFeedSnap, useSubscriptions } from './hooks/feeds'
import { useDateFns, useTranslate } from './hooks/Internationalization'
import useCurrentUser from './hooks/useCurrentUser'
import { Container, Pusher, Spacer } from './layout/Layout'
import OutlineButton from './OutlineButton'
import PopoverWrapper from './PopoverWrapper'
import RoundAvatar from './RoundAvatar'
import { BodyText } from './Text'
import { showErrorToast, showSuccessToast } from './Toast'
import { useHistory } from 'react-router-dom'

type FeedCardProps = {
  feedId: string
}
function FeedCard({ feedId }: FeedCardProps) {
  const [loading, setLoading] = useState(false)
  const t = useTranslate()
  const colors = useColors()
  const feedSnap = useFeedSnap(feedId)

  const feedRef = useOrganizationDoc().collection('feeds').doc(feedId)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )
  const feed = feedSnap.read().data()
  const latestPostRef = feedSnap
    .read()
    .ref.collection('posts')
    .orderBy('createdDate', 'desc')
    .limit(1)

  const latestPost:
    | firebase.firestore.QueryDocumentSnapshot<PostDocument>
    | undefined = useFirestoreCollection(latestPostRef).read()
    .docs[0] as firebase.firestore.QueryDocumentSnapshot<PostDocument>

  let latestPostCreatedDate: string | undefined = undefined
  const { formatDistance } = useDateFns()
  if (latestPost) {
    latestPostCreatedDate = t('Posted %{dateTime} ago', {
      dateTime: formatDistance(
        new Date(),
        latestPost?.data().createdDate.toDate()
      ),
    })
  }

  const currentUser = useCurrentUser()
  const currentUserId = useCurrentUserId()

  const followFeed = useCallback(async () => {
    setLoading(true)
    try {
      const subscribe = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('addCombinedFeedSubscriptions')
      await subscribe({
        combinedFeedIds: [currentUserId],
        feedIds: [feedId],
      })
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
      setLoading(false)
    }
    setLoading(false)
    showSuccessToast(`You now follow ${feed?.name} `)
  }, [currentUserId, feed?.name, feedId, t])

  const isAdmin =
    feedSnap.read().data()?.admins?.[currentUserId] === true ||
    currentUser?.organizationAdmin ||
    false

  const organizationDoc = useOrganizationDoc()

  const unfollow = useCallback(async () => {
    setLoading(true)
    try {
      const batch = firebase.firestore().batch()
      if (isAdmin) {
        batch.update(
          feedSnap.read().ref,
          `admins.${currentUserId}`,
          firebase.firestore.FieldValue.delete()
        )

        const combinedFeedRef = organizationDoc
          .collection('combinedFeeds')
          .doc(currentUserId)

        batch.update(
          combinedFeedRef,
          `subscriptions.${feedId}`,
          firebase.firestore.FieldValue.delete()
        )

        await batch.commit()
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
      showErrorToast(t('Something went wrong, please try again later'))
    }

    setLoading(false)
    showSuccessToast(t('You have been removed from the feed'))
  }, [currentUserId, feedId, feedSnap, isAdmin, organizationDoc, t])

  const statisticsText = t('%{memberCount} Followers • %{postCount} Posts', {
    memberCount: followers.read().data()?.ids.length || 0,
    postCount: feed?.postCount || 0,
  })

  const history = useHistory()

  const subscriptions = useSubscriptions().read()
  const isSubscribed = subscriptions.has(feedId)

  if (!feed) {
    return null
  }

  return (
    <Card
      style={{
        display: 'flex',
        padding: 16,
        boxShadow: `0px 0px 3px ${colors.shadow}`,
        width: 300,
        border: `1px solid ${colors.inputBorder}`,
      }}
    >
      <Suspense fallback={null}>
        <RoundAvatar
          style={{
            alignSelf: 'flex-start',
          }}
          size={60}
          avatar={feed.avatar}
          name={feed.name}
        />
        <BodyText
          style={{
            fontSize: 16,
            wordWrap: 'break-word',
          }}
        >
          {feed.name}
        </BodyText>
        <BodyText style={{ fontSize: 14, color: colors.labelStrong }}>
          {statisticsText}
        </BodyText>
        <BodyText style={{ fontSize: 14, color: colors.labelStrong }}>
          {latestPostCreatedDate}
        </BodyText>
        <Spacer size={16} />
        <BodyText
          style={{
            fontSize: 14,
            color: colors.labelInfoText,
            wordWrap: 'break-word',
          }}
        >
          {feed.description}
        </BodyText>
        <Spacer size={20} />
        <Pusher />
        <Container
          hAlign="left"
          style={{
            flexDirection: 'row',
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <OutlineButton
            onClick={() => {
              history.push(`/feeds/${feedId}`)
            }}
          >
            {t('Go to feed')}
          </OutlineButton>
          {isSubscribed ? (
            <OutlineButton
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation()
                unfollow()
              }}
              danger
            >
              {t('Unfollow')}
            </OutlineButton>
          ) : (
            <OutlineButton
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation()
                followFeed()
              }}
              style={{ alignSelf: 'inline-flex' }}
            >
              {t('Follow')}
            </OutlineButton>
          )}
        </Container>
      </Suspense>
    </Card>
  )
}

type FeedInfoModalProps = {
  feedId: string
} & PopoverProps

export default function FeedInfoModal({ feedId, ...rest }: FeedInfoModalProps) {
  return (
    <Suspense fallback={null}>
      <PopoverWrapper {...rest}>
        <FeedCard feedId={feedId} />
      </PopoverWrapper>
    </Suspense>
  )
}
