import { css, SerializedStyles } from '@emotion/react'
import { useAllUsers } from 'connect-shared/AllUsersContext'
import { isEqualMention, Mention } from 'connect-shared/types'
import { userFullName } from 'connect-shared/User'
import Linkify from 'linkify-react'
import React, { CSSProperties, ReactNode } from 'react'
import { useColors } from './Colors'
import LinkText from './LinkText'
import { usePopoverHover } from './PopoverWrapper'
import { UserCardPopover } from './user-profile/UserCard'

function linkify(children: ReactNode | ReactNode[]) {
  return (
    <span
      css={css`
        a {
          text-decoration: underline;
        }
      `}
    >
      <Linkify options={{ target: '_blank' }} tagName="span">
        {children}
      </Linkify>
    </span>
  )
}

const MentionItem = React.memo(
  function MentionText({
    mention,
    color,
  }: {
    mention: Mention
    color: string
  }) {
    const { allUsers } = useAllUsers()
    const user = allUsers.get(mention.userId)
    const { anchorProps, popOverProps } = usePopoverHover()

    if (!user) {
      return <span style={{ fontWeight: 600 }}>{userFullName(user)}</span>
    } else {
      return (
        <>
          <UserCardPopover
            userId={user.id}
            style={{ display: 'inline' }}
            {...popOverProps}
          />
          <LinkText
            to={'/users/' + user.id}
            {...anchorProps}
            style={{ color, fontWeight: 600 }}
          >
            @{userFullName(user)}
          </LinkText>
        </>
      )
    }
  },
  (prev, next) => isEqualMention(prev.mention, next.mention)
)

function mentionify(
  text: string,
  mentions: Mention[] | undefined,
  color: string
) {
  if (!text) return null

  const sorted = [...(mentions || [])].sort(
    (a, b) => (a.index || 0) - (b.index || 0)
  )

  const parts: (string | Mention)[] = []

  let prevMention: Mention | undefined = undefined
  for (const mention of sorted) {
    if (mention.index === undefined) continue

    parts.push(
      text.slice(
        prevMention?.index === undefined
          ? 0
          : prevMention.index + prevMention.text.length,
        mention.index
      )
    )
    parts.push(mention)
    prevMention = mention
  }

  parts.push(
    text.slice(
      prevMention?.index === undefined
        ? 0
        : prevMention.index + prevMention.text.length
    )
  )

  return (
    <>
      {parts.map((part, i) => {
        if (typeof part === 'string') {
          return <span key={i}>{part}</span>
        } else {
          return <MentionItem mention={part} color={color} key={i} />
        }
      })}
    </>
  )
}

type HighlightTextProps = {
  highlightLinks?: boolean
  highlightMentions?: boolean
  mentions?: Mention[]
  text?: string
  linkColor?: string
  style?: CSSProperties
  cssStyle?: SerializedStyles
}

export default function HighlightText({
  highlightLinks = true,
  highlightMentions = true,
  mentions,
  text = '',
  style,
  cssStyle,
  ...props
}: HighlightTextProps) {
  const colors = useColors()
  const linkColor = props.linkColor || colors.linkText

  let children: ReactNode = highlightMentions
    ? mentionify(text, mentions, linkColor)
    : text

  if (highlightLinks) {
    children = linkify(children)
  }

  return (
    <span
      style={style}
      css={css`
        a {
          color: ${linkColor};
        }
        ${cssStyle}
      `}
    >
      {children}
    </span>
  )
}
