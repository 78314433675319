import React from 'react'
import { useColors } from './Colors'
import { LowTooltip } from './feeds/comments/CommentItemHeader'
import { css } from '@emotion/css'

type TooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined

export default function HelpHover({
  text,
  style,
  tooltipPlacement = 'top',
}: {
  text: string
  style?: React.CSSProperties
  tooltipPlacement?: TooltipPlacement
}) {
  const colors = useColors()
  return (
    <LowTooltip
      placement={tooltipPlacement}
      title={text}
      style={{
        whiteSpace: 'pre-wrap',
        padding: 20,
        lineHeight: 1.4,
        ...style,
        backgroundColor: colors.foreground,
        zIndex: 12,
      }}
    >
      <div
        css={css`
          border: 1px solid ${colors.labelDim};
          color: ${colors.labelDim};

          &:hover {
            background-color: ${colors.beeworkBlue};
            color: ${colors.textInverted};
            border: 1px solid ${colors.beeworkBlue};
          }
        `}
        style={{
          width: 16,
          height: 16,
          paddingTop: 1,
          fontSize: 11,
          borderRadius: 16 / 2,
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          lineHeight: 1,
          fontWeight: 700,
        }}
      >
        ?
      </div>
    </LowTooltip>
  )
}
