import { useFirestoreDoc } from '@iwikal/reactfire'
import firebase from 'firebase/app'

export default function PreloadDoc({
  docRef,
}: {
  docRef: firebase.firestore.DocumentReference
}) {
  useFirestoreDoc(docRef)
  return null
}
