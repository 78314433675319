import { FileInfo } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/storage'
import { Dispatch, useMemo } from 'react'
import { useCurrentUserId, useOrganizationId } from './auth'
import {
  addUpload,
  deleteUploads,
  removeUpload,
  Upload,
  UploadAction,
  useUploads,
} from './useUploads'

export type FileUpload = Upload<FileInfo>

export type FileUploadAction = UploadAction<FileInfo>

type Reference = firebase.storage.Reference

export function addFile(
  storageRef: Reference,
  file: File,
  setState: Dispatch<FileUploadAction>
) {
  return addUpload(
    file,
    setState,
    async (id, startUploadTask): Promise<FileInfo> => {
      const ref = storageRef.child(id).child(file.name)
      await startUploadTask(ref, file)
      return {
        uri: await ref.getDownloadURL(),
        fileName: file.name,
        mimeType: file.type,
        size: file.size,
      }
    }
  )
}

export function deleteFiles(setState: Dispatch<FileUploadAction>) {
  return deleteUploads(setState)
}

export function removeFile(
  uploadId: string,
  setState: Dispatch<FileUploadAction>
) {
  return removeUpload(uploadId, setState)
}

export default function useFileUploads() {
  return useUploads<FileInfo>()
}

export function useFeedFileRef(feedId: string, postId: string) {
  const organizationId = useOrganizationId()
  const currentUserId = useCurrentUserId()

  return useMemo(() => {
    return firebase
      .storage()
      .ref()
      .child('organizations')
      .child(organizationId)
      .child('users')
      .child(currentUserId)
      .child('feeds')
      .child(feedId)
      .child('posts')
      .child(postId)
      .child('files')
  }, [organizationId, currentUserId, feedId, postId])
}

export function useChatFileRef() {
  const organizationId = useOrganizationId()
  const currentUserId = useCurrentUserId()

  return useMemo(() => {
    return firebase
      .storage()
      .ref()
      .child('organizations')
      .child(organizationId)
      .child('users')
      .child(currentUserId)
      .child('chat')
      .child('files')
  }, [organizationId, currentUserId])
}
