import { useAllUsers } from 'connect-shared/AllUsersContext'
import { colors } from 'connect-shared/constants'
import { usePranks } from './hooks/pranks'
import useOrganizationTheme from './hooks/useOrganizationColors'
import { placeholderAvatar } from './placeholderAvatar'

interface UserAvatarProps {
  userId?: string
}

export default function UserAvatar({ userId }: UserAvatarProps) {
  const { allUsers } = useAllUsers()
  const user = allUsers.get(userId || '')

  const rotation = usePranks().avatarRotation

  const avatarStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 1000ms',
    transform: rotation === undefined ? undefined : `rotate(${rotation}deg)`,
  }

  const { primary, primaryText } = useOrganizationTheme()

  if (!user) {
    return (
      <div style={{ ...avatarStyle, backgroundColor: colors.active }}></div>
    )
  }

  const src =
    user.avatar ||
    placeholderAvatar(user.fullName, primary, primaryText).toString()

  return <img alt={''} src={src} style={avatarStyle} />
}
