import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { FeedDocument, PostDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useColors } from '../../Colors'
import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import FeedSettingsButton from '../../FeedSettingsButton'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { Container, Spacer } from '../../layout/Layout'
import PopoutMenu, { PopoutMenuOption } from '../../PopoutMenu'
import { BodyText } from '../../Text'
import { showErrorToast, showSuccessToast } from '../../Toast'
import { FeedError } from './Feed'
import InviteFeedFollowersModal from './InviteFeedUsersModal'
import RoundAvatar from '../../RoundAvatar'
import { useForcedSubscriptions, useSubscriptions } from '../../hooks/feeds'
import { useDateFns, useTranslate } from '../../hooks/Internationalization'

type FeedSettingsCardProps = {
  feedSnap: firebase.firestore.DocumentSnapshot<FeedDocument>
  isAdmin: boolean
  postId?: string
}

export default function FeedSettingsCard({
  feedSnap,
  isAdmin,
  postId,
}: FeedSettingsCardProps) {
  const [inviteMembersOpen, setInviteMembersOpen] = useState(false)
  const closeInviteMembers = useCallback(() => {
    setInviteMembersOpen(false)
  }, [])
  const openInviteMembers = useCallback(() => {
    setInviteMembersOpen(true)
  }, [])

  const [confirmLeaveModalOpen, setConfirmLeaveModalOpen] = useState(false)

  const closeConfirmLeaveModal = useCallback(() => {
    setConfirmLeaveModalOpen(false)
  }, [])
  const openConfirmLeaveModal = useCallback(() => {
    setConfirmLeaveModalOpen(true)
  }, [])

  const feed = feedSnap.data()

  const colors = useColors()
  const t = useTranslate()

  const history = useHistory()
  const navigateToPosts = useCallback(() => {
    history.push(`/feeds/${feedSnap.id}/posts`)
  }, [feedSnap.id, history])

  const navigateToAbout = useCallback(() => {
    history.push(`/feeds/${feedSnap.id}/about`)
  }, [feedSnap.id, history])

  const navigateToSettings = useCallback(() => {
    history.push(`/feeds/${feedSnap.id}/settings`)
  }, [feedSnap.id, history])

  const navigateToMembers = useCallback(() => {
    history.push(`/feeds/${feedSnap.id}/members`)
  }, [feedSnap.id, history])

  const currentUserId = useCurrentUserId()
  const organizationDoc = useOrganizationDoc()

  const removeFromFeed = useCallback(async () => {
    const batch = firebase.firestore().batch()
    if (isAdmin) {
      batch.update(
        feedSnap.ref,
        ['admins', currentUserId].join('.'),
        firebase.firestore.FieldValue.delete()
      )
    }

    const combinedFeedRef = organizationDoc
      .collection('combinedFeeds')
      .doc(currentUserId)
    batch.update(
      combinedFeedRef,
      ['subscriptions', feedSnap.id].join('.'),
      firebase.firestore.FieldValue.delete()
    )

    await batch.commit().catch((e) => {
      showErrorToast(t('Something went wrong, please try again later'))
      throw e
    })
    history.push('/feeds')
    showSuccessToast(t('You have been removed from the feed'))
  }, [
    currentUserId,
    feedSnap.id,
    feedSnap.ref,
    history,
    isAdmin,
    organizationDoc,
    t,
  ])

  const subscriptions = useSubscriptions().read()
  const isSubscribed = subscriptions.has(feedSnap.id)
  const forcedSubscriptions = useForcedSubscriptions()
  const isForced = forcedSubscriptions.read().has(feedSnap.id)

  const options: PopoutMenuOption[] = []

  if (isAdmin) {
    options.push(
      {
        text: t('Invite new followers'),
        onClick: openInviteMembers,
      },
      {
        text: t('Change settings'),
        onClick: navigateToSettings,
      }
    )
  }

  if (isSubscribed && !isForced) {
    options.push({
      text: t('Leave & unfollow feed'),
      onClick: openConfirmLeaveModal,
      danger: true,
    })
  }

  const latestPostRef = feedSnap.ref
    .collection('posts')
    .orderBy('createdDate', 'desc')
    .limit(1)

  const latestPost:
    | firebase.firestore.QueryDocumentSnapshot<PostDocument>
    | undefined = useFirestoreCollection(latestPostRef).read()
    .docs[0] as firebase.firestore.QueryDocumentSnapshot<PostDocument>

  const { formatDistance } = useDateFns()
  let latestPostCreatedDate: string | undefined = undefined
  if (latestPost) {
    latestPostCreatedDate = t('Posted %{distance} ago', {
      distance: formatDistance(
        new Date(),
        latestPost?.data().createdDate.toDate()
      ),
    })
  }

  const feedRef = useOrganizationDoc().collection('feeds').doc(feedSnap.id)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )

  const memberCountText = followers.read().data()?.ids.length || 0
  const postCountText = feed?.postCount || 0

  const statisticsText = useMemo(() => {
    if (latestPostCreatedDate) {
      return t(
        '%{memberCount} Followers • %{postCount} Posts • %{latestPostCreatedDate}',
        {
          latestPostCreatedDate,
          postCount: postCountText,
          memberCount: memberCountText,
        }
      )
    } else {
      return t('%{memberCount} Followers • %{postCount} Posts', {
        postCount: postCountText,
        memberCount: memberCountText,
      })
    }
  }, [latestPostCreatedDate, memberCountText, postCountText, t])

  if (!feed) {
    return <FeedError />
  }

  return (
    <>
      <Container
        style={{
          boxShadow: `0 1px 3px ${colors.announcementShadow}`,
          borderRadius: 5,
          backgroundColor: colors.foreground,
          padding: 20,
        }}
      >
        <Container vAlign="center" horizontal style={{ paddingBottom: 20 }}>
          <Container>
            <RoundAvatar size={80} avatar={feed.avatar} name={feed.name} />
          </Container>
          <Spacer size={16} />
          <Container
            flex={1}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <BodyText
              style={{
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {feed.name}
            </BodyText>
            <BodyText style={{ fontSize: 14, color: colors.labelStrong }}>
              {statisticsText}
            </BodyText>
          </Container>
          <PopoutMenu menuWidth={200} options={options} />
        </Container>
        <Container horizontal>
          <FeedSettingsButton
            active={postId === 'posts'}
            onClick={navigateToPosts}
            id="navigate_feed"
          >
            {t('Feed')}
          </FeedSettingsButton>
          <Spacer size={4} />
          <FeedSettingsButton
            active={postId === 'about'}
            onClick={navigateToAbout}
          >
            {t('About')}
          </FeedSettingsButton>
          {isAdmin && (
            <>
              <Spacer size={4} />
              <FeedSettingsButton
                active={postId === 'settings'}
                onClick={navigateToSettings}
              >
                {t('Settings')}
              </FeedSettingsButton>
            </>
          )}
          <Spacer size={4} />
          <FeedSettingsButton
            active={postId === 'members'}
            onClick={navigateToMembers}
          >
            {t('Followers')}
          </FeedSettingsButton>
        </Container>
      </Container>
      <InviteFeedFollowersModal
        feedSnap={feedSnap}
        open={inviteMembersOpen}
        onRequestClose={closeInviteMembers}
      />
      {confirmLeaveModalOpen && (
        <ConfirmDeleteModal
          onRequestClose={closeConfirmLeaveModal}
          onConfirm={removeFromFeed}
          title={t('Leave %{feedName}?', {
            feedName: feedSnap.data()?.name,
          })}
          text={t('Are you sure you sure you wish to leave %{feedName}', {
            feedName: feedSnap.data()?.name,
          })}
          buttonText={t('Leave')}
        />
      )}
    </>
  )
}
