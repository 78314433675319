import { Modal } from '@mui/material'
import { feedIdFromPostPaths, feedIdFromPostSnap } from 'connect-shared/feed'
import { Timestamp } from 'connect-shared/firestore'
import { PostDocument } from 'connect-shared/types'
import { differenceInMinutes } from 'date-fns'
import firebase from 'firebase/app'
import React, { Suspense, useCallback, useMemo, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useHistory } from 'react-router-dom'
import BlockButton from '../../BlockButton'
import Card from '../../Card'
import { sendFeedPostReminder } from '../../cloud-functions'
import { useColors } from '../../Colors'
import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import {
  useFeedSnap,
  usePostPollResultSnap,
  usePostPollVotesSnap,
} from '../../hooks/feeds'
import { useTranslate } from '../../hooks/Internationalization'
import useCurrentUser from '../../hooks/useCurrentUser'
import { Container, Pusher } from '../../layout/Layout'
import ModalCloseButton from '../../ModalCloseButton'
import PopoutMenu, { PopoutMenuOption } from '../../PopoutMenu'
import { BodyText } from '../../Text'
import { showErrorToast, showSuccessToast } from '../../Toast'
import AnnouncementWrapper from '../feed/AnnouncementWrapper'
import { useFeedInteractivityDisabled } from '../feed/Feed'
import FeedPostEditCard from '../FeedPostEditCard'
import { pollDisabled } from '../PollEditor'
import EventCard from './event/EventCard'
import FeedPostAttachments from './FeedPostAttachments'
import FeedPostBody from './FeedPostBody'
import FeedPostCommentButton from './FeedPostCommentButton'
import FeedPostCommentCounter from './FeedPostCommentCounter'
import FeedPostHeader from './FeedPostHeader'
import FeedPostLikeButton from './FeedPostLikeButton'
import FeedPostLikeCounter from './FeedPostLikeCounter'
import FeedPostLikedBy from './FeedPostLikedBy'
import FeedPostGallery from './gallery/FeedPostGallery'
import Poll from './Poll'

function WaitFiveMinutesModal({
  onRequestClose,
  latestConfirmReminder,
}: {
  onRequestClose: () => void
  latestConfirmReminder: Timestamp
}) {
  const sentMinutesAgo = differenceInMinutes(
    new Date(),
    latestConfirmReminder.toDate()
  )

  const colors = useColors()
  const t = useTranslate()

  return (
    <Modal
      open
      onClose={onRequestClose}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Container
        style={{
          backgroundColor: colors.background,
          width: 350,
          padding: 20,
          borderRadius: 12,
          boxShadow: `2px 2px 1px 0px ${colors.shadow}`,
        }}
      >
        <BodyText style={{ fontWeight: 600, fontSize: 16 }}>
          {sentMinutesAgo < 1
            ? t('You sent a reminder less than one minute ago.')
            : t(
                'You sent a reminder %{smart_count} minute ago. |||| You sent a reminder %{smart_count} minutes ago.',
                { smart_count: sentMinutesAgo }
              )}
        </BodyText>
        <BodyText style={{ color: colors.textDim }}>
          {t('Wait at least 5 minutes and try again.')}
        </BodyText>
        <BlockButton style={{ marginTop: 20 }} onClick={onRequestClose}>
          {t('Ok')}
        </BlockButton>
      </Container>
    </Modal>
  )
}

/**
 * Indicates what feed the post is showing in
 */
export enum PostContext {
  HOME_FEED,
  COMBINED_FEED,
  ORG_FEED,
}

type FeedPostProps = {
  postSnap: firebase.firestore.QueryDocumentSnapshot<PostDocument>
  context?: PostContext
  firstUnseen?: boolean
  onCommentClick?: () => void
}

export default function FeedPost({
  postSnap,
  firstUnseen,
  context = PostContext.ORG_FEED,
  onCommentClick,
}: FeedPostProps) {
  const feedId = feedIdFromPostSnap(postSnap)
  const postId = postSnap.id

  const colors = useColors()
  const t = useTranslate()
  const interactivityDisabled = useFeedInteractivityDisabled(feedId)

  const pollVotesResource = usePostPollVotesSnap(feedId, postId)
  const pollResultResource = usePostPollResultSnap(feedId, postId)

  const history = useHistory()
  const feedResource = useFeedSnap(feedId)
  const organization = useOrganizationDoc()
  const currentUserId = useCurrentUserId()
  const [editMode, setEditMode] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [likedByModalVisible, setLikedByModalVisible] = useState(false)

  const feed = feedResource.read().data()
  const post = postSnap.data()

  const currentUser = useCurrentUser()
  const isAdmin = (feed?.admins && feed.admins[currentUserId]) || false
  const isAuthor = post.author === currentUserId
  const allowEditMode = isAdmin || isAuthor || currentUser?.organizationAdmin

  const isAnnouncement = post.readReceipts || false
  const [loading, setLoading] = useState(false)

  const handleRemovePost = useCallback(async () => {
    setLoading(true)
    try {
      await organization
        .collection('feeds')
        .doc(feedId)
        .collection('posts')
        .doc(postId)
        .delete()

      showSuccessToast(t('Post deleted'))
      setLoading(false)
      setConfirmModalVisible(false)
    } catch (err) {
      showErrorToast(t('Something went wrong, please try again later'))
      setLoading(false)
      setConfirmModalVisible(false)
      throw err
    }
  }, [feedId, organization, postId, t])

  const handleCommentClick = useCallback(() => {
    if (onCommentClick) {
      onCommentClick()
    } else if (context === PostContext.HOME_FEED) {
      history.push(`/home/posts/${feedId}/${postId}`)
    } else if (context === PostContext.COMBINED_FEED) {
      history.push(`/feeds/all/${feedId}/${postId}`)
    } else if (context === PostContext.ORG_FEED) {
      history.push(`/feeds/${feedId}/${postId}`)
    }
  }, [onCommentClick, context, history, feedId, postId])

  const [reminderModalState, setReminderModalState] = useState<
    'disabled' | 'open' | 'closed'
  >('closed')

  const openReminderConfirmation = useCallback(() => {
    if (
      post.latestConfirmReminder &&
      differenceInMinutes(new Date(), post.latestConfirmReminder.toDate()) < 5
    ) {
      setReminderModalState('disabled')
    } else {
      setReminderModalState('open')
    }
  }, [post.latestConfirmReminder])

  const sendReminder = useCallback(async () => {
    const feedId = feedIdFromPostPaths(
      post.originalCollectionPath,
      postSnap.ref.parent.path
    )
    const postId = postSnap.ref.id
    await sendFeedPostReminder(feedId, postId)
    setReminderModalState('closed')
    showSuccessToast(t('Reminder has been sent'))
  }, [
    post.originalCollectionPath,
    postSnap.ref.id,
    postSnap.ref.parent.path,
    t,
  ])

  const options: PopoutMenuOption[] = []

  if (!interactivityDisabled) {
    options.push({
      text: t('Show who has liked'),
      onClick: () => setLikedByModalVisible(true),
    })
  }
  const goToFeed = useCallback(() => {
    const personalId = feedId.split('personal_')
    if (personalId[1]) {
      history.push(`/users/${personalId[1]}`)
    } else {
      history.push(`/feeds/${feedId}`)
    }
  }, [feedId, history])

  if (allowEditMode && !pollDisabled(pollResultResource.read().data())) {
    options.push({
      text: t('Edit post'),
      onClick: () => setEditMode(true),
    })
  }

  if (allowEditMode && pollDisabled(pollResultResource.read().data())) {
    options.push({
      text: t('Edit'),
      disabled: true,
      tooltip: t('Edit disabled, voting has started.'),
    })
  }

  if (allowEditMode && post.readReceipts) {
    options.push({
      text: t('Send reminder'),
      tooltip: t('Send a reminder to everyone who have not yet confirmed'),
      onClick: openReminderConfirmation,
    })
  }

  if (allowEditMode) {
    options.push({
      danger: true,
      text: t('Delete post'),
      onClick: () => setConfirmModalVisible(true),
    })
  }

  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const imageUrls = useMemo(() => {
    return (
      post.images?.map((image) => {
        return image.fullImage.uri
      }) || []
    )
  }, [post.images])

  if (allowEditMode && editMode) {
    return (
      <FeedPostEditCard
        initialFeedId={feedId}
        style={{ marginBottom: 35 }}
        onEditCancel={() => setEditMode(false)}
        postSnapToEdit={postSnap}
      />
    )
  }

  const lineStyle: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    height: 1,
    backgroundColor: colors.labelDimHard,
  }

  return (
    <>
      <AnnouncementWrapper
        introAsModal
        postSnap={postSnap}
        shouldRender={isAnnouncement}
        style={{
          marginBottom: 30,
          width: '100%',
        }}
      >
        <Card
          style={{
            opacity: loading ? 0.4 : 1,
            padding: 0,
            borderBottomLeftRadius: isAnnouncement ? 0 : 5,
            borderBottomRightRadius: isAnnouncement ? 0 : 5,
          }}
          className="feed_post"
        >
          <Container
            style={{
              marginTop: 20,
              marginBottom: 0,
            }}
          >
            <Container style={{ marginLeft: 20, marginRight: 20 }}>
              <Container
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 17,
                }}
              >
                <FeedPostHeader
                  feedId={feedId}
                  postSnap={postSnap}
                  onClick={goToFeed}
                />
                {!!options.length && (
                  <Container
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      flex: '0 1 auto',
                      alignItems: 'center',
                    }}
                  >
                    <PopoutMenu options={options} />
                  </Container>
                )}
              </Container>
              <Container>
                <FeedPostBody postSnap={postSnap} />
                <FeedPostAttachments
                  style={{ marginTop: 10 }}
                  feedId={feedId}
                  postId={postId}
                />
                <FeedPostGallery
                  style={{ cursor: 'pointer' }}
                  postSnap={postSnap}
                  onImageClick={(index) => {
                    setLightboxIndex(index)
                    setLightboxOpen(true)
                  }}
                />
                {post.poll && (
                  <Poll
                    feedId={feedId}
                    postId={postId}
                    votesResource={pollVotesResource}
                    poll={post.poll}
                    resultResource={pollResultResource}
                  />
                )}
                {post.event && <EventCard feedId={feedId} postId={postId} />}
              </Container>
              <Container
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '15px',
                  marginTop: '20px',
                  height: '25px',
                  fontSize: '14px',
                }}
              >
                {!interactivityDisabled && (
                  <>
                    <FeedPostLikeCounter
                      feedId={feedId}
                      postId={postId}
                      onClick={() => setLikedByModalVisible(true)}
                    />
                    <Pusher />
                    <FeedPostCommentCounter
                      feedId={feedId}
                      postId={postId}
                      onClick={handleCommentClick}
                    />
                  </>
                )}
              </Container>
            </Container>
            {!interactivityDisabled && (
              <Container
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontSize: 12,
                }}
              >
                <FeedPostLikeButton feedId={feedId} postId={postId} />
                <FeedPostCommentButton onClick={handleCommentClick} />
              </Container>
            )}
          </Container>
          {confirmModalVisible && (
            <ConfirmDeleteModal
              loading={loading}
              title={t('Delete post?')}
              text={t('Are you sure you want to delete the post?')}
              onConfirm={handleRemovePost}
              onRequestClose={() => setConfirmModalVisible(false)}
            />
          )}
          {likedByModalVisible && (
            <Modal
              onClose={() => setLikedByModalVisible(false)}
              open={likedByModalVisible}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Suspense fallback={null}>
                <Container
                  style={{
                    position: 'relative',
                    width: 480,
                    zIndex: 1,
                  }}
                >
                  <FeedPostLikedBy
                    style={{
                      borderRadius: 5,
                    }}
                    feedId={feedId}
                    postId={postId}
                  />
                  <ModalCloseButton
                    onClick={() => setLikedByModalVisible(false)}
                  />
                </Container>
              </Suspense>
            </Modal>
          )}
        </Card>
      </AnnouncementWrapper>
      {firstUnseen && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <div style={lineStyle} />
          <p
            style={{
              margin: '0 10px',
              fontSize: 10,
              color: colors.label,
            }}
          >
            {t('New Posts')}
          </p>
          <div style={lineStyle} />
        </div>
      )}
      {reminderModalState === 'open' && (
        <ConfirmDeleteModal
          title={t('Send reminder?')}
          text={t(
            'Are you sure you want to send a reminder to all followers who have not yet confirmed?'
          )}
          onRequestClose={() => setReminderModalState('closed')}
          onConfirm={sendReminder}
          buttonText="Yes"
        />
      )}
      {reminderModalState === 'disabled' && post.latestConfirmReminder && (
        <WaitFiveMinutesModal
          latestConfirmReminder={post.latestConfirmReminder}
          onRequestClose={() => setReminderModalState('closed')}
        />
      )}

      {lightboxOpen && (
        <Lightbox
          mainSrc={imageUrls[lightboxIndex]}
          nextSrc={imageUrls[(lightboxIndex + 1) % imageUrls.length]}
          prevSrc={
            imageUrls[(lightboxIndex + imageUrls.length - 1) % imageUrls.length]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + imageUrls.length - 1) % imageUrls.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % imageUrls.length)
          }
        />
      )}
    </>
  )
}
