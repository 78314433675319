import { useAllUsers } from 'connect-shared/AllUsersContext'
import React, { HTMLAttributes } from 'react'
import UserAvatar from './UserAvatar'

interface RoundUserAvatarProps extends HTMLAttributes<HTMLDivElement> {
  userId?: string
  size: number
  style?: React.CSSProperties
  showLabel?: boolean
}

const RoundUserAvatar = React.memo(function RoundUserAvatar({
  userId,
  size,
  style,
  showLabel,
  ...divProps
}: RoundUserAvatarProps) {
  const { allUsers } = useAllUsers()
  const name = allUsers.get(userId || '')?.fullName

  return (
    <div
      title={showLabel ? name : undefined}
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        alignContent: 'center',
        width: size,
        height: size,
        borderRadius: size / 2,
        overflow: 'hidden',
        ...style,
      }}
      {...divProps}
    >
      <UserAvatar userId={userId} />
    </div>
  )
})

export default RoundUserAvatar
