import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { useAllUsers } from 'connect-shared/AllUsersContext'
import { groupName } from 'connect-shared/types'
import React, { useMemo } from 'react'
import { useTranslate } from './hooks/Internationalization'
import { useAllGroups } from './hooks/useAllGroups'
import { Container, Pusher } from './layout/Layout'
import RoundAvatar from './RoundAvatar'

export type MembersPermissionsTableRowData =
  | {
      type: 'user'
      id: string
      permission: string
    }
  | {
      type: 'group'
      id: string
      permission: string
    }

export type OnRequestSetPermission<P> = (data: {
  type: 'group' | 'user'
  id: string
  permission: P
}) => void

type OnRequestDelete = (data: { type: 'group' | 'user'; id: string }) => void

function RowImpl<P>({
  data,
  permissions: _permissions,
  onRequestSetPermission,
  onRequestDelete,
  disabled,
}: {
  data: MembersPermissionsTableRowData
  onRequestSetPermission: OnRequestSetPermission<P>
  onRequestDelete: OnRequestDelete
  permissions: P[]
  disabled?: boolean
}) {
  const permissions = _permissions as any as string[]
  const { allUsers } = useAllUsers()
  const groups = useAllGroups()

  const [avatar, name] = useMemo(() => {
    if (data.type === 'group') {
      const group = groups.get(data.id)
      return [group?.data().avatar || undefined, groupName(group)] as const
    } else {
      const user = allUsers.get(data.id)
      return [user?.avatar, user?.fullName] as const
    }
  }, [allUsers, data.id, data.type, groups])
  const t = useTranslate()

  return (
    <TableRow key={data.id}>
      <TableCell
        style={{
          maxWidth: 1,
          overflow: 'hidden',
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <Container horizontal>
          <RoundAvatar name={name} avatar={avatar} type={data.type} size={40} />
          <Typography
            style={{ alignSelf: 'center', marginLeft: 12 }}
            variant="body1"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name}
          </Typography>
          <Pusher />
          <Select
            disabled={disabled}
            value={data.permission}
            onChange={(e) => {
              if (e.target.value === 'delete') {
                onRequestDelete({ type: data.type, id: data.id })
              } else {
                onRequestSetPermission({
                  type: data.type,
                  id: data.id,
                  permission: e.target.value as any as P,
                })
              }
            }}
          >
            {permissions.map((permission) => {
              return (
                <MenuItem value={permission} key={permission}>
                  {permission === 'Admin' && t('Admin')}
                  {permission === 'Editor' && t('Editor')}
                  {permission === 'Viewer' && t('Viewer')}
                  {permission === 'Member' && t('Member')}
                </MenuItem>
              )
            })}
            <MenuItem value="delete">{t('Delete')}</MenuItem>
          </Select>
        </Container>
      </TableCell>
    </TableRow>
  )
}

const Row = React.memo(RowImpl, (prev, next) => {
  if (prev.data.permission !== next.data.permission) return false
  if (prev.data.id !== next.data.id) return false
  if (prev.data.type !== next.data.type) return false
  if (prev.onRequestSetPermission !== next.onRequestSetPermission) return false
  if (prev.onRequestDelete !== next.onRequestDelete) return false
  if (prev.permissions.length !== next.permissions.length) return false

  for (let i = 0; i < prev.permissions.length; i++) {
    if (prev.permissions[i] !== next.permissions[i]) {
      return false
    }
  }

  return true
}) as typeof RowImpl

const MembersPermissionsTableImpl = function MembersPermissionsTable<P>({
  items,
  permissions,
  onRequestSetPermission: onRequestSetPermission,
  onRequestDelete: onRequestDelete,
  disabled,
}: {
  items: MembersPermissionsTableRowData[]
  permissions: P[]
  onRequestSetPermission: OnRequestSetPermission<P>
  onRequestDelete: OnRequestDelete
  disabled?: boolean
}) {
  const { allUsers } = useAllUsers()

  const groups = useAllGroups()

  const sortedItems = useMemo(() => {
    return [...items]
      .filter(
        (item) => item.type !== 'user' || allUsers.get(item.id)?.hidden !== true
      )
      .sort((a, b) => {
        if (a.type === 'user' && b.type === 'user') {
          return (
            allUsers
              .get(a.id)
              ?.fullName.localeCompare(allUsers.get(b.id)?.fullName || '') || 0
          )
        }

        if (a.type === 'group' && b.type === 'group') {
          return groupName(groups.get(a.id)).localeCompare(
            groupName(groups.get(b.id))
          )
        }

        if (a.type === 'group') return -1
        return 1
      })
  }, [allUsers, groups, items])

  return (
    <TableContainer style={{ overflow: 'hidden' }}>
      <Table>
        <TableBody>
          {sortedItems.map((data) => {
            return (
              <Row
                disabled={disabled}
                data={data}
                key={data.id}
                permissions={permissions}
                onRequestDelete={onRequestDelete}
                onRequestSetPermission={onRequestSetPermission}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MembersPermissionsTable = React.memo(
  MembersPermissionsTableImpl,
  (prev, next) => {
    if (prev.items.length !== next.items.length) return false
    for (let i = 0; i < prev.items.length; i++) {
      if (prev.items[i].id !== next.items[i].id) return false
      if (prev.items[i].type !== next.items[i].type) return false
      if (prev.items[i].permission !== next.items[i].permission) return false
    }

    if (prev.onRequestSetPermission !== next.onRequestSetPermission) {
      return false
    }
    if (prev.onRequestDelete !== next.onRequestDelete) {
      return false
    }
    if (prev.disabled !== next.disabled) {
      return false
    }
    if (prev.permissions.length !== next.permissions.length) {
      return false
    }

    for (let i = 0; i < prev.permissions.length; i++) {
      if (prev.permissions[i] !== next.permissions[i]) {
        return false
      }
    }

    return true
  }
) as typeof MembersPermissionsTableImpl

export default MembersPermissionsTable
