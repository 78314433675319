import { css } from '@emotion/react'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { PostDocument } from 'connect-shared/types'
import { useCallback, useState } from 'react'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import HighlightText from '../../HighlightText'
import { useTranslate } from '../../hooks/Internationalization'
import { Container, ContainerProps } from '../../layout/Layout'

const MAX_LENGTH = 300
const MAX_LINES = 4

function clampBody(body: string) {
  const lines = body.split(/\r\n|\r|\n/)

  const truncatedLines = lines
    .slice(0, MAX_LINES)
    .join('\n')
    .substr(0, MAX_LENGTH)

  if (lines.length >= MAX_LINES) {
    return truncatedLines + '...'
  }
  if (body?.length >= MAX_LENGTH) {
    return body.substr(0, MAX_LENGTH) + '...'
  }
}

interface FeedPostBodyProps extends ContainerProps {
  postSnap: QueryDocumentSnapshot<PostDocument>
}

export default function FeedPostBody({
  postSnap,
  style,
  ...rest
}: FeedPostBodyProps) {
  const colors = useColors()
  const t = useTranslate()

  const fullBody = postSnap.data().body
  const clampedBody = clampBody(fullBody)

  const [expanded, setExpanded] = useState(clampedBody === undefined)

  const handleShowMore = useCallback(() => {
    setExpanded(true)
  }, [])

  return (
    <Container>
      <Container
        onClick={handleShowMore}
        style={{ cursor: !expanded ? 'pointer' : undefined, ...style }}
        {...rest}
      >
        <Container
          style={{
            marginBottom: 7,
            color: colors.text,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            fontSize: 16,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <HighlightText
            highlightMentions={true}
            highlightLinks={true}
            text={expanded ? fullBody : clampedBody}
            mentions={postSnap?.data().mentions}
          />

          {expanded || (
            <div
              style={{
                background: `linear-gradient(${colors.none}, ${colors.foreground})`,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            />
          )}
        </Container>
        {expanded || (
          <ClickableOpacity
            css={css`
              color: ${colors.beeworkBlue};
              &:hover {
                color: ${colors.showMoreHover};
              }
            `}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: 'transparent',
              marginRight: 5,
              fontWeight: 600,
              textAlign: 'left',
              fontSize: 16,
            }}
            onClick={handleShowMore}
          >
            {t('Show more')}
          </ClickableOpacity>
        )}
      </Container>
    </Container>
  )
}
