import ClickableOpacity, { ClickableOpacityProps } from '../../ClickableOpacity'
import { usePostCurrentUserSnap, usePostSnap } from '../../hooks/feeds'
import { ReactComponent as LikeIcon } from '../../icons/ico-like.svg'

type FeedPostLikeCounterProps = {
  feedId: string
  postId: string
} & ClickableOpacityProps

export default function FeedPostLikeCounter({
  feedId,
  postId,
  style,
  ...rest
}: FeedPostLikeCounterProps) {
  const post = usePostSnap(feedId, postId)
  const postUser = usePostCurrentUserSnap(feedId, postId)

  const likedByCurrentUser = postUser.read().data()?.likes || 0
  const likeCount = post.read().data()?.likeCount || 0

  return (
    <ClickableOpacity
      style={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        color: '#828282',
        ...style,
      }}
      onClick={() => {
        postUser
          .read()
          .ref.set(
            { likes: likedByCurrentUser ? 0 : 1, subscribed: true },
            { merge: true }
          )
      }}
      {...rest}
    >
      <LikeIcon
        style={{ marginRight: 7 }}
        width={20}
        height={20}
        filled={Boolean(likedByCurrentUser)}
      />
      {likeCount}
    </ClickableOpacity>
  )
}
