import firebase from 'firebase/app'
import React, { KeyboardEvent, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import BlockButton from './BlockButton'
import { useColors } from './Colors'
import { Container, Spacer } from './layout/Layout'
import LinkText from './LinkText'
import PlayippSpinner from './playippSpinner/PlayippSpinner'
import { BodyText } from './Text'
import TextInput from './TextInput'
import { showSuccessToast } from './Toast'
import SignUpLayout from './SignUpLayout'
import { useTranslate } from './hooks/Internationalization'

export default function ForgotPassword() {
  const auth = firebase.auth()
  const [email, setEmail] = useState('')
  const [errorCode, setErrorCode] = useState<string>()
  const [state, setState] = useState<
    'initial' | 'loading' | 'error' | 'success'
  >('initial')
  const colors = useColors()
  const t = useTranslate()

  const sendResetEmail = useCallback(() => {
    setState('loading')
    setErrorCode(undefined)
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        showSuccessToast(
          t(
            'An email has been sent to %{email}. Click the link in the mail to change your password',
            { email }
          ),
          15000
        )
        setState('success')
      })
      .catch((e: firebase.FirebaseError) => {
        setErrorCode(e.code)
        setState('error')
      })
  }, [auth, email, t])

  const handleKeyEvent = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        sendResetEmail()
      }
    },
    [sendResetEmail]
  )

  const loading = state === 'loading'

  if (state === 'success') {
    return <Redirect to="/" />
  }

  function handleErrorMessage() {
    if (!errorCode) return
    if (errorCode === 'auth/invalid-email') {
      return 'The email address is badly formatted.'
    }
    if (errorCode === 'auth/user-not-found') {
      return `No account with that email was found.`
    }
    return 'Something went wrong, please try again later'
  }

  const fontSize = isMobile ? 32 : 16

  return (
    <SignUpLayout
      backgroundColor={colors.beeworkGoldLight}
      footer={
        <Container style={{ marginTop: 24 }} hAlign="center">
          <BodyText
            style={{
              fontSize,
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t('Found your password?')}
          </BodyText>
          <LinkText
            to="/sign-in"
            style={{
              fontSize,
              marginLeft: 5,
              color: colors.beeworkBlue,
              cursor: 'pointer',
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t('Sign in')}
          </LinkText>
        </Container>
      }
      caption={t('Forgot password')}
    >
      <Container vAlign="center" flex={1}>
        <BodyText
          style={{
            fontSize: isMobile ? 32 : 18,
            color: colors.beeworkBlue,
            fontFamily: 'Work sans, sans-serif',
          }}
        >
          {t(
            'Everyone can lose something at some point, we help you with your lost password. Just enter your email and we send you instructions to reset your password.'
          )}
        </BodyText>
        <Spacer size={24} />
        <TextInput
          style={{
            marginTop: isMobile ? 40 : 0,
          }}
          size={isMobile ? 40 : undefined}
          onKeyDown={handleKeyEvent}
          type="email"
          id="email"
          error={handleErrorMessage()}
          onChangeText={setEmail}
          value={email}
          placeholder={t('Email')}
        />
        <Spacer size={20} />
        <Container hAlign="center">
          <BlockButton
            style={{
              width: isMobile ? 320 : 184,
              fontSize: isMobile ? 32 : 16,
              padding: isMobile ? 40 : undefined,
              marginTop: isMobile ? 40 : 0,
              backgroundColor: colors.beeworkGold,
              color: colors.beeworkBlue,
              borderRadius: 27,
            }}
            onClick={sendResetEmail}
            type="submit"
            disabled={loading || !email.trim()}
          >
            {loading ? (
              <PlayippSpinner size={'18px'} color={colors.textInverted} />
            ) : (
              t('Reset password')
            )}
          </BlockButton>
        </Container>
      </Container>
    </SignUpLayout>
  )
}
