import 'focus-visible'
import { css, Global } from '@emotion/react'
import emojiStyles from './EmojiStyles'

export default function GlobalStyle() {
  return (
    <Global
      styles={css`
        ${emojiStyles}
      `}
    />
  )
}
