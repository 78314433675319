import { Modal } from '@mui/material'
import React, { Dispatch, useCallback } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import BlockButton from '../BlockButton'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { initialCrop, Upload } from '../crop-utils'
import { Container, Spacer } from '../layout/Layout'
import ModalCloseButton from '../ModalCloseButton'
import { useTranslate } from '../hooks/Internationalization'

type CropImageModalProps = {
  avatar: Upload
  crop: Crop
  setCrop: Dispatch<React.SetStateAction<ReactCrop.Crop>>
  imageRef: React.MutableRefObject<HTMLImageElement | undefined>
  onSubmit: () => void
  onRequestClose: () => void
  circularCrop?: boolean
  targetWidth?: number
  targetHeight?: number
}
export default function CropImageModal({
  avatar,
  crop,
  setCrop,
  imageRef,
  onSubmit,
  onRequestClose,
  circularCrop = true,
  targetWidth = 300,
  targetHeight = 300,
}: CropImageModalProps) {
  const colors = useColors()
  const t = useTranslate()

  const handleImageLoaded = useCallback(
    (image: HTMLImageElement) => {
      imageRef.current = image
      setTimeout(() => {
        setCrop(initialCrop(image, targetWidth, targetHeight))
      }, 0)
    },
    [imageRef, setCrop, targetHeight, targetWidth]
  )
  return (
    <Modal
      open
      onClose={onRequestClose}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Container
        style={{
          position: 'relative',
          backgroundColor: colors.webChatModalBackground,
          padding: 20,
          borderRadius: 5,
        }}
      >
        <ReactCrop
          src={avatar.url}
          style={{ alignSelf: 'center' }}
          imageStyle={{ maxHeight: 600, maxWidth: 800 }}
          onImageLoaded={handleImageLoaded}
          circularCrop={circularCrop}
          crop={crop}
          onChange={setCrop}
        />
        <Spacer size={20} />
        <Container horizontal hAlign="right">
          <ClickableOpacity onClick={onRequestClose}>
            {t('Cancel')}
          </ClickableOpacity>
          <Spacer size={20} />
          <BlockButton onClick={onSubmit}>{t('Crop image')}</BlockButton>
        </Container>
        <ModalCloseButton />
      </Container>
    </Modal>
  )
}
