import firebase from 'firebase/app'
import 'firebase/storage'
import { useMemo, useState, Dispatch, SetStateAction } from 'react'
import uuid from 'uuid'

interface Uploads<Result> {
  /**
   * If all uploads are ready this array is defined, otherwise if any of
   * them are still in progress it is undefined.
   **/
  finishedUploads?: Result[]
  /**
   * All uploads, whether finished or in progress.
   */
  uploads: Upload<Result>[]
}

export interface Upload<Result> {
  id: string
  file: File
  progress: number
  promise: Promise<Result>
}

interface UploadStateEntry<Result> {
  file: File
  progress: number
  promise: Promise<Result>
  result?: Result
  /**
   * Cancel this upload if it is still running.
   */
  cancel: () => void
  /**
   * Cancel this upload if it is still running, otherwise delete all associated
   * files from the server.
   */
  delete: () => Promise<void>
}

const { storage } = firebase
type UploadState<Result> = Map<string, UploadStateEntry<Result>>
export type UploadAction<Result> = SetStateAction<UploadState<Result>>

type Reference = firebase.storage.Reference
type UploadTask = firebase.storage.UploadTask

interface RunTasks<Result> {
  (
    uploadId: string,
    startUploadTask: (ref: Reference, blob: Blob) => UploadTask
  ): Promise<Result>
}

/**
 * Start uploading a file, adding it to the existing upload tasks. You may
 * upload several files per call, but they should all be based on the same
 * original file. For example, you can upload an image and a thumbnail of that
 * image.
 */
export async function addUpload<Result>(
  /** The file you wish to upload. */
  file: File,
  /** A dispatch function obtained from useUploads */
  setState: Dispatch<UploadAction<Result>>,
  /**
   * A callback that starts all upload tasks and awaits them, then returns
   * your result type
   */
  runTasks: RunTasks<Result>
) {
  const uploadId = uuid.v4()

  let totalBytes = 0
  let bytesTransferred = 0

  const tasks: firebase.storage.UploadTask[] = []

  function startUploadTask(
    ref: Reference,
    blob: Blob
  ): firebase.storage.UploadTask {
    const task = ref.put(blob, {
      cacheControl: 'private, max-age=604800, immutable',
    })
    tasks.push(task)
    totalBytes += task.snapshot.totalBytes

    let bytesTransferredLast = 0

    task.on(storage.TaskEvent.STATE_CHANGED, (snap) => {
      bytesTransferred += snap.bytesTransferred - bytesTransferredLast
      bytesTransferredLast = snap.bytesTransferred

      const progress = bytesTransferred / totalBytes

      setState((state) => {
        const entry = state.get(uploadId)

        if (!entry) {
          task.cancel()
          return state
        }

        function percent(progress: number) {
          return Math.floor(progress * 100)
        }

        // Don't bother updating if difference is within 1%
        if (percent(entry.progress) === percent(progress)) return state

        const newState = new Map(state)
        newState.set(uploadId, { ...entry, progress })
        return newState
      })
    })

    return task
  }

  function cancel() {
    for (const task of tasks) task.cancel()
  }

  async function deleteUpload() {
    await Promise.all(
      tasks.map(async (task) => {
        const taskComplete = !task.cancel()
        if (taskComplete) {
          // await task.snapshot.ref.delete() TODO uncomment once we have storage rules
        }
      })
    )
  }

  const promise = (async () => {
    await new Promise((r) => setTimeout(r, 0))
    return await runTasks(uploadId, startUploadTask)
  })()

  setState((oldState) => {
    const newState = new Map(oldState)
    newState.set(uploadId, {
      file,
      promise,
      progress: 0,
      cancel,
      delete: deleteUpload,
    })
    return newState
  })

  try {
    const result = await promise

    setState((oldState) => {
      const entry = oldState.get(uploadId)
      if (!entry) return oldState
      const newState = new Map(oldState)

      newState.set(uploadId, { ...entry, progress: 1, result })
      return newState
    })

    return true
  } catch (e) {
    if (e.code === 'storage/canceled') {
      return false
    }
    throw e
  }
}

/**
 * Delete the upload and the state associated with it. This will cancel the
 * upload if still in progress, otherwise it will delete the already finished
 * upload from the file server.
 */
export async function removeUpload<Result>(
  key: string,
  setState: Dispatch<UploadAction<Result>>
) {
  await new Promise<void>((resolve) => {
    setState((oldState) => {
      const entry = oldState.get(key)

      if (!entry) {
        resolve(undefined)
        return oldState
      }

      // resolve(entry.delete()) TODO uncomment once we have storage rules
      resolve(new Promise((r) => r()))

      const newState = new Map(oldState)
      newState.delete(key)

      return newState
    })
  })
}

/**
 * Remove the state associated with the uploads. This will cancel all
 * upload tasks still in progress, but finished uploads will remain on the file server.
 */
export function clearUploads<Result>(setState: Dispatch<UploadAction<Result>>) {
  setState((state) => {
    if (state.size === 0) return state

    for (const entry of state.values()) {
      entry.cancel()
    }

    return new Map()
  })
}

/**
 * Delete all uploads and the state associated with them. This will cancel
 * all upload tasks still in progress, and delete the already finished
 * uploads from the file server.
 */
export async function deleteUploads<Result>(
  setState: Dispatch<UploadAction<Result>>
) {
  await new Promise<void>((resolve) => {
    setState((state) => {
      if (state.size === 0) {
        resolve()
        return state
      }

      const promises = [...state.values()].map((entry) => entry.delete())
      Promise.all(promises).then(() => resolve())

      return new Map()
    })
  })
}

export function useUploads<Result>(): [
  Uploads<Result>,
  Dispatch<UploadAction<Result>>
] {
  const [state, setState] = useState<UploadState<Result>>(new Map())

  const uploads: Upload<Result>[] = useMemo(() => {
    return [...state.entries()].map(([id, upload]) => {
      const { file, progress, promise } = upload
      return { file, progress, promise, id }
    })
  }, [state])

  const finishedUploads = useMemo(() => {
    const results: Result[] = []
    for (const { result } of state.values()) {
      if (!result) return
      results.push(result)
    }
    return results
  }, [state])

  return [{ finishedUploads, uploads }, setState]
}
