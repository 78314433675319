import { useFirestoreCollection } from '@iwikal/reactfire'
import { useMemo } from 'react'
import { useCurrentUserId, useOrganizationDoc } from './auth'

export function useAllGroups() {
  const orgRef = useOrganizationDoc()
  const currentUserId = useCurrentUserId()
  const groupsSnap = useFirestoreCollection(
    orgRef.collection('users').doc(currentUserId).collection('groups')
  ).read()

  const groups = useMemo(() => {
    return new Map(
      groupsSnap.docs.map((snap) => {
        return [snap.id, snap]
      })
    )
  }, [groupsSnap.docs])

  return groups
}
