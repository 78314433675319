import { WikiPermissionsSettingsDocument } from 'connect-shared/types'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import MembersPermissionsTable, {
  MembersPermissionsTableRowData,
  OnRequestSetPermission,
} from '../MembersPermissionsTable'
import UserGroupSearch from '../UserGroupSearch'

export default function WikiPermissionsEditor({
  value,
  onChange,
  searchPlaceholder,
  disabled,
}: {
  value: WikiPermissionsSettingsDocument
  onChange: Dispatch<SetStateAction<WikiPermissionsSettingsDocument>>
  searchPlaceholder?: string
  disabled?: boolean
}) {
  const items = useMemo<MembersPermissionsTableRowData[]>(() => {
    return [
      ...(value.viewerGroups || []).map(
        (id): MembersPermissionsTableRowData => {
          const permission = () => {
            if (value.adminGroups?.includes(id)) return 'Editor'
            // if (value.editorGroups?.includes(id)) return 'Editor'
            return 'Viewer'
          }
          return {
            type: 'group',
            id,
            permission: permission(),
          }
        }
      ),
      ...(value.viewerUsers || []).map((id): MembersPermissionsTableRowData => {
        const permission = () => {
          if (value.adminUsers?.includes(id)) return 'Editor'
          // if (value.editorUsers?.includes(id)) return 'Editor'
          return 'Viewer'
        }

        return {
          type: 'user',
          id,
          permission: permission(),
        }
      }),
    ]
  }, [
    value.adminGroups,
    value.adminUsers,
    value.viewerGroups,
    value.viewerUsers,
  ])

  const deleteFromSettings = useCallback(
    (key: keyof WikiPermissionsSettingsDocument, id: string) => {
      onChange((value) => ({
        ...value,
        [key]: (value[key] || []).filter((x) => x !== id),
      }))
    },
    [onChange]
  )

  const addToSettings = useCallback(
    (key: keyof WikiPermissionsSettingsDocument, id: string) => {
      onChange((value) => ({
        ...value,
        [key]: [...new Set([...(value[key] || []), id])],
      }))
    },
    [onChange]
  )

  const filteredUsers = useMemo(() => {
    return new Set(value.viewerUsers)
  }, [value.viewerUsers])

  const filteredGroups = useMemo(() => {
    return new Set(value.viewerGroups)
  }, [value.viewerGroups])

  const handleRequestSetPermission: OnRequestSetPermission<
    'Editor' | 'Viewer'
  > = useCallback(
    async ({ id, permission, type }) => {
      if (type === 'user') {
        deleteFromSettings('adminUsers', id)
        deleteFromSettings('editorUsers', id)
        deleteFromSettings('viewerUsers', id)
        /* eslint-disable no-fallthrough */
        switch (permission) {
          case 'Editor':
            addToSettings('adminUsers', id)
            // case 'Editor':
            addToSettings('editorUsers', id)
          default:
            addToSettings('viewerUsers', id)
        }
        /* eslint-enable */
      }

      if (type === 'group') {
        deleteFromSettings('adminGroups', id)
        deleteFromSettings('editorGroups', id)
        deleteFromSettings('viewerGroups', id)
        /* eslint-disable no-fallthrough */
        switch (permission) {
          case 'Editor':
            addToSettings('adminGroups', id)
            // case 'Editor':
            addToSettings('editorGroups', id)
          default:
            addToSettings('viewerGroups', id)
        }
        /* eslint-enable */
      }
    },
    [addToSettings, deleteFromSettings]
  )

  const handleRequestDelete = useCallback(
    async ({ id, type }: { id: string; type: string }) => {
      if (type === 'user') {
        deleteFromSettings('adminUsers', id)
        deleteFromSettings('editorUsers', id)
        deleteFromSettings('viewerUsers', id)
      }

      if (type === 'group') {
        deleteFromSettings('adminGroups', id)
        deleteFromSettings('editorGroups', id)
        deleteFromSettings('viewerGroups', id)
      }
    },
    [deleteFromSettings]
  )

  return (
    <div>
      <UserGroupSearch
        disabled={disabled}
        placeholder={searchPlaceholder}
        filteredUsers={filteredUsers}
        filteredGroups={filteredGroups}
        onSelectUser={(id) => addToSettings('viewerUsers', id)}
        onSelectGroup={(id) => addToSettings('viewerGroups', id)}
      />
      <MembersPermissionsTable
        disabled={disabled}
        items={items}
        onRequestDelete={handleRequestDelete}
        onRequestSetPermission={handleRequestSetPermission}
        permissions={['Editor', 'Viewer']}
      />
    </div>
  )
}
