import styled from '@emotion/styled'
import firebase from 'firebase/app'
import { colors } from 'connect-shared/constants'
import { useSubscriptions } from '../../hooks/feeds'
import { FeedDocument } from 'connect-shared/types'
import RoundAvatar from '../../RoundAvatar'

const StyledAddFeedSearchItem = styled.li`
  display: flex;
  margin-bottom: 5px;

  .avatarWrapper {
    margin-right: 10px;
    cursor: pointer;
  }
  .nameWrapper {
    flex: 9;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .followWrapper {
    flex: 1;
    width: 50px;
    color: #fff;
    div.badge {
      background: #828282;
      line-height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-left: auto;
      font-size: 33px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      margin-right: 12px;
    }
  }
`
const AddFeedSearchItem = (props: {
  feed: FeedDocument
  feedId: string
  user: string
  selectFeed: (feedId: string) => void
  close: () => void
}) => {
  const subscribed = useSubscriptions().read().has(props.feedId)

  const followFeed = () => {
    const subscribe = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('addCombinedFeedSubscriptions')
    subscribe({
      combinedFeedIds: [props.user],
      feedIds: [props.feedId],
    })
    props.selectFeed(props.feedId)
    props.close()
  }

  return (
    <StyledAddFeedSearchItem>
      <div
        className="avatarWrapper"
        onClick={() => {
          props.selectFeed(props.feedId)
        }}
      >
        <RoundAvatar
          avatar={props.feed.avatar}
          size={30}
          name={props.feed.name}
        />
      </div>
      <div className="nameWrapper">
        <button
          style={{
            cursor: 'pointer',
            maxWidth: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            border: 0,
            backgroundColor: colors.none,
          }}
          onClick={() => {
            props.selectFeed(props.feedId)
          }}
        >
          {props.feed.name}
        </button>
      </div>
      <div
        className="followWrapper"
        onClick={() => {
          followFeed()
        }}
      >
        {!subscribed && (
          <div className="badge" data-test-id="follow-feed-add">
            +
          </div>
        )}
      </div>
    </StyledAddFeedSearchItem>
  )
}

export default AddFeedSearchItem
