import { UserDocument } from 'connect-shared/types'
import { useCallback } from 'react'
import StatusButton from './dashboardWidgets/sidebarAvatar/StatusButton'
import { firestore } from './firestore'
import { useCurrentUserId } from './hooks/auth'
import useAvailabilityEnabled from './hooks/useAvailabilityEnabled'
import useCurrentUser from './hooks/useCurrentUser'
import { ReactComponent as CheckOutIcon } from './icons/ico-check-out.svg'
import { ReactComponent as CheckedInIcon } from './icons/ico-on-site.svg'
import { ReactComponent as AvailableRemote } from './icons/ico-remote.svg'
import { ReactComponent as AbsenceIcon } from './icons/ico-sick.svg'
import { ReactComponent as VacationIcon } from './icons/ico-vacation.svg'
import { Container } from './layout/Layout'
import { useMediaQuery } from '@mui/material'
import { lWidth, mWidth } from './style'
import { statuses } from 'connect-shared/statuses'
import { emitTutorialEvent } from './Tutorials'
import { useTranslate } from './hooks/Internationalization'

export default function StatusButtons({
  size = 50,
  onClick,
  onVacationClick,
  isModal,
}: {
  size?: number
  onClick?: () => void
  onVacationClick: () => void
  isModal?: boolean
}) {
  const t = useTranslate()
  const currentUserId = useCurrentUserId()
  const currentUserDoc = firestore().collection('users').doc(currentUserId)

  const currentUser = useCurrentUser()

  const setAvailable = useCallback(() => {
    const user: Partial<UserDocument> = {
      checkedIn: true,
      availableRemote: false,
      sick: false,
    }
    emitTutorialEvent('status_available')
    currentUserDoc.update(user)
    onClick?.()
  }, [currentUserDoc, onClick])

  const setCheckedOut = useCallback(() => {
    const user: Partial<UserDocument> = {
      checkedIn: false,
      availableRemote: false,
      sick: false,
    }
    emitTutorialEvent('status_unavailable')
    currentUserDoc.update(user)
    onClick?.()
  }, [currentUserDoc, onClick])

  const setAvailableRemote = useCallback(() => {
    const user: Partial<UserDocument> = {
      checkedIn: false,
      availableRemote: true,
      sick: false,
    }
    emitTutorialEvent('status_available')
    currentUserDoc.update(user)
    onClick?.()
  }, [currentUserDoc, onClick])

  const toggleSick = useCallback(() => {
    const user: Partial<UserDocument> = {
      checkedIn: false,
      availableRemote: false,
      sick: !currentUser?.sick,
    }
    currentUserDoc.update(user)
    onClick?.()
  }, [currentUser?.sick, currentUserDoc, onClick])

  const midWidth = useMediaQuery(`(max-width: ${lWidth}px`)
  const narrow = useMediaQuery(`(max-width: ${mWidth}px`)

  const availabilityEnabled = useAvailabilityEnabled().read()
  if (!availabilityEnabled) return null

  const padding = midWidth && !narrow ? '0 50px' : narrow ? '0 20px' : 0
  return (
    <Container
      horizontal
      flex={1}
      style={{
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: isModal ? 0 : padding,
      }}
    >
      {currentUser?.checkedIn || currentUser?.availableRemote ? (
        <StatusButton
          size={size}
          Icon={CheckOutIcon}
          iconSize={size * 0.5}
          hoverText={'Check out'}
          color={statuses.unavailable.color}
          onClick={setCheckedOut}
          iconColor={statuses.unavailable.color}
          className="availability_button_check_out"
        />
      ) : (
        <StatusButton
          size={size}
          Icon={CheckedInIcon}
          iconSize={size * 0.5}
          hoverText={t('On site')}
          color={statuses.available.color}
          onClick={setAvailable}
          iconColor={statuses.available.color}
          className="availability_button_on_site"
        />
      )}

      {currentUser?.availableRemote ? (
        <StatusButton
          size={size}
          Icon={CheckedInIcon}
          iconSize={size * 0.5}
          hoverText={t('On site')}
          color={statuses.available.color}
          onClick={setAvailable}
          iconColor={statuses.available.color}
          className="availability_button_on_site"
        />
      ) : (
        <StatusButton
          size={size}
          Icon={AvailableRemote}
          iconSize={size * 0.6}
          hoverText={t('Remote')}
          color={statuses.available.color}
          onClick={setAvailableRemote}
          iconColor={statuses.available.color}
          className="availability_button_remote"
        />
      )}
      <StatusButton
        size={size}
        Icon={VacationIcon}
        iconSize={size * 0.5}
        hoverText={t('Vacation')}
        color={statuses.vacation.color}
        onClick={onVacationClick}
        iconColor={statuses.vacation.color}
        className="availability_button_vacation"
      />
      {currentUser?.sick ? (
        <StatusButton
          size={size}
          Icon={AbsenceIcon}
          iconSize={size * 0.5}
          hoverText={t('Available')}
          color={statuses.available.color}
          onClick={toggleSick}
          iconColor={statuses.available.color}
          opacity={0.4}
          className="availability_button_not_sick"
        />
      ) : (
        <StatusButton
          size={size}
          Icon={AbsenceIcon}
          iconSize={size * 0.5}
          hoverText={t("I'm sick")}
          color={statuses.absent.color}
          onClick={toggleSick}
          iconColor={statuses.absent.color}
          className="availability_button_sick"
        />
      )}
    </Container>
  )
}
