import { useFirestoreDoc } from '@iwikal/reactfire'
import { DocumentSnapshot } from 'connect-shared/firestore'
import { AccountDocument } from 'connect-shared/types'
import { firestore } from '../firestore'
import { Resource } from '../resource'
import useAccountInfo, { AccountInfoState } from './useAccountInfo'

export function useAccountId(): string | null {
  const accountInfo = useAccountInfo()
  if (
    accountInfo.state === AccountInfoState.SignedInAccount ||
    accountInfo.state === AccountInfoState.SignedInUserByAccount
  ) {
    return accountInfo.accountId
  } else {
    return null
  }
}

export default function useAccountSnap(): Resource<
  DocumentSnapshot<never | AccountDocument>
> {
  const accountId = useAccountId()
  const accountInfo = useAccountInfo()
  const emailVerified =
    accountInfo.state === AccountInfoState.SignedInUserByAccount ||
    (accountInfo.state === AccountInfoState.SignedInAccount &&
      accountInfo.emailVerified)

  const accountDoc =
    accountId && emailVerified
      ? firestore().collection('accounts').doc(accountId)
      : firestore().collection('empty').doc('empty')
  return useFirestoreDoc(accountDoc)
}
