import { useFirestoreDoc } from '@iwikal/reactfire'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container as MuiContainer,
  Stack,
  Typography,
} from '@mui/material'
import { useCallback, useState } from 'react'
import BackLabelButton from '../BackLabelButton'
import { showErrorToast, showSuccessToast } from '../Toast'
import { useTranslate } from '../hooks/Internationalization'
import { useOrganizationDoc } from '../hooks/auth'
import { Container, Spacer } from '../layout/Layout'
import WikiPermissionsEditor from '../wiki/WikiPermissionsEditor'

type WikiSettingsProps = {}
export default function WikiSettings({}: WikiSettingsProps) {
  const t = useTranslate()

  const orgRef = useOrganizationDoc()

  const settingsRef = orgRef.collection('wikiPermissionsSettings').doc('root')
  const settings = useFirestoreDoc(settingsRef).read()
  const [settingsData, setSettingsData] = useState(settings.data() || {})

  const save = useCallback(async () => {
    try {
      await settingsRef.set(settingsData)
      showSuccessToast(t('Changes saved'))
    } catch (e) {
      console.error(e)
      showErrorToast()
    }
  }, [settingsData, settingsRef, t])

  const restoreData = useCallback(() => {
    setSettingsData(settings.data() || {})
  }, [settings])

  return (
    <MuiContainer maxWidth="md" sx={{ marginBottom: 11 }}>
      <Stack spacing={2} marginBottom={4}>
        <Box paddingTop={2}>
          <BackLabelButton />
        </Box>
        <Box flexDirection="row" display="flex">
          <Box flex="1">
            <Typography variant="h4">{t('Pages settings')}</Typography>
          </Box>
        </Box>
      </Stack>
      <Card>
        <CardContent style={{ padding: 24 }}>
          <Typography style={{ marginBottom: 8 }}>
            {t(
              'Edit default permissions for pages which applies to categories and articles which have no custom permissions, or inherits custom permissions.'
            )}
          </Typography>
          <WikiPermissionsEditor
            searchPlaceholder={t('Add people and groups')}
            value={settingsData}
            onChange={setSettingsData}
          />
          <Spacer size={18} />
          <Container style={{ marginTop: 18 }} hAlign="right" horizontal>
            <Button variant="text" onClick={restoreData}>
              {t('Cancel')}
            </Button>
            <Spacer size={8} />
            <Button variant="contained" onClick={save}>
              {t('Save')}
            </Button>
          </Container>
        </CardContent>
      </Card>
    </MuiContainer>
  )
}
