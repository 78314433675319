import React, { useState } from 'react'
import { useColors } from '../../Colors'
import { useTranslate } from '../../hooks/Internationalization'
import { Container, ContainerProps, Spacer } from '../../layout/Layout'

type RemoveCommentButtonProps = { danger?: boolean } & ContainerProps

function RemoveCommentButton({
  danger = false,
  style,
  children,
  ...rest
}: RemoveCommentButtonProps) {
  const colors = useColors()
  const [hover, setHover] = useState(false)

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor: 'pointer',
        backgroundColor: hover && danger ? colors.dangerHover : 'transparent',
        padding: 10,
        whiteSpace: 'nowrap',
        border: danger ? `1px solid ${colors.danger}` : undefined,
        borderRadius: 5,
        textAlign: 'center',
        color: danger ? colors.danger : hover ? '#4b9fcc' : '#828282',
        justifyContent: 'center',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Container>
  )
}

type RemoveCommentItemProps = {
  onCancel: () => void
  onAccept: () => void
} & ContainerProps

export default function RemoveCommentItem({
  onCancel,
  onAccept,
  style,
  ...rest
}: RemoveCommentItemProps) {
  const t = useTranslate()
  return (
    <Container
      vAlign="center"
      hAlign="center"
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        paddingTop: 20,
        paddingRight: 30,
        paddingBottom: 20,
        paddingLeft: 30,
        ...style,
      }}
      {...rest}
    >
      <Container
        vAlign="center"
        style={{
          flex: 1,
          color: '#222',
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {t('Are you sure you want to delete this comment?')}
      </Container>
      <Spacer size={20} />
      <Container
        vAlign="center"
        hAlign="center"
        style={{
          flex: 1,
          flexDirection: 'row',
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        <RemoveCommentButton onClick={onCancel}>
          {t('Cancel')}
        </RemoveCommentButton>
        <Spacer size={20} />
        <RemoveCommentButton danger onClick={onAccept}>
          {t('Delete comment')}
        </RemoveCommentButton>
      </Container>
    </Container>
  )
}
