import { minVersion } from 'connect-shared/constants'
import firebase from 'firebase/app'
import { useEffect } from 'react'
import { updateClaims } from '../cloud-functions'
import localVersion from '../version.json'

const VERSION =
  process.env.NODE_ENV === 'development' ? minVersion : localVersion
let tokenUpdatedState: 'initial' | 'in_progress' | 'done' = 'initial'

export default function useClaimUpdater() {
  useEffect(() => {
    return firebase.auth().onIdTokenChanged(
      async (user) => {
        if (!user) return
        const idTokenResult = await user?.getIdTokenResult()
        const claims = idTokenResult?.claims
        if (!claims) return
        if (claims?.versionString !== VERSION) {
          if (tokenUpdatedState === 'done') {
            // Prevent infinite loop if a previous login didn't fix our claims
            console.error(
              `Claims update failed. Expected ${VERSION} but got ${claims?.versionString}`
            )
            return
          } else if (tokenUpdatedState === 'initial') {
            tokenUpdatedState = 'in_progress'
            const token = await updateClaims(VERSION)
            await firebase.auth().signInWithCustomToken(token)
            tokenUpdatedState = 'done'
            return
          }
        }
      },
      (err) => {
        console.error(err)
      }
    )
  }, [])
}
