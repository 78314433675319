import { Checkbox, Input, TextField } from '@mui/material'
import { colors } from 'connect-shared/constants'
import { FeedEvent } from 'connect-shared/types'
import { addHours, startOfDay, subHours } from 'date-fns'
import firebase from 'firebase/app'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import RoundActionButton from '../../../RoundActionButton'
import { BodyText } from '../../../Text'
import { useTranslate } from '../../../hooks/Internationalization'
import { ReactComponent as CloseIcon } from '../../../icons/remove.svg'
import { Container, Pusher, Spacer } from '../../../layout/Layout'
import DateTimePicker from './DateTimePicker'

type EditEventCardProps = {
  postEvent: FeedEvent
  /* The snap is only set if we are editing an existing PostEventDocument */
  onChange: (data: FeedEvent) => void
  onRequestClose?: () => void
}

export default function EditEventCard({
  postEvent,
  onChange,
  onRequestClose,
}: EditEventCardProps) {
  const t = useTranslate()
  const [showErrors, setShowErrors] = useState(false)

  // Use to update any data inside postEvent
  const updateData = useCallback(
    (data: Partial<FeedEvent>) => {
      onChange({ ...postEvent, ...data })
    },
    [onChange, postEvent]
  )

  const toggleCancel = useCallback(() => {
    updateData({ canceled: !postEvent.canceled })
  }, [postEvent.canceled, updateData])

  const handleTitleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!showErrors) {
        setShowErrors(true)
      }
      if (e.target.value.length <= 100) {
        updateData({ title: e.target.value })
      }
    },
    [showErrors, updateData]
  )

  const handleToDateChange = useCallback(
    (date: Date) => {
      if (
        postEvent.toDateEnabled &&
        postEvent.fromDate &&
        postEvent.fromDate.toDate() >= date
      ) {
        updateData({
          toDate: firebase.firestore.Timestamp.fromDate(date),
          fromDate: firebase.firestore.Timestamp.fromDate(subHours(date, 1)),
        })
      } else {
        updateData({ toDate: firebase.firestore.Timestamp.fromDate(date) })
      }
    },
    [postEvent.fromDate, postEvent.toDateEnabled, updateData]
  )

  const handleFromDateChange = useCallback(
    (date: Date) => {
      if (
        postEvent.toDateEnabled &&
        postEvent.toDate &&
        postEvent.toDate.toDate() <= date
      ) {
        updateData({
          fromDate: firebase.firestore.Timestamp.fromDate(date),
          toDate: firebase.firestore.Timestamp.fromDate(addHours(date, 1)),
        })
      } else {
        updateData({ fromDate: firebase.firestore.Timestamp.fromDate(date) })
      }
    },
    [postEvent.toDate, postEvent.toDateEnabled, updateData]
  )

  const dateError: boolean = useMemo(() => {
    return (
      (postEvent.toDateEnabled &&
        !!postEvent.fromDate &&
        !!postEvent.toDate &&
        postEvent.fromDate.toDate() > postEvent.toDate.toDate()) ||
      false
    )
  }, [postEvent.fromDate, postEvent.toDate, postEvent.toDateEnabled])

  const handleAllDayChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        if (postEvent.fromDate) {
          const fromDate = startOfDay(postEvent.fromDate.toDate())
          postEvent.fromDate =
            postEvent.fromDate &&
            firebase.firestore.Timestamp.fromDate(fromDate)
        }
        if (postEvent.toDate) {
          const toDate = startOfDay(postEvent.toDate.toDate())
          postEvent.toDate = firebase.firestore.Timestamp.fromDate(toDate)
        }
      }
      updateData({
        allDay: event.currentTarget.checked,
        fromDate: postEvent.fromDate,
        toDate: postEvent.toDate,
      })
    },
    [postEvent, updateData]
  )

  const handleLocationChange = useCallback(
    (text: string) => {
      updateData({ location: text })
    },
    [updateData]
  )

  const handleDescriptionChange = useCallback(
    (text: string) => {
      updateData({ description: text })
    },
    [updateData]
  )

  const handleEnableToDate = useCallback(
    () =>
      updateData({
        toDateEnabled: !postEvent.toDateEnabled,
        toDate: postEvent.fromDate,
      }),
    [postEvent.fromDate, postEvent.toDateEnabled, updateData]
  )

  const titleWithinLimit = useMemo(() => {
    const titleLength = postEvent.title?.length || 0
    return titleLength < 100
  }, [postEvent.title?.length])

  return (
    <Container
      style={{
        borderRadius: '5px',
        border: `1px solid ${colors.faint}`,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 24,
        paddingBottom: 24,
      }}
    >
      <Container horizontal vAlign="center">
        <p style={{ fontSize: 16, fontWeight: 600, color: colors.eventTitle }}>
          {t('Edit event')}
        </p>
        <Pusher />

        <RoundActionButton onClick={onRequestClose} Icon={CloseIcon} />
      </Container>
      <Spacer size={16} />
      <Container>
        <Input
          placeholder={t('Add event title')}
          value={postEvent.title || ''}
          onChange={handleTitleChange}
          error={showErrors && !postEvent.title?.trim()}
        />
        <BodyText
          style={{
            flex: 1,
            textAlign: 'end',
            fontSize: 11,
            color: titleWithinLimit ? colors.textDimHard : colors.warning,
          }}
        >
          {`${postEvent.title?.length || 0} / 100`}
        </BodyText>
      </Container>
      <Spacer size={12} />
      <Container style={{ color: colors.text }} vAlign="center">
        <Container horizontal>
          <DateTimePicker
            showTime={!postEvent.allDay}
            value={postEvent.fromDate?.toDate() || new Date()}
            onChange={handleFromDateChange}
            error={dateError}
          />
          <Spacer size={20} />
          {postEvent.toDateEnabled && (
            <>
              <BodyText
                style={{
                  display: 'flex',
                  alignSelf: 'flex-end',
                  marginBottom: 3,
                  fontSize: 14,
                }}
              >
                {t('To')}
              </BodyText>
              <Spacer size={20} />
              <DateTimePicker
                timeFirst
                showTime={!postEvent.allDay}
                value={postEvent.toDate?.toDate() || new Date()}
                onChange={handleToDateChange}
                error={dateError}
              />
            </>
          )}
        </Container>
        <Spacer size={12} />
        <Container>
          <Container vAlign="center" horizontal>
            <Checkbox
              color={'primary'}
              id={'allDayLong'}
              checked={postEvent.allDay}
              onChange={handleAllDayChange}
            />
            <label
              style={{ color: colors.labelStrong, cursor: 'pointer' }}
              htmlFor={'allDayLong'}
            >
              {t('All day')}
            </label>
          </Container>
          <Container vAlign="center" horizontal>
            <Checkbox
              color={'primary'}
              id={'toDateEnabled'}
              checked={postEvent.toDateEnabled}
              onChange={handleEnableToDate}
            />
            <label
              style={{ color: colors.labelStrong, cursor: 'pointer' }}
              htmlFor={'toDateEnabled'}
            >
              {t('Select end-time')}
            </label>
          </Container>
          <Container vAlign="center" horizontal>
            <Checkbox
              color={'primary'}
              id={'canceled'}
              checked={postEvent.canceled}
              onChange={toggleCancel}
            />
            <label
              style={{ color: colors.labelStrong, cursor: 'pointer' }}
              htmlFor={'canceled'}
            >
              {t('Canceled')}
            </label>
          </Container>
        </Container>
      </Container>
      <Spacer size={10} />
      <TextField
        variant="outlined"
        multiline
        rows={3}
        placeholder={t('Description')}
        value={postEvent.description}
        onChange={(e) => handleDescriptionChange(e.target.value)}
      />
      <Spacer size={20} />
      <TextField
        variant="outlined"
        placeholder={t('Location')}
        value={postEvent.location}
        onChange={(e) => handleLocationChange(e.target.value)}
      />
      <Spacer size={25} />
    </Container>
  )
}
