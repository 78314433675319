import { ContainerProps, Container } from './Layout'
import {
  lSideContentWidth,
  lWidth,
  mGutters,
  mSideContentWidth,
  mWidth,
  sGutters,
  sideContentHeight,
  sideContentOffset,
  sSideContentWidth,
} from '../style'
import styled from '@emotion/styled'

const StyledContainer = styled(Container)`
  width: ${sSideContentWidth}px;
  margin: 0 ${sGutters}px;

  @media (min-width: ${mWidth}px) {
    width: ${mSideContentWidth}px;
    margin: 0 ${mGutters}px;
  }

  @media (min-width: ${lWidth}px) {
    width: ${lSideContentWidth}px;
  }
`

type SideContentProps = ContainerProps & { gutters?: 'right' | 'left' }

export default function SideContent({
  flex = '0 0 auto',
  gutters,
  style,
  children,
  ...containerProps
}: SideContentProps) {
  return (
    <StyledContainer
      className="side-content"
      style={{
        marginLeft: gutters !== 'right' ? 0 : undefined,
        marginRight: gutters !== 'left' ? 0 : undefined,
        overflowY: 'auto',
        height: sideContentHeight,
        position: 'sticky',
        top: sideContentOffset,
        flex,
        ...style,
      }}
      {...containerProps}
    >
      {children}
    </StyledContainer>
  )
}
