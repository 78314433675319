import { userFullName } from 'connect-shared/User'
import { useCurrentUserId } from '../../hooks/auth'
import useUser from '../../hooks/useUser'
import { Container } from '../../layout/Layout'
import { BodyText } from '../../Text'
import { useFeedSnap } from '../../hooks/feeds'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { PostDocument } from 'connect-shared/types'
import ClickableOpacity, { ClickableOpacityProps } from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import RoundAvatar from '../../RoundAvatar'
import FeedInfoModal from '../../FeedInfoModal'
import { usePopoverHover } from '../../PopoverWrapper'
import { useDateFns, useTranslate } from '../../hooks/Internationalization'
import { UserCardPopover } from '../../user-profile/UserCard'
import { useAllUsers } from 'connect-shared/AllUsersContext'

type FeedPostAuthorProps = {
  userId: string
}

function FeedPostAuthor({ userId }: FeedPostAuthorProps) {
  const currentUserId = useCurrentUserId()
  const t = useTranslate()
  const user = useUser(userId)
  const authorName = userId === currentUserId ? t('you') : userFullName(user)

  return (
    <div
      style={{
        display: 'contents',
        whiteSpace: 'nowrap',
      }}
    >
      {authorName}
    </div>
  )
}

type FeedPostHeaderProps = {
  postSnap: QueryDocumentSnapshot<PostDocument>
  feedId: string
} & ClickableOpacityProps

export default function FeedPostHeader({
  style,
  feedId,
  postSnap,
  ...rest
}: FeedPostHeaderProps) {
  const { formatDistanceToNow } = useDateFns()
  const colors = useColors()
  const feed = useFeedSnap(feedId).read().data()

  const { allUsers } = useAllUsers()
  const authorUser = allUsers.get(postSnap.data().author)
  const personal = feedId?.startsWith('personal_')
  const feedName = personal ? authorUser?.fullName : feed?.name
  const avatar = personal ? authorUser?.avatar : feed?.avatar
  const t = useTranslate()

  const { author, createdDate, editedBy } = postSnap.data()
  const { anchorProps, popOverProps } = usePopoverHover()

  return (
    <>
      {feedId.startsWith('personal_') ? (
        <UserCardPopover
          userId={feedId.split('personal_')[1]}
          {...popOverProps}
        />
      ) : (
        <FeedInfoModal feedId={feedId} {...popOverProps} />
      )}
      <RoundAvatar avatar={avatar} size={60} name={feedName} {...anchorProps} />
      <ClickableOpacity
        style={{
          display: 'flex',
          cursor: 'pointer',
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
        {...rest}
      >
        <Container
          flex={1}
          style={{
            marginLeft: 16,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'flex-start',
          }}
        >
          <BodyText
            style={{
              fontSize: 16,
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'start',
            }}
          >
            {feedName}
          </BodyText>
          <BodyText
            style={{
              textAlign: 'start',
              width: '100%',
              flex: 0,
              color: colors.label,
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t('Published %{distance} ago by ', {
              distance: formatDistanceToNow(createdDate.toDate()),
            })}
            <FeedPostAuthor userId={author} />
            {editedBy && (
              <div style={{ display: 'contents' }}>
                {' '}
                {(() => {
                  const [before, after] = t('edited by [[author]]').split(
                    '[[author]]'
                  )
                  return (
                    <>
                      {before}
                      <FeedPostAuthor userId={editedBy} />
                      {after}
                    </>
                  )
                })()}{' '}
              </div>
            )}
          </BodyText>
        </Container>
      </ClickableOpacity>
    </>
  )
}
