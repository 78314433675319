import { useColors } from './Colors'
import PlayippSpinner from './playippSpinner/PlayippSpinner'

export default function LoadingOverlay() {
  const colors = useColors()
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: colors.background,
        opacity: 0.7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PlayippSpinner size="60px" />
    </div>
  )
}
