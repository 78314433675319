import { ButtonHTMLAttributes } from 'react'
import { css } from '@emotion/react'

export type ClickableOpacityProps = {
  hover?: boolean
  hoverColor?: string
  hoverTextColor?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

export default function ClickableOpacity({
  hover,
  hoverColor = '#EDEDED',
  hoverTextColor,
  disabled,
  ...buttonProps
}: ClickableOpacityProps) {
  const { style, ...otherProps } = buttonProps

  return (
    <button
      css={css`
        background-color: transparent;

        &:hover {
          background-color: ${hover ? hoverColor : ''};
          color: ${hover ? hoverTextColor : ''};
        }
      `}
      disabled={disabled}
      onClick={buttonProps.onClick}
      style={{
        padding: 0,
        border: 'none',
        cursor: disabled ? '' : 'pointer',
        ...style,
      }}
      {...otherProps}
    >
      {buttonProps.children}
    </button>
  )
}
