import SidebarLink from '../sidebar/SidebarLink'
import { ReactComponent as UsersIcon } from '../icons/ico-contacts.svg'
import { ReactComponent as InviteIcon } from '../icons/ico-send-email.svg'
import useCurrentUser from '../hooks/useCurrentUser'
import { useTranslate } from '../hooks/Internationalization'

export default function ManageUsersMenu() {
  const t = useTranslate()
  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.organizationAdmin
  return (
    <>
      {isAdmin && (
        <>
          <SidebarLink
            style={{ padding: 15 }}
            Icon={UsersIcon}
            text={t('Manage users')}
            to={'/settings/users'}
          />
          <SidebarLink
            style={{ padding: 15 }}
            Icon={InviteIcon}
            text={t('Invitations')}
            to={'/settings/invitations'}
          />
        </>
      )}
    </>
  )
}
