import styled from '@emotion/styled'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { Box, FormControlLabel, Switch } from '@mui/material'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { useDeferredValue, useEffect, useState } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useRouteMatch } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'
import { useScrollLimitQuery } from '../../chat/useScrollLimitQuery'
import { useColors } from '../../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { useWritableFeeds } from '../../hooks/feeds'
import { useTranslate } from '../../hooks/Internationalization'
import FeedPostEditCard from '../FeedPostEditCard'
import FeedPost, { PostContext } from '../post/FeedPost'
import PostDetailsInline from '../PostDetailsInline'
import useFeatureFlag from '../../hooks/useFeatureFlag'

const Button = styled.button`
  border: none;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 60px);
  background: #4b9fcc;
  color: #fff;
  line-height: 34px;
  margin: 0 30px 30px;
  border-radius: 6px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

function useCombinedFeedPosts({
  isHome,
  includePersonal,
}: {
  isHome?: boolean
  includePersonal?: boolean
}) {
  const feedDoc = useOrganizationDoc()
    .collection('combinedFeeds')
    .doc(useCurrentUserId())

  const query = feedDoc
    .collection('posts')
    .orderBy('pinned', 'desc')
    .orderBy('createdDate', 'desc')

  const personalFilteredQuery = query.where('personal', '==', false)

  const { loadMore, snap, hasMore, setLimit } = useScrollLimitQuery(
    useDeferredValue(includePersonal) ? query : personalFilteredQuery
  )

  const [unseenPost] = useState(
    useFirestoreDoc(
      useOrganizationDoc().collection('combinedFeeds').doc(useCurrentUserId())
    )
      .read()
      .data()?.firstUnseenPost
  )

  const posts = snap.docs.map((postSnap) => {
    return (
      <FeedPost
        key={postSnap.id}
        postSnap={postSnap}
        firstUnseen={unseenPost === postSnap.id}
        context={
          isHome === true ? PostContext.HOME_FEED : PostContext.COMBINED_FEED
        }
      />
    )
  })

  return [loadMore, posts, hasMore, setLimit] as const
}

type CombinedFeedProps = {
  dashboard: boolean
  isHome?: boolean
}

export default function CombinedFeed({ dashboard, isHome }: CombinedFeedProps) {
  const colors = useColors()
  const t = useTranslate()

  const [includePersonal, setIncludePersonal] = useLocalStorage(
    'combined_feeds_include_personal',
    'yes'
  )
  const [loadMore, posts, hasMore, setLimit] = useCombinedFeedPosts({
    isHome,
    includePersonal: includePersonal === 'yes',
  })
  useBottomScrollListener<HTMLDivElement>(loadMore)

  const match = useRouteMatch<{ feedId: string; postId: string }>(
    '/feeds/all/:feedId/:postId'
  )
  const feedId = match?.params.feedId
  const postId = match?.params.postId

  const writableFeeds = useWritableFeeds()

  const combinedFeedRef = useOrganizationDoc()
    .collection('combinedFeeds')
    .doc(useCurrentUserId())

  const [unseenPost] = useState(
    useFirestoreDoc(
      useOrganizationDoc().collection('combinedFeeds').doc(useCurrentUserId())
    )
      .read()
      .data()?.firstUnseenPost
  )

  useEffect(() => {
    if (unseenPost) {
      combinedFeedRef.update({
        firstUnseenPost: firebase.firestore.FieldValue.delete(),
      })
    }
  }, [combinedFeedRef, unseenPost])

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)

  if (feedId && postId) {
    return <PostDetailsInline feedId={feedId} postId={postId} />
  }

  return (
    <div
      style={{
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
      }}
    >
      {writableFeeds.read().length > 0 && (
        <FeedPostEditCard style={{ marginBottom: 16 }} />
      )}
      {hasFeaturePersonalFeeds && (
        <Box mb={1} display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Switch
                checked={includePersonal === 'yes'}
                onChange={(e) => {
                  setIncludePersonal(e.target.checked ? 'yes' : 'no')
                  setLimit(20)
                }}
              />
            }
            label={t('Show people posts')}
          />
        </Box>
      )}

      {posts.length === 0 ? (
        <div
          style={{
            flex: 1,
            textAlign: 'center',
            color: colors.labelDim,
            fontSize: 14,
          }}
        >
          {t('Nothing here...')}
        </div>
      ) : (
        posts
      )}

      {dashboard && Boolean(posts.length) && (
        <Button
          disabled={!hasMore}
          style={{
            cursor: hasMore ? 'pointer' : 'default',
            backgroundColor: hasMore ? undefined : colors.labelDim,
            opacity: hasMore ? 1 : 0.5,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15px',
          }}
          onClick={() => loadMore()}
        >
          {t('Load more posts')}
        </Button>
      )}
    </div>
  )
}
