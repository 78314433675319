import { useFirestoreDoc } from '@iwikal/reactfire'
import { IconButton, PopoverProps, Tooltip } from '@mui/material'
import { colors } from 'connect-shared/constants'
import { privateRoomId } from 'connect-shared/new-chat'
import { Suspense, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Img } from 'react-suspense-img'
import Card from '../Card'
import { useColors } from '../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useTranslate } from '../hooks/Internationalization'
import useUser from '../hooks/useUser'
import { ReactComponent as ChatIcon } from '../icons/ico-chat.svg'
import { ReactComponent as PersonIcon } from '../icons/ico-profile.svg'
import { ReactComponent as EmailIcon } from '../icons/ico-send-email.svg'
import { Container, ContainerProps, Pusher, Spacer } from '../layout/Layout'
import PopoverWrapper from '../PopoverWrapper'
import Spinner from '../Spinner'
import StatusAvatar from '../StatusAvatar'
import coverImages from './coverImages'

type UserCardPopoverProps = {
  userId?: string
  delay?: number
} & PopoverProps

export function UserCardPopover({ userId, ...rest }: UserCardPopoverProps) {
  const user = useUser(userId)
  if (!user) {
    return null
  }

  return (
    <PopoverWrapper {...rest}>
      <Suspense fallback={null}>
        <UserCard userId={userId} />
      </Suspense>
    </PopoverWrapper>
  )
}

type UserCardButtonProps = {
  label: string
  href?: string
  onClick?: () => void
} & ContainerProps

function UserCardButton({
  label,
  href,
  onClick,
  children,
}: UserCardButtonProps) {
  return (
    <Container>
      <Tooltip title={label}>
        <IconButton
          onClick={onClick}
          aria-label={label}
          href={href || ''}
          size="large"
        >
          {children}
        </IconButton>
      </Tooltip>
    </Container>
  )
}

function UserCardFallback() {
  return (
    <Container
      vAlign="center"
      hAlign="center"
      style={{ height: '100%', width: '100%' }}
    >
      <Container
        style={{
          backgroundColor: colors.faint,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          width: '100%',
          height: 80,
        }}
      />
      <Container
        style={{ flex: 1, marginLeft: 20, marginTop: -33, width: '100%' }}
        horizontal
        hAlign="left"
        vAlign="center"
      >
        <div
          style={{
            backgroundColor: colors.noAvatar,
            borderRadius: '50%',
            width: 60,
            height: 60,
          }}
        />
      </Container>
      <Spacer size={25} />
      <Spinner />
      <Spacer size={50} />
    </Container>
  )
}

type UserCardProps = {
  userId?: string
}

function UserCard({ userId }: UserCardProps) {
  const colors = useColors()
  const currentUserId = useCurrentUserId()
  const user = useUser(userId)
  const history = useHistory()

  const organizationDoc = useOrganizationDoc()
  const orgResource = useFirestoreDoc(organizationDoc)
  const chatEnabled = orgResource.read().data()?.chatEnabled ?? true

  const navigateChat = useCallback(() => {
    if (!user) return
    const roomId = privateRoomId(currentUserId, user?.id)
    history.push(`/chat/rooms/${roomId}`)
  }, [currentUserId, history, user])

  const navigateProfile = useCallback(() => {
    history.push(`/users/${userId}`)
  }, [history, userId])
  const t = useTranslate()

  if (!user) return null

  return (
    <Card
      style={{
        display: 'flex',
        width: 300,
        minHeight: 300,
        padding: 0,
        boxShadow: `0px 0px 3px ${colors.shadow}`,
      }}
    >
      <Suspense fallback={<UserCardFallback />}>
        <Img
          style={{
            objectFit: 'cover',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: 80,
          }}
          src={user?.coverImage || coverImages[1]}
        />
        <Container
          style={{
            marginTop: -33,
            flex: 1,
            fontSize: 14,
            fontWeight: 300,
            color: colors.labelInfoText,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 11,
          }}
        >
          <Container style={{ color: colors.labelTopic }}>
            <StatusAvatar userId={userId} size={60} />
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop: 4,
                fontSize: 16,
              }}
            >
              {user?.fullName}
            </p>
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop: 5,
              }}
            >
              {user?.statusText}
            </p>
          </Container>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: 10,
            }}
          >
            {user?.jobTitle}
          </p>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: 4,
            }}
          >
            {user?.workplace}
          </p>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: 4,
            }}
          >
            {user?.email}
          </p>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: 4,
            }}
          >
            {user?.phone}
          </p>
          <Pusher />
          <Container horizontal style={{ marginLeft: -10, marginTop: 20 }}>
            {chatEnabled && (
              <UserCardButton label={t('Chat')} onClick={navigateChat}>
                <ChatIcon width={24} fill={colors.labelDim} />
              </UserCardButton>
            )}
            <UserCardButton label={t('E-mail')} href={'mailto:' + user?.email}>
              <EmailIcon width={24} fill={colors.labelDim} />
            </UserCardButton>
            <UserCardButton
              label={t('Visit profile')}
              onClick={navigateProfile}
            >
              <PersonIcon width={24} fill={colors.labelDim} />
            </UserCardButton>
          </Container>
        </Container>
      </Suspense>
    </Card>
  )
}
