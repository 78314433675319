import { Backdrop, Tooltip, Zoom } from '@mui/material'
import { colors } from 'connect-shared/constants'
import getStatus from 'connect-shared/statuses'
import User, { userFullName } from 'connect-shared/User'
import { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Img } from 'react-suspense-img'
import useClickOutside from 'use-click-outside'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import {
  language,
  useDateFns,
  useTranslate,
} from '../../hooks/Internationalization'
import useAccountInfo, { isAccount } from '../../hooks/useAccountInfo'
import useAvailabilityEnabled from '../../hooks/useAvailabilityEnabled'
import IconButton from '../../IconButton'
import IconHoverWrapper from '../../IconHoverWrapper'
import { ReactComponent as CloseIcon } from '../../icons/ico-not-going.svg'
import { ReactComponent as SwitchUserIcon } from '../../icons/ico-profile.svg'
import { ReactComponent as AccountSettingsIcon } from '../../icons/ico-settings.svg'
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg'
import { Container, Pusher } from '../../layout/Layout'
import RoundUserAvatar from '../../RoundUserAvatar'
import StatusButtons from '../../StatusButtons'
import { navigationFullHeight } from '../../style'
import { formatDate } from '../../user-profile/ProfileCover'
import StatusTextInput from './StatusTextInput'
import { useFirestoreDoc } from '@iwikal/reactfire'

export default function StatusModal({
  close,
  currentUser,
  onVacationClick,
  isModal,
}: {
  close: () => void
  currentUser: User
  onVacationClick: () => void
  isModal?: boolean
}) {
  const t = useTranslate()
  const currentUserId = useCurrentUserId()

  const modalRef = useRef<HTMLDivElement>(null)
  useClickOutside(modalRef, close)

  const isAccountUser = isAccount(useAccountInfo())

  const history = useHistory()
  const navigateSwitchUser = useCallback(() => {
    close()
    // TODO For some reason this setTimeout is needed :(
    setTimeout(() => {
      history.push('/switch-user')
    }, 1)
  }, [close, history])

  const navigateAccountSettings = useCallback(() => {
    close()
    // TODO For some reason this setTimeout is needed :(
    setTimeout(() => {
      history.push('/account-settings')
    }, 1)
  }, [close, history])

  const availabilityEnabled = useAvailabilityEnabled().read()

  const status = getStatus(currentUser)
  const { format } = useDateFns()
  const organization = useFirestoreDoc(useOrganizationDoc()).read().data()

  const isSupportUser =
    organization && currentUserId === organization.supportUserId

  return (
    <Backdrop
      style={{
        top: isSupportUser ? navigationFullHeight - 82 : navigationFullHeight,
      }}
      open
    >
      <Container
        onClick={(e) => e.stopPropagation()}
        divRef={modalRef}
        style={{
          position: 'absolute',
          right: 0,
          zIndex: 1,
          top: isSupportUser ? 125 : 0,
          backgroundImage: `url(${currentUser.coverImage})`,
          backgroundSize: '100% 110px',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          width: 310,
          backgroundColor: colors.background,
          borderRadius: 5,
        }}
      >
        <Container
          horizontal
          style={{
            width: '100%',
            top: 0,
            zIndex: 2,
          }}
        >
          <IconHoverWrapper>
            <Link
              to="/sign-out"
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                border: 0,
                backgroundColor: colors.none,
                textDecoration: 'none',
              }}
            >
              <Tooltip
                title={t('Sign out')}
                arrow
                placement="top"
                TransitionComponent={Zoom}
              >
                <LogoutIcon
                  fill={colors.headerIcon}
                  width={20}
                  height={20}
                  style={{
                    filter: 'drop-shadow(2px 2px 4px black)',
                  }}
                />
              </Tooltip>
            </Link>
          </IconHoverWrapper>
          <Pusher />
          {isAccountUser && (
            <IconHoverWrapper>
              <span
                onClick={navigateAccountSettings}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 12,
                  border: 0,
                  backgroundColor: colors.none,
                  textDecoration: 'none',
                }}
              >
                <Tooltip
                  title={t('Account settings')}
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <AccountSettingsIcon
                    fillColor={colors.headerIcon}
                    width={20}
                    height={20}
                    style={{
                      filter: 'drop-shadow(2px 2px 4px black)',
                    }}
                  />
                </Tooltip>
              </span>
            </IconHoverWrapper>
          )}
          {isAccountUser && (
            <IconHoverWrapper>
              <span
                onClick={navigateSwitchUser}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 12,
                  border: 0,
                  backgroundColor: colors.none,
                  textDecoration: 'none',
                }}
              >
                <Tooltip
                  title={t('Switch user')}
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <SwitchUserIcon
                    fillColor={colors.headerIcon}
                    width={20}
                    height={20}
                    style={{
                      filter: 'drop-shadow(2px 2px 4px black)',
                    }}
                  />
                </Tooltip>
              </span>
            </IconHoverWrapper>
          )}
          <IconHoverWrapper>
            <IconButton
              fill={colors.headerIcon}
              onClick={close}
              Icon={CloseIcon}
              size={20}
              style={{
                padding: 12,
                filter: 'drop-shadow(2px 2px 4px black)',
              }}
            />
          </IconHoverWrapper>
        </Container>
        {!!currentUser.coverImage && (
          <div
            style={{
              height: 110,
              overflow: 'hidden',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Img
              style={{
                width: '100%',
              }}
              src={currentUser.coverImage}
            />
          </div>
        )}
        <Link
          to={`/users/${currentUser.id}`}
          onClick={close}
          style={{
            zIndex: 1,
            borderRadius: 98 / 2,
            width: 98,
            height: 98,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.background,
            margin: '20px 0 0 0',
          }}
        >
          <RoundUserAvatar
            className="status_modal_avatar"
            userId={currentUserId}
            size={90}
          />
        </Link>
        <Container horizontal vAlign="center">
          {currentUser.status && availabilityEnabled && (
            <div
              style={{
                padding: '5px 10px',
                borderRadius: 6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: status.color,
                fontSize: 11,
                color: colors.textInverted,
                fontWeight: 600,
              }}
            >
              {status.name[language]}
            </div>
          )}
          {currentUser.lastStatusChangeTimestamp && availabilityEnabled && (
            <span
              style={{ marginLeft: 10, color: colors.chatRead, fontSize: 12 }}
            >
              {t('since %{date}', {
                date: formatDate(format, currentUser.lastStatusChangeTimestamp),
              })}
            </span>
          )}
        </Container>
        <div
          style={{
            alignSelf: 'stretch',
            flex: 1,
            padding: '20px 30px 0 30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              maxWidth: '100%',
              fontWeight: 600,
              fontSize: 16,
              color: colors.text,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {userFullName(currentUser)}
          </p>
          <p
            style={{
              marginTop: 5,
              fontSize: 12,
              color: colors.text,
              marginBottom: 40,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {currentUser.jobTitle}
          </p>
          {availabilityEnabled && (
            <>
              <StatusTextInput />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 20,
                  justifyContent: 'center',
                  marginBottom: 20,
                  width: '100%',
                }}
              >
                <StatusButtons
                  isModal={isModal}
                  onVacationClick={onVacationClick}
                  onClick={close}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    </Backdrop>
  )
}
