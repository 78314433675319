import { useFirestoreDoc } from '@iwikal/reactfire'
import { colors } from 'connect-shared/constants'
import { useTransition, useCallback, useState } from 'react'
import { useOrganizationDoc } from '../../hooks/auth'
import useCurrentUser from '../../hooks/useCurrentUser'
import StatusAvatar from '../../StatusAvatar'
import StatusModal from './StatusModal'
import PlannedLeaveModal from '../../PlannedLeaveModal'

export default function SidebarAvatar() {
  const [statusModalOpen, setStatusModalOpen] = useState(false)
  const [vacationModalOpen, setVacationModalOpen] = useState(false)
  const openVacationModal = useCallback(() => {
    setStatusModalOpen(false)
    setVacationModalOpen(true)
  }, [])
  const currentUser = useCurrentUser()

  // Preload
  useFirestoreDoc(useOrganizationDoc())

  const closeModal = useCallback(() => {
    setStatusModalOpen(false)
  }, [])

  const [transitionRunning, runTransition] = useTransition()
  const openModal = useCallback(() => {
    runTransition(() => {
      setStatusModalOpen(true)
    })
  }, [runTransition])

  const SIZE = 50
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <button
        style={{
          padding: 1,
          margin: '0 0 15px 0',
          cursor: 'pointer',
          alignSelf: 'center',
          width: SIZE,
          height: SIZE,
          borderRadius: SIZE,
          border: `1px solid ${colors.foreground}`,
          opacity: transitionRunning ? 0.6 : 1,
        }}
        onClick={openModal}
        className="sidebar_avatar"
      >
        {currentUser && currentUser.avatar && (
          <StatusAvatar userId={currentUser.id} size={SIZE - 4} />
        )}
      </button>
      {statusModalOpen && currentUser && (
        <StatusModal
          isModal
          onVacationClick={openVacationModal}
          close={closeModal}
          currentUser={currentUser}
        />
      )}
      {vacationModalOpen && (
        <PlannedLeaveModal onClose={() => setVacationModalOpen(false)} />
      )}
    </div>
  )
}
