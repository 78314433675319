import { DocumentSnapshot } from 'connect-shared/firestore'
import { FeedDocument } from 'connect-shared/types'
import React, { useMemo } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useScrollLimitQuery } from '../../chat/useScrollLimitQuery'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { usePosts } from '../../hooks/feeds'
import useCurrentUser from '../../hooks/useCurrentUser'
import { Container, Spacer } from '../../layout/Layout'
import EmptyFeed from '../EmptyFeed'
import FeedPostEditCard from '../FeedPostEditCard'
import FeedPost from '../post/FeedPost'
import { useFirestoreCollection } from '@iwikal/reactfire'

function usePostsSnap(feedId: string) {
  const query = usePosts(feedId).orderBy('createdDate', 'desc')
  return useScrollLimitQuery(query)
}

function useFeedPosts(feedId: string) {
  const { loadMore, snap } = usePostsSnap(feedId)
  const currentUserId = useCurrentUserId()
  const organizationDoc = useOrganizationDoc()
  const pinnedSubsRef = useMemo(() => {
    return organizationDoc
      .collection('combinedFeeds')
      .doc(currentUserId)
      .collection('posts')
      .where('pinned', '==', true)
  }, [currentUserId, organizationDoc])

  const pinnedPostIds = useFirestoreCollection(pinnedSubsRef)
    .read()
    .docs.map((doc) => doc.id)

  const pinnedPosts: JSX.Element[] = []
  const posts: JSX.Element[] = []

  for (const post of snap.docs) {
    if (pinnedPostIds.includes(post.id)) {
      pinnedPosts.push(<FeedPost postSnap={post} key={post.id} />)
    } else {
      posts.push(<FeedPost postSnap={post} key={post.id} />)
    }
  }

  return [loadMore, posts, pinnedPosts] as const
}

type FeedPostsProps = {
  feedSnap: DocumentSnapshot<FeedDocument>
}

export default function FeedPosts({ feedSnap }: FeedPostsProps) {
  const [loadMore, posts, pinnedPosts] = useFeedPosts(feedSnap.id)

  const currentUserId = useCurrentUserId()
  const currentUser = useCurrentUser()
  const isAdmin =
    feedSnap.data()?.admins?.[currentUserId] === true ||
    currentUser?.organizationAdmin ||
    false
  const mayPost = isAdmin || feedSnap.data()?.anyoneMayPost

  useBottomScrollListener<HTMLDivElement>(loadMore)

  return (
    <Container style={{ marginTop: mayPost ? 30 : 0 }}>
      {mayPost && <FeedPostEditCard initialFeedId={feedSnap.id} />}
      {posts.length === 0 && pinnedPosts.length === 0 ? (
        <EmptyFeed isAdmin={isAdmin} />
      ) : (
        <Spacer size={30} />
      )}
      {pinnedPosts}
      {posts}
    </Container>
  )
}
