import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { CommentDocument } from 'connect-shared/types'
import { userFullName } from 'connect-shared/User'
import { isToday, isYesterday } from 'date-fns'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { QueryDocumentSnapshot, Timestamp } from 'connect-shared/firestore'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { useCurrentUserId } from '../../hooks/auth'
import useUser from '../../hooks/useUser'
import { Container, Pusher } from '../../layout/Layout'
import PopoutMenu, { PopoutMenuOption } from '../../PopoutMenu'
import { BodyText } from '../../Text'
import { ReactComponent as ReplyIcon } from '../../icons/ico-answer.svg'
import RoundAvatar from '../../RoundAvatar'
import CommentLikeCounter from './CommentLikeCounter'
import { UserCardPopover } from '../../user-profile/UserCard'
import { usePopoverHover } from '../../PopoverWrapper'
import { useDateFns, useTranslate } from '../../hooks/Internationalization'

export const LowTooltip = withStyles({
  tooltip: {
    margin: 0,
  },
})(Tooltip)

type CommentItemHeaderProps = {
  authorId: string
  commentSnap: QueryDocumentSnapshot<CommentDocument>
  onEditClick?: () => void
  onRemoveClick?: () => void
  editMode?: boolean
  onReply?: () => void
  energized?: boolean
  onEnergize?: () => void
  isAdmin: boolean
}
export default function CommentItemHeader({
  authorId,
  commentSnap,
  onEditClick,
  onRemoveClick,
  editMode,
  onReply,
  isAdmin,
}: CommentItemHeaderProps) {
  const colors = useColors()
  const t = useTranslate()

  const isAuthor = useCurrentUserId() === authorId
  const allowEdit = isAuthor || isAdmin
  const { format } = useDateFns()

  const options: PopoutMenuOption[] = allowEdit
    ? [
        { text: t('Edit'), onClick: onEditClick },
        { text: t('Remove'), onClick: onRemoveClick, danger: true },
      ]
    : []

  const formatDate = useCallback(
    (timestamp: Timestamp | undefined) => {
      if (!timestamp) {
        return undefined
      }
      const date = timestamp.toDate()

      if (isToday(date)) {
        return t('Today %{time}', { time: format(date, 'HH:mm') })
      }
      if (isYesterday(date)) {
        return t('Yesterday %{time}', { time: format(date, 'HH:mm') })
      }
      return format(date, 'dd MMMM yyyy HH:mm')
    },
    [format, t]
  )

  const { anchorProps, popOverProps } = usePopoverHover()
  const author = useUser(authorId)
  return (
    <Container
      flex={1}
      vAlign="center"
      horizontal
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <UserCardPopover userId={authorId} {...popOverProps} />
      <Link
        to={`/users/${authorId}`}
        style={{
          textDecoration: 'none',
        }}
        {...anchorProps}
      >
        <RoundAvatar
          avatar={author?.avatar}
          size={40}
          name={author?.fullName}
        />
      </Link>
      <Container
        vAlign="center"
        flex={1}
        horizontal
        style={{
          marginLeft: 10,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Link
          to={`/users/${authorId}`}
          style={{
            width: '100%',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Container
            style={{
              width: '100%',
              flex: undefined,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {commentSnap.data().editedDate ? (
              <LowTooltip
                title={t('Edited %{date}', {
                  date: formatDate(commentSnap.data().editedDate),
                })}
                placement={'bottom-start'}
              >
                <div>
                  <BodyText
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: 5,
                    }}
                  >
                    {userFullName(author)}
                  </BodyText>
                  <BodyText
                    style={{ fontSize: '11px', color: colors.labelDim }}
                  >
                    {`${formatDate(commentSnap.data().createdDate)} (edited)`}
                  </BodyText>
                </div>
              </LowTooltip>
            ) : (
              <div>
                <BodyText
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginRight: 5,
                  }}
                >
                  {userFullName(author)}
                </BodyText>
                <BodyText style={{ fontSize: '11px', color: colors.labelDim }}>
                  {formatDate(commentSnap.data().createdDate)}
                </BodyText>
              </div>
            )}
          </Container>
        </Link>
        <Pusher />
        {!editMode && (
          <Container horizontal vAlign="center">
            {options.length > 0 && <PopoutMenu options={options} />}
            {onReply && (
              <ClickableOpacity onClick={onReply}>
                <ReplyIcon width={22} height={22} />
              </ClickableOpacity>
            )}
            <CommentLikeCounter commentSnap={commentSnap} />
          </Container>
        )}
      </Container>
    </Container>
  )
}
