import { Button, Tooltip } from '@mui/material'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Timestamp } from 'connect-shared/firestore'
import { InvitationDocument, UserDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useColors } from '../Colors'
import { firestore } from '../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import IconButton from '../IconButton'
import { ReactComponent as RemoveIcon } from '../icons/ico-close.svg'
import { Container, Pusher, Spacer } from '../layout/Layout'
import { BodyText, CaptionText, NoteText } from '../Text'
import TextInput from '../TextInput'
import ModalCloseButton from '../ModalCloseButton'
import { emitTutorialEvent } from '../Tutorials'
import { useUserlimit } from '../hooks/useUserlimit'
import { useTranslate } from '../hooks/Internationalization'

function EmailList({
  emails,
  removeItem,
  invitesLeft,
}: {
  emails: Set<string>
  removeItem: (email: string) => void
  invitesLeft: number | undefined
}) {
  const colors = useColors()
  const t = useTranslate()

  const handleRemoveItem = useCallback(
    (email: string) => {
      removeItem(email)
    },
    [removeItem]
  )

  const { userLimit, reached } = useUserlimit()
  return (
    <>
      <Container horizontal>
        {invitesLeft && (
          <>
            <CaptionText style={{ marginBottom: 10, marginRight: 5 }}>
              {t('Invites used')}
            </CaptionText>
            <Tooltip
              placement="top"
              title={reached ? t('User limit exceeded') : ''}
            >
              <div>
                <CaptionText
                  style={{
                    marginBottom: 10,
                    color:
                      emails.size === invitesLeft
                        ? colors.warning
                        : emails.size > invitesLeft
                        ? colors.danger
                        : colors.label,
                  }}
                >
                  {emails.size} / {invitesLeft}{' '}
                </CaptionText>
              </div>
            </Tooltip>
            <Pusher />
            <Tooltip
              placement="top"
              title={t(
                'The amount of users you can have set by your current plan'
              )}
            >
              <div>
                <CaptionText>
                  {t('User limit: ')}
                  <span style={{ color: colors.label }}>{userLimit}</span>
                </CaptionText>
              </div>
            </Tooltip>
          </>
        )}
      </Container>
      <Container
        style={{
          backgroundColor: colors.foreground,
          border: `1px solid ${colors.inputBorder}`,
          overflowY: 'auto',
          height: 240,
          borderRadius: 5,
          padding: 10,
        }}
      >
        <Container horizontal style={{ flexWrap: 'wrap' }}>
          {[...emails].map((email, i) => {
            return (
              <Container
                key={i}
                horizontal
                vAlign="center"
                style={{
                  height: 22,
                  backgroundColor: colors.selected,
                  color: colors.inputBorder,
                  borderRadius: 5,
                  padding: 3,
                  marginRight: 10,
                  marginBottom: 10,
                }}
              >
                <BodyText>{email}</BodyText>
                <IconButton
                  fill={colors.labelDim}
                  Icon={RemoveIcon}
                  size={20}
                  onClick={() => handleRemoveItem(email)}
                />
              </Container>
            )
          })}
        </Container>
      </Container>
    </>
  )
}
type InviteUsersModalProps = {
  close: () => void
}
export default function InviteUsersModal({ close }: InviteUsersModalProps) {
  const colors = useColors()
  const t = useTranslate()
  const [emails, setEmails] = useState(new Set<string>())
  const [inputValue, setInputValue] = useState('')
  const organizationDoc = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const onSubmit = useCallback(async () => {
    const promises = [...emails].map((email) => {
      const batch = firestore().batch()
      const userData: UserDocument = {
        organization: organizationDoc,
        email: email.toLowerCase(),
        invitePending: true,
        avatar: '',
      }
      const userDoc = firestore().collection('users').doc()
      batch.set(userDoc, userData)
      const codeDoc = firestore().collection('invitations').doc(userDoc.id)
      const randomInts = crypto.getRandomValues(new Uint32Array(10))
      const chars = 'ABCDEFGHJKMNPQRTWXYZ2346789'
      const code = [...randomInts]
        .map((randomInt) => chars[randomInt % chars.length])
        .join('')

      const codeData: InvitationDocument = {
        code,
        invitee: currentUserId,
        inviter: currentUserId,
        timestamp:
          firebase.firestore.FieldValue.serverTimestamp() as any as Timestamp,
      }
      batch.set(codeDoc, codeData)
      return batch.commit()
    })
    emitTutorialEvent('invite_user')
    await Promise.all(promises).catch((err) => {
      console.error(err)
    })

    close()
  }, [close, currentUserId, emails, organizationDoc])

  const addEmails = useCallback(() => {
    if (!inputValue) return

    setEmails((emails) => {
      const newEmails = new Set(emails)
      for (const email of inputValue.split(' ')) {
        newEmails.add(email.toLowerCase())
      }
      setInputValue('')
      return newEmails
    })
  }, [inputValue])

  const removeEmail = useCallback((email: string) => {
    setEmails((emails) => {
      const newEmails = new Set(emails)
      newEmails.delete(email)
      return newEmails
    })
  }, [])

  const validEmails =
    inputValue &&
    !inputValue.split(' ').some((email) => {
      return email.match(/^[\w\d-.+]+@[\w\d-.]+\.\w+$/) === null
    })

  const history = useHistory()

  const { reached, userCount, userLimit } = useUserlimit()

  const invitesLeft = userLimit ? userLimit - userCount : undefined
  const withinLimit = useMemo(() => {
    if (!invitesLeft) return undefined
    return emails.size <= invitesLeft
  }, [emails.size, invitesLeft])

  const tooltipText =
    !inputValue && emails.size === 0
      ? t('Enter an email address')
      : reached
      ? t(
          'You have reached your user-limit, upgrade your plan to send more invites'
        )
      : !validEmails && !!inputValue
      ? t('You need to enter valid emails to continue')
      : ''

  return (
    <DialogOverlay style={{ zIndex: 10 }} onDismiss={close} aria-label=" ">
      <DialogContent
        aria-label=" "
        style={{
          position: 'relative',
          width: 652,
          borderRadius: 10,
          backgroundColor: colors.webChatModalBackground,
        }}
      >
        <Container horizontal>
          <CaptionText
            style={{ marginBottom: 20, fontSize: 20, color: colors.text }}
            strong
          >
            {t('Invite users to your organization')}
          </CaptionText>
          <Pusher />
          <Tooltip
            title={
              reached
                ? t(
                    'You have reached your user-limit, upgrade your plan to send more invites'
                  )
                : ''
            }
          >
            <div>
              <Button
                style={{ textTransform: 'initial' }}
                variant="outlined"
                disabled={reached}
                size="small"
                onClick={useCallback(() => {
                  history.push('/settings/users/bulk-invite')
                }, [history])}
              >
                <span>{t('Invite in bulk')}</span>
              </Button>
            </div>
          </Tooltip>
        </Container>
        <NoteText style={{ marginBottom: 5 }}>
          {t('List of space separated e-mail addresses')}
        </NoteText>
        <Container flex={1} horizontal vAlign="top">
          <TextInput
            onKeyDown={useCallback(
              (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  addEmails()
                }
              },
              [addEmails]
            )}
            inputStyle={{ backgroundColor: colors.foreground }}
            value={inputValue}
            onChangeText={setInputValue}
            style={{ flex: 1 }}
            placeholder={t('Email addresses')}
            size={12}
          />
          <Spacer size={20} />
          <Tooltip title={tooltipText} placement="top">
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!validEmails || reached}
                onClick={addEmails}
                style={{ height: 40, marginTop: 5 }}
                className="invite_add"
              >
                {t('Add')}
              </Button>
            </div>
          </Tooltip>
        </Container>
        <EmailList
          removeItem={removeEmail}
          emails={emails}
          invitesLeft={invitesLeft}
        />
        <Container horizontal style={{ marginTop: 30 }}>
          <Pusher />
          <Tooltip
            placement="top"
            title={
              !withinLimit && userLimit !== undefined
                ? t(
                    "You can't send more then %{invitesLeft} invites, remove some or upgrade your plan",
                    { invitesLeft }
                  )
                : !validEmails
                ? t('Click the "Add" button first')
                : undefined
            }
          >
            <div>
              <Button
                size="large"
                variant="contained"
                className="invite_submit"
                disabled={
                  !emails.size || (userLimit !== undefined && !withinLimit)
                }
                onClick={onSubmit}
              >
                {t('Invite')}
              </Button>
            </div>
          </Tooltip>
        </Container>
        <ModalCloseButton onClick={close} />
      </DialogContent>
    </DialogOverlay>
  )
}
