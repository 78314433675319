import { useCallback, useMemo } from 'react'
import firebase from 'firebase/app'
import RoundUserAvatar from '../RoundUserAvatar'
import styled from '@emotion/styled'
import { ReactComponent as RemoveIcon } from '../events/assets/close_white.svg'
import User, { userFullName } from 'connect-shared/User'
import { useCurrentUserId } from '../hooks/auth'
import { useFirestoreDoc } from '@iwikal/reactfire'
import ClickableOpacity from '../ClickableOpacity'
import { useHistory } from 'react-router'
import { Container } from '../layout/Layout'
import { BodyText } from '../Text'
import { useColors } from '../Colors'
import { useTranslate } from '../hooks/Internationalization'

const RemoveWrapper = styled.div`
  width: 30px;
  margin-left: auto;
`
const Remove = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #db5f5d;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 12px;
  }
`
const ListUserItem = (props: {
  userId: string
  handleUnselected?: (user: any) => void
}) => {
  const colors = useColors()
  const t = useTranslate()
  const { handleUnselected } = props

  const currentUserId = useCurrentUserId()

  const creator = useMemo(() => {
    return currentUserId === props.userId
  }, [currentUserId, props.userId])

  const history = useHistory()
  const navigateToUser = useCallback(() => {
    history.push(`/users/${props.userId}`)
  }, [history, props.userId])

  const userResource = useFirestoreDoc(
    firebase.firestore().collection('users').doc(props.userId)
  )
  if (!props.userId || !userResource.read().exists) {
    return null
  }
  const user = new User(userResource.read())

  return (
    <ClickableOpacity
      style={{
        flex: 1,
        display: 'flex',
        padding: `10px 0`,
      }}
      hover
      onClick={navigateToUser}
    >
      <Container
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        flex={1}
        horizontal
      >
        <Container>
          <RoundUserAvatar userId={props.userId} size={46} />
        </Container>
        <Container
          flex={1}
          style={{
            paddingLeft: 10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}
          vAlign="center"
          hAlign="left"
        >
          <BodyText
            style={{
              maxWidth: '100%',
              fontSize: 14,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {userFullName(user)}
            {creator ? t(' (you)') : null}
          </BodyText>
          <BodyText
            style={{
              fontSize: 12,
              color: colors.label,
              maxWidth: '100%',

              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {user.jobTitle || ''} {user.workplace || ''}
          </BodyText>
        </Container>
      </Container>
      <RemoveWrapper>
        {!creator && handleUnselected && (
          <Remove onClick={() => handleUnselected(props.userId)}>
            <RemoveIcon />
          </Remove>
        )}
      </RemoveWrapper>
    </ClickableOpacity>
  )
}

export default ListUserItem
