import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { TextField } from '@mui/material'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { UserDocument } from 'connect-shared/types'
import {
  Suspense,
  SuspenseList,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from 'react'
import { resource } from 'react-suspense-img'
import Card from '../Card'
import { useColors } from '../Colors'
import FilterPill from '../FilterPill'
import OutlineButton from '../OutlineButton'
import PreloadDoc from '../PreloadDoc'
import { BodyText, NoteText } from '../Text'
import { useScrollLimitQuery } from '../chat/useScrollLimitQuery'
import { firestore } from '../firestore'
import { useTranslate } from '../hooks/Internationalization'
import { useAuthData, useOrganizationDoc } from '../hooks/auth'
import useUserSearch from '../hooks/useUserSearch'
import { Container, Pusher, Spacer } from '../layout/Layout'
import PageLayout from '../layout/PageLayout'
import PlayippSpinner from '../playippSpinner/PlayippSpinner'
import TableInfoRow from '../wiki/TableInfoRow'
import InviteUsersModal from './InviteUsersModal'
import ManageUsersItem from './ManageUsersItem'
import ManageUsersMenu from './ManageUsersMenu'

export function useUserCounts(orgId?: string) {
  const { organizationId } = useAuthData(false)
  const orgDoc = firestore()
    .collection('organizations')
    .doc(organizationId || orgId)

  const doc = orgDoc.collection('extra').doc('userCounters')

  return useFirestoreDoc(doc)
}

type ActiveList = 'all' | 'admins'
function ListLayout({
  userSnaps,
  activeList,
}: {
  userSnaps: QueryDocumentSnapshot<UserDocument>[]
  activeList: ActiveList
}) {
  const userCountResource = useUserCounts()
  const userCounts = userCountResource.read().data()

  const colors = useColors()
  const t = useTranslate()

  const hasWorkplaces = !useFirestoreCollection(
    useOrganizationDoc().collection('workplaces').limit(1)
  ).read().empty

  return (
    <>
      <Card>
        <NoteText style={{ fontSize: 16, fontWeight: 600 }}>
          {t('Users')}{' '}
          <span style={{ color: colors.label }}>
            {activeList === 'all'
              ? userCounts?.totalUsers
              : userCounts?.organizationAdmins}
          </span>
        </NoteText>
        <Spacer size={20} />
        <Container
          style={{
            borderBottom: `1px solid ${colors.inputBorder}`,
            paddingBottom: 8,
          }}
          flex={1}
          horizontal
        >
          <Spacer size={61} />
          <TableInfoRow text={t('Name')} strong style={{ flex: 1.5 }} />
          <TableInfoRow text={t('Job title')} strong />
          {hasWorkplaces && <TableInfoRow text={t('Workplace')} strong />}
          <Spacer size={30} />
        </Container>
        <SuspenseList revealOrder="forwards">
          {userSnaps.map((userSnap) => {
            return (
              <Suspense key={userSnap.id} fallback={null}>
                <ManageUsersItem userSnap={userSnap} />
              </Suspense>
            )
          })}
        </SuspenseList>
      </Card>
    </>
  )
}

export default function ManageUsers() {
  const [activeList, setActiveList] = useState<ActiveList>('all')
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const openInviteModal = useCallback(() => {
    setInviteModalOpen(true)
  }, [])

  const closeInviteModal = useCallback(() => {
    setInviteModalOpen(false)
  }, [])

  const organizationDoc = useOrganizationDoc()
  const colors = useColors()
  const t = useTranslate()
  const query = useMemo(() => {
    const userQuery = firestore()
      .collection('users')
      .where('organization', '==', organizationDoc)
      .where('hidden', '==', false)

    if (activeList === 'admins') {
      return userQuery
        .where('organizationAdmin', '==', true)
        .orderBy('lowerFullName', 'asc')
    }
    return userQuery.orderBy('lowerFullName', 'asc')
  }, [activeList, organizationDoc])
  const { snap, hasMore } = useScrollLimitQuery(query, { initialLimit: 30 })

  const foundUsers = useUserSearch(
    useDeferredValue(searchTerm).toLowerCase()
  ).filter((user) => {
    return activeList === 'all' || user.data().organizationAdmin
  })

  useMemo(() => {
    for (const userSnap of snap.docs) {
      const { avatar } = userSnap.data()
      avatar && resource.preloadImage(avatar)
    }
  }, [snap])

  const userSnaps = searchTerm ? foundUsers : snap.docs

  return (
    <PageLayout style={{ marginTop: 30 }} leftSideContent={<ManageUsersMenu />}>
      <TextField
        variant="outlined"
        style={{ backgroundColor: colors.foreground, marginBottom: 30 }}
        placeholder={t('Search')}
        onChange={useCallback((e) => {
          setSearchTerm(e.target.value)
        }, [])}
      />
      <Container vAlign="center" horizontal style={{ marginBottom: 10 }}>
        <BodyText
          style={{
            fontSize: 14,
            fontWeight: 600,
            marginRight: 8,
            color: colors.menuUnselected,
          }}
        >
          {t('Show :')}
        </BodyText>
        <FilterPill
          text={t('All')}
          selected={activeList === 'all'}
          onClick={useCallback(() => {
            setActiveList('all')
          }, [])}
        />
        <Spacer size={8} />
        <FilterPill
          text={t('Organization admins')}
          selected={activeList === 'admins'}
          onClick={useCallback(() => {
            setActiveList('admins')
          }, [])}
        />
        <Pusher />
        <OutlineButton
          onClick={openInviteModal}
          style={{ display: 'inline-flex' }}
        >
          {t('Invite users')}
        </OutlineButton>
      </Container>
      {userSnaps.map((snap) => {
        return <PreloadDoc docRef={snap.ref} key={snap.id} />
      })}
      <ListLayout userSnaps={userSnaps} activeList={activeList} />
      {hasMore && !searchTerm && (
        <Container style={{ marginTop: 20 }} hAlign="center">
          <PlayippSpinner size={50} />
        </Container>
      )}
      {inviteModalOpen && <InviteUsersModal close={closeInviteModal} />}
    </PageLayout>
  )
}
