import styled from '@emotion/styled'
import React, { ReactText, useCallback } from 'react'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useColors } from './Colors'
import { ReactComponent as SuccessIcon } from './icons/ico-going.svg'
import { ReactComponent as ErrorIcon } from './icons/remove.svg'
import PlayippSpinner from './playippSpinner/PlayippSpinner'
import { InformationIcon } from './Icons'

const DEFAULT_AUTOCLOSE = 4000

const StyledContainer = styled.div`
  .Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast {
    border-radius: 5px;
    background-color: #505050;
    padding: 8px 30px;
    display: flex;
  }
`

function Text({ text }: { text: string }) {
  const colors = useColors()
  return (
    <p
      style={{
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        color: colors.textInverted,
      }}
    >
      {text}
    </p>
  )
}

function SuccessToast({ text }: { text: string }) {
  const colors = useColors()
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <SuccessIcon fillColor={colors.textInverted} width={30} height={30} />
      <Text text={text} />
    </div>
  )
}

function InfoToast({ text }: { text: string }) {
  const colors = useColors()
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <InformationIcon fillColor={colors.textInverted} width={30} height={30} />
      <Text text={text} />
    </div>
  )
}

function ErrorToast({ text }: { text: string }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ErrorIcon width={30} height={30} />
      <Text text={text} />
    </div>
  )
}

export function showErrorToast(
  text = 'Something went wrong, please try again later',
  autoClose = DEFAULT_AUTOCLOSE
) {
  toast(<ErrorToast text={text} />, { autoClose })
}

export function showSuccessToast(text: string, autoClose = DEFAULT_AUTOCLOSE) {
  toast(<SuccessToast text={text} />, { autoClose })
}

export function showInfoToast(text: string, autoClose = DEFAULT_AUTOCLOSE) {
  toast(<InfoToast text={text} />, { autoClose })
}

function SpinnerToast() {
  const colors = useColors()
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <PlayippSpinner size={'30px'} color={colors.active} />
      <Text text="Saving..." />
    </div>
  )
}

export function useSpinnerToast() {
  const toastRef = React.useRef<ReactText | null>(null)
  function show() {
    toastRef.current = toast(<SpinnerToast />)
  }

  const showError = useCallback((autoClose = DEFAULT_AUTOCLOSE) => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        render: <ErrorToast text={'Could not save'} />,
        autoClose,
      })
    }
  }, [])

  const dismissSuccess = useCallback((autoClose = DEFAULT_AUTOCLOSE) => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        render: <SuccessToast text="Saved!" />,
        autoClose,
      })
    }
  }, [])

  return {
    show,
    dismissSuccess,
    showError,
  }
}

export default function Toast() {
  return (
    <StyledContainer>
      <ToastContainer
        autoClose={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        position="bottom-center"
        closeButton={false}
        hideProgressBar
        transition={Bounce}
      />
    </StyledContainer>
  )
}
