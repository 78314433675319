import { CSSProperties, useCallback } from 'react'
import BlockButton, { BlockButtonProps } from './BlockButton'
import { useTranslate } from './hooks/Internationalization'

type CopyButtonProps = {
  flex?: CSSProperties['flex']
  content: string
} & BlockButtonProps

export default function CopyButton({
  flex = '0 0 auto',
  style,
  content,
  ...buttonProps
}: CopyButtonProps) {
  const t = useTranslate()
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(content)
  }, [content])
  return (
    <BlockButton
      onClick={handleClick}
      style={{
        flex,
        ...style,
      }}
      {...buttonProps}
    >
      {t('Copy')}
    </BlockButton>
  )
}
