import firebase from 'firebase/app'
import images from './coverImages.json'

const { projectId } = firebase.app().options as { projectId: string }
const url = `https://${projectId}.web.app/cover-images/`

const coverImages = Object.values(images).map(({ fileName }) => {
  return url + fileName
})
export default coverImages
