import styled from '@emotion/styled'
import React, { CSSProperties, TextareaHTMLAttributes } from 'react'
import { useColors } from './Colors'

const StyledTextarea = styled.textarea<{
  borderColor: string
  focusColor: string
}>`
  border-color: ${(p) => p.borderColor};

  &:hover,
  &:focus-within {
    border-color: ${(p) => p.focusColor};
  }
`

type TextareaInputProps = {
  onChangeText?: (text: string) => void
  value?: string
  label?: string
  placeholder?: string
  flex?: CSSProperties['flex']
} & TextareaHTMLAttributes<HTMLTextAreaElement>

export default function TextareaInput({
  onChangeText,
  value,
  label,
  placeholder,
  flex = '0 0 auto',
  rows = 4,
  ...textareaProps
}: TextareaInputProps) {
  const colors = useColors()

  return (
    <label
      style={{
        flex,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'text',
        fontSize: 16,
        color: colors.labelDim,
        overflow: 'hidden',
      }}
    >
      {label && (
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
          {label}
        </div>
      )}
      <StyledTextarea
        focusColor={colors.active}
        borderColor={colors.border}
        placeholder={placeholder}
        style={{
          height: 'auto',
          resize: 'none',
          color: colors.text,
          lineHeight: '18px',
          width: '100%',
          borderRadius: 5,
          borderWidth: 1,
          borderStyle: 'solid',
          padding: 10,
          fontSize: 16,
          outline: 'none',
          backgroundColor: colors.none,
        }}
        value={value}
        onChange={React.useCallback(
          (e) => {
            onChangeText?.(e.target.value)
          },
          [onChangeText]
        )}
        rows={rows}
        {...textareaProps}
      />
    </label>
  )
}
