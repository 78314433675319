export const navigationPadding = 10 as const
export const navigationHeight = 66 as const
export const navigationBorder = 5 as const
export const navigationFullHeight =
  navigationHeight + navigationBorder + navigationPadding
export const sideContentOffset = navigationFullHeight
export const sideContentHeight = `calc(100vh - ${sideContentOffset}px)`

export const sGutters = 16
export const mGutters = 20

export const sSideContentWidth = 172
export const mSideContentWidth = 217
export const lSideContentWidth = 264

export const sMainContentWidth = 552
export const mMainContentWidth = 686
export const lMainContentWidth = 832

const scrollbarMargin = 15

export const mWidth =
  mGutters * 4 + mSideContentWidth * 2 + mMainContentWidth + scrollbarMargin
export const lWidth =
  mGutters * 4 + lSideContentWidth * 2 + lMainContentWidth + scrollbarMargin
