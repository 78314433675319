import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { QueryDocumentSnapshot, QuerySnapshot } from 'connect-shared/firestore'
import {
  CalendarDocument,
  FeedDocument,
  UserDocument,
  WorkplaceDocument,
} from 'connect-shared/types'
import User from 'connect-shared/User'
import firebase from 'firebase/app'
import { Suspense, useCallback, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { resource } from 'react-suspense-img'
import BlockButton from '../BlockButton'
import Card from '../Card'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import ConfirmDeleteModal from '../ConfirmDeleteModal'
import DropdownCard from '../DropdownCard'
import { firestore } from '../firestore'
import FullscreenSpinner from '../FullscreenSpinner'
import HelpHover from '../HelpHover'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import useCurrentUser from '../hooks/useCurrentUser'
import { useWorkplacesWhereAdmin } from '../hooks/useIsWorkplaceAdmin'
import useOrganizationTheme from '../hooks/useOrganizationColors'
import { ReactComponent as EventIcon } from '../icons/ico-event.svg'
import { ReactComponent as FeedIcon } from '../icons/ico-feeds.svg'
import { ReactComponent as ForwardIcon } from '../icons/ico-chevron-right.svg'
import { ReactComponent as Illustration } from '../icons/ico-settings.svg'
import InlineBackButton from '../InlineBackButton'
import InputModal from '../InputModal'
import { Container, Pusher, Spacer } from '../layout/Layout'
import { placeholderAvatar } from '../placeholderAvatar'
import { Resource } from '../resource'
import RoundAvatar from '../RoundAvatar'
import { BodyText, CaptionText } from '../Text'
import WorkplaceAddChannelModal from './WorkplaceAddChannelModal'
import WorkplaceAddUserModal from './WorkplaceAddUserModal'
import WorkplaceListItem from './WorkplaceListItem'
import WorkplaceUserListItem from './WorkplaceUserListItem'
import useFeatureFlag from '../hooks/useFeatureFlag'
import { useTranslate } from '../hooks/Internationalization'

function InfoRow({
  leftText,
  rightText,
  bottomBorder,
  onClick,
}: {
  leftText: string
  rightText?: string
  bottomBorder?: boolean
  onClick: () => void
}) {
  const colors = useColors()
  const t = useTranslate()
  return (
    <ClickableOpacity onClick={onClick} hover>
      <Container
        style={{
          borderTop: `1px solid ${colors.selected}`,
          borderBottom: bottomBorder ? `1px solid ${colors.selected}` : '',
          padding: '20px 30px',
        }}
        vAlign="center"
        horizontal
      >
        <Container hAlign="left" style={{ width: 200 }}>
          <BodyText>{leftText}</BodyText>
        </Container>
        <BodyText
          style={{ fontWeight: 300, fontStyle: rightText ? '' : 'italic' }}
        >
          {rightText ? rightText : t('No information')}
        </BodyText>
        <Pusher />
        <ForwardIcon height={20} width={20} />
      </Container>
    </ClickableOpacity>
  )
}

function AvatarPreloader<T>({
  avatarResource,
  getAvatar,
}: {
  avatarResource: Resource<QuerySnapshot<T>>
  getAvatar: (data: QueryDocumentSnapshot<T>) => string | undefined
}) {
  avatarResource.read().docs.forEach((doc) => {
    const avatar = getAvatar(doc)
    if (avatar) {
      resource.preloadImage(avatar)
    }
  })
  return null
}

export default function WorkplaceSettings({ id }: { id: string }) {
  const [itemHovered, setItemHovered] = useState(false)
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const [mailModalOpen, setMailModalOpen] = useState(false)
  const [phoneModalOpen, setPhoneModalOpen] = useState(false)
  const [addressModalOpen, setAddressModalOpen] = useState(false)
  const [addAdminsOpen, setAddAdminsOpen] = useState(false)
  const [addFeedOpen, setAddFeedOpen] = useState(false)
  const [addCalendarOpen, setAddCalendarOpen] = useState(false)
  const [deleteWorkplaceModalOpen, setDeleteWorkplaceModalOpen] =
    useState(false)
  const [deleteSelfModalOpen, setDeleteSelfModalOpen] = useState(false)

  const handleItemHovered = useCallback((hovered: boolean) => {
    setItemHovered(hovered)
  }, [])

  const organizationDoc = useOrganizationDoc()
  const colors = useColors()
  const t = useTranslate()

  const workplacesCollection = organizationDoc.collection('workplaces')
  const workplaceRef = id
    ? workplacesCollection.doc(id)
    : workplacesCollection.doc()
  const emptyDoc = firestore().collection('empty').doc('empty')
  const workplace = useFirestoreDoc(id ? workplaceRef : emptyDoc)
    .read()
    .data()

  const workplaceFeedCollection = organizationDoc
    .collection('workplaceFeedSettings')
    .where('workplaceId', '==', id)
  const workplaceFeedsResource = useFirestoreCollection(workplaceFeedCollection)
  const workplaceFeeds = workplaceFeedsResource
    .read()
    .docs.filter((snap) => snap.data().autoSubscribe)

  const workplaceCalendarCollection = organizationDoc
    .collection('workplaceCalendarSettings')
    .where('workplaceId', '==', id)
  const workplaceCalendarsResource = useFirestoreCollection(
    workplaceCalendarCollection
  )
  const workplaceCalendars = workplaceCalendarsResource
    .read()
    .docs.filter((snap) => snap.data().autoSubscribe)

  const numOfAdmins = workplace?.admins?.length || 0
  const adminIds = workplace?.admins || []

  const workplaceUserCollection = firestore()
    .collection('users')
    .where('organization', '==', organizationDoc)
    .where('workplaceId', '==', id)

  const workplaceUsersResource = useFirestoreCollection(workplaceUserCollection)

  const numOfUsers = workplaceUsersResource.read().docs.length

  const feedsCollection = organizationDoc
    .collection('feeds')
    .where('inviteOnly', '==', false)
    .orderBy('name')

  const feedsResource = useFirestoreCollection(feedsCollection)

  const eventListCollection = organizationDoc
    .collection('calendars')
    .where('inviteOnly', '==', false)
    .orderBy('name')

  const eventListsResource = useFirestoreCollection(eventListCollection)

  const handleNameModal = useCallback((open: boolean) => {
    setNameModalOpen(open)
  }, [])

  const handlePhoneModal = useCallback((open: boolean) => {
    setPhoneModalOpen(open)
  }, [])

  const currentUserId = useCurrentUserId()

  const removeAdmin = useCallback(
    async (changes: Partial<WorkplaceDocument>) => {
      if (!workplace) return
      if (
        workplace.admins?.includes(currentUserId) &&
        !changes.admins?.includes(currentUserId)
      ) {
        setDeleteSelfModalOpen(true)
        return
      }
      const newWorkplace: WorkplaceDocument = { ...workplace, ...changes }
      await workplaceRef.set(newWorkplace, { merge: true })
    },
    [workplace, currentUserId, workplaceRef]
  )

  const removeMember = useCallback(async (userId: string) => {
    const userRef = firestore().collection('users').doc(userId)
    await userRef.update({
      workplace: firebase.firestore.FieldValue.delete(),
      workplaceId: firebase.firestore.FieldValue.delete(),
    })
  }, [])

  const [deleteLoading, setDeleteLoading] = useState(false)

  const history = useHistory()
  const deleteWorkplace = useCallback(async () => {
    setDeleteLoading(true)
    await workplaceRef.delete()
    history.push('/settings/workplaces')
  }, [workplaceRef, history])

  const handleDeleteSelf = useCallback(() => {
    if (!workplace) return
    const newAdmins = new Set(workplace.admins)
    newAdmins.delete(currentUserId)
    workplaceRef.set({ admins: [...newAdmins] }, { merge: true })
    setDeleteSelfModalOpen(false)
  }, [currentUserId, workplace, workplaceRef])

  const emptyMessageStyle: React.CSSProperties = {
    fontStyle: 'italic',
    padding: '20px 30px',
  }

  const openAddCalendar = useCallback(() => {
    setAddCalendarOpen(true)
  }, [])

  const openAddFeed = useCallback(() => {
    setAddFeedOpen(true)
  }, [])

  const openDeleteWorkplace = useCallback(() => {
    setDeleteWorkplaceModalOpen(true)
  }, [])

  // TODO never load all users at once, use infinite scroll loader
  const usersQuery = firestore()
    .collection('users')
    .where('organization', '==', organizationDoc)
    .where('hidden', '==', false)

  const currentUser = useCurrentUser()

  const usersResource = useFirestoreCollection(usersQuery)

  const getUserAvatar = useCallback(
    (snap: QueryDocumentSnapshot<UserDocument>) => {
      return new User(snap).avatar
    },
    []
  )

  const { primary, primaryText } = useOrganizationTheme()
  const getFeedAvatar = useCallback(
    (snap: QueryDocumentSnapshot<FeedDocument | CalendarDocument>) => {
      const data = snap.data()
      const avatar = data.avatar
        ? data.avatar
        : placeholderAvatar(data.name || '', primary, primaryText).toString()
      return avatar
    },
    [primary, primaryText]
  )
  const workplacesWhereAdmin = useWorkplacesWhereAdmin()
    .read()
    .docs.map((snap) => snap.id)
  const isWorkplaceAdmin = workplacesWhereAdmin.includes(workplaceRef.id)

  const legacyEventsEnabled = useFeatureFlag('legacyEvents', false)

  if (!currentUser?.organizationAdmin && !isWorkplaceAdmin) {
    return <Redirect to="/" />
  }
  if (workplace === undefined) {
    return <FullscreenSpinner />
  }

  return (
    <>
      <Suspense fallback={null}>
        <AvatarPreloader
          avatarResource={usersResource}
          getAvatar={getUserAvatar}
        />
        <AvatarPreloader
          avatarResource={feedsResource}
          getAvatar={getFeedAvatar}
        />
        <AvatarPreloader
          avatarResource={eventListsResource}
          getAvatar={getFeedAvatar}
        />
      </Suspense>
      <Container hAlign="center" flex={1} style={{ overflowY: 'auto' }}>
        <Spacer size={60} />
        <Container style={{ width: 820 }}>
          <Container hAlign="left" horizontal>
            <InlineBackButton text />
            <Pusher />
            <BlockButton onClick={openDeleteWorkplace} danger>
              {t('Delete workplace')}
            </BlockButton>
          </Container>
          <Spacer size={14} />
          <Card
            flex={1}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <RoundAvatar
              size={150}
              name={workplace?.name}
              avatar={workplace?.avatar}
            />
            <Spacer size={25} />
            <Container vAlign="center">
              <CaptionText
                style={{
                  fontSize: 35,
                  maxWidth: 400,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {workplace?.name}
              </CaptionText>
              <Spacer size={22} />
              <Container vAlign="center" horizontal>
                <FeedIcon width={16} height={16} fill={colors.labelStrong} />
                <Spacer size={5} />
                <BodyText
                  style={{
                    fontSize: 15,
                    fontWeight: 300,
                    color: colors.labelStrong,
                  }}
                >
                  {workplaceFeeds.length}
                </BodyText>
                <EventIcon
                  style={{ marginLeft: 25 }}
                  width={16}
                  height={16}
                  fill={colors.labelStrong}
                />
                <Spacer size={5} />
                <BodyText
                  style={{
                    fontSize: 15,
                    fontWeight: 300,
                    color: colors.labelStrong,
                  }}
                >
                  {workplaceCalendars.length}
                </BodyText>
              </Container>
            </Container>
            <Pusher />
            <Illustration width={160} height={80} fill={colors.inputBorder} />
          </Card>
          <Spacer size={20} />
          <Card style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}>
            {workplace && (
              <>
                <CaptionText style={{ fontSize: 20, marginLeft: 30 }}>
                  {t('General settings')}
                </CaptionText>
                <Spacer size={20} />
                <InfoRow
                  onClick={() => handleNameModal(true)}
                  leftText={t('Name')}
                  rightText={workplace?.name ? workplace.name : ''}
                />
                <InfoRow
                  onClick={() => handlePhoneModal(true)}
                  leftText={t('Phone number')}
                  rightText={workplace.phone}
                />
              </>
            )}
          </Card>
          <Spacer size={26} />
          <DropdownCard
            onClick={() => setAddAdminsOpen(true)}
            text={t('Admins')}
            buttonText={t('Add admins')}
            shouldHover={!itemHovered}
            count={numOfAdmins}
          >
            {workplace && adminIds.length > 0 ? (
              adminIds.map((id, index) => (
                <WorkplaceUserListItem
                  workplace={workplace}
                  onRemoveUser={removeAdmin}
                  onMouseEnter={() => handleItemHovered(true)}
                  onMouseLeave={() => handleItemHovered(false)}
                  id={id}
                  key={id}
                  noBorder={index === adminIds.length - 1}
                />
              ))
            ) : (
              <CaptionText style={emptyMessageStyle}>
                {t('No admins added')}
              </CaptionText>
            )}
          </DropdownCard>
          <Spacer size={20} />
          <DropdownCard
            shouldHover={!itemHovered}
            text={t('Members')}
            count={numOfUsers || 0}
          >
            {workplace && numOfUsers > 0 ? (
              workplaceUsersResource.read().docs.map((snap, index) => {
                return (
                  <WorkplaceUserListItem
                    onRemoveUser={() => removeMember(snap.id)}
                    workplace={workplace}
                    onMouseEnter={() => handleItemHovered(true)}
                    onMouseLeave={() => handleItemHovered(false)}
                    id={snap.id}
                    key={snap.id}
                    noBorder={
                      index === workplaceUsersResource.read().docs.length + 1
                    }
                  />
                )
              })
            ) : (
              <CaptionText style={emptyMessageStyle}>
                {t('No members added')}
              </CaptionText>
            )}
          </DropdownCard>
          <Spacer size={50} />
          <Container horizontal>
            <BodyText style={{ fontWeight: 600 }}>
              {t('Pre selected for members')}
            </BodyText>
            <Spacer size={8} />
            <HelpHover
              style={{ width: 350 }}
              text={t(
                'Members of this workplace will automatically get these feeds, lists and group chats when they connect to this workplace.'
              )}
            />
          </Container>
          <Spacer size={20} />
          <DropdownCard
            onClick={openAddFeed}
            shouldHover={!itemHovered}
            buttonText={t('Add feeds')}
            text={t('Information feeds')}
            count={0}
          >
            {workplaceFeeds.length > 0 ? (
              workplaceFeeds.map((snap) => {
                return (
                  <WorkplaceListItem
                    onMouseEnter={() => handleItemHovered(true)}
                    onMouseLeave={() => handleItemHovered(false)}
                    snap={snap}
                    key={snap.id}
                  />
                )
              })
            ) : (
              <CaptionText style={emptyMessageStyle}>
                {t('No feeds added')}
              </CaptionText>
            )}
          </DropdownCard>
          <Spacer size={20} />
          {Boolean(legacyEventsEnabled) && (
            <DropdownCard
              onClick={openAddCalendar}
              shouldHover={!itemHovered}
              buttonText={t('Add event lists')}
              text={t('Event lists')}
              count={workplaceCalendars.length}
            >
              {workplaceCalendars.length > 0 ? (
                workplaceCalendars.map((snap) => {
                  return (
                    <WorkplaceListItem
                      onMouseEnter={() => handleItemHovered(true)}
                      onMouseLeave={() => handleItemHovered(false)}
                      snap={snap}
                      key={snap.id}
                    />
                  )
                })
              ) : (
                <CaptionText style={emptyMessageStyle}>
                  {t('No event lists added')}
                </CaptionText>
              )}
            </DropdownCard>
          )}
          <Spacer size={20} />
        </Container>
        <Spacer size={100} />
      </Container>
      {nameModalOpen && (
        <InputModal
          value={workplace?.name || ''}
          onSubmit={async (name) => {
            await workplaceRef.set({ name }, { merge: true })
          }}
          title={t('Change name')}
          placeholder={t('Name')}
          close={() => setNameModalOpen(false)}
        />
      )}
      {mailModalOpen && (
        <InputModal
          value={workplace?.email || ''}
          onSubmit={async (email) => {
            await workplaceRef.set({ email }, { merge: true })
          }}
          title={t('Change mail')}
          placeholder={t('Mail')}
          close={() => setMailModalOpen(false)}
        />
      )}
      {phoneModalOpen && (
        <InputModal
          value={workplace?.phone || ''}
          onSubmit={async (phone) => {
            await workplaceRef.set({ phone }, { merge: true })
          }}
          title={t('Change phone')}
          placeholder={t('Phone')}
          close={() => setPhoneModalOpen(false)}
        />
      )}
      {addressModalOpen && (
        <InputModal
          value={workplace?.address?.formatted || ''}
          onSubmit={async (address) => {
            await workplaceRef.set(
              { address: { formatted: address } },
              { merge: true }
            )
          }}
          title={t('Change address')}
          placeholder={t('Address')}
          close={() => setAddressModalOpen(false)}
        />
      )}
      {addFeedOpen && (
        <WorkplaceAddChannelModal
          workplaceId={id}
          selectedIds={workplaceFeeds.map((doc) => doc.data().feedId)}
          title={t('Add information feeds')}
          close={() => setAddFeedOpen(false)}
          snaps={feedsResource.read()}
        />
      )}
      {addCalendarOpen && (
        <WorkplaceAddChannelModal
          selectedIds={workplaceCalendars.map((doc) => doc.data().calendarId)}
          workplaceId={id}
          title={t('Add event lists')}
          close={() => setAddCalendarOpen(false)}
          snaps={eventListsResource.read()}
        />
      )}
      {addAdminsOpen && workplace && (
        <WorkplaceAddUserModal
          workplaceDoc={workplaceRef}
          title={t('Add admins')}
          selectedUsers={workplace?.admins || []}
          close={() => setAddAdminsOpen(false)}
          usersResource={usersResource}
        />
      )}
      {deleteWorkplaceModalOpen && workplace && (
        <ConfirmDeleteModal
          loading={deleteLoading}
          title={t(`Do you want to delete %{workplace}`, {
            workplace: workplace?.name,
          })}
          text={t(
            'This will permanently delete the workplace. This action cannot be undone.'
          )}
          toggleText={t('Yes, i want to delete')}
          onRequestClose={() => setDeleteWorkplaceModalOpen(false)}
          onConfirm={deleteWorkplace}
        />
      )}
      {deleteSelfModalOpen && (
        <ConfirmDeleteModal
          toggle
          title={t('Do you want to remove yourself as admin?')}
          text={t(
            'You will no longer have access to the workplace settings, If you want admin access again, an other admin needs to invite you as admin again'
          )}
          toggleText={t('Yes, i want to delete')}
          onRequestClose={() => setDeleteSelfModalOpen(false)}
          onConfirm={handleDeleteSelf}
        />
      )}
    </>
  )
}
