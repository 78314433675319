import styled from '@emotion/styled'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { feedIdFromPostSnap, Snap } from 'connect-shared/feed'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { CommentDocument, CommentUserDocument } from 'connect-shared/types'
import { useCallback, useState } from 'react'
import { PostNode } from 'connect-shared/datamodel'
import { ModelDocument } from '../../firestore'
import { useCurrentUserId } from '../../hooks/auth'
import { useFeedSnap } from '../../hooks/feeds'
import { useIsEnergized } from './CommentItem'
import CommentItemHeader from './CommentItemHeader'
import EditCommentItem from './EditCommentItem'
import RemoveCommentItem from './RemoveCommentItem'
import HighlightText from '../../HighlightText'

const StyledCommentsList = styled.li`
  width: 100%;
  list-style: none;
  padding: 10px 0 0 10px;
  font-size: 13px;
  margin-bottom: 20px;
  .commentBody {
    word-break: break-word;
    overflow: hidden;
  }
  .subCommentResponseButtons {
    width: 100px;
    flex: 2;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
`

type CommentRepliesProps = {
  commentSnap: QueryDocumentSnapshot<CommentDocument>
  postRef: ModelDocument<PostNode>
}
const CommentReplies = ({ commentSnap, postRef }: CommentRepliesProps) => {
  const [editMode, setEditMode] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)

  const comment = commentSnap.data()
  const currentUserId = useCurrentUserId()
  const energizedResource = useIsEnergized(commentSnap)
  const postSnap = useFirestoreDoc(postRef).read()
  const feedSnap = useFeedSnap(feedIdFromPostSnap(postSnap as Snap))
  const feed = feedSnap.read().data()

  const isAdmin = (feed && feed.admins && feed.admins[currentUserId]) || false

  const energizeComment = useCallback(async () => {
    const updateData: CommentUserDocument = {
      likes: energizedResource.read() ? 0 : 1,
    }
    await commentSnap.ref
      .collection('users')
      .doc(currentUserId)
      .set(updateData, { merge: true })
  }, [energizedResource, commentSnap.ref, currentUserId])

  const removeComment = useCallback(() => {
    commentSnap.ref.delete()
  }, [commentSnap.ref])

  if (deleteMode) {
    return (
      <RemoveCommentItem
        onCancel={() => setDeleteMode(false)}
        onAccept={removeComment}
      />
    )
  }

  if (editMode) {
    return (
      <EditCommentItem
        subComment
        commentSnap={commentSnap}
        onEditCancel={() => setEditMode(false)}
      />
    )
  }

  return (
    <StyledCommentsList id={commentSnap.id}>
      <CommentItemHeader
        onEditClick={() => setEditMode(true)}
        onRemoveClick={() => setDeleteMode(true)}
        onEnergize={energizeComment}
        energized={energizedResource.read()}
        authorId={commentSnap.data().author}
        commentSnap={commentSnap}
        isAdmin={isAdmin}
      />
      <div
        className="commentBody"
        style={{
          whiteSpace: 'pre-wrap',
          lineHeight: 1.4,
        }}
      >
        <HighlightText text={comment.body} mentions={comment.mentions} />
      </div>
    </StyledCommentsList>
  )
}

export default CommentReplies
