import { GetOauth2CodeOptions, GetOauth2CodeResponse } from 'connect-shared/api'
import { minVersion } from 'connect-shared/constants'
import {
  BackOfficeOptions,
  BackOfficeResponse,
  ClaimInvitationEmailOptions,
  ClaimInvitationEmailResponse,
  ClaimInvitationOptions,
  ClaimInvitationReponse,
  CreateOrganizationOptions,
  CreateOrganizationResponse,
  CreateSsoProviderOptions,
  DeleteSsoProviderOptions,
  GetInvitationInfoOptions,
  GetInvitationInfoResponse,
  GetSsoProviderOptions,
  GetSsoProviderResponse,
  ResendInvitationEmailParams,
  SendFeedPostReminderOptions,
  SetPasswordOptions,
  SsoAuthOptions,
  SsoAuthResponse,
  SwitchUserHeroSupportOptions,
  UpdateClaimsOptions,
  UpdateClaimsResponse,
  UpdateSsoProviderOptions,
} from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/functions'
import version from './version.json'

const cloudFunctions = firebase.app().functions('europe-west1')

export async function createOrganization(
  params: CreateOrganizationOptions
): Promise<CreateOrganizationResponse> {
  const { data } = await cloudFunctions.httpsCallable('createOrganization')(
    params
  )
  return data
}

export const getUserWikiSearchKey = cloudFunctions.httpsCallable(
  'getUserWikiSearchKey'
)

export async function resendInvitationEmail(invitedUserId: string) {
  const params: ResendInvitationEmailParams = { invitedUserId }
  await cloudFunctions.httpsCallable('resendInvitationEmail')(params)
}

export async function switchUser(userId: string) {
  const { data } = await cloudFunctions.httpsCallable('switchUser')({
    userId,
    client: `app-web`,
    version: version === 'dev' ? minVersion : version,
  })
  return data
}

export async function ssoAuth() {
  const options: SsoAuthOptions = {
    client: `app-web`,
    version: version === 'dev' ? minVersion : version,
  }
  const { data } = await cloudFunctions.httpsCallable('ssoAuth')(options)
  return data as SsoAuthResponse
}

export async function deleteSsoProvider(options: DeleteSsoProviderOptions) {
  return cloudFunctions.httpsCallable('deleteSsoProvider')(options)
}

export async function updateSsoProvider(options: UpdateSsoProviderOptions) {
  return cloudFunctions.httpsCallable('updateSsoProvider')(options)
}

export async function createSsoProvider(options: CreateSsoProviderOptions) {
  return cloudFunctions.httpsCallable('createSsoProvider')(options)
}

export async function getSsoProvider(options: GetSsoProviderOptions) {
  const { data } = await cloudFunctions.httpsCallable('getSsoProvider')(options)
  return data as GetSsoProviderResponse
}

export async function getOauth2Code(options: GetOauth2CodeOptions) {
  const { data } = await cloudFunctions.httpsCallable('getOauth2Code')(options)
  return data as GetOauth2CodeResponse
}

export async function claimInvitation(code: string) {
  const options: ClaimInvitationOptions = { code }
  const data = (await cloudFunctions.httpsCallable('claimInvitation')(options))
    .data as ClaimInvitationReponse
  return data.userId
}

export async function getInvitationInfo(code: string) {
  const options: GetInvitationInfoOptions = { code }
  const res = (await cloudFunctions.httpsCallable('getInvitationInfo')(options))
    .data as GetInvitationInfoResponse
  return res
}

export async function updateClaims(version: string) {
  const options: UpdateClaimsOptions = { version }
  const res = (await cloudFunctions.httpsCallable('updateClaims')(options))
    .data as UpdateClaimsResponse
  return res
}

export async function refreshAccount() {
  await cloudFunctions.httpsCallable('refreshAccount')()
}

export async function switchUserSupport(organizationId: string) {
  const { data } = await cloudFunctions.httpsCallable('switchUserSupport')({
    organizationId,
    client: `app-web`,
    version: version === 'dev' ? minVersion : version,
  })
  return data
}

export async function setPassword(password: string) {
  const options: SetPasswordOptions = { password }
  await cloudFunctions.httpsCallable('setPassword')(options)
}

export async function backOffice(
  options: BackOfficeOptions
): Promise<BackOfficeResponse> {
  const res = await cloudFunctions.httpsCallable('backOffice')(options)
  return res.data
}

export async function sendVerificationEmail() {
  await cloudFunctions.httpsCallable('sendVerificationEmail')({})
}

export async function sendFeedPostReminder(
  feedId: string,
  postId: string
): Promise<void> {
  const options: SendFeedPostReminderOptions = { postId, feedId }
  const res = await cloudFunctions.httpsCallable('sendFeedPostReminder')(
    options
  )
  return res.data
}

export async function switchUserHeroSupport(secret: string) {
  const res = await cloudFunctions.httpsCallable('switchUserHeroSupport')({
    secret,
  } satisfies SwitchUserHeroSupportOptions)
  return res.data.token
}

export async function claimInvitationEmail(
  options: ClaimInvitationEmailOptions
): Promise<ClaimInvitationEmailResponse> {
  const res = await cloudFunctions.httpsCallable('claimInvitationEmail')(
    options
  )
  return res.data
}
