import { PostNode } from 'connect-shared/datamodel'
import { Mention } from 'connect-shared/types'
import firebase from 'firebase/app'
import { useCallback, useRef, useState } from 'react'
import { ReactComponent as SendIcon } from '../../icons/ico-send.svg'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { ModelDocument } from '../../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { Container } from '../../layout/Layout'
import MentionsInput from '../../MentionsInput'
import { useTranslate } from '../../hooks/Internationalization'

export default function NewCommentItem({
  post,
  inputRef,
}: {
  post: ModelDocument<PostNode>
  inputRef?: React.Ref<HTMLTextAreaElement>
}) {
  const [value, setValue] = useState('')
  const userId = useCurrentUserId()

  const colors = useColors()
  const t = useTranslate()

  const mentionsRef = useRef<Mention[]>([])
  const textRef = useRef('')

  const postNewCommentItem = useCallback(async () => {
    if (!textRef.current) return

    const submitPromise = post.collection('comments').add({
      body: textRef.current,
      author: userId,
      parent: null,
      createdDate:
        firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      mentions: mentionsRef.current,
    })

    const seenIndexPromise = post
      .collection('users')
      .doc(userId)
      .update({
        latestSeenCommentIndex: firebase.firestore.FieldValue.increment(1),
      })

    await submitPromise
    setValue('')
    textRef.current = ''
    mentionsRef.current = []
    await seenIndexPromise
  }, [post, userId])

  const handleKeyPress = useCallback(
    (event) => {
      if (!event.shiftKey && event.key === 'Enter') {
        if (textRef.current.trim().length > 0) postNewCommentItem()
        event.preventDefault()
      }
    },
    [postNewCommentItem]
  )

  const organizationRef = useOrganizationDoc()
  const feedId = post.parent?.parent?.id
  const followersRef = feedId
    ? organizationRef
        .collection('feeds')
        .doc(feedId)
        .collection('extra')
        .doc('followers')
    : undefined

  const [sendHovered, setSendHovered] = useState(false)

  return (
    <Container
      flex={1}
      horizontal
      style={{
        position: 'relative',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
      }}
    >
      <Container
        style={{
          width: '100%',
          padding: '0 20px',
        }}
      >
        <Container
          horizontal
          flex={1}
          style={{
            width: '100%',
            borderRadius: '18px',
            border: '1px solid #E4E4E4',
            padding: '5px 0px 5px 8px',
          }}
        >
          <MentionsInput
            inputRef={inputRef}
            filter={followersRef}
            onKeyPress={(e) => handleKeyPress(e)}
            value={value}
            style={{
              flex: 1,
              flexFlow: 'wrap',
              display: 'flex',
              maxWidth: 'calc(100% - 40px)',
            }}
            onChange={({ value, mentions, text }) => {
              setValue(value)
              mentionsRef.current = mentions
              textRef.current = text
            }}
            placeholder={t('Comment')}
          />
          <Container
            style={{
              marginBottom: 2,
            }}
            horizontal
            vAlign="bottom"
            onMouseLeave={() => setSendHovered(false)}
            onMouseEnter={() => setSendHovered(true)}
          >
            <ClickableOpacity
              style={{
                marginRight: 10,
              }}
              onClick={postNewCommentItem}
            >
              <SendIcon
                width={26}
                height={22}
                fillColor={
                  sendHovered && value.length > 0
                    ? colors.beeworkBlueLight
                    : colors.beeworkBlue
                }
              />
            </ClickableOpacity>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
