import { Img } from 'react-suspense-img'
import useOrganizationTheme from './hooks/useOrganizationColors'
import { placeholderAvatar } from './placeholderAvatar'
import { Suspense } from 'react'
import { useColors } from './Colors'
import { usePranks } from './hooks/pranks'

interface AvatarProps {
  name?: string
  avatar?: string
}

function Fallback() {
  const colors = useColors()
  return (
    <div
      style={{ width: '100%', height: '100%', backgroundColor: colors.faint }}
    />
  )
}

export default function Avatar({ name = '', avatar }: AvatarProps) {
  const { primary, primaryText } = useOrganizationTheme()

  const src =
    avatar ||
    placeholderAvatar(name.toUpperCase(), primary, primaryText).toString()
  const rotation = usePranks().avatarRotation

  return (
    <Suspense fallback={<Fallback />}>
      <Img
        alt={''}
        src={src}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          transition: 'transform 1000ms',
          transform:
            rotation === undefined ? undefined : `rotate(${rotation}deg)`,
        }}
      />
    </Suspense>
  )
}
