import { Button, ButtonProps } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from './Icons'
import { useTranslate } from './hooks/Internationalization'

type BackButtonProps = {
  label?: string
  to?: string
} & ButtonProps

export default function BackLabelButton({
  label = '',
  to,
  sx,
  ...rest
}: BackButtonProps) {
  const history = useHistory()
  const t = useTranslate()

  return (
    <Button
      variant="text"
      startIcon={<ChevronLeftIcon />}
      disableRipple
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
        alignSelf: 'flex-start',
        ...sx,
      }}
      href={to || '..'}
      onClick={
        to
          ? undefined
          : (e) => {
              e.preventDefault()
              history.goBack()
            }
      }
      {...rest}
    >
      {label === '' ? t('Back') : label}
    </Button>
  )
}
