import firebase from 'firebase/app'
import { PostDocument } from 'connect-shared/types'
import { useColors } from '../Colors'
import { useFeedInteractivityDisabled } from './feed/Feed'
import { useOrganizationDoc } from '../hooks/auth'
import { feedIdFromPostSnap } from 'connect-shared/feed'
import { useCallback, useMemo, useRef } from 'react'
import { Container } from '../layout/Layout'
import { useFirestoreCollection } from '@iwikal/reactfire'
import CommentsList from './comments/CommentsList'
import NewCommentItem from './comments/NewCommentItem'
import ClickableOpacity from '../ClickableOpacity'
import { ReactComponent as BackIcon } from '../icons/ico-chevron-left.svg'
import { css } from '@emotion/css'
import { useHistory } from 'react-router-dom'
import FeedPost from './post/FeedPost'
import { useTranslate } from '../hooks/Internationalization'

type PostDetailsInlineProps = {
  feedId: string
  postId: string
}

const usePostRef = (
  postSnap: firebase.firestore.QueryDocumentSnapshot<PostDocument>
) => {
  const organizationRef = useOrganizationDoc()
  const feedId = feedIdFromPostSnap(postSnap)
  return useMemo(() => {
    return organizationRef
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postSnap.id)
  }, [feedId, organizationRef, postSnap.id])
}

const hoverAnimation = css`
  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.5;
    }
  }

  &:hover {
    animation-duration: 0.4s;
    opacity: 0.5;
    animation-name: fadeOut;
  }
`

function BackButton() {
  const history = useHistory()
  const t = useTranslate()
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <ClickableOpacity
      onClick={goBack}
      css={hoverAnimation}
      style={{
        width: 'max-content',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 5,
        marginTop: 30,
      }}
    >
      <BackIcon width={22} height={22} />
      <span style={{ fontSize: 16, fontWeight: 600, color: undefined }}>
        {t('Go back')}
      </span>
    </ClickableOpacity>
  )
}

export default function PostDetailsInline({
  feedId,
  postId,
}: PostDetailsInlineProps) {
  const postQuery = useOrganizationDoc()
    .collection('feeds')
    .doc(feedId)
    .collection('posts')
    .where(firebase.firestore.FieldPath.documentId(), '==', postId)

  const queryResource = useFirestoreCollection(postQuery)

  const postResource = useMemo(() => {
    return {
      read() {
        return queryResource.read().docs[0]
      },
    }
  }, [queryResource])
  const colors = useColors()
  const postSnap = postResource.read()
  const postRef = usePostRef(postSnap)

  const interactivityDisabled = useFeedInteractivityDisabled(feedId)

  const commentInputRef = useRef<HTMLTextAreaElement>(null)
  const focusCommentInput = useCallback(() => {
    commentInputRef.current?.focus()
  }, [])

  return (
    <Container>
      <BackButton />
      <Container
        style={{
          overflow: 'hidden',
          backgroundColor: colors.foreground,
          border: `1px solid ${colors.border}`,
          borderRadius: 12,
        }}
      >
        <FeedPost postSnap={postSnap} onCommentClick={focusCommentInput} />
        {!interactivityDisabled && (
          <>
            <NewCommentItem post={postRef} inputRef={commentInputRef} />
            <CommentsList post={postRef} />
          </>
        )}
      </Container>
      <div style={{ height: 400 }} />
    </Container>
  )
}
