import { useFirestoreDoc } from '@iwikal/reactfire'
import { Alert, AlertTitle, Box } from '@mui/material'
import { CSSProperties, FunctionComponent, SVGProps, useCallback } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import Card from '../Card'
import { useColors } from '../Colors'
import { useOrganizationDoc } from '../hooks/auth'
import { useTranslate } from '../hooks/Internationalization'
import useCurrentUser from '../hooks/useCurrentUser'
import useFeatureFlag from '../hooks/useFeatureFlag'
import { useIsWorkplaceAdmin } from '../hooks/useIsWorkplaceAdmin'
import { useUserlimit } from '../hooks/useUserlimit'
import IconHoverWrapper from '../IconHoverWrapper'
import { ReactComponent as MangeUsers } from '../icons/ico-contacts.svg'
import { ReactComponent as workplacesBox } from '../icons/ico-groups.svg'
import { ReactComponent as GeneralSettings } from '../icons/ico-settings.svg'
import { ReactComponent as Saml } from '../icons/ico-sso.svg'
import { ReactComponent as BillingIcon } from '../icons/ico-vacation.svg' // TODO actual icon
import { ReactComponent as WikiIcon } from '../icons/ico-wiki.svg'
import { Container } from '../layout/Layout'
import PageLayoutLegacy from '../layout/PageLayoutLegacy'
import Billing from './Billing'
import BulkInvite from './BulkInvite'
import Invitations from './Invitations'
import ManageUsers from './ManageUsers'
import OrganizationSettings from './OrganizationSettings'
import Sso from './Sso'
import WikiSettings from './WikiSettings'
import Workplaces from './Workplaces'

export function useAllowSettingsPage() {
  const currentUser = useCurrentUser()
  const isWorkplaceAdmin = useIsWorkplaceAdmin()
  return currentUser?.organizationAdmin || isWorkplaceAdmin
}

function SettingsCard({
  title,
  description,
  to,
  Icon,
  style,
  attention,
}: {
  title: string
  description?: string
  to: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
  style?: CSSProperties
  attention?: boolean
}) {
  const colors = useColors()
  const history = useHistory()
  const onClick = useCallback(() => {
    history.push(to)
  }, [to, history])
  return (
    <IconHoverWrapper>
      <button
        onClick={onClick}
        style={{
          borderRadius: 20,
          padding: 0,
          border: 0,
          cursor: 'pointer',
          margin: 0,
          marginRight: 20,
          marginBottom: 20,
          ...style,
        }}
      >
        <Card
          style={{
            alignItems: 'center',
            width: 260,
            height: 267,
            border: attention ? `1px solid ${colors.warning}` : undefined,
          }}
        >
          <Icon
            width={90}
            height={90}
            fill={colors.selected}
            style={{
              marginTop: 10,
              marginBottom: 23,
            }}
          />
          <Container>
            <p style={{ fontSize: 20, margin: 0, marginBottom: 10 }}>{title}</p>
            <p style={{ fontSize: 14, color: colors.label }}>{description}</p>
          </Container>
        </Card>
      </button>
    </IconHoverWrapper>
  )
}

function Navigation() {
  const t = useTranslate()
  const currentUser = useCurrentUser()
  const { url } = useRouteMatch()
  const ssoEnabled = useFeatureFlag('sso', false)
  const isWorkplaceAdmin = useIsWorkplaceAdmin()
  const organization = useFirestoreDoc(useOrganizationDoc()).read().data()
  const { exceeded } = useUserlimit()
  return (
    <PageLayoutLegacy wide text={t('Settings')} flex={1}>
      <Box
        sx={{
          width: { xs: (260 + 20) * 1, md: (260 + 20) * 2, lg: (260 + 20) * 3 },
          alignSelf: 'center',
        }}
      >
        <Container horizontal hAlign="center" style={{ flexWrap: 'wrap' }}>
          {currentUser?.organizationAdmin && (
            <>
              <SettingsCard
                Icon={GeneralSettings}
                to={url + '/organization'}
                title={t('Profile settings')}
                description={t(
                  'Update your organization name and personalize your experience.'
                )}
              />
              <SettingsCard
                attention={exceeded}
                Icon={MangeUsers}
                to={url + '/users'}
                title={t('Manage Users')}
                description={t('Manage and invite users to your organization.')}
              />
              {ssoEnabled && (
                <SettingsCard
                  Icon={Saml}
                  to={url + '/sso'}
                  title={t('SSO')}
                  description={t('Configure single sign on using SAML.')}
                />
              )}
            </>
          )}
          {(isWorkplaceAdmin || currentUser?.organizationAdmin) && (
            <SettingsCard
              Icon={workplacesBox}
              to={url + '/workplaces'}
              title={t('Workplaces')}
              description={t(
                'Organize your employees into workplaces and manage their shared settings.'
              )}
            />
          )}
          {currentUser?.organizationAdmin && (
            <>
              <SettingsCard
                Icon={WikiIcon}
                to={url + '/wiki'}
                title={t('Pages')}
                description={t(
                  'Edit default permission settings for categories and articles'
                )}
              />
              {!!organization?.plan && (
                <SettingsCard
                  attention={exceeded}
                  Icon={BillingIcon}
                  to={url + '/billing'}
                  title={t('Plans and billing')}
                  description={t('Manage billing and plan information')}
                />
              )}
            </>
          )}
        </Container>
      </Box>
    </PageLayoutLegacy>
  )
}

export default function Settings() {
  const currentUser = useCurrentUser()
  const { path } = useRouteMatch()
  const isWorkplaceAdmin = useIsWorkplaceAdmin()
  const isOrgAdmin = currentUser?.organizationAdmin || false
  const ssoEnabled = useFeatureFlag('sso', false)
  const { exceeded, userLimit, userCount } = useUserlimit()
  const organization = useFirestoreDoc(useOrganizationDoc()).read().data()
  const t = useTranslate()

  return (
    <>
      {exceeded && userLimit !== undefined && (
        <div style={{ padding: 20 }}>
          {(() => {
            const [bodyStart, strongUpdatePlan, bodyEnd] = t(
              'You have exceeded the maximum number of users of %{userLimit} included in your license. |Update your plan or remove %{smart_count} user to resolve this issue|. |||| You have exceeded the maximum number of users of %{userLimit} included in your license. |Update your plan or remove %{smart_count} users to resolve this issue|.',
              { userLimit, smart_count: userCount - userLimit }
            ).split('|')
            return (
              <Alert sx={{}} severity="error">
                <AlertTitle>{t('Warning')}</AlertTitle>
                {bodyStart}
                <strong>{strongUpdatePlan}</strong>
                {bodyEnd}
              </Alert>
            )
          })()}
        </div>
      )}
      <Switch>
        {isOrgAdmin ? (
          <>
            <Switch>
              <Route
                path={path + '/organization'}
                component={OrganizationSettings}
              />
              <Route
                exact
                path={path + '/invitations'}
                component={Invitations}
              />
              <Route
                exact
                path={path + '/users/bulk-invite'}
                component={BulkInvite}
              />
              {ssoEnabled && <Route path={path + '/sso'} component={Sso} />}
              <Route path={path + '/users'} component={ManageUsers} />
              <Route path={path + '/wiki'} component={WikiSettings} />
              <Route path={path + '/workplaces'} component={Workplaces} />
              {!!organization?.plan && (
                <Route path={path + '/billing'} component={Billing} />
              )}
              <Route component={Navigation} />
            </Switch>
          </>
        ) : (
          <Switch>
            {isWorkplaceAdmin && (
              <Route path={path + '/workplaces'} component={Workplaces} />
            )}
            <Route component={Navigation} />
          </Switch>
        )}
      </Switch>
    </>
  )
}
