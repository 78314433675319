import { useFirestoreCollection } from '@iwikal/reactfire'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { CalendarDocument, FeedDocument } from 'connect-shared/types'
import { useCallback } from 'react'
import BlockButton from '../BlockButton'
import { useColors } from '../Colors'
import { useOrganizationDoc } from '../hooks/auth'
import { Container, Pusher, Spacer } from '../layout/Layout'
import RoundAvatar from '../RoundAvatar'
import { BodyText, CaptionText } from '../Text'
import Toggle from '../Toggle'
import { ReactComponent as UpdateIcon } from '../icons/ico-recently-updated.svg'
import { DocumentSnapshot } from 'connect-shared/firestore'
import ModalCloseButton from '../ModalCloseButton'
import { useDateFns, useTranslate } from '../hooks/Internationalization'

function LineSeparator() {
  const colors = useColors()
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: colors.inputBorder,
        height: 1,
        margin: '30px 0',
      }}
    />
  )
}

type WorkplaceChannelInfoModalProps = {
  snap: DocumentSnapshot<FeedDocument | CalendarDocument>
  close: () => void
  removeItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCanUnfollowToggle: (canUnfollow: boolean) => void
  canUnfollow: boolean
  isFeed?: boolean
}

export default function WorkplaceChannelInfoModal({
  snap,
  close,
  removeItem,
  onCanUnfollowToggle,
  canUnfollow,
  isFeed,
}: WorkplaceChannelInfoModalProps) {
  const colors = useColors()
  const t = useTranslate()
  const item = snap.data()
  const doc = snap.ref

  const type = isFeed ? t('Information feed') : t('Event calendar')
  const latestCalendartEventRef = useOrganizationDoc()
    .collection('calendars')
    .doc(doc.id)
    .collection('events')
    .orderBy('createdDate')
    .limit(1)

  const latestFeedPostRef = useOrganizationDoc()
    .collection('feeds')
    .doc(doc.id)
    .collection('posts')
    .orderBy('createdDate', 'desc')
    .limit(1)
  const latestPost = useFirestoreCollection(
    isFeed ? latestFeedPostRef : latestCalendartEventRef
  ).read().docs[0]

  const { format } = useDateFns()
  const latestUpdate = latestPost
    ? format(latestPost.data().createdDate.toDate(), 'yyy-MM-dd')
    : t('Nothing added yet')

  return (
    <DialogOverlay
      aria-label=" "
      onDismiss={close}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Pusher />
      <DialogContent
        aria-label=" "
        style={{
          display: 'flex',
          height: 'max-content',
          width: 654,
          padding: 0,
          flex: 1,
          position: 'relative',
          borderRadius: 10,
          backgroundColor: colors.webChatModalBackground,
          boxShadow: `0 1px 3px ${colors.webChatBoxShadow}`,
        }}
      >
        <Container
          hAlign="center"
          flex={1}
          style={{ padding: '0 40px', width: 654 }}
        >
          <RoundAvatar
            name={item?.name}
            avatar={item?.avatar}
            size={150}
            style={{
              position: 'absolute',
              top: -75,
              border: `2px solid ${colors.foreground}`,
              boxShadow: `0 1px 3px ${colors.announcementShadow}`,
            }}
          />
          <Spacer size={75} />
          <CaptionText style={{ fontSize: 35, fontWeight: 300 }}>
            {item?.name}
          </CaptionText>
          <BodyText style={{ fontSize: 15, color: colors.labelDim }}>
            {type}
          </BodyText>
          <Spacer size={30} />
          <Container horizontal vAlign="center">
            <UpdateIcon
              width={23}
              height={23}
              fill={colors.unavailable}
              style={{ marginRight: 10 }}
            />
            <BodyText style={{ fontSize: 14, color: colors.unavailable }}>
              {t('Updated')}
            </BodyText>
            <Spacer size={10} />
            <BodyText>{latestUpdate}</BodyText>
          </Container>
          <LineSeparator />
          <Container hAlign="left" style={{ width: '100%' }}>
            <CaptionText strong style={{ fontSize: 20 }}>
              {t('About')}
            </CaptionText>
            <Spacer size={10} />
            <BodyText
              style={{
                fontSize: 14,
                fontStyle: item?.description ? 'none' : 'italic',
              }}
            >
              {item?.description ? item?.description : t('No information')}
            </BodyText>
          </Container>
          <LineSeparator />
          <Container
            hAlign="left"
            vAlign="center"
            horizontal
            style={{ width: '100%', paddingBottom: 40 }}
          >
            <BodyText style={{ fontSize: 14 }}>
              {t('Members can unfollow')}
            </BodyText>
            <Spacer size={10} />
            <Toggle value={canUnfollow} onChange={onCanUnfollowToggle} />
            <Pusher />
            <BlockButton
              onClick={useCallback(
                (e) => {
                  removeItem(e)
                },
                [removeItem]
              )}
              style={{ fontSize: 14 }}
              danger
            >
              {t('Remove from pre-selected channels')}
            </BlockButton>
          </Container>
          <ModalCloseButton onClick={close} />
        </Container>
      </DialogContent>
      <Pusher />
    </DialogOverlay>
  )
}
