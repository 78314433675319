import { useFirestoreDoc } from '@iwikal/reactfire'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Unstable_Grid2'
import { Organization, PlanKey, plans } from 'connect-shared/types'
import firebase from 'firebase/app'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { firestore } from '../firestore'
import { useOrganizationDoc } from '../hooks/auth'
import { useUserlimit } from '../hooks/useUserlimit'
import { CheckIcon, CloseIcon } from '../Icons'
import { Pusher } from '../layout/Layout'
import materialTheme from '../materialTheme'
import { showErrorToast } from '../Toast'
import BackLabelButton from '../BackLabelButton'
import { useTranslate } from '../hooks/Internationalization'

const billingFunction = firebase
  .app()
  .functions('europe-west1')
  .httpsCallable('billing')

function PlanCard({
  planKey,
  monthPrice,
  yearPrice,
  planName,
  active,
  activeInterval,
  description,
  features,
  subscriptionCanceled,
}: {
  planKey: PlanKey
  monthPrice?: number
  yearPrice?: number
  planName: string
  active: boolean
  activeInterval?: 'year' | 'month'
  description: string
  features?: string[]
  subscriptionCanceled?: boolean
}) {
  const [subscribeLoading, setSubscribeLoading] = useState(false)
  const [portalLoading, setPortalLoading] = useState(false)
  const [yearly, setYearly] = useState(!!yearPrice)
  const t = useTranslate()

  const switchToInterval =
    active &&
    yearPrice &&
    monthPrice &&
    (activeInterval === 'month' ? 'year' : 'month')

  const subscribe = useCallback(async () => {
    const interval = active ? switchToInterval : yearly ? 'year' : 'month'

    setSubscribeLoading(true)
    if (planKey === 'free') {
      await billingFunction({
        action: 'subscribeToFree',
      })
    } else if (interval) {
      try {
        const res = await billingFunction({
          action: 'createCheckoutSession',
          planKey,
          successUrl: window.location.href,
          cancelUrl: window.location.href,
          interval,
        })
        window.location.href = res.data.url
      } catch (err) {
        showErrorToast()
        console.error(err)
      }
    }
    setSubscribeLoading(false)
  }, [active, planKey, switchToInterval, yearly])

  const openPortal = useCallback(async () => {
    setPortalLoading(true)
    try {
      const res = await billingFunction({
        action: 'createPortalSession',
        returnUrl: window.location.href,
        cancelUrl: window.location.href,
      })
      window.open(res.data.url)
      setPortalLoading(false)
    } catch (err) {
      console.error(err)
      setPortalLoading(false)
      showErrorToast()
    }
  }, [])

  function getPrice() {
    if (active) {
      if (activeInterval === 'month') return monthPrice
      if (activeInterval === 'year') return yearPrice
    } else if (yearPrice && monthPrice) {
      return yearly ? yearPrice : monthPrice
    } else {
      return yearPrice || monthPrice
    }
  }
  const price = (getPrice() || 0) / 100

  return (
    <>
      <Card raised sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%', padding: 4 }}>
          <Stack spacing={1} marginX={2}>
            <Box>
              <Typography color="primary" variant="h4">
                {planName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">{description}</Typography>
              <List>
                {features?.map((text) => {
                  return (
                    <ListItem disablePadding key={text} sx={{ height: 30 }}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText inset={false}>{text}</ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
            <Box
              sx={{
                backgroundColor: active
                  ? materialTheme.palette.info.light
                  : materialTheme.palette.warning.light + '70',
                borderRadius: materialTheme.spacing(1),
                height: materialTheme.spacing(24),
              }}
              style={{
                marginLeft: `-${materialTheme.spacing(2)}`,
                marginRight: `-${materialTheme.spacing(2)}`,
              }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <Typography variant="h4">
                {price}{' '}
                <Typography
                  display="inline"
                  variant="body1"
                  color={materialTheme.palette.primary.main}
                  fontWeight={600}
                >
                  {t('kr')}
                </Typography>
                {price > 0 && (
                  <Typography
                    display="inline"
                    variant="body1"
                    color={materialTheme.palette.primary.main}
                    fontWeight={600}
                  >
                    {t('/user & month')}
                  </Typography>
                )}
              </Typography>
              {!!yearPrice && !active && yearly && (
                <Typography variant="overline">
                  {t('%{price} billed annually', {
                    price: `${((yearPrice || 0) / 100) * 12} SEK`,
                  })}
                </Typography>
              )}
              {price > 0 && !!activeInterval && (
                <Typography variant="overline">
                  {activeInterval === 'year'
                    ? t('You have a yearly plan')
                    : t('You have a monthly plan')}
                </Typography>
              )}
              {active && (
                <Button
                  sx={{ pointerEvents: 'none' }}
                  variant="text"
                  disableRipple
                  startIcon={
                    subscriptionCanceled ? (
                      <CloseIcon
                        style={{
                          color: materialTheme.palette.error.main,
                        }}
                      />
                    ) : (
                      <CheckIcon
                        style={{
                          color: materialTheme.palette.success.main,
                        }}
                      />
                    )
                  }
                >
                  {subscriptionCanceled
                    ? t('Subscription is canceled')
                    : t('Current plan')}
                </Button>
              )}
              <Pusher />
              {price > 0 && active && (
                <Button
                  disabled={portalLoading}
                  variant="outlined"
                  onClick={openPortal}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    fontSize: isMobile ? 30 : undefined,
                  }}
                >
                  {t('Billing settings')}
                </Button>
              )}
              {!active && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={subscribe}
                  disabled={
                    subscribeLoading ||
                    (planKey === 'free' && subscriptionCanceled)
                  }
                >
                  {t('Switch to plan')}
                </Button>
              )}
            </Box>
            {!!yearPrice && !!monthPrice && (
              <>
                <Stack spacing={2} paddingTop={2}>
                  <Box>
                    <Typography variant="body1">
                      {t(
                        'We offer monthly or a discounted annual payment for your subscription plan.'
                      )}
                    </Typography>
                  </Box>
                  {!active && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={yearly}
                          onChange={() => setYearly((x) => !x)}
                        />
                      }
                      label={t('Annual payment')}
                    />
                  )}
                  {!!switchToInterval && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={subscribe}
                      disabled={subscribeLoading}
                    >
                      {switchToInterval === 'month'
                        ? t('Change to monthly payments')
                        : t('Change to annual payments')}
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

export default function Billing() {
  const orgRef = useOrganizationDoc()
  const org = useFirestoreDoc(useOrganizationDoc())
    .read()
    .data() as Organization
  const t = useTranslate()

  const customPlanRef = orgRef.collection('extra').doc('customPlan')
  const customPlan = useFirestoreDoc(customPlanRef).read().data()
  const customPlanAvalible =
    (customPlan && customPlan?.used !== true) || org.plan === 'custom'

  useEffect(() => {
    billingFunction({
      action: 'wakeUp',
    })
  }, [])

  const subscription = useFirestoreDoc(
    firestore().collection('stripeSubscriptions').doc(orgRef.id)
  )
    .read()
    .data()

  const { userCount, userLimit = 0 } = useUserlimit()

  const invitesLeft = userLimit - userCount
  return (
    <Container maxWidth="md" sx={{ marginBottom: 5 }}>
      <Stack spacing={2}>
        <Box paddingTop={2}>
          <BackLabelButton label={t('Back to settings')} to="/settings" />
        </Box>
        <Box flexDirection="row" display="flex">
          <Box flex="1">
            <Typography variant="h4">{t('Plans and billing')}</Typography>
          </Box>
          <Stack>
            <Typography
              variant="body1" /*Has custom none existing MUI variant*/
              color={materialTheme.palette.primary.main}
              fontWeight={600}
            >
              {t('Users:')}
            </Typography>
            <Typography
              variant="body1" /*Has custom none existing MUI variant*/
              color={materialTheme.palette.primary.main}
              fontWeight={600}
            >
              {invitesLeft >= 0 ? t('Invites left: ') : t('Users over limit: ')}
            </Typography>
          </Stack>
          <Stack alignItems="end" marginLeft={1}>
            <Typography variant="body1">
              {userCount}/{userLimit}
            </Typography>
            <Typography variant="body1">{Math.abs(invitesLeft)}</Typography>
          </Stack>
        </Box>
        <Grid container spacing={3} mt={1}>
          <Grid xs={12} sm={isMobile ? 12 : 6}>
            <PlanCard
              planName={t('Free')}
              planKey="free"
              yearPrice={0}
              monthPrice={0}
              active={org.plan === 'free'}
              description={t('For the small team, ten people or less.')}
              features={[
                t('All app functionality'),
                t('Up to ten users'),
                t('Totally free'),
              ]}
              subscriptionCanceled={subscription?.cancel_at_period_end}
            />
          </Grid>
          <Grid xs={12} sm={isMobile ? 12 : 6}>
            <PlanCard
              planName={t('Standard')}
              planKey="standard"
              yearPrice={(plans.standard.pricing?.year.sek || 0) / 12}
              monthPrice={plans.standard.pricing?.month.sek || 0}
              active={org.plan === 'standard'}
              activeInterval={
                org.plan === 'standard'
                  ? subscription?.interval === 'month'
                    ? 'month'
                    : subscription?.interval === 'year'
                    ? 'year'
                    : undefined
                  : undefined
              }
              description={t('For larger teams up to 100 users.')}
              features={[
                t('All app functionality'),
                t('Up to 100 users'),
                t('No binding time'),
              ]}
              subscriptionCanceled={subscription?.cancel_at_period_end}
            />
          </Grid>
          {!!customPlan && customPlanAvalible && (
            <Grid xs={12} sm={isMobile ? 12 : 6}>
              <PlanCard
                planName={t('Custom')}
                planKey="custom"
                yearPrice={
                  customPlan.pricing.interval === 'year'
                    ? customPlan.pricing.perUser.sek
                    : undefined
                }
                monthPrice={
                  customPlan.pricing.interval === 'month'
                    ? customPlan.pricing.perUser.sek
                    : undefined
                }
                active={org.plan === 'custom'}
                activeInterval={
                  org.plan === 'custom'
                    ? customPlan.pricing.interval
                    : undefined
                }
                description={t('Your custom offer')}
                subscriptionCanceled={subscription?.cancel_at_period_end}
              />
            </Grid>
          )}
          <Grid xs={12} sm={customPlanAvalible ? (isMobile ? 12 : 6) : 12}>
            <Card raised sx={{ height: '100%' }}>
              <CardContent>
                <Grid container>
                  <Grid xs={customPlanAvalible ? 12 : isMobile ? 12 : 6}>
                    <Stack spacing={1}>
                      <Typography variant="h5">
                        {t('Need extra room?')}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          'If you are a large organization with more than 100 users we would love to have a chat to be able to meet your needs.'
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid xs={6}>
                    <Box
                      alignItems={customPlanAvalible ? undefined : 'center'}
                      justifyContent={customPlanAvalible ? undefined : 'center'}
                      display="flex"
                      height="100%"
                    >
                      <Button
                        variant="outlined"
                        href={t('https://cmz0jv22r16.typeform.com/to/kdqmNDyQ')}
                      >
                        {t('Contact me')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}
