import { PopoverProps } from '@mui/material'
import { bindHover, bindPopover } from 'material-ui-popup-state'
import { usePopupState } from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'

export default function PopoverWrapper({
  anchorOrigin = { vertical: 'center', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  ...rest
}: PopoverProps) {
  return (
    <HoverPopover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{ padding: 0, margin: 0 }}
      {...rest}
    />
  )
}

export function usePopoverHover() {
  const state = usePopupState({ variant: 'popover', popupId: 'wrapper' })
  return {
    anchorProps: bindHover(state),
    popOverProps: bindPopover(state),
  }
}
