import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import {
  lMainContentWidth,
  lSideContentWidth,
  lWidth,
  mGutters,
  mMainContentWidth,
  mSideContentWidth,
  mWidth,
  sGutters,
  sMainContentWidth,
  sSideContentWidth,
} from '../style'
import { Container, ContainerProps } from './Layout'
import SideContent from './SideContent'

const StyledContainer = styled(Container)`
  width: ${sMainContentWidth + sSideContentWidth * 2 + sGutters * 2}px;
  padding: 0 ${sGutters}px;

  @media (min-width: ${mWidth}px) {
    width: ${mMainContentWidth + mSideContentWidth * 2 + mGutters * 2}px;
    padding: 0 ${mGutters}px;
  }

  @media (min-width: ${lWidth}px) {
    width: ${lMainContentWidth + lSideContentWidth * 2 + mGutters * 2}px;
    padding: 0 ${mGutters}px;
  }
`

type PageLayoutProps = ContainerProps & {
  leftSideContent?: React.ReactNode
  leftSideStyle?: CSSProperties
  rightSideContent?: React.ReactNode
  rightSideStyle?: CSSProperties
  style?: CSSProperties
  fullWidth?: boolean
}

export default function PageLayout({
  flex = '0 0 auto',
  style,
  children,
  rightSideContent,
  rightSideStyle,
  leftSideContent,
  leftSideStyle,
  fullWidth,
  ...containerProps
}: PageLayoutProps) {
  return (
    <StyledContainer
      flex={flex}
      {...containerProps}
      horizontal
      hAlign="center"
      style={{
        margin: '0 auto',
        minHeight: '100%',
        width: fullWidth ? '100%' : undefined,
        ...style,
      }}
    >
      <SideContent gutters="left" style={leftSideStyle}>
        {leftSideContent}
      </SideContent>
      <Container flex={1} style={{ minWidth: 0 }}>
        {children}
      </Container>
      <SideContent gutters="right" style={rightSideStyle}>
        {rightSideContent}
      </SideContent>
    </StyledContainer>
  )
}
