import { HTMLAttributes } from 'react'
import { useColors } from '../Colors'

type SeparatorProps = {
  flex?: React.CSSProperties['flex']
} & HTMLAttributes<HTMLDivElement>
export default function Separator({
  flex = '0 0 auto',
  style,
  ...divProps
}: SeparatorProps) {
  const colors = useColors()
  return (
    <div
      style={{
        minWidth: 1,
        minHeight: 1,
        backgroundColor: colors.border,
        flex,
        ...style,
      }}
      {...divProps}
    />
  )
}
