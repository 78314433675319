import { css } from '@emotion/react'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { CommentDocument, Mention } from 'connect-shared/types'
import firebase from 'firebase/app'
import { KeyboardEvent, useCallback, useRef, useState } from 'react'
import { ReactComponent as SendIcon } from '../../icons/ico-send.svg'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { useOrganizationDoc } from '../../hooks/auth'
import { Container, Pusher } from '../../layout/Layout'
import MentionsInput from '../../MentionsInput'
import { showErrorToast } from '../../Toast'
import CommentItemHeader from './CommentItemHeader'
import { useTranslate } from '../../hooks/Internationalization'

type EditCommentItemProps = {
  commentSnap: QueryDocumentSnapshot<CommentDocument>
  onEditCancel?: () => void
  subComment?: boolean
}

export default function EditCommentItem({
  commentSnap,
  onEditCancel,
  subComment,
}: EditCommentItemProps) {
  const comment = commentSnap.data()
  const colors = useColors()
  const t = useTranslate()
  const authorId = comment.author

  const [body, setBody] = useState(comment.body)

  const mentionsRef = useRef<Mention[]>([])
  const textRef = useRef('')
  const submit = useCallback(async () => {
    const updateData: Partial<CommentDocument> = {
      editedDate:
        firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
      body,
    }
    await commentSnap.ref.update(updateData).catch((error) => {
      showErrorToast(t('Something went wrong, please try again'))
      throw error
    })
    onEditCancel?.()
  }, [body, commentSnap.ref, onEditCancel, t])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!event.shiftKey && event.key === 'Enter') {
        if (body.trim().length > 0) {
          submit()
        }
        event.preventDefault()
      }
    },
    [body, submit]
  )

  const changesMade = body !== commentSnap.data().body

  const submitDisabled = body.trim().length === 0 || !changesMade

  const organizationRef = useOrganizationDoc()
  const feedId = commentSnap.ref.parent?.parent?.parent?.parent?.id
  const followersRef = feedId
    ? organizationRef
        .collection('feeds')
        .doc(feedId)
        .collection('extra')
        .doc('followers')
    : undefined

  return (
    <Container style={{ marginBottom: 10 }}>
      <CommentItemHeader
        editMode
        authorId={authorId}
        commentSnap={commentSnap}
        isAdmin={false}
      />
      <Container
        style={{
          position: 'relative',
          backgroundColor: colors.background,
          margin: subComment ? '0 -20px 0 -30px' : `0 -10px`,
          padding: subComment ? `10px 10px 10px 30px` : `10px 20px`,
        }}
      >
        <MentionsInput
          filter={followersRef}
          onKeyPress={handleKeyDown}
          value={body}
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 18,
            border: `1px solid ${colors.border}`,
            backgroundColor: colors.foreground,
            fontSize: 13,

            control: {
              flex: 1,
            },

            input: {
              marginLeft: 5,
            },
          }}
          onChange={({ value, mentions, text }) => {
            setBody(value)
            mentionsRef.current = mentions
            textRef.current = text
          }}
          placeholder={t('Comment')}
        />
        <ClickableOpacity
          style={{
            position: 'absolute',
            right: subComment ? 25 : 35,
            bottom: 40,
            opacity: submitDisabled ? 0.4 : 1,
            cursor: submitDisabled ? 'default' : 'pointer',
          }}
          css={css`
            svg {
              fill: ${colors.accent};
            }

            &:hover svg {
              fill: ${colors.sendHover};
            }
          `}
          onClick={submitDisabled ? undefined : submit}
        >
          <SendIcon width={26} height={20} />
        </ClickableOpacity>
        <Container
          style={{
            display: 'inline-block',
            alignContent: 'flex-start',
          }}
          onClick={onEditCancel}
        >
          <ClickableOpacity
            style={{ marginLeft: 10, marginTop: 10, fontWeight: 600 }}
            css={css`
              color: ${colors.label};

              &:hover {
                color: ${colors.cancelHover};
              }
            `}
          >
            {t('Cancel')}
          </ClickableOpacity>
          <Pusher />
        </Container>
      </Container>
    </Container>
  )
}
