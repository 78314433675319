import { TextField } from '@mui/material'
import React, { Dispatch, ReactNode, SetStateAction, useCallback } from 'react'
import { useTranslate } from '../hooks/Internationalization'
import { Container, Spacer } from '../layout/Layout'
import { FeedPostEditCardState } from './FeedPostEditCard'

export default function PostCreateCardClosedState({
  avatar,
  setState,
}: {
  avatar: ReactNode
  setState: Dispatch<SetStateAction<FeedPostEditCardState>>
}) {
  const t = useTranslate()
  const handleOnFocus = useCallback(() => {
    setState('open')
  }, [setState])

  return (
    <Container horizontal style={{ padding: 20 }}>
      {avatar}
      <Spacer size={10} />
      <TextField
        onFocus={handleOnFocus}
        label={t('Give your colleagues an update')}
        style={{ flex: 1 }}
        variant="outlined"
        id="feed_input_closed"
      />
    </Container>
  )
}
