import IconHoverWrapper from './IconHoverWrapper'

export interface IconProps {
  style?: React.SVGProps<SVGSVGElement>
  width?: number
  height?: number
  fill?: string
  title?: string
}

type Dimensions =
  | { size: number }
  | { size: undefined; width: number; height: number }

type IconButtonProps = Dimensions & {
  Icon: React.ComponentType<IconProps & any>
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  style?: React.CSSProperties
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onBlur?: () => void
  fill?: string
  disabled?: boolean
  className?: string
}

export default function IconButton(props: IconButtonProps) {
  const {
    Icon,
    onClick,
    style,
    onMouseEnter,
    onMouseLeave,
    fill,
    onFocus,
    onBlur,
    disabled,
    className,
  } = props

  return (
    <IconHoverWrapper>
      <button
        className={className}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onMouseDown={(e) => {
          e.preventDefault()
        }}
        style={{
          cursor: 'pointer',
          border: 'none',
          backgroundColor: 'transparent',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
      >
        <Icon
          style={{ pointerEvents: 'none' }}
          width={props.size ?? props.width}
          height={props.size ?? props.height}
          fillColor={fill}
        />
      </button>
    </IconHoverWrapper>
  )
}
