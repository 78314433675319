import FilePreview from '../../FilePreview'
import { usePostSnap } from '../../hooks/feeds'
import { Container, ContainerProps } from '../../layout/Layout'

type FeedPostAttachmentsProps = {
  feedId: string
  postId: string
} & ContainerProps

export default function FeedPostAttachments({
  feedId,
  postId,
  style,
  ...rest
}: FeedPostAttachmentsProps) {
  const post = usePostSnap(feedId, postId).read().data()

  if (!post?.files) return null

  return (
    <Container style={{ ...style }} {...rest}>
      {post.files.map((file) => (
        <FilePreview
          key={file.uri}
          name={file.fileName}
          url={file.uri}
          size={file.size}
          style={{ marginBottom: 11 }}
        />
      ))}
    </Container>
  )
}
