import { Img } from 'react-suspense-img'
import Playstore from './assets/Playstore.png'
import { Container } from './layout/Layout'

export default function AndroidPlaystoreButton() {
  return (
    <Container hAlign="center">
      <a href="https://play.google.com/store/apps/details?id=com.playipp.connect">
        <Img style={{ width: 450, height: 200 }} src={Playstore} />
      </a>
    </Container>
  )
}
