import { Tooltip } from '@mui/material'
import React, { ComponentType, ReactNode } from 'react'
import { useColors } from '../Colors'
import { IconProps } from '../IconButton'
import { Container, ContainerProps, Pusher } from '../layout/Layout'
import { BodyText } from '../Text'
import SidebarAddButton from './SidebarAddButton'
import { useTranslate } from '../hooks/Internationalization'

type SidebarContainerProps = {
  text: string
  rightSideButton?: boolean
  onRightSideClick?: () => void
  Icon?: ComponentType<IconProps & any>
  children?: ReactNode
  tooltip?: string
} & ContainerProps

export default function SidebarContainer({
  Icon,
  text,
  rightSideButton,
  onRightSideClick,
  children,
  tooltip,
  ...props
}: SidebarContainerProps) {
  const colors = useColors()
  const t = useTranslate()

  return (
    <Container vAlign="center" {...props}>
      <Container
        style={{ padding: '14px 0px 14px 10px' }}
        horizontal
        flex={1}
        vAlign="center"
      >
        {Icon && (
          <Icon
            height={14}
            width={14}
            fill={colors.labelDim}
            style={{ marginRight: 6 }}
          />
        )}
        {tooltip ? (
          <Tooltip
            title={tooltip}
            placement="top"
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <div>
              <BodyText
                style={{
                  fontSize: 14,
                  color: colors.mediumHeading,
                  cursor: 'default',
                  fontWeight: 600,
                }}
              >
                {text}
              </BodyText>
            </div>
          </Tooltip>
        ) : (
          <BodyText
            style={{
              fontSize: 14,
              color: colors.mediumHeading,
              cursor: 'default',
              fontWeight: 600,
            }}
          >
            {text}
          </BodyText>
        )}
        <Pusher />
        {rightSideButton && (
          <SidebarAddButton
            tooltip={t('Create new feed')}
            onClick={onRightSideClick || undefined}
          />
        )}
      </Container>
      {children}
    </Container>
  )
}
