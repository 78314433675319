import { Route, Switch } from 'react-router-dom'
import ForgotPassword from './ForgotPassword'
import SsoAuth from './SsoAuth'
import SignUp from './SignUp'
import SignIn from './SignIn'

export default function Login() {
  return (
    <Switch>
      <Route path="/sso/:providerId?">
        <SsoAuth />
      </Route>
      <Route path="/forgot">
        <ForgotPassword />
      </Route>
      <Route path="/sign-up" component={SignUp} />
      <Route>
        <SignIn />
      </Route>
    </Switch>
  )
}
