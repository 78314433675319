import { useAllUsers } from 'connect-shared/AllUsersContext'
import React, { useRef, useState } from 'react'
import { useColors } from '../Colors'
import { ReactComponent as RemoveIcon } from '../icons/remove.svg'
import RoundUserAvatar from '../RoundUserAvatar'
import StatusAvatar from '../StatusAvatar'

interface UserListItemProps {
  userId: string
  style?: React.CSSProperties
  backgroundColor?: string
  backgroundColorHover?: string
  avatarSize?: number
  hideStatusDot?: boolean
  noHover?: boolean
  customIcon?: JSX.Element
  clicked?: () => void
  removeClicked?: (userId: string) => void
}

export default function UserListItem({
  userId,
  avatarSize = 40,
  style,
  backgroundColor,
  backgroundColorHover,
  clicked,
  removeClicked,
  hideStatusDot,
  customIcon,
  noHover,
}: UserListItemProps) {
  const [hovered, setHovered] = useState<boolean>(false)
  const [optimisticRemoved, setOptimisticRemoved] = useState(false)

  const colors = useColors()
  const wrapperRef = useRef<HTMLButtonElement>(null)
  const { allUsers } = useAllUsers()
  const name = allUsers.get(userId)?.fullName || ''

  const iconSize = 30

  const hoverBackground = backgroundColorHover
    ? backgroundColorHover
    : colors.backgroundHighlight

  const background = backgroundColor ? backgroundColor : colors.none

  const removeIcon =
    hovered && removeClicked ? (
      <RemoveIcon
        width={iconSize}
        height={iconSize}
        onClick={(e) => {
          e.preventDefault()
          setOptimisticRemoved(true)
          removeClicked(userId)
        }}
      />
    ) : (
      <React.Fragment />
    )

  const icon = customIcon || removeIcon

  if (optimisticRemoved) return null

  return (
    <button
      onClick={() => {
        clicked && clicked()
      }}
      ref={wrapperRef}
      onMouseOver={() => {
        if (noHover) return
        setHovered(true)
      }}
      onMouseOut={() => {
        if (noHover) return
        setHovered(false)
      }}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        border: 'none',
        display: 'flex',
        alignSelf: 'stretch',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        background: hovered && !noHover ? hoverBackground : background,
        paddingLeft: 20,
        paddingRight: 20,
        ...style,
      }}
    >
      {hideStatusDot ? (
        <RoundUserAvatar userId={userId} size={avatarSize} />
      ) : (
        <StatusAvatar userId={userId} size={avatarSize} />
      )}
      <span
        style={{
          flex: 1,
          marginLeft: 15,
          color: colors.text,
          textAlign: 'start',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </span>
      {icon}
    </button>
  )
}
