import { useFirestoreDoc } from '@iwikal/reactfire'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import {
  defaultOrganizationPrimaryColor,
  defaultOrganizationPrimaryTextColor,
} from 'connect-shared/constants'
import firebase from 'firebase/app'
import 'firebase/storage'
import useIsMounted from 'ismounted'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Crop } from 'react-image-crop'
import { useHistory } from 'react-router-dom'
import BackLabelButton from '../BackLabelButton'
import ImageUploadField from '../ImageUploadField'
import TextInput from '../TextInput'
import { useSpinnerToast } from '../Toast'
import { emitTutorialEvent } from '../Tutorials'
import {
  Upload,
  canvasSize,
  cropImage,
  fileToUpload,
  fitToAspectRatio,
} from '../crop-utils'
import CropImageModal from '../feeds/CropImageModal'
import { useTranslate } from '../hooks/Internationalization'
import { useOrganizationDoc, useOrganizationId } from '../hooks/auth'
import { Language, languages } from 'connect-shared/translations'

function validColor(color: string) {
  return color.length === 6 && sanitizeColor(color) === color
}

function sanitizeColor(color: string) {
  return color
    .toLowerCase()
    .replace(/[^a-f0-9]/g, '')
    .substr(0, 6)
}

const targetWidth = 512
const targetHeight = 128
export default function OrganizationSettings() {
  const organizationRef = useOrganizationDoc()
  const organizationResource = useFirestoreDoc(organizationRef)
  const history = useHistory()
  const t = useTranslate()

  const [originalState] = useState({
    name: organizationResource.read().data()?.name || '',
    allowedEmailDomainEnabled:
      organizationResource.read().data()?.allowedEmailDomainEnabled || false,
    allowedEmailDomain:
      organizationResource.read().data()?.allowedEmailDomain || '',
    primaryColor:
      organizationResource.read().data()?.primaryColor ||
      defaultOrganizationPrimaryColor,
    primaryTextColor:
      organizationResource.read().data()?.primaryTextColor ||
      defaultOrganizationPrimaryTextColor,
    enableCustomTheme:
      organizationResource.read().data()?.enableCustomTheme || false,
    logo: organizationResource.read().data()?.logo || '',
    allowSupportSignIn:
      organizationResource.read().data()?.allowSupportSignIn || false,
    emailLanguage: organizationResource.read().data()?.emailLanguage || 'sv',
  })

  const [logoUpload, setLogoUpload] = useState<Upload>()

  const [logo, setLogo] = useState<File | Blob | string | undefined>(
    organizationResource.read().data()?.logo || ''
  )
  const handleLogo = useCallback(async (file?: File) => {
    if (file) {
      const upload = await fileToUpload(file)
      const [width, height] = fitToAspectRatio(
        upload,
        targetWidth / targetHeight
      )
      setLogoUpload(
        await fileToUpload(await canvasSize(upload, width, height, 'image/png'))
      )
      setCropModalOpen(true)
    } else {
      setLogoUpload(undefined)
    }
  }, [])

  const [name, setName] = useState(
    organizationResource.read().data()?.name || ''
  )
  const [allowSupportSignIn, setAllowSupportSignIn] = useState(
    organizationResource.read().data()?.allowSupportSignIn || false
  )
  const [allowedEmailDomainEnabled, setAllowedEmailDomainEnabled] = useState(
    organizationResource.read().data()?.allowedEmailDomainEnabled || false
  )

  const [enableCustomTheme, setEnableCustomTheme] = useState(
    organizationResource.read().data()?.enableCustomTheme || false
  )

  const [allowedEmailDomain, setAllowedEmailDomain] = useState<string>(
    organizationResource.read().data()?.allowedEmailDomain || ''
  )
  const [emailLanguage, setEmailLanguage] = useState<Language>(
    organizationResource.read().data()?.emailLanguage || 'sv'
  )
  const [primaryColor, setPrimaryColor] = useState(originalState.primaryColor)
  const [primaryTextColor, setPrimaryTextColor] = useState(
    originalState.primaryTextColor
  )

  const handlePrimaryColorChange = useCallback((value) => {
    setPrimaryColor(sanitizeColor(value))
  }, [])
  const handlePrimaryTextColorChange = useCallback((value) => {
    setPrimaryTextColor(sanitizeColor(value))
  }, [])

  const [loading, setLoading] = useState(false)

  const [nameError, setNameError] = useState<string | undefined>()
  const [primaryColorErrorState, setPrimaryColorError] = useState<string>()
  const [primaryTextColorErrorState, setPrimaryTextColorError] =
    useState<string>()
  const primaryColorError = enableCustomTheme && primaryColorErrorState
  const primaryTextColorError = enableCustomTheme && primaryTextColorErrorState

  const {
    show: showToast,
    dismissSuccess: dismissSuccessToast,
    showError: showErrorToast,
  } = useSpinnerToast()

  const changesMade = useMemo(() => {
    return (
      originalState.name !== name ||
      originalState.primaryColor !== primaryColor ||
      originalState.primaryTextColor !== primaryTextColor ||
      originalState.allowedEmailDomainEnabled !== allowedEmailDomainEnabled ||
      originalState.allowedEmailDomain !== allowedEmailDomain ||
      originalState.enableCustomTheme !== enableCustomTheme ||
      originalState.allowSupportSignIn !== allowSupportSignIn ||
      originalState.emailLanguage !== emailLanguage ||
      typeof logo === 'object'
    )
  }, [
    allowSupportSignIn,
    allowedEmailDomain,
    allowedEmailDomainEnabled,
    emailLanguage,
    enableCustomTheme,
    logo,
    name,
    originalState.allowSupportSignIn,
    originalState.allowedEmailDomain,
    originalState.allowedEmailDomainEnabled,
    originalState.emailLanguage,
    originalState.enableCustomTheme,
    originalState.name,
    originalState.primaryColor,
    originalState.primaryTextColor,
    primaryColor,
    primaryTextColor,
  ])

  const isMounted = useIsMounted()

  const organizationId = useOrganizationId()
  const save = useCallback(async () => {
    setLoading(true)
    setNameError(undefined)
    setPrimaryColorError(undefined)
    setPrimaryTextColorError(undefined)

    let hasErrors = false
    if (enableCustomTheme && !validColor(primaryColor)) {
      setPrimaryColorError(t('Invalid color'))
      hasErrors = true
    }

    if (enableCustomTheme && !validColor(primaryTextColor)) {
      setPrimaryTextColorError(t('Invalid color'))
      hasErrors = true
    }

    if (!name) {
      setNameError(t('You must enter a name'))
      hasErrors = true
    }

    if (hasErrors) {
      setLoading(false)
      return
    }

    showToast()

    async function logoUrl() {
      if (typeof logo === 'object') {
        const ref = firebase
          .storage()
          .ref('organizations')
          .child(organizationId)
          .child('logo')
        await ref.put(logo, {
          cacheControl: 'private, max-age=604800, immutable',
        })
        return ref.getDownloadURL() || ''
      } else {
        return logo || ''
      }
    }

    const nextLogo = await logoUrl()
    const newOrganization = {
      name,
      allowedEmailDomainEnabled: allowedEmailDomain
        ? allowedEmailDomainEnabled
        : false,
      allowedEmailDomain: allowedEmailDomain || '',
      enableCustomTheme,
      primaryColor: validColor(primaryColor)
        ? primaryColor
        : defaultOrganizationPrimaryColor,
      primaryTextColor: validColor(primaryTextColor)
        ? primaryTextColor
        : defaultOrganizationPrimaryTextColor,
      logo: nextLogo,
      allowSupportSignIn,
      emailLanguage,
    }

    try {
      await organizationRef.update(newOrganization)
      history.push('/settings')
      dismissSuccessToast()
      if (nextLogo) {
        emitTutorialEvent('org_with_logo_saved')
      }
    } catch (e) {
      if (isMounted) {
        showErrorToast()
        console.error(e)
      }
    } finally {
      setLoading(false)
    }
  }, [
    allowSupportSignIn,
    allowedEmailDomain,
    allowedEmailDomainEnabled,
    dismissSuccessToast,
    emailLanguage,
    enableCustomTheme,
    history,
    isMounted,
    logo,
    name,
    organizationId,
    organizationRef,
    primaryColor,
    primaryTextColor,
    showErrorToast,
    showToast,
    t,
  ])

  const undo = useCallback(() => {
    setName(originalState.name)
    setAllowedEmailDomainEnabled(originalState.allowedEmailDomainEnabled)
    setAllowedEmailDomain(originalState.allowedEmailDomain)
    setPrimaryColor(originalState.primaryColor)
    setPrimaryTextColor(originalState.primaryTextColor)
  }, [
    originalState.name,
    originalState.allowedEmailDomainEnabled,
    originalState.allowedEmailDomain,
    originalState.primaryColor,
    originalState.primaryTextColor,
  ])

  const imagePlaceholder = (
    <Container style={{ width: 512 / 2, height: 128 / 2 }}></Container>
  )

  const [cropModalOpen, setCropModalOpen] = useState(false)
  const [crop, setCrop] = useState<Crop>({ aspect: targetWidth / targetHeight })
  const imageRef = useRef<HTMLImageElement>()

  const handleCropSubmit = useCallback(async () => {
    if (!imageRef.current || !logoUpload) return
    const blob = await cropImage(
      logoUpload,
      crop,
      imageRef.current,
      targetWidth,
      targetHeight,
      'image/png'
    )
    const upload = await fileToUpload(blob as File)
    setLogo(upload.file)
    setCropModalOpen(false)
  }, [crop, logoUpload])

  return (
    <Container maxWidth="md" sx={{ marginBottom: 11 }}>
      <Stack spacing={2} marginBottom={4}>
        <Box paddingTop={2}>
          <BackLabelButton />
        </Box>
        <Box flexDirection="row" display="flex">
          <Box flex="1">
            <Typography variant="h4">{t('Company profile')}</Typography>
          </Box>
        </Box>
      </Stack>
      <Stack spacing={2} paddingX={2}>
        <Card raised sx={{ height: '100%' }}>
          <CardContent sx={{ margin: 2 }}>
            <Stack spacing={2}>
              <TextInput
                error={nameError}
                value={name}
                onChangeText={setName}
                size={16}
                placeholder={t('Company name')}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={allowSupportSignIn}
                    onChange={() => setAllowSupportSignIn((value) => !value)}
                  />
                }
                label={t('Enable support sign in')}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card raised sx={{ height: '100%' }}>
          <CardContent sx={{ margin: 2 }}>
            <Stack spacing={2}>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Switch
                    checked={enableCustomTheme}
                    onChange={() => setEnableCustomTheme((value) => !value)}
                  />
                }
                label={t('Enable custom theme')}
              />
              <Box
                marginBottom={6}
                style={{
                  alignSelf: 'flex-start',
                  padding: 10,
                  borderRadius: 5,
                  backgroundColor:
                    validColor(primaryColor) && enableCustomTheme
                      ? '#' + primaryColor
                      : '#' + defaultOrganizationPrimaryColor,
                  color:
                    validColor(primaryTextColor) && enableCustomTheme
                      ? '#' + primaryTextColor
                      : '#' + defaultOrganizationPrimaryTextColor,
                }}
              >
                {t('Color preview')}
              </Box>
              <TextInput
                prefix="#"
                size={16}
                disabled={!enableCustomTheme}
                value={
                  enableCustomTheme
                    ? primaryColor
                    : defaultOrganizationPrimaryColor
                }
                onChangeText={handlePrimaryColorChange}
                style={{ marginTop: 10 }}
                placeholder={t('Primary color')}
                error={primaryColorError}
              />
              <TextInput
                error={primaryTextColorError}
                prefix="#"
                size={16}
                disabled={!enableCustomTheme}
                value={
                  enableCustomTheme
                    ? primaryTextColor
                    : defaultOrganizationPrimaryTextColor
                }
                onChangeText={handlePrimaryTextColorChange}
                style={{ marginTop: 10 }}
                placeholder={t('Text Color')}
              />
              <ImageUploadField
                disabled={!enableCustomTheme}
                value={logo}
                onChange={handleLogo}
                accept="image/png"
                label={t('Logo')}
                subLabel={t('.png file (512px x 128px)')}
                imgStyle={{
                  width: 512 / 2,
                  height: 128 / 2,
                }}
                placerholder={imagePlaceholder}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card raised sx={{ height: '100%' }}>
          <CardContent sx={{ margin: 2 }}>
            <Stack spacing={2}>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Switch
                    checked={allowedEmailDomainEnabled}
                    onChange={() =>
                      setAllowedEmailDomainEnabled((value) => !value)
                    }
                  />
                }
                label={t('Allow users with right email domain to join')}
              />
              <TextInput
                disabled={!allowedEmailDomainEnabled}
                size={16}
                value={allowedEmailDomain}
                onChangeText={setAllowedEmailDomain}
                placeholder={t('Email domain')}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card raised sx={{ height: '100%' }}>
          <CardContent sx={{ margin: 2 }}>
            <Stack spacing={2}>
              <InputLabel>{t('Language for invite e-mails')}</InputLabel>
              <FormControl>
                <Select
                  value={emailLanguage}
                  onChange={(e) => {
                    setEmailLanguage((e.target.value || 'sv') as Language)
                  }}
                >
                  {languages.map((lang) => {
                    const languageTranslations: Record<Language, string> = {
                      da: 'Dansk',
                      en: 'English',
                      sv: 'Svenska',
                    }
                    return (
                      <MenuItem key={lang} value={lang}>
                        {languageTranslations[lang]}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Stack>
          </CardContent>
        </Card>
        {changesMade && (
          <>
            <Grid
              container
              spacing={2}
              justifyContent={'right'}
              flex={1}
              display={'flex'}
              paddingRight={2}
            >
              <Grid item>
                <Button variant="outlined" color="primary" onClick={undo}>
                  {t('Cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={save}
                >
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
      {cropModalOpen && logoUpload && (
        <CropImageModal
          circularCrop={false}
          onSubmit={handleCropSubmit}
          crop={crop}
          setCrop={setCrop}
          imageRef={imageRef}
          avatar={logoUpload}
          onRequestClose={() => setCropModalOpen(false)}
          targetWidth={targetWidth}
          targetHeight={targetHeight}
        />
      )}
    </Container>
  )
}
