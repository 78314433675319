import { colors } from 'connect-shared/constants'
import { useCallback } from 'react'
import HoverButton from '../../HoverButton'
import { ReactComponent as DropdownIcon } from '../../icons/ico-chevron-down.svg'
import { Pusher } from '../../layout/Layout'
import RoundAvatar from '../../RoundAvatar'

interface FeedListItemProps {
  onClick?: (feedId: string) => void
  isSelected?: boolean
  disabled?: boolean
  feedId?: string
  avatar?: string
  name?: string
}

export default function FeedListItem({
  onClick,
  isSelected,
  disabled,
  avatar,
  feedId,
  name,
}: FeedListItemProps) {
  const handleOnClick = useCallback(() => {
    feedId && onClick?.(feedId)
  }, [feedId, onClick])

  return (
    <HoverButton
      disabled={disabled}
      onClick={handleOnClick}
      backgroundColor={isSelected ? colors.selected : colors.foreground}
      hoverColor={colors.selected}
      style={{
        paddingLeft: 20,
        width: '100%',
        minHeight: 70,
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'stretch',
        flex: 1,
        alignItems: 'center',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      <RoundAvatar avatar={avatar} name={name} size={50} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 20,
          flex: 1,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          textAlign: 'start',
        }}
      >
        <p
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'start',
            alignSelf: 'stretch',
            justifyContent: 'space-between',
            alignItems: 'center',
            lineHeight: 1.4,
            fontSize: 16,
            fontWeight: isSelected ? 600 : 400,
          }}
        >
          {name}
        </p>
        {isSelected && (
          <>
            <Pusher />
            <DropdownIcon
              fill={colors.active}
              height={16}
              width={16}
              style={{ marginRight: 20, transform: 'rotate(90deg)' }}
            />
          </>
        )}
      </div>
    </HoverButton>
  )
}
