import { HTMLAttributes } from 'react'
import styled from '@emotion/styled'

const SpinnerContainer = styled.div`
  .loadingCircleCircular {
    animation: loadingCircleRotate 3s linear infinite;
    transform-origin: center center;
  }

  .loadingCirclePath {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: loadingCircleDash 2s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #4b9fcc;
  }

  @keyframes loadingCircleRotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loadingCircleDash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -20;
    }
    100% {
      stroke-dasharray: 10, 200;
      stroke-dashoffset: -124;
    }
  }
`

export default function Spinner(
  props: HTMLAttributes<HTMLDivElement> & { size?: number }
) {
  const { size = 50 } = props
  return (
    <SpinnerContainer
      {...props}
      style={{
        overflow: 'hidden',
        width: size,
        height: size,
        ...props.style,
      }}
    >
      <svg className="loadingCircleCircular" viewBox="25 25 50 50">
        <circle
          className="loadingCirclePath"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
      </svg>
    </SpinnerContainer>
  )
}
