import { Container, ContainerProps } from './layout/Layout'
import { statuses, StatusKey } from 'connect-shared/statuses'
import { language } from './hooks/Internationalization'

type StatusBadgeProps = ContainerProps & { statusKey?: StatusKey }

export default function StatusBadge({
  flex = '0 0 auto',
  style,
  statusKey,
  ...containerProps
}: StatusBadgeProps) {
  const status = statuses[statusKey || 'unavailable']

  return (
    <Container
      style={{
        flex,
        padding: '5px 10px',
        backgroundColor: status.color,
        fontSize: 13,
        color: '#fff',
        borderRadius: 6,
        ...style,
      }}
      {...containerProps}
    >
      {status.name[language]}
    </Container>
  )
}
