import { useCallback, useReducer, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ReactComponent as MoreIcon } from './icons/more.svg'
import { useColors } from './Colors'
import { LowTooltip } from './user-profile/ProfileActionButtons'

export type PopoutMenuOption = {
  text: string
  onClick?: () => void
  danger?: boolean
  disabled?: boolean
  tooltip?: string
}

export default function PopoutMenu({
  options,
  menuWidth,
  buttonSize = 22,
}: {
  options: PopoutMenuOption[]
  menuWidth?: number
  buttonSize?: number
}) {
  const [hovered, toggleHover] = useReducer((state) => !state, false)
  const colors = useColors()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOnClick = useCallback(
    (callback?: () => void) => {
      handleClose()
      callback && callback()
    },
    [handleClose]
  )

  return (
    <div
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      style={{
        opacity: options.length ? undefined : 0,
        pointerEvents: options.length ? undefined : 'none',
      }}
    >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreIcon
          height={buttonSize}
          width={buttonSize}
          fill={hovered ? colors.active : colors.labelDim}
        />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: menuWidth ? menuWidth : '20ch',
          },
        }}
      >
        {options.map((option) => {
          if (option.tooltip) {
            return (
              <TooltipItem
                key={option.text}
                option={option}
                onClick={
                  option.onClick
                    ? () => handleOnClick(option.onClick)
                    : undefined
                }
                tooltip={option.tooltip}
              />
            )
          }
          return (
            <MenuItem
              style={{
                color: option.danger ? colors.danger : undefined,
                opacity: option.disabled ? 0.4 : 1,
                cursor: option.disabled ? 'default' : 'pointer',
              }}
              disabled={option.disabled}
              key={option.text}
              selected={false}
              onClick={
                option.disabled
                  ? undefined
                  : () => handleOnClick(option.onClick)
              }
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

type TooltipItemProps = {
  option: PopoutMenuOption
  onClick?: () => void
  tooltip: string
}

function TooltipItem({ option, onClick, tooltip }: TooltipItemProps) {
  const colors = useColors()
  return (
    <LowTooltip placement="top" title={tooltip}>
      <div>
        <MenuItem
          style={{
            color: option.danger ? colors.danger : undefined,
            opacity: option.disabled ? 0.4 : 1,
            cursor: option.disabled ? 'default' : 'pointer',
          }}
          disabled={option.disabled}
          key={option.text}
          selected={false}
          onClick={option.disabled ? undefined : onClick}
        >
          {option.text}
        </MenuItem>
      </div>
    </LowTooltip>
  )
}
