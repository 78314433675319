import styled from '@emotion/styled'
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useColors } from './Colors'
import { Spacer } from './layout/Layout'
import { CaptionText } from './Text'

export const StyledInputWrapper = styled.div<{
  borderColor: string
  focusColor: string
  size: number
}>`
  border-color: ${(p) => p.borderColor};

  &:hover,
  &:focus-within {
    border-color: ${(p) => p.focusColor};
  }

  input {
    &:-webkit-autofill {
      transition: background-color 1ms 0ms ease-in-out;
    }

    &:-webkit-autofill::first-line {
      font-size: ${(p) => p.size}px;
      font-family: 'Open sans', sans-serif;
    }
  }
`

type TextInputProps = {
  onChangeText?: (text: string) => void
  value?: string
  label?: string
  error?: boolean | string | ReactNode
  noErrorPlaceholder?: boolean
  size?: number
  inputStyle?: CSSProperties
  labelStyle?: CSSProperties
  containerStyle?: CSSProperties
} & React.InputHTMLAttributes<HTMLInputElement>

export default function TextInput({
  onChangeText,
  value,
  label,
  error,
  noErrorPlaceholder,
  style,
  placeholder,
  size = 16,
  inputStyle,
  containerStyle,
  labelStyle,
  ...inputProps
}: TextInputProps) {
  const colors = useColors()

  const [focused, setFocused] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const [hasAutofilledValue, setHasAutofilledValue] = useState(false)
  useLayoutEffect(() => {
    const input = inputRef.current
    if (!input) return
    function listener() {
      setHasAutofilledValue(true)
    }

    input.addEventListener('transitionend', listener)
    return () => {
      input.removeEventListener('transitionend', listener)
    }
  }, [])

  const hasErrorMessage =
    typeof error !== 'boolean' && typeof error !== 'undefined'
  const inlinePlaceholder: boolean =
    !!placeholder && (focused || !!value || (!value && hasAutofilledValue))

  const paddingHorizontal = size

  return (
    <label style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {label && (
        <>
          <CaptionText
            style={{
              fontSize: size,
              color: colors.beeworkBlue,
              fontWeight: 700,
              ...labelStyle,
            }}
          >
            {label}&nbsp;
          </CaptionText>
          <Spacer size={size * 0.6} />
        </>
      )}
      <StyledInputWrapper
        focusColor={inputProps.disabled ? 'none' : colors.black}
        borderColor={colors.border}
        size={size}
        style={{
          opacity: inputProps.disabled ? 0.4 : 1,
          cursor: 'text',
          width: '100%',
          fontSize: size,
          color: colors.labelDim,
          borderRadius: size / 3,
          borderWidth: 1,
          borderStyle: 'solid',
          position: 'relative',
          overflow: 'hidden',
          ...containerStyle,
          ...(error ? { borderColor: colors.danger } : {}),
        }}
      >
        <p
          style={{
            marginLeft: paddingHorizontal,
            marginRight: paddingHorizontal,
            paddingTop: size / 2,
            fontSize: size * 0.75,
            color:
              focused && !inputProps.disabled ? colors.active : colors.label,
            pointerEvents: 'none',
            opacity: inlinePlaceholder ? 1 : 0,
          }}
        >
          {inlinePlaceholder ? placeholder : 'a'}
        </p>
        <p
          style={{
            fontSize: size,
            marginTop: size / 8,
            marginBottom: size * 1.125,
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          a
        </p>
        <input
          ref={inputRef}
          onBlur={useCallback(() => {
            setFocused(false)
          }, [])}
          onFocus={useCallback(() => {
            setFocused(true)
          }, [])}
          placeholder={inlinePlaceholder ? '' : placeholder}
          style={{
            height: '100%',
            width: '100%',
            top: 0,
            bottom: 0,
            paddingRight: paddingHorizontal,
            paddingLeft: paddingHorizontal,
            paddingTop: inlinePlaceholder ? size * 0.65 : 0,
            fontSize: size,
            border: 0,
            outline: 'none',
            position: 'absolute',
            backgroundColor: colors.none,
            ...inputStyle,
          }}
          value={value}
          onChange={React.useCallback(
            (e) => {
              setHasAutofilledValue(false)
              onChangeText?.(e.target.value)
            },
            [onChangeText]
          )}
          {...inputProps}
        />
      </StyledInputWrapper>
      {!noErrorPlaceholder && (
        <div style={{ minHeight: 20 }}>
          {hasErrorMessage && (
            <p style={{ color: colors.danger, fontSize: 14, marginTop: 3 }}>
              {error}
            </p>
          )}
        </div>
      )}
    </label>
  )
}
