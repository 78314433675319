import { useObservable } from '@iwikal/reactfire'
import useAccountInfo, { isAccount } from './hooks/useAccountInfo'
import { firestore } from './firestore'
import firebase from 'firebase/app'
import { Observable } from 'rxjs'

let refreshPromise: Promise<void>
let refreshFinished = false

/**
  When a user verifies their email and refreshes the browser the firebase js
  sdk will see that the email has been verified, but firestore will not until
  the user has been refreshed. This component tries to fetch
  /accounts/{accountId} from firestore and reloads the user if it throws a
  permission-denied error.
 */
export default function EnsureTokenRefreshAfterEmailVerification({ children }) {
  const accountInfo = useAccountInfo()

  const doc = isAccount(accountInfo)
    ? firestore().collection('emailVerified').doc(accountInfo.accountId)
    : firestore().collection('empty').doc('empty')
  const observable = new Observable((subscriber) => doc.onSnapshot(subscriber))

  try {
    // Use refreshFinished as part of the cache id to make sure a new firstore
    // request is fired after refreshing our token
    useObservable(
      observable,
      'ensure-token-refresh-account-doc-' + refreshFinished + doc.path
    ).read()
  } catch (err) {
    const currentUser = firebase.auth().currentUser
    // Refresh the token once if we get a permission-denied
    if (!refreshFinished && currentUser && err?.code === 'permission-denied') {
      if (!refreshPromise) {
        refreshPromise = currentUser
          .reload()
          .then(() => {
            return currentUser.getIdToken(true)
          })
          .then(() => {
            refreshFinished = true
          })
      }
      throw refreshPromise
    }

    throw err
  }

  return children
}
