import styled from '@emotion/styled'
import { useTranslate } from '../../hooks/Internationalization'
import RoundAvatar from '../../RoundAvatar'

const StyledCreateFeedPreview = styled.div`
  display: flex;
  flex: 10;
  margin: 30px 0;
  padding: 30px 10px;
  background: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 3px #00000040;

  .avatarWrapper {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: #ccc;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    img {
      width: 140px;
      height: 140px;
      object-fit: cover;
    }
  }
  h3 {
    text-align: center;
    font-size: 22px;
    font-weight: 100;
  }
  p {
    text-align: center;
    font-size: 12px;
  }
`

const CreateFeedPreview = (props: any) => {
  const t = useTranslate()
  return (
    <StyledCreateFeedPreview>
      <div
        style={{
          flex: 4,
        }}
      >
        <div className="avatarWrapper">
          {props.acceptedFiles &&
            props.acceptedFiles.length > 0 &&
            props.acceptedFiles.map(
              (acceptedFile: MediaSource, index: number) => (
                <div
                  key={index}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={URL.createObjectURL(acceptedFile)}
                    alt=""
                    onClick={() => props.removeFile()}
                  />
                </div>
              )
            )}
          {props.acceptedFiles &&
            props.acceptedFiles.length === 0 &&
            !props.existingAvatar && (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <RoundAvatar
                  avatar={props.existingAvatar}
                  size={140}
                  name={props.name}
                />
              </div>
            )}
          {props.existingAvatar && (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <img src={props.existingAvatar} alt={t('Feed avatar')} />
            </div>
          )}
        </div>
        {props.acceptedFiles && props.acceptedFiles.length > 0 && (
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '11px',
              marginTop: '5px',
              marginBottom: '-23px',
              color: '#c55757',
            }}
            onClick={() => {
              props.removeFile()
            }}
          >
            {t('Remove image')}
          </div>
        )}
      </div>
      <div
        style={{
          flex: 6,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h3>{props.name ? props.name : t('Feed name')}</h3>
        <p>{props.desc ? props.desc : t('Description')}</p>
      </div>
    </StyledCreateFeedPreview>
  )
}

export default CreateFeedPreview
