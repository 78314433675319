import { useEffect } from 'react'
import firebase from 'firebase/app'

export default function SignOut() {
  useEffect(() => {
    if (!firebase.auth().currentUser) {
      return
    }
    // HACK: hide page while waiting for reload to hide some jank
    document.body.style.display = 'none'
    Promise.all([firebase.auth().signOut()]).then(() => {
      // Clear reactfire cache by signing out to avoid cached permission
      // denied errors when signing in again
      // TODO: Don't reload
      window.location.reload()
    })
  }, [])
  return null
}
