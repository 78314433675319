import {
  Resource,
  useFirestoreCollection,
  useFirestoreDoc,
} from '@iwikal/reactfire'
import { DialogContent } from '@mui/material'
import { DialogOverlay } from '@reach/dialog'
import { useAllUsers } from 'connect-shared/AllUsersContext'
import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'connect-shared/firestore'
import { CombinedFeedDocument, FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import produce from 'immer'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useColors } from '../Colors'
import { firestore } from '../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useTranslate } from '../hooks/Internationalization'
import useFeatureFlag from '../hooks/useFeatureFlag'
import { ReactComponent as CrossIcon } from '../icons/ico-close.svg'
import { ReactComponent as EventsIcon } from '../icons/ico-event.svg'
import { ReactComponent as FeedIcon } from '../icons/ico-feeds.svg'
import { ReactComponent as ProfileIconSvg } from '../icons/ico-profile.svg'
import { ReactComponent as SearchIcon } from '../icons/ico-search.svg'
import { Container } from '../layout/Layout'
import RoundAvatar from '../RoundAvatar'
import Sidebar from '../sidebar/Sidebar'
import SidebarContainer from '../sidebar/SidebarContainer'
import SidebarLink from '../sidebar/SidebarLink'
import SidebarSeparator from '../sidebar/SidebarSeparator'
import { BodyText } from '../Text'
import AddFeed from './addFeed/AddFeed'
import CreateNewFeedModal from './CreateNewFeedModal'

function FeedListItem({
  snap,
  id,
}: {
  id: string
  snap?: firebase.firestore.DocumentSnapshot<FeedDocument>
}) {
  // Keep the feed doc subscribed for less yank
  useFirestoreDoc(snap?.ref || firestore().collection('empty').doc('empty'))
  const t = useTranslate()

  const feed = snap?.data()
  const feedId = snap?.id
  const feedRef = useOrganizationDoc().collection('feeds').doc(id)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )
  const followerCount = followers.read().data()?.ids.length || 0

  if (!feed || !feedId) {
    return null
  }

  const basePath = `/feeds/${snap?.id}`

  return (
    <SidebarLink
      toOrActiveList={[
        `${basePath}/about`,
        `${basePath}/settings`,
        `${basePath}/members`,
      ]}
      subText={t('%{followerCount} followers', { followerCount })}
      AvatarComponent={
        <RoundAvatar size={30} avatar={feed.avatar} name={feed.name} />
      }
      text={feed?.name || ' '}
      to={'/feeds/' + id + '/posts'}
    />
  )
}

function PersonalFeedListItem({ userId }: { userId?: string }) {
  const { allUsers } = useAllUsers()
  const user = allUsers.get(userId || '')
  const t = useTranslate()

  const feedRef = useOrganizationDoc()
    .collection('feeds')
    .doc('personal_' + userId)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )
  const followerCount = followers.read().data()?.ids.length || 0

  return (
    <SidebarLink
      subText={t('%{followerCount} followers', { followerCount })}
      AvatarComponent={
        <RoundAvatar size={30} avatar={user?.avatar} name={user?.fullName} />
      }
      text={user?.fullName || ' '}
      to={'/users/' + userId}
    />
  )
}

export default function FeedMenu(props: {
  selectFeed: (feedId: string) => void
  combinedFeedRes: Resource<
    firebase.firestore.DocumentSnapshot<CombinedFeedDocument>
  >
}) {
  const [createNewFeedModalOpen, setCreateFeedModalOpen] = useState(false)
  const openNewFeedModal = useCallback(() => {
    setCreateFeedModalOpen(true)
  }, [])

  const closeNewFeedModal = useCallback(() => {
    setCreateFeedModalOpen(false)
  }, [])

  const colors = useColors()
  const t = useTranslate()
  const { selectFeed, combinedFeedRes } = props

  const currentUserId = useCurrentUserId()

  const [showAddFeed, setShowAddFeed] = useState(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const organizationDoc = useOrganizationDoc()

  const [feedSnaps, setFeedSnaps] = useState(
    new Map<string, firebase.firestore.DocumentSnapshot<FeedDocument>>()
  )

  useLayoutEffect(() => {
    const unsubscribers: (() => void)[] = []

    Object.keys(combinedFeedRes.read().data()?.subscriptions || {}).forEach(
      (feedId) => {
        unsubscribers.push(
          organizationDoc
            .collection('feeds')
            .doc(feedId)
            .onSnapshot((snap) => {
              setFeedSnaps(
                produce((nextFeedSnaps: typeof feedSnaps) => {
                  nextFeedSnaps.set(snap.id, snap)
                })
              )
            })
        )
      }
    )

    return () => {
      unsubscribers.map((unsubscribe) => {
        unsubscribe()
      })
    }
  }, [combinedFeedRes, organizationDoc])

  type Snap =
    | DocumentSnapshot<FeedDocument>
    | QueryDocumentSnapshot<FeedDocument>
    | undefined
  const sortAlphabetically = useCallback((aSnap: Snap, bSnap: Snap) => {
    const aName = aSnap?.data()?.name?.toLowerCase() || ''
    const bName = bSnap?.data()?.name?.toLowerCase() || ''
    if (!aName) return 1
    if (!bName) return -1
    return aName.localeCompare(bName)
  }, [])

  const feedIds = Object.keys(
    combinedFeedRes.read().data()?.subscriptions || {}
  )
  feedIds.sort((a, b) => {
    const aSnap = feedSnaps.get(a)
    const bSnap = feedSnaps.get(b)
    return sortAlphabetically(aSnap, bSnap)
  })

  const feedIdsWhereNotAdmin = feedIds.filter((id) => {
    if (id.startsWith('personal_')) return false
    const snap = feedSnaps.get(id)
    if (!snap) return

    const admins = Object.keys(snap.data()?.admins || {})

    return !admins.includes(currentUserId)
  })

  const feedsWhereAdminRef = organizationDoc
    .collection('feeds')
    .where(`admins.${currentUserId}`, '==', true)

  const feedsWhereAdmin = useFirestoreCollection(feedsWhereAdminRef)
    .read()
    .docs.sort(sortAlphabetically)

  const eventsInFeedsFlag = useFeatureFlag('eventsInFeeds', false)

  const personalFeedUserIds = Object.keys(
    combinedFeedRes.read().data()?.subscriptions || {}
  ).flatMap((feedId) => feedId.split('personal_').slice(1))

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)

  return (
    <>
      {showAddFeed && (
        <DialogOverlay
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          isOpen={showAddFeed}
        >
          <DialogContent
            aria-label="feedpostconfirmmodal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Container style={{ position: 'relative' }}>
              <Container
                style={{
                  width: 15,
                  height: 15,
                  position: 'absolute',
                  right: 5,
                  top: 5,
                }}
                onClick={() => setShowAddFeed(false)}
              >
                <CrossIcon />
              </Container>
              <AddFeed
                user={currentUserId}
                selectFeed={selectFeed}
                close={() => setShowAddFeed(false)}
              />
            </Container>
          </DialogContent>
        </DialogOverlay>
      )}
      <Sidebar
        style={{
          paddingTop: 30,
          width: 'auto',
          flex: 1,
          backgroundColor: colors.none,
        }}
      >
        <div
          ref={scrollRef}
          style={{ overflowY: 'auto', overflowX: 'hidden', paddingBottom: 20 }}
        >
          <SidebarLink
            Icon={FeedIcon}
            big
            text={t('Feeds')}
            to={'/feeds/all/posts'}
            id="navigate_combined_feeds"
          />
          <SidebarLink
            Icon={SearchIcon}
            big
            text={t('Find new feeds')}
            to={'/feeds/discover'}
          />
          {eventsInFeedsFlag && (
            <SidebarLink
              Icon={EventsIcon}
              big
              text={t('Events')}
              to={'/feeds/all/events'}
            />
          )}
          {hasFeaturePersonalFeeds && (
            <SidebarLink
              Icon={ProfileIconSvg}
              big
              text={t('People posts')}
              to={'/feeds/personal/events'}
            />
          )}
          <SidebarSeparator style={{ marginTop: 10 }} />
          {
            <SidebarContainer
              tooltip={t(
                'Here you can see the feeds where you are admin, you can edit settings, avatar and invite other users or admins'
              )}
              Icon={FeedIcon}
              text={t('Feeds you manage')}
              rightSideButton
              onRightSideClick={openNewFeedModal}
            >
              {feedsWhereAdmin.length === 0 ? (
                <BodyText
                  style={{ textAlign: 'center', color: colors.textDimHard }}
                >
                  {t("You don't manage any feeds")}
                </BodyText>
              ) : (
                feedsWhereAdmin.map((snap) => {
                  return <FeedListItem id={snap.id} snap={snap} key={snap.id} />
                })
              )}
            </SidebarContainer>
          }
          {!!feedIdsWhereNotAdmin.length && (
            <>
              <SidebarSeparator style={{ marginTop: 10 }} />
              <SidebarContainer text={t('Feeds you follow')}>
                {feedIdsWhereNotAdmin.map((id) => {
                  const snap = feedSnaps.get(id)
                  if (!snap) {
                    return null
                  }
                  return <FeedListItem id={snap.id} snap={snap} key={snap.id} />
                })}
              </SidebarContainer>
            </>
          )}
          {hasFeaturePersonalFeeds && !!personalFeedUserIds.length && (
            <>
              <SidebarSeparator style={{ marginTop: 10 }} />
              <SidebarContainer text={t('People you follow')}>
                {personalFeedUserIds.map((userId) => {
                  return <PersonalFeedListItem userId={userId} key={userId} />
                })}
              </SidebarContainer>
            </>
          )}
        </div>
      </Sidebar>
      <CreateNewFeedModal
        close={closeNewFeedModal}
        open={createNewFeedModalOpen}
      />
    </>
  )
}
