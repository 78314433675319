import { useFirestoreDoc } from '@iwikal/reactfire'
import { FeatureFlags, Organization, UserDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { useMemo } from 'react'
import { firestore } from '../firestore'
import { Resource } from '../resource'
import { useUser } from './useAccountInfo'

export function useIdTokenResult(): Resource<firebase.auth.IdTokenResult | null> {
  const resource = useUser()
  return {
    read: () => {
      return resource.read()?.idTokenResult || null
    },
  }
}

export default function useFeatureFlag(
  key: keyof FeatureFlags,
  defaultValue = false,
  forOrganizationId?: string
): boolean {
  const userToken = useUser().read()

  const { userId, organizationId } = useMemo(() => {
    const userTokenLocal = userToken

    if (forOrganizationId) {
      return {
        userId: undefined,
        organizationId: forOrganizationId,
      }
    }

    if (userTokenLocal?.idTokenResult?.claims.userType !== 'human') {
      return { userId: undefined, organizationId: undefined }
    } else {
      return {
        userId: userTokenLocal.user.uid,
        organizationId: userTokenLocal.idTokenResult.claims.organization,
      }
    }
  }, [forOrganizationId, userToken])

  const globalDoc = useMemo(() => {
    return firestore().collection('meta').doc('featureFlags')
  }, [])
  const globalSnapRes = useFirestoreDoc(globalDoc)

  const userDoc = useMemo(() => {
    return userId
      ? firestore().collection('users').doc(userId)
      : firestore().collection('empty').doc('empty')
  }, [userId])
  const userSnapRes = useFirestoreDoc(userDoc)

  const organizationDoc = useMemo(() => {
    return organizationId
      ? firestore().collection('organizations').doc(organizationId)
      : firestore().collection('empty').doc('empty')
  }, [organizationId])
  const orgSnapRes = useFirestoreDoc(organizationDoc)

  // --- hooks above, returns below ---
  const userData = userSnapRes.read().data() as UserDocument | undefined
  const userFlags = userData?.featureFlags
  const userFlag = userFlags?.[key]
  if (typeof userFlag === 'boolean') {
    return userFlag
  }

  const organizationData = orgSnapRes.read().data() as Organization | undefined
  const organizationFlags = organizationData?.featureFlags
  const organizationFlag = organizationFlags?.[key]
  if (typeof organizationFlag === 'boolean') {
    return organizationFlag
  }

  const globalFlags = globalSnapRes.read().data() as FeatureFlags | undefined
  const globalFlag = globalFlags?.[key]
  if (typeof globalFlag === 'boolean') {
    return globalFlag
  }

  return defaultValue
}
