import { useState, useCallback, useMemo } from 'react'
import { useColors } from '../Colors'
import { useFirestoreDoc } from '@iwikal/reactfire'
import {
  WorkplaceCalendarSettings,
  WorkplaceFeedSettings,
} from 'connect-shared/workplaces'
import { useOrganizationDoc } from '../hooks/auth'
import { Container, Pusher, Spacer } from '../layout/Layout'
import ClickableOpacity from '../ClickableOpacity'
import RoundAvatar from '../RoundAvatar'
import { BodyText } from '../Text'
import IconButton from '../IconButton'
import WorkplaceChannelInfoModal from './WorkplaceChannelInfoModal'
import { ReactComponent as RemoveIcon } from '../icons/ico-delete.svg'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'

export default function WorkplaceListItem({
  snap,
  onMouseEnter,
  onMouseLeave,
}: {
  snap: QueryDocumentSnapshot<WorkplaceFeedSettings | WorkplaceCalendarSettings>
  onMouseEnter: () => void
  onMouseLeave: () => void
}) {
  const colors = useColors()
  const [hovered, setHovered] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const isFeed = useMemo(() => {
    return snap.ref.path.split('/')[2] === 'workplaceFeedSettings'
  }, [snap.ref.path])
  const organizationDoc = useOrganizationDoc()
  const id = snap.id.split('_')[1]
  const itemRef = organizationDoc
    .collection(isFeed ? 'feeds' : 'calendars')
    .doc(id)
  const itemSnap = useFirestoreDoc(itemRef).read()
  const itemData = itemSnap.data()

  const handleHover = useCallback(() => {
    setHovered(true)
    onMouseEnter()
  }, [onMouseEnter])

  const handleUnHover = useCallback(() => {
    setHovered(false)
    onMouseLeave()
  }, [onMouseLeave])

  const workplaceItemSettingsDoc = snap.ref

  const workplaceItemSettings = snap.data()

  const updateForceSubscribe = useCallback(
    (forceSubscribe: boolean) => {
      workplaceItemSettingsDoc.update({ forceSubscribe: !forceSubscribe })
    },
    [workplaceItemSettingsDoc]
  )

  const updateAutoSubscribe = useCallback(
    (autoSubscribe: boolean) => {
      const updateData = autoSubscribe
        ? { autoSubscribe }
        : { autoSubscribe, forceSubscribe: false }
      workplaceItemSettingsDoc.update(updateData)
    },
    [workplaceItemSettingsDoc]
  )

  const removeItem = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()

      updateAutoSubscribe(false)
    },

    [updateAutoSubscribe]
  )

  if (!itemData) return null

  return (
    <Container
      onMouseEnter={handleHover}
      onMouseLeave={handleUnHover}
      onClick={() => setInfoModalOpen(true)}
      style={{
        padding: '20px 30px',
        cursor: 'pointer',
        backgroundColor: hovered ? colors.selected : colors.none,
        borderBottom: `1px solid ${colors.selected}`,
      }}
      horizontal
      vAlign="center"
      hAlign="left"
    >
      <ClickableOpacity>
        <RoundAvatar name={itemData.name} avatar={itemData.avatar} size={40} />
      </ClickableOpacity>
      <Spacer size={15} />
      <BodyText>{itemData.name}</BodyText>
      <Pusher />
      <IconButton onClick={(e) => removeItem(e)} Icon={RemoveIcon} size={24} />
      {infoModalOpen && workplaceItemSettings && (
        <WorkplaceChannelInfoModal
          isFeed={isFeed}
          canUnfollow={!workplaceItemSettings.forceSubscribe}
          onCanUnfollowToggle={updateForceSubscribe}
          removeItem={removeItem}
          close={() => setInfoModalOpen(false)}
          snap={itemSnap}
        />
      )}
    </Container>
  )
}
