import { ProgressiveImage as MaybeProgressiveImage } from 'connect-shared/types'
import firebase from 'firebase/app'
import 'firebase/storage'
import { Dispatch, useMemo } from 'react'
import { useCurrentUserId, useOrganizationId } from './auth'
import {
  addUpload,
  deleteUploads,
  removeUpload,
  Upload,
  UploadAction,
  useUploads,
} from './useUploads'

interface ImageInfo {
  width: number
  height: number
  uri: string
}

interface ProgressiveImage extends MaybeProgressiveImage {
  thumbnail: ImageInfo
}

export type ImageUpload = Upload<ProgressiveImage>
export type ImageUploadAction = UploadAction<ProgressiveImage>

/**
 * Start uploading an image file, adding it to the existing upload tasks.
 */
export async function addImage(
  storageRef: firebase.storage.Reference,
  image: File,
  setState: Dispatch<ImageUploadAction>
) {
  function loadImageElement(file: Blob): Promise<HTMLImageElement> {
    const element = new Image()
    return new Promise((resolve, reject) => {
      element.addEventListener('load', async () => resolve(element))
      element.addEventListener('error', async (evt) => reject(evt.error))
      element.src = URL.createObjectURL(file)
    })
  }

  function genThumbnail(image: HTMLImageElement): Promise<Blob> {
    const MAX_SIZE = 1500
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Failed to create context!')

    const { width, height } = image
    const aspectRatio = width / height

    if (width < MAX_SIZE && height < MAX_SIZE) {
      canvas.width = width
      canvas.height = height
    } else if (width > height) {
      canvas.width = MAX_SIZE
      canvas.height = MAX_SIZE / aspectRatio
    } else {
      canvas.width = MAX_SIZE * aspectRatio
      canvas.height = MAX_SIZE
    }

    ctx.drawImage(
      image,
      0,
      0,
      width,
      height, // source metrics
      0,
      0,
      canvas.width,
      canvas.height // dest metrics
    )

    return new Promise((resolve, reject) => {
      ctx.canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('could not create blob'))
          } else {
            resolve(blob)
          }
        },
        'image/png',
        0.95
      )
    })
  }

  return addUpload(image, setState, async (id, startUploadTask) => {
    const imageRef = storageRef.child(id).child(image.name)

    async function uploadThumbnail(): Promise<ImageInfo> {
      const imageElement = await loadImageElement(image)

      const thumbnailBlob = await genThumbnail(imageElement)
      const thumbnailRef = imageRef.child('thumbnail.png')
      await startUploadTask(thumbnailRef, thumbnailBlob)

      return {
        width: imageElement.width,
        height: imageElement.height,
        uri: await thumbnailRef.getDownloadURL(),
      }
    }

    const fullImageTask = startUploadTask(imageRef, image)
    const thumbnailPromise = uploadThumbnail()
    const elementPromise = loadImageElement(image)

    await fullImageTask

    const uri = await imageRef.getDownloadURL()
    const { width, height } = await elementPromise

    return {
      fullImage: { width, height, uri },
      thumbnail: await thumbnailPromise,
    }
  })
}

/**
 * Delete the upload and the state associated with it. This will cancel the
 * upload if still in progress, otherwise it will delete the already finished
 * upload from the file server.
 */
export async function removeImage(
  key: string,
  setState: Dispatch<ImageUploadAction>
) {
  return removeUpload(key, setState)
}

/**
 * Delete all uploads and the state associated with them. This will cancel
 * all upload tasks still in progress, and delete the already finished
 * uploads from the file server.
 */
export async function deleteImages(setState: Dispatch<ImageUploadAction>) {
  return deleteUploads(setState)
}

export default function useImageUploads() {
  return useUploads<ProgressiveImage>()
}

export function useChatImageRef() {
  const organizationId = useOrganizationId()
  const currentUserId = useCurrentUserId()

  return useMemo(() => {
    return firebase
      .storage()
      .ref()
      .child('organizations')
      .child(organizationId)
      .child('users')
      .child(currentUserId)
      .child('chat')
      .child('images')
  }, [currentUserId, organizationId])
}

export function useFeedImageRef(feedId: string, postId: string) {
  const organizationId = useOrganizationId()
  const currentUserId = useCurrentUserId()

  return useMemo(() => {
    return firebase
      .storage()
      .ref()
      .child('organizations')
      .child(organizationId)
      .child('users')
      .child(currentUserId)
      .child('feeds')
      .child(feedId)
      .child('posts')
      .child(postId)
      .child('images')
  }, [feedId, postId, currentUserId, organizationId])
}
