import { useFirestoreDoc } from '@iwikal/reactfire'
import { PlanMetadata, plans } from 'connect-shared/types'
import { emptyDoc, firestore } from '../firestore'
import { useAuthData } from './auth'

export function usePlanMetadata(orgId?: string): PlanMetadata {
  const { organizationId } = useAuthData(false)

  const fetchOrgId = orgId || organizationId

  const orgRef = fetchOrgId
    ? firestore().collection('organizations').doc(fetchOrgId)
    : emptyDoc
  const orgDoc = useFirestoreDoc(orgRef)

  const customPlanDoc = useFirestoreDoc(
    fetchOrgId ? orgRef.collection('extra').doc('customPlan') : emptyDoc
  )

  const planKey = orgDoc.read().data()?.plan

  if (!planKey) {
    return {}
  }

  if (planKey === 'custom') {
    const customPlan = customPlanDoc.read().data()
    if (customPlan) {
      return customPlan.metadata || {}
    }
  }

  const plan = plans[planKey]
  if (plan.metadata) {
    return plan.metadata
  }

  return {}
}
