import { Crop } from 'react-image-crop'

export type Upload = {
  file: File | Blob
  url: string
  img: HTMLImageElement
}

export function fileToUrl(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('error', reject)
    reader.addEventListener('abort', reject)
    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject('Result not string')
        }
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  })
}

function urlToImg(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = reject
    img.src = url
  })
}

export async function fileToUpload(file: File | Blob): Promise<Upload> {
  const url = await fileToUrl(file)
  const img = await urlToImg(url)
  return {
    file,
    url,
    img,
  }
}

function canvasToBlob(
  canvas: HTMLCanvasElement,
  filetype = 'image/jpeg' as 'image/png' | 'image/jpeg'
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject('Blob is null')
        }
      }, filetype)
    } catch (err) {
      reject(err)
    }
  })
}

function cropDimensions(
  width: number,
  height: number,
  targetWidth: number,
  targetHeight: number
) {
  const aspect = targetWidth / targetHeight
  if (height * aspect > width) {
    return [width, width / aspect]
  } else {
    return [height * aspect, height]
  }
}

export function initialCrop(
  { naturalWidth, naturalHeight, width, height }: HTMLImageElement,
  targetWidth: number,
  targetHeight: number
): Crop {
  const [cropWidth, cropHeight] = cropDimensions(
    naturalWidth,
    naturalHeight,
    targetWidth,
    targetHeight
  )
  const x = (naturalWidth - cropWidth) / 2
  const y = (naturalHeight - cropHeight) / 2

  const scaleX = width / naturalWidth
  const scaleY = height / naturalHeight

  return {
    aspect: targetWidth / targetHeight,
    width: cropWidth * scaleX,
    height: cropHeight * scaleY,
    x: x * scaleX,
    y: y * scaleY,
  }
}

function resultDimensions(
  width: number,
  height: number,
  targetWidth: number,
  targetHeight: number
) {
  const [cropWidth, cropHeight] = cropDimensions(
    width,
    height,
    targetWidth,
    targetHeight
  )
  if (cropWidth > targetWidth) {
    return [targetWidth, targetHeight]
  } else {
    return [cropWidth, cropHeight]
  }
}

export async function cropImage(
  upload: Upload,
  crop: Crop,
  cropImg: HTMLImageElement,
  targetWidth: number,
  targetHeight: number,

  filetype = 'image/jpeg' as 'image/png' | 'image/jpeg'
): Promise<Blob> {
  if (
    crop.x === undefined ||
    crop.y === undefined ||
    crop.width === undefined ||
    crop.height === undefined
  ) {
    throw new Error('Missing crop data')
  }

  const canvas = document.createElement('canvas')
  const [width, height] = resultDimensions(
    crop.width,
    crop.height,
    targetWidth,
    targetHeight
  )
  const scaleX = cropImg.naturalWidth / cropImg.width
  const scaleY = cropImg.naturalHeight / cropImg.height
  canvas.width = width || 0
  canvas.height = height || 0
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Null ctx')
  }

  ctx.drawImage(
    upload.img,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height
  )
  return canvasToBlob(canvas, filetype)
}

export function fitToAspectRatio(
  upload: Upload,
  targetAspect: number
): [number, number] {
  const { width, height } = upload.img

  const originalAspect = width / height
  if (originalAspect < targetAspect) {
    return [(width * targetAspect) / originalAspect, height]
  } else {
    return [width, (height * originalAspect) / targetAspect]
  }
}

export async function canvasSize(
  upload: Upload,
  targetWidth: number,
  targetHeight: number,
  filetype = 'image/jpeg' as 'image/png' | 'image/jpeg'
): Promise<Blob> {
  const { width, height } = upload.img

  const canvas = document.createElement('canvas')
  canvas.width = targetWidth
  canvas.height = targetHeight
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Null ctx')
  }

  const x = (targetWidth - width) / 2
  const y = (targetHeight - height) / 2

  ctx.drawImage(upload.img, x, y)
  return canvasToBlob(canvas, filetype)
}
