/* eslint-disable no-restricted-imports */
import Polyglot from 'node-polyglot'
import en from '../translations/en.json'
import sv from '../translations/sv.json'
import da from '../translations/da.json'
import {
  enGB as dateFnsEn,
  sv as dateFnsSv,
  da as dateFnsDa,
} from 'date-fns/locale'
import {
  formatRelative,
  formatDistance,
  formatDistanceToNow,
  format as dateFnsFormat,
} from 'date-fns'

function isLang(setting: string, lang: string) {
  return (
    setting.toLowerCase() === lang ||
    setting.toLowerCase().startsWith(lang + '-')
  )
}

function prefersLang(lang: string) {
  return isLang(navigator.language, lang)
}

function hasLang(lang: string) {
  return navigator.languages.some((setting) => isLang(setting, lang))
}

const langs = ['sv', 'da'] as const
const defaultLang = 'en'
export const language =
  langs.find(prefersLang) || langs.find(hasLang) || defaultLang

const polyglot = new Polyglot({
  locale: 'sv',
  phrases: { sv, da, en }[language],
  allowMissing: true,
  onMissingKey: (key, options) => {
    // Don't fill the console with warning about missing keys, git hooks and
    // CI is enough
    return Polyglot.transformPhrase(key, options)
  },
})

export function useTranslate() {
  return polyglot.t.bind(polyglot)
}

export function useDateFns() {
  const locale = { sv: dateFnsSv, da: dateFnsDa, en: dateFnsEn }[language]
  return {
    locale,
    format: (
      date: Date | number,
      format: string,
      options?: {
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        firstWeekContainsDate?: number
        useAdditionalWeekYearTokens?: boolean
        useAdditionalDayOfYearTokens?: boolean
      }
    ) => {
      return dateFnsFormat(date, format, {
        locale,
        ...options,
      })
    },
    formatRelative: (
      date: Date | number,
      baseDate: Date | number,
      options?: {
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      }
    ) => {
      return formatRelative(date, baseDate, {
        locale,
        ...options,
      })
    },
    formatDistance: (
      date: Date | number,
      baseDate: Date | number,
      options?: {
        includeSeconds?: boolean
        addSuffix?: boolean
      }
    ) => {
      return formatDistance(date, baseDate, {
        locale,
        ...options,
      })
    },
    formatDistanceToNow: (
      date: Date | number,
      options?: {
        includeSeconds?: boolean | undefined
        addSuffix?: boolean | undefined
        locale?: Locale | undefined
      }
    ) => {
      return formatDistanceToNow(date, {
        locale,
        ...options,
      })
    },
  }
}
