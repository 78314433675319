import { useColors } from './Colors'
import { Container, ContainerProps } from './layout/Layout'

export default function Card({
  flex = '0 0 auto',
  style,
  ...divProps
}: ContainerProps) {
  const colors = useColors()
  return (
    <Container
      style={{
        padding: 30,
        borderRadius: 5,
        borderColor: colors.border,
        backgroundColor: colors.foreground,
        flex,
        boxShadow: `0px 1px 3px #00000026`,
        ...style,
      }}
      {...divProps}
    />
  )
}
