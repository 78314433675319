import { CSSProperties, HTMLAttributes, useCallback } from 'react'
import BlockButton from './BlockButton'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import InlineBackButton from './InlineBackButton'
import { Container, Spacer } from './layout/Layout'
import { RightButtonProps } from './layout/PageLayoutLegacy'
import Separator from './layout/Separator'
import { BodyText, CaptionText } from './Text'

export type MenuButtonProps = {
  text: string
  onClick?: () => void
  selected?: boolean
}
type PageHeaderProps = {
  flex?: React.CSSProperties['flex']
  backButton?: boolean
  text?: string
  rightButton?: RightButtonProps
  menuButtons?: MenuButtonProps[]
  backButtonOnClick?: (e: React.MouseEvent<HTMLDivElement>) => void
} & HTMLAttributes<HTMLDivElement>

export default function PageHeader({
  backButtonOnClick,
  flex = '0 0 auto',
  style,
  backButton,
  text,
  rightButton,
  menuButtons,
  ...divProps
}: PageHeaderProps) {
  const handleRightButtonClick = useCallback(() => {
    rightButton?.onClick()
  }, [rightButton])

  return (
    <Container
      vAlign="center"
      style={{
        flex,
        ...style,
      }}
      {...divProps}
    >
      <Container horizontal vAlign="center">
        {backButton && (
          <InlineBackButton
            onClick={backButtonOnClick}
            style={{
              paddingRight: 5,
              alignSelf: 'stretch',
              alignItems: 'center',
            }}
          />
        )}
        <PageHeaderText flex={1} text={text} />

        {rightButton ? (
          <BlockButton onClick={handleRightButtonClick}>
            {rightButton.text}
          </BlockButton>
        ) : (
          <div style={{ height: 36 }} />
        )}
      </Container>
      <Spacer size={10} />
      {menuButtons && (
        <Container horizontal style={{ marginBottom: 0 }}>
          {menuButtons.map((menubutton, index) => {
            const { text, selected, onClick } = menubutton
            return (
              <PageHeaderMenuButton
                style={{ marginRight: 30 }}
                key={index}
                text={text}
                selected={selected}
                onClick={onClick}
              />
            )
          })}
        </Container>
      )}
      <Separator />
    </Container>
  )
}

type PageHeaderTextProps = {
  flex?: React.CSSProperties['flex']
  text?: string
} & HTMLAttributes<HTMLDivElement>

function PageHeaderText({
  flex = '0 0 auto',
  style,
  text,
  ...divProps
}: PageHeaderTextProps) {
  const colors = useColors()
  return (
    <div
      style={{
        width: '100%',
        fontWeight: 500,
        color: colors.textDim,
        flex,
        ...style,
      }}
      {...divProps}
    >
      <CaptionText
        style={{
          fontSize: 26,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </CaptionText>
    </div>
  )
}

function PageHeaderMenuButton({
  selected,
  text,
  onClick,
  style,
}: {
  selected?: boolean
  text: string
  onClick?: () => void
  style: CSSProperties
}) {
  const colors = useColors()
  return (
    <ClickableOpacity
      onClick={useCallback(() => {
        onClick && onClick()
      }, [onClick])}
      style={{
        borderBottom: selected
          ? `3px solid ${colors.active}`
          : `3px solid ${colors.none}`,
        ...style,
      }}
    >
      <BodyText
        style={{
          fontWeight: selected ? 600 : 400,
          fontSize: 14,
          color: selected ? colors.active : colors.menuUnselected,
        }}
      >
        {text}
      </BodyText>
    </ClickableOpacity>
  )
}
