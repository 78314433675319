import { useColors } from '../Colors'
import useUserObject from '../hooks/useUser'
import RoundUserAvatar from '../RoundUserAvatar'
import { userShortName } from 'connect-shared/User'
import HoverButton from '../HoverButton'

interface SelectedUserPillProps {
  userId: string
  clicked: (userID: string) => void
}

export default function SelectedUserPill(props: SelectedUserPillProps) {
  const colors = useColors()
  const user = useUserObject(props.userId)

  return (
    <HoverButton
      onClick={() => props.clicked(props.userId)}
      backgroundColor={colors.selected}
      hoverColor={colors.danger}
      key={props.userId}
      style={{
        padding: '0 10px 0 0',
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        borderRadius: 100,
        paddingRight: 10,
        marginRight: 8,
        marginTop: 12,
        color: colors.text,
      }}
    >
      <RoundUserAvatar userId={props.userId} size={28} />
      <p style={{ margin: '0 0 0 2px' }}>{userShortName(user)}</p>
    </HoverButton>
  )
}
