import styled from '@emotion/styled'

const UnderlineWrapper = styled.div<{
  unfocusedColor: string
  focusedColor: string
}>`
  border: 0;
  border-bottom: 2px solid ${(props) => props.unfocusedColor};

  :focus-within {
    border-bottom: 2px solid ${(props) => props.focusedColor};
  }
`
interface HoverableUnderlineWrapperProps {
  children: any
  unfocusedColor: string
  focusedColor: string
  style?: React.CSSProperties
}
export default function HoverableUnderlineWrapper({
  children,
  unfocusedColor,
  focusedColor,
  style,
}: HoverableUnderlineWrapperProps) {
  return (
    <UnderlineWrapper
      unfocusedColor={unfocusedColor}
      focusedColor={focusedColor}
      style={style}
    >
      {children}
    </UnderlineWrapper>
  )
}
