import { useState } from 'react'
import styled from '@emotion/styled'
import AddFeedSearch from './AddFeedSearch'
import CreateFeed from '../createFeed/CreateFeed'
import useOrganizationTheme from '../../hooks/useOrganizationColors'
import { useTranslate } from '../../hooks/Internationalization'

const StyledAddFeed = styled.div`
  background-color: #fff;
  .createFeedFormWrapper {
    max-height: 888px;
    .header {
      width: 100%;
      background: #222222;
      color: #fff;
      line-height: 68px;
      font-size: 20px;
      text-align: center;
    }
    .addFeedWrapper {
      padding: 30px;
    }
  }
`
const AddFeed = (props: {
  user: string
  selectFeed: (feedId: string) => void
  close: () => void
}) => {
  const t = useTranslate()
  const [showCreateFeed, setShowCreateFeed] = useState(false)

  const handleShowCreateFeed = () => {
    setShowCreateFeed(!showCreateFeed)
  }
  const { organizationStyle } = useOrganizationTheme()
  return (
    <StyledAddFeed>
      <div className="createFeedFormWrapper">
        <div style={organizationStyle} className="header">
          {t('Add feed')}
        </div>
        {showCreateFeed && (
          <CreateFeed
            user={props.user}
            handleShowCreateFeed={handleShowCreateFeed}
            close={props.close}
          />
        )}
        {!showCreateFeed && (
          <div className="addFeedWrapper">
            <AddFeedSearch
              user={props.user}
              handleShowCreateFeed={handleShowCreateFeed}
              selectFeed={props.selectFeed}
              close={props.close}
            />
          </div>
        )}
      </div>
    </StyledAddFeed>
  )
}

export default AddFeed
