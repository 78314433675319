import { Box, Button, Modal } from '@mui/material'
import User from 'connect-shared/User'
import { addDays, isSameDay, isSameYear } from 'date-fns'
import firebase from 'firebase/app'
import 'firebase/storage'
import React, { useCallback, useRef, useState, useTransition } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Img } from 'react-suspense-img'
import uuid from 'uuid'
import { useColors } from '../Colors'
import {
  useCurrentUserId,
  useOrganizationDoc,
  useOrganizationId,
} from '../hooks/auth'
import useMayEditProfile from '../hooks/useMayEditProfile'
import { ReactComponent as CheckIcon } from '../icons/ico-check.svg'
import { ReactComponent as PhotoAlbumIcon } from '../icons/ico-add-image.svg'
import { Container, Pusher, Spacer } from '../layout/Layout'
import ModalCloseButton from '../ModalCloseButton'
import StatusBadge from '../StatusBadge'
import { HeadingText } from '../Text'
import { showErrorToast } from '../Toast'
import coverImages from './coverImages'
import { cropImage, fileToUpload, initialCrop, Upload } from '../crop-utils'
import useAvailabilityEnabled from '../hooks/useAvailabilityEnabled'
import { useDateFns, useTranslate } from '../hooks/Internationalization'
import { useSubscriptions } from '../hooks/feeds'
import useFeatureFlag from '../hooks/useFeatureFlag'

function CoverPhotoUpload({ onFile }: { onFile?: (file: File) => void }) {
  const colors = useColors()
  const t = useTranslate()
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      file && onFile?.(file)
    },
    [onFile]
  )

  return (
    <label
      css={{
        width: 240,
        height: 140,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: colors.accent,
        cursor: 'pointer',
        margin: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: colors.accentHover,
        },
      }}
    >
      <input
        type="file"
        accept="image/png, image/jpeg"
        style={{
          display: 'none',
        }}
        onChange={handleChange}
      />
      <PhotoAlbumIcon fill={colors.accent} height={62} />
      <Spacer size={12} />
      <span
        style={{
          color: colors.accent,
          fontSize: 15,
        }}
      >
        {t('Upload cover image')}
      </span>
    </label>
  )
}

function CoverPhotoGalleryImage({
  url,
  onClick,
  active,
  className,
}: {
  url: string
  onClick?: (url: string) => void
  active?: boolean
  className?: string
}) {
  const colors = useColors()
  const handleClick = useCallback(() => {
    onClick?.(url)
  }, [onClick, url])
  return (
    <Container
      style={{
        position: 'relative',
      }}
      className={className}
    >
      <Img
        css={{
          borderRadius: 5,
          margin: 5,
          width: 240,
          height: 140,
          objectFit: 'cover',
          objectPosition: 'center',
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.7,
          },
        }}
        src={url}
        onClick={handleClick}
      />
      {active && (
        <Container
          style={{
            position: 'absolute',
            top: 15,
            right: 15,
            backgroundColor: colors.success,
            borderRadius: 100,
            pointerEvents: 'none',
            display: 'flex',
          }}
          hAlign={'center'}
          vAlign={'center'}
        >
          <CheckIcon fillColor="#fff" height={20} width={20} />
        </Container>
      )}
    </Container>
  )
}

export function CoverPhotoGalleryPreloader() {
  return (
    <>
      {coverImages.map((url, i) => {
        return <Img style={{ display: 'none' }} src={url} key={i} />
      })}
    </>
  )
}

function CoverPhotoGallery({
  onFile,
  onUrl,
  currentUrl,
}: {
  onFile?: (file: File) => void
  onUrl?: (url: string) => void
  currentUrl?: string
}) {
  return (
    <Container horizontal style={{ flexWrap: 'wrap' }} hAlign="center">
      <CoverPhotoUpload onFile={onFile} />
      {coverImages.map((url, i) => {
        return (
          <CoverPhotoGalleryImage
            active={url === currentUrl}
            key={i}
            url={url}
            onClick={onUrl}
            className={'cover_photo_' + i}
          />
        )
      })}
    </Container>
  )
}

function CoverPhotoCrop({
  upload,
  onConfirm,
  onCancel,
  user,
}: {
  upload: Upload
  onConfirm?: () => void
  onCancel?: () => void
  user: User
}) {
  const t = useTranslate()
  const targetWidth = 1200
  const targetHeight = 750
  const [crop, setCrop] = useState<Crop>({
    aspect: targetWidth / targetHeight,
  })

  const imageRef = useRef<HTMLImageElement>()
  const handleImageLoaded = useCallback((image: HTMLImageElement) => {
    imageRef.current = image
    setTimeout(() => {
      setCrop(initialCrop(image, targetWidth, targetHeight))
    }, 0)
  }, [])

  const organizationId = useOrganizationId()
  const handleConfirm = useCallback(async () => {
    async function callback() {
      if (!imageRef.current) {
        throw new Error('Missing imageRef')
      }

      const blob = await cropImage(
        upload,
        crop,
        imageRef.current,
        targetWidth,
        targetHeight
      )

      const ref = firebase
        .app()
        .storage()
        .ref('organizations')
        .child(organizationId)
        .child('users')
        .child(user.id)
        .child('cover_images')
        .child(uuid.v4())

      await ref.put(blob, {
        cacheControl: 'private, max-age=604800, immutable',
      })
      const url = await ref.getDownloadURL()
      await user.ref.update({ coverImage: url })
      onConfirm?.()
    }
    try {
      await callback()
    } catch (err) {
      console.error(err)
      showErrorToast(t('Failed to save cover photo'))
    }
  }, [crop, onConfirm, organizationId, t, upload, user.id, user.ref])

  return (
    <Container>
      <ReactCrop
        src={upload.url}
        style={{ alignSelf: 'center' }}
        imageStyle={{ maxHeight: 'calc(100vh - 146px)' }}
        onImageLoaded={handleImageLoaded}
        crop={crop}
        onChange={setCrop}
      />
      <Spacer size={20} />
      <Container horizontal>
        <Pusher />
        <Button onClick={onCancel}>Cancel</Button>
        <Spacer size={20} />
        <Button variant="outlined" onClick={handleConfirm} color="primary">
          {t('Save')}
        </Button>
      </Container>
    </Container>
  )
}

function CoverPhotoPicker({
  user,
  onRequestClose,
}: {
  user: User
  onRequestClose: () => void
}) {
  const colors = useColors()
  const t = useTranslate()
  const [upload, setUpload] = useState<Upload>()

  const saveImage = useCallback(
    (url: string) => {
      user.ref.update({ coverImage: url })
      onRequestClose()
    },
    [onRequestClose, user.ref]
  )

  const handleFile = useCallback(async (file: File) => {
    setUpload(await fileToUpload(file))
  }, [])

  const cancelCrop = useCallback(() => {
    setUpload(undefined)
  }, [])

  return (
    <Modal
      open
      onClose={onRequestClose}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Container
        style={{
          flex: '0 1 800px',
          padding: 25,
          borderRadius: 10,
          backgroundColor: colors.foreground,
          maxHeight: '100%',
          position: 'relative',
        }}
      >
        <ModalCloseButton onClick={onRequestClose} />
        <Container flex="1">
          <HeadingText>{t('Edit cover photo')}</HeadingText>
          <Spacer size={15} />
          {upload ? (
            <CoverPhotoCrop
              onConfirm={onRequestClose}
              onCancel={cancelCrop}
              upload={upload}
              user={user}
            />
          ) : (
            <CoverPhotoGallery
              currentUrl={user.coverImage}
              onFile={handleFile}
              onUrl={saveImage}
            />
          )}
        </Container>
      </Container>
    </Modal>
  )
}

export function formatDate(
  format: ReturnType<typeof useDateFns>['format'],
  date: Date
) {
  const sameDay = isSameDay(date, new Date())
  const fewDaysAgo = addDays(new Date(), -6) < date
  const sameYear = isSameYear(date, new Date())

  if (sameDay) {
    return format(date, 'HH:mm')
  } else if (fewDaysAgo) {
    return format(date, 'eeee HH:mm')
  } else if (sameYear) {
    return format(date, 'MMMM do HH:mm')
  } else {
    return format(date, 'MMMM do, yyyy HH:mm')
  }
}

function ToggleUserFollowButton({ userId }: { userId: string }) {
  const orgDoc = useOrganizationDoc()
  const feedId = 'personal_' + userId
  const currentUserId = useCurrentUserId()
  const subscriptions = useSubscriptions()
  const isFollowing = subscriptions.read().has(feedId)
  const [loading, setLoading] = useState(false)

  const toggleFollow = useCallback(async () => {
    try {
      if (isFollowing) {
        const combinedFeedRef = orgDoc
          .collection('combinedFeeds')
          .doc(currentUserId)
        await combinedFeedRef.update(
          ['subscriptions', feedId].join('.'),
          firebase.firestore.FieldValue.delete()
        )
      } else {
        setLoading(true)
        const subscribe = firebase
          .app()
          .functions('europe-west1')
          .httpsCallable('addCombinedFeedSubscriptions')

        await subscribe({
          combinedFeedIds: [currentUserId],
          feedIds: [feedId],
        })
      }
    } catch (err) {
      showErrorToast()
      console.error(err)
    }
    setLoading(false)
  }, [currentUserId, feedId, isFollowing, orgDoc])

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)
  if (!hasFeaturePersonalFeeds) return null

  return (
    <Button
      disabled={loading}
      color={isFollowing ? 'info' : 'success'}
      variant={'contained'}
      onClick={toggleFollow}
    >
      {isFollowing ? 'Unfollow' : 'Follow'}
    </Button>
  )
}

export default function ProfileCover({ user }: { user: User }) {
  const t = useTranslate()
  const mayEdit = useMayEditProfile(user.id)
  const [pickerOpen, setPickerOpen] = useState(false)

  const [, runTransition] = useTransition()
  const closePicker = useCallback(() => {
    setPickerOpen(false)
  }, [])

  const openPicker = useCallback(() => {
    runTransition(() => {
      setPickerOpen(true)
    })
  }, [runTransition])

  const availabilityEnabled = useAvailabilityEnabled().read()

  const { format } = useDateFns()
  const currentUserId = useCurrentUserId()

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)

  return (
    <>
      {pickerOpen && (
        <CoverPhotoPicker user={user} onRequestClose={closePicker} />
      )}
      <Container
        css={{
          position: 'relative',
          '& .pickerButton': {
            display: 'none',
          },
          '&:hover': {
            '& .pickerButton': {
              display: 'block',
            },
          },
          minHeight: 100,
        }}
      >
        <Img
          src={user.coverImage || coverImages[1]}
          className="cover_image"
          style={{
            height: 250,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        {hasFeaturePersonalFeeds && user.id !== currentUserId && (
          <Box position="absolute" top={20} right={20}>
            <ToggleUserFollowButton userId={user.id} />
          </Box>
        )}

        <Container
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            left: 0,
            background:
              'linear-gradient(0deg,  hsl(0, 0%, 0%) 0%,  hsla(0, 0%, 0%, 0.738) 19%,  hsla(0, 0%, 0%, 0.541) 34%,  hsla(0, 0%, 0%, 0.382) 47%,  hsla(0, 0%, 0%, 0.278) 56.5%,  hsla(0, 0%, 0%, 0.194) 65%,  hsla(0, 0%, 0%, 0.126) 73%,  hsla(0, 0%, 0%, 0.075) 80.2%,  hsla(0, 0%, 0%, 0.042) 86.1%,  hsla(0, 0%, 0%, 0.021) 91%,  hsla(0, 0%, 0%, 0.008) 95.2%,  hsla(0, 0%, 0%, 0.002) 98.2%,  hsla(0, 0%, 0%, 0) 100%)',
            height: 125,
            backgroundSize: 'cover',
          }}
        />
        <Container
          style={{
            position: 'absolute',
            right: 20,
            bottom: 0,
            left: 20,
          }}
        >
          {availabilityEnabled && (
            <Container horizontal vAlign="center">
              <StatusBadge
                style={{ marginTop: 10, marginBottom: 10 }}
                statusKey={user.status}
              />
              <Spacer size={8} />
              {user.lastStatusChangeTimestamp && (
                <span
                  style={{
                    fontSize: 13,
                    color: '#fff',
                  }}
                >
                  {t('since %{distance}', {
                    distance: formatDate(
                      format,
                      user.lastStatusChangeTimestamp
                    ),
                  })}
                </span>
              )}
              <Pusher />
              {mayEdit && (
                <Button
                  className="pickerButton"
                  onClick={openPicker}
                  variant="outlined"
                  style={{
                    fontSize: 13,
                    textTransform: 'none',
                    color: '#fff',
                    borderColor: '#fff',
                  }}
                >
                  {t('Edit cover photo')}
                </Button>
              )}
            </Container>
          )}
          <span style={{ fontSize: 13, color: '#fff' }}>{user.statusText}</span>
          <Spacer size={10} />
        </Container>
      </Container>
    </>
  )
}
