import styled from '@emotion/styled'
import { Container, ContainerProps } from './layout/Layout'

const Wrapper = styled.div<{ hoverColor: string; backgroundColor }>`
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`

type HoverableContainerProps = {
  hoverColor: string
  backgroundColor?: string
  children?: React.ReactNode
  style?: React.CSSProperties
} & ContainerProps
export default function HoverableContainer({
  backgroundColor = 'transparent',
  hoverColor,
  children,
  style,
  ...containerProps
}: HoverableContainerProps) {
  return (
    <Wrapper
      style={style}
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
    >
      <Container {...containerProps}>{children}</Container>
    </Wrapper>
  )
}
