import { DialogContent, DialogOverlay } from '@reach/dialog'
import { useCallback, useState } from 'react'
import BlockButton from './BlockButton'
import { useColors } from './Colors'
import IconButton from './IconButton'
import { Container } from './layout/Layout'
import { CaptionText } from './Text'
import TextInput from './TextInput'
import { ReactComponent as CloseIcon } from './icons/closeModal.svg'
import { css } from '@emotion/react'
import ClickableOpacity from './ClickableOpacity'
import { useTranslate } from './hooks/Internationalization'

type InputModalProps = {
  close: () => void
  onSubmit: (value: string) => void
  title: string
  placeholder?: string
  value?: string
}

export default function InputModal({
  onSubmit,
  close,
  title,
  placeholder,
  value = '',
}: InputModalProps) {
  const [inputValue, setInputValue] = useState<string>(value)
  const colors = useColors()
  const t = useTranslate()
  return (
    <DialogOverlay
      onDismiss={close}
      aria-label=" "
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.webChatTransparentBackground,
      }}
    >
      <DialogContent
        style={{
          position: 'relative',
          width: 460,
          backgroundColor: colors.webChatModalBackground,
          borderRadius: 10,
        }}
        aria-label=" "
      >
        <CaptionText
          style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}
        >
          {title}
        </CaptionText>
        <TextInput
          onKeyDown={useCallback(
            (e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                onSubmit(inputValue)
                close()
              }
            },
            [onSubmit, inputValue, close]
          )}
          placeholder={placeholder}
          value={inputValue}
          onChangeText={setInputValue}
        />
        <Container horizontal hAlign="right">
          <ClickableOpacity
            css={css`
              color: ${colors.label};

              &:hover {
                color: ${colors.danger};
              }
            `}
            style={{
              fontWeight: 600,
              fontSize: 16,
              marginRight: 20,
            }}
            onClick={close}
          >
            {t('Cancel')}
          </ClickableOpacity>
          <BlockButton
            onClick={useCallback(() => {
              onSubmit(inputValue)
              close()
            }, [onSubmit, inputValue, close])}
          >
            {t('Ok')}
          </BlockButton>
        </Container>
        <IconButton
          size={40}
          Icon={CloseIcon}
          onClick={close}
          style={{
            position: 'absolute',
            top: -40,
            right: -40,
          }}
        />
      </DialogContent>
    </DialogOverlay>
  )
}
