import { CSSProperties, ReactNode } from 'react'
import ClickableOpacity, { ClickableOpacityProps } from './ClickableOpacity'
import { useColors } from './Colors'
import HoverableContainer from './HoverableContainer'

type OutlineButtonProps = {
  onClick?: (e: MouseEvent) => void
  children: ReactNode
  disabled?: boolean
  style?: CSSProperties
  danger?: boolean
} & ClickableOpacityProps

export default function OutlineButton({
  onClick,
  children,
  disabled,
  style,
  danger,
  ...props
}: OutlineButtonProps) {
  const colors = useColors()
  return (
    <ClickableOpacity disabled={disabled} onClick={onClick} {...props}>
      <HoverableContainer
        style={{
          fontWeight: 600,
          opacity: disabled ? 0.4 : 1,
          padding: '9px 20px',
          borderRadius: 5,
          border: `1px solid ${danger ? colors.danger : colors.active}`,
          color: danger ? colors.danger : colors.active,
          cursor: disabled ? 'default' : 'pointer',
          ...style,
        }}
        hoverColor={
          disabled && !danger
            ? colors.none
            : !disabled && danger
            ? 'rgba(255,0,0,0.2)'
            : colors.actionButtonHover
        }
      >
        {children}
      </HoverableContainer>
    </ClickableOpacity>
  )
}
