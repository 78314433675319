import TextField from '@mui/material/TextField'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { startOfSecond } from 'date-fns'
import { useCallback } from 'react'
import { Container, Spacer } from '../../../layout/Layout'

type DateTimePickerProps = {
  value: Date
  timeFirst?: boolean
  showTime?: boolean
  error?: boolean
  onChange: (date: Date) => void
}

export default function DateTimePicker({
  value,
  timeFirst,
  showTime,
  error,
  onChange,
}: DateTimePickerProps) {
  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (!date) return
      onChange(date)
    },
    [onChange]
  )

  const handleTimeChange = useCallback(
    (date: Date | null) => {
      if (!date) return
      onChange(startOfSecond(date))
    },
    [onChange]
  )

  return (
    <Container
      style={{
        flexDirection: timeFirst ? 'row-reverse' : 'row',
      }}
    >
      <DatePicker
        disableMaskedInput
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            style={{ width: 170, display: 'flex' }}
            variant="standard"
          />
        )}
        inputFormat="dd MMM yyyy"
        value={value}
        onChange={handleDateChange}
      />
      {showTime && (
        <>
          <Spacer size={12} />
          <TimePicker
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: 115 }}
                variant="standard"
              />
            )}
            ampm={false}
            value={value}
            onChange={handleTimeChange}
          />
        </>
      )}
    </Container>
  )
}
