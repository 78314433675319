import firebase from 'firebase/app'
import { useColors } from './Colors'
import { CSSProperties, useCallback, useState } from 'react'
import { sendVerificationEmail } from './cloud-functions'
import { showErrorToast } from './Toast'
import { css } from '@emotion/css'
import { isMobile } from 'react-device-detect'
import SignUpLayout from './SignUpLayout'
import { Container, Spacer } from './layout/Layout'
import { BodyText } from './Text'
import LinkText from './LinkText'
import TextInput from './TextInput'
import ClickableOpacity from './ClickableOpacity'
import BlockButton from './BlockButton'
import { useTranslate } from './hooks/Internationalization'

export default function SignUp() {
  const colors = useColors()
  const t = useTranslate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState<boolean | string>(false)
  const [emailError, setEmailError] = useState<boolean | string>(false)
  const [acceptedTosAndPrivacy, setAcceptedTosAndPrivacy] = useState(false)
  const [acceptDataAgreement, setAcceptDataAgreement] = useState(false)
  const [acceptError, setAcceptError] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    setAcceptError(false)
    setEmailError(false)
    setPasswordError(false)
    if (
      password !== confirmPassword ||
      password === '' ||
      confirmPassword === ''
    ) {
      setPasswordError('Passwords do not match')
      return
    }

    setAcceptError(false)
    if (!acceptDataAgreement || !acceptedTosAndPrivacy) {
      setAcceptError('Required')
      return
    }

    setLoading(true)
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
      await sendVerificationEmail()
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setEmailError(err.message)
      } else if (err.code === 'auth/invalid-email') {
        setEmailError(err.message)
      } else if (err.code === 'auth/weak-password') {
        setPasswordError(err.message)
      } else {
        showErrorToast(err.message)
      }
    }
    setLoading(false)
  }, [
    email,
    password,
    confirmPassword,
    acceptedTosAndPrivacy,
    acceptDataAgreement,
  ])

  const hoverCSS = css`
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `

  const linkStyle: CSSProperties = {
    marginLeft: 5,
    color: colors.beeworkBlue,
    cursor: 'pointer',
    fontFamily: 'Work sans, sans-serif',
  }

  const fontSize = isMobile ? 32 : 16

  return (
    <SignUpLayout
      caption={t('Create account')}
      footer={
        <Container style={{ marginTop: 24 }} hAlign="center">
          <BodyText
            style={{
              fontSize,
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t('Do you already have an account?')}
          </BodyText>
          <LinkText to="/sign-in" style={{ fontSize }}>
            {t('Sign in')}
          </LinkText>
        </Container>
      }
    >
      <Container>
        <Spacer size={isMobile ? 50 : 0} />
        <TextInput
          size={isMobile ? 40 : undefined}
          placeholder="Your email"
          error={emailError}
          label={t('Email address')}
          labelStyle={{
            fontSize: isMobile ? 40 : 20,
            fontFamily: 'Work sans, sans-serif',
          }}
          className="email"
          value={email}
          inputStyle={{
            fontFamily: 'Work sans, sans-serif',
          }}
          onChange={useCallback(
            (e) => setEmail(e.target.value.replace(/[^a-zA-Z0-9_+\-@.]/g, '')),
            []
          )}
        />
        <TextInput
          size={isMobile ? 40 : undefined}
          placeholder={t('Set a password')}
          label={t('Password')}
          className="password"
          type="password"
          inputStyle={{
            fontFamily: 'Work sans, sans-serif',
          }}
          labelStyle={{
            fontSize: isMobile ? 40 : 20,
            fontFamily: 'Work sans, sans-serif',
          }}
          value={password}
          noErrorPlaceholder
          style={{ marginBottom: 10 }}
          onChange={useCallback((e) => setPassword(e.target.value), [])}
          error={!!passwordError}
        />
        <TextInput
          size={isMobile ? 40 : undefined}
          placeholder={t('Repeat password')}
          className="confirm-password"
          labelStyle={{
            fontSize: isMobile ? 40 : 20,
            fontFamily: 'Work sans, sans-serif',
          }}
          type="password"
          value={confirmPassword}
          inputStyle={{
            fontFamily: 'Work sans, sans-serif',
          }}
          onChange={useCallback((e) => setConfirmPassword(e.target.value), [])}
          error={passwordError}
        />
        <ClickableOpacity
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: isMobile ? 18 : undefined,
            fontSize: isMobile ? 28 : 14,
            userSelect: 'none',
            marginBottom: 14,
          }}
        >
          <input
            defaultChecked={acceptedTosAndPrivacy}
            checked={acceptedTosAndPrivacy}
            onChange={() => setAcceptedTosAndPrivacy(!acceptedTosAndPrivacy)}
            style={{
              height: isMobile ? 48 : 16,
              width: isMobile ? 48 : 16,
              marginRight: isMobile ? 20 : 8,
            }}
            type="checkbox"
          />
          {(() => {
            const [accept, termsOfServices, and, privacyPolicy] = t(
              'I accept the|Terms of services|&|Privacy policy'
            ).split('|')
            const termsOfServicesURL = t(
              'https://playipp.com/en/terms-and-conditions/'
            )
            const privacyPolicyURL = t('https://playipp.com/en/privacy-policy/')
            privacyPolicyURL
            return (
              <span
                style={{
                  fontSize: isMobile ? 28 : 14,
                  color: colors.beeworkBlue,
                }}
              >
                <span
                  onClick={() =>
                    setAcceptedTosAndPrivacy(!acceptedTosAndPrivacy)
                  }
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {accept}
                </span>
                <a
                  css={hoverCSS}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={termsOfServicesURL}
                  style={{
                    marginRight: 5,
                    whiteSpace: 'nowrap',
                    ...linkStyle,
                  }}
                >
                  {termsOfServices}
                </a>
                {and}
                <a
                  css={hoverCSS}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={privacyPolicyURL}
                  style={{ whiteSpace: 'nowrap', ...linkStyle }}
                >
                  {privacyPolicy}
                </a>
              </span>
            )
          })()}
        </ClickableOpacity>
        <div
          style={{
            marginTop: isMobile ? 24 : undefined,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            fontSize: isMobile ? 28 : 14,
            userSelect: 'none',
          }}
        >
          <input
            defaultChecked={acceptDataAgreement}
            checked={acceptDataAgreement}
            onChange={() => setAcceptDataAgreement(!acceptDataAgreement)}
            style={{
              height: isMobile ? 48 : 16,
              width: isMobile ? 48 : 16,
              marginRight: isMobile ? 20 : 8,
            }}
            type="checkbox"
          />
          {(() => {
            const [accept, dataProcessingAgreement] = t(
              'I accept the|Data processing agreement'
            ).split('|')
            const dataProcessingAgreementURL = t(
              'https://playipp.com/en/data-processing-agreement/'
            )

            return (
              <span
                style={{
                  fontSize: isMobile ? 28 : 14,
                  color: colors.beeworkBlue,
                }}
              >
                <span
                  onClick={() => setAcceptDataAgreement(!acceptDataAgreement)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {accept}
                </span>
                <a
                  css={hoverCSS}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={dataProcessingAgreementURL}
                  style={{ whiteSpace: 'nowrap', ...linkStyle }}
                >
                  {dataProcessingAgreement}
                </a>
              </span>
            )
          })()}
        </div>
        <Container
          hAlign="center"
          style={{
            marginTop: isMobile ? 40 : 20,
          }}
        >
          <BlockButton
            className="submit"
            type="submit"
            style={{
              backgroundColor: colors.beeworkGold,
              color: colors.beeworkBlue,
              borderRadius: 27,
              padding: isMobile ? 40 : undefined,
              fontSize: isMobile ? 32 : 16,
            }}
            onClick={handleSubmit}
            disabled={
              !email ||
              !password ||
              !confirmPassword ||
              !acceptedTosAndPrivacy ||
              !acceptDataAgreement ||
              loading
            }
          >
            {t('Continue')}
          </BlockButton>
        </Container>
        {acceptError !== false && (
          <div
            style={{
              marginTop: 10,
              height: 20,
              color: colors.danger,
              fontSize: 14,
            }}
          >
            {'* ' + acceptError}
          </div>
        )}
      </Container>
    </SignUpLayout>
  )
}
