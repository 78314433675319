import './App.css'
import { createTheme } from '@mui/material'
import { LinkProps } from '@mui/material/Link'
import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(function LinkBehavior(props, ref) {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

const primaryMain = '#0f3951'

const materialTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
    h1: {
      color: primaryMain,
      fontWeight: 600,
    },
    h2: {
      color: primaryMain,
      fontWeight: 600,
    },
    h3: {
      color: primaryMain,
      fontWeight: 600,
    },
    h4: {
      color: primaryMain,
      fontWeight: 600,
      fontSize: 32,
    },
    h5: {
      color: primaryMain,
      fontWeight: 600,
    },
    subtitle1: {
      color: primaryMain,
    },
  },
  shadows: [
    'none',
    '0px 4px 7px rgba(0, 0, 0, 0.13), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.05);',
    '0px 7px 14px rgba(0, 0, 0, 0.13), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.05);',
    '0px 12px 21px rgba(0, 0, 0, 0.13), 0px 5px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);',
    '0px 16px 28px rgba(0, 0, 0, 0.13), 0px 7px 8px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.05);',
    '0px 20px 35px rgba(0, 0, 0, 0.13), 0px 7px 10px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.05);',
    '0px 24px 42px rgba(0, 0, 0, 0.13), 0px 9px 12px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.05);',
    '0px 28px 49px rgba(0, 0, 0, 0.13), 0px 11px 14px rgba(0, 0, 0, 0.1), 0px 2px 7px rgba(0, 0, 0, 0.05);',
    '0px 32px 56px rgba(0, 0, 0, 0.13), 0px 12px 16px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.05);',
    '0px 36px 63px rgba(0, 0, 0, 0.13), 0px 14px 18px 1px rgba(0, 0, 0, 0.1), 0px 3px 9px rgba(0, 0, 0, 0.05);',
    '0px 40px 70px rgba(0, 0, 0, 0.13), 0px 16px 20px 1px rgba(0, 0, 0, 0.1), 0px 3px 10px rgba(0, 0, 0, 0.05);',
    '0px 44px 77px rgba(0, 0, 0, 0.13), 0px 16px 22px rgba(0, 0, 0, 0.1), 0px 4px 11px rgba(0, 0, 0, 0.05);',
    '0px 48px 84px rgba(0, 0, 0, 0.13), 0px 18px 24px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.05);',
    '0px 52px 91px rgba(0, 0, 0, 0.13), 0px 20px 26px rgba(0, 0, 0, 0.1), 0px 4px 13px rgba(0, 0, 0, 0.05);',
    '0px 56px 98px rgba(0, 0, 0, 0.13), 0px 21px 28px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.05);',
    '0px 60px 105px rgba(0, 0, 0, 0.13), 0px 23px 30px rgba(0, 0, 0, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.05);',
    '0px 64px 112px rgba(0, 0, 0, 0.13), 0px 25px 32px rgba(0, 0, 0, 0.1), 0px 5px 16px rgba(0, 0, 0, 0.05);',
    '0px 68px 119px rgba(0, 0, 0, 0.13), 0px 26px 34px rgba(0, 0, 0, 0.1), 0px 5px 17px 5px rgba(0, 0, 0, 0.05);',
    '0px 72px 126px rgba(0, 0, 0, 0.13), 0px 28px 36px rgba(0, 0, 0, 0.1), 0px 6px 18px rgba(0, 0, 0, 0.05);',
    '0px 76px 133px rgba(0, 0, 0, 0.13), 0px 30px 38px rgba(0, 0, 0, 0.1), 0px 6px 19px rgba(0, 0, 0, 0.05);',
    '0px 79px 140px rgba(0, 0, 0, 0.13), 0px 30px 40px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.05);',
    '0px 84px 147px rgba(0, 0, 0, 0.13), 0px 32px 42px rgba(0, 0, 0, 0.1), 0px 7px 21px rgba(0, 0, 0, 0.05);',
    '0px 89px 154px rgba(0, 0, 0, 0.13), 0px 34px 44px 3px rgba(0, 0, 0, 0.1), 0px 7px 22px rgba(0, 0, 0, 0.05);',
    '0px 91px 161px rgba(0, 0, 0, 0.13), 0px 35px 46px rgba(0, 0, 0, 0.1), 0px 7px 23px rgba(0, 0, 0, 0.05);',
    '0px 96px 168px rgba(0, 0, 0, 0.13), 0px 37px 48px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.05);',
  ],
  palette: {
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#9e9e9e',
    },
    primary: {
      main: primaryMain,
      dark: '#0b2b3c',
      light: '#33576b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0f3952',
      dark: '#0b2b3d',
      light: '#33576c',
      contrastText: '#fffffe',
    },
    error: {
      main: '#b41d1d',
      dark: '#991212',
      light: '#d96060',
      contrastText: '#f2f5f5',
    },
    warning: {
      main: '#e1cc81',
      dark: '#98701a',
      light: '#f0e6c0',
      contrastText: '#663D00',
    },
    info: {
      main: '#ced7db',
      dark: '#4a6b7b',
      light: '#f2f5f5',
      contrastText: '#0b2b3d',
    },
    success: {
      main: '#7cc175',
      dark: '#36845d',
      light: '#b5deb1',
      contrastText: '#094330',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme: { unstable_sx: sx } }) =>
          sx({
            borderRadius: 2,
          }),
        startIcon: {
          svg: {
            color: 'inherit',
          },
        },
        endIcon: {
          svg: {
            color: 'inherit',
          },
        },
        containedError: ({ theme: { unstable_sx: sx } }) =>
          sx({
            backgroundColor: 'info.light',
            color: 'error.main',
            '&:hover': { color: 'info.light' },
          }),
        containedWarning: ({ theme: { unstable_sx: sx } }) =>
          sx({
            backgroundColor: 'warning.dark',
            color: '#ffffff',
            '&:hover': {
              color: 'warning.contrastText',
              backgroundColor: 'warning.light',
            },
          }),
        containedInfo: ({ theme: { unstable_sx: sx } }) =>
          sx({
            backgroundColor: 'info.light',
            '&:hover': {
              backgroundColor: 'info.main',
            },
          }),
        containedSuccess: ({ theme: { unstable_sx: sx } }) =>
          sx({
            backgroundColor: 'success.light',
            '&:hover': {
              backgroundColor: 'success.main',
            },
          }),
        outlinedWarning: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'warning.contrastText',
          }),
        outlinedInfo: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'info.contrastText',
          }),
        outlinedSuccess: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'success.contrastText',
          }),
        textWarning: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'warning.contrastText',
          }),
        textInfo: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'info.contrastText',
          }),
        textSuccess: ({ theme: { unstable_sx: sx } }) =>
          sx({
            color: 'success.contrastText',
          }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
        tooltipPlacementTop: {
          marginBottom: '6px !important',
        },
        tooltipPlacementBottom: {
          marginTop: '6px !important',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 24,
          marginRight: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: 28,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
})

export default materialTheme
