import { css } from '@emotion/css'
import { CSSProperties, MouseEvent, ReactNode, useCallback } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useColors } from './Colors'

type LinkTextProps = {
  children?: ReactNode
  onClick?: (event?: MouseEvent<HTMLAnchorElement>) => void
  to?: string
  style?: CSSProperties
} & LinkProps

export default function LinkText({
  children,
  onClick,
  to,
  style,
  ...otherProps
}: LinkTextProps) {
  const colors = useColors()
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) onClick(event)
    },
    [onClick]
  )
  return (
    <Link
      css={css`
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      `}
      style={{ color: colors.beeworkBlue, ...style }}
      to={to || ''}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </Link>
  )
}
