import 'firebase/firestore'
import firebase from 'firebase/app'
import { Datamodel } from 'connect-shared/datamodel'
import {
  DocumentData,
  FieldValue,
  FieldPath,
  DocumentReference,
} from 'connect-shared/firestore'
import { UpdateOptions } from 'react-toastify'

interface ModelNode {
  documents: {
    [id: string]: DocumentData
  }
  subcollections: {
    [id: string]: ModelNode
  }
}

export interface ModelCollection<M extends ModelNode>
  extends firebase.firestore.CollectionReference<M['documents'][string]> {
  doc<ID extends string>(id?: ID): ModelDocument<M, ID>
}

export interface ModelDocument<
  M extends ModelNode = ModelNode,
  ID extends string = string
> extends firebase.firestore.DocumentReference<M['documents'][ID]> {
  collection<ID extends keyof M['subcollections']>(
    id: ID
  ): ModelCollection<M['subcollections'][ID]>
  collection(id: string): firebase.firestore.CollectionReference
}

interface WriteBatch extends firebase.firestore.WriteBatch {
  update<T>(
    documentRef: DocumentReference<T>,
    data: Partial<T>,
    options?: UpdateOptions
  ): WriteBatch
  update(
    documentRef: DocumentReference<any>,
    field: string | FieldPath,
    value: any,
    ...moreFieldsAndValues: any[]
  ): WriteBatch
}

interface Firestore extends firebase.firestore.Firestore {
  collection<ID extends keyof Datamodel>(id: ID): ModelCollection<Datamodel[ID]>
  collection(id: string): firebase.firestore.CollectionReference
  batch(): WriteBatch
}

const firestore = firebase.firestore as {
  (): Firestore
  FieldValue: typeof FieldValue
  FieldPath: typeof FieldPath
}

const emptyCollection = firestore().collection('empty')
export const emptyDoc = emptyCollection.doc('empty')

export { firestore }
