import { Resource, useFirestoreDoc } from '@iwikal/reactfire'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import {
  FeedEvent,
  FileInfo,
  Mention,
  Poll,
  PollResult,
  PostDocument,
  PostUserDocument,
  ProgressiveImage,
  SubscriberPost,
} from 'connect-shared/types'
import useStabilized from 'connect-shared/useStabilized'
import firebase from 'firebase/app'
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { firestore } from '../firestore'
import FullscreenSpinner from '../FullscreenSpinner'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useFeeds } from '../hooks/feeds'
import { useTranslate } from '../hooks/Internationalization'
import useCurrentUser from '../hooks/useCurrentUser'
import useFileUploads, {
  addFile,
  deleteFiles,
  removeFile,
  useFeedFileRef,
} from '../hooks/useFileUploads'
import useImageUploads, {
  addImage,
  deleteImages,
  removeImage,
  useFeedImageRef,
} from '../hooks/useImageUploads'
import { ReactComponent as DropdownIcon } from '../icons/ico-chevron-right.svg'
import { Container, Spacer } from '../layout/Layout'
import { mentionsInputString } from '../MentionsInput'
import { asyncSuspend } from '../resource'
import RoundAvatar from '../RoundAvatar'
import { BodyText, CaptionText } from '../Text'
import { showErrorToast, showSuccessToast } from '../Toast'
import { emitTutorialEvent } from '../Tutorials'
import { pollDisabled, useStateHistory } from './PollEditor'
import PostCreateCardClosedState from './PostEditCardClosedState'
import PostCreateCardOpenState from './PostEditCardOpenState'
import PostCreateCardSelectFeedState from './PostEditSelectFeedState'
import useFeatureFlag from '../hooks/useFeatureFlag'

export type FeedPostEditCardState = 'closed' | 'open' | 'selectFeed'

type FeedPostEditCardProps = {
  style?: CSSProperties
  initialFeedId?: string
  postSnapToEdit?: QueryDocumentSnapshot<PostDocument>
  onEditCancel?: () => void
}

const emptyDoc = firebase.firestore().collection('empty').doc('empty')

function useMaybeDoc<D extends { [key: string]: any }>(
  ref?: firebase.firestore.DocumentReference<D>
) {
  const resource = useFirestoreDoc(ref ?? emptyDoc)
  return ref && resource
}

function useFeedSnap(feedId: string | undefined) {
  const collectionRef = useFeeds()
  const resource = useFirestoreDoc(
    feedId ? collectionRef.doc(feedId) : emptyDoc
  )
  if (feedId) return resource
}

function usePollResultSnap(feedId: string | undefined, postId: string) {
  const collectionRef = useFeeds()
  const ref = feedId
    ? collectionRef
        .doc(feedId)
        .collection('posts')
        .doc(postId)
        .collection('extra')
        .doc('pollResult')
    : undefined

  return useMaybeDoc(ref)
}

function normalizePoll(poll: Poll | undefined): Poll | undefined {
  if (!poll) return

  const options = Object.fromEntries(
    Object.entries(poll.options).map(([id, opt]) => [
      id,
      { ...opt, title: opt.title.trim() },
    ])
  )

  return { ...poll, options }
}

const defaultEvent: FeedEvent = {
  allDay: false,
  canceled: false,
  location: '',
  toDate: firebase.firestore.Timestamp.now(),
  toDateEnabled: false,
  title: '',
  fromDate: firebase.firestore.Timestamp.now(),
  attendingCounters: {
    yes: 0,
    no: 0,
    maybe: 0,
  },
}

function FeedPostEditCard({
  style,
  initialFeedId,
  postSnapToEdit,
  onEditCancel,
  remount,
}: FeedPostEditCardProps & { remount: () => void }) {
  const organizationDoc = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const hasFeaturePersonalFeeds = useFeatureFlag('personalFeeds', true)

  const [selectedFeedId, setSelectedFeedId] = useState<string | undefined>(
    initialFeedId ??
      (hasFeaturePersonalFeeds ? 'personal_' + currentUserId : undefined)
  )

  useEffect(() => {
    if (!initialFeedId) {
      return
    }
    setSelectedFeedId(initialFeedId)
  }, [initialFeedId])

  const feedResource = useFeedSnap(selectedFeedId)

  const [state, setState] = useState<FeedPostEditCardState>(
    postSnapToEdit ? 'open' : 'closed'
  )
  const colors = useColors()
  const t = useTranslate()
  const [dropdownHovered, setDropdownHovered] = useState(false)
  const [postId] = useState(() => {
    return postSnapToEdit
      ? postSnapToEdit.id
      : firestore().collection('empty').doc().id
  })

  const pollResultResource = usePollResultSnap(selectedFeedId, postId)
  const [eventEditorOpen, setEventEditorOpen] = useState(
    !!postSnapToEdit?.data().event
  )

  const handleToggleEvent = useCallback(() => {
    setEventEditorOpen((value) => !value)
  }, [])

  const [text, setText] = useState(
    postSnapToEdit ? postSnapToEdit.data().body : ''
  )
  const [isAnnouncement, setIsAnnouncement] = useState(
    postSnapToEdit ? postSnapToEdit.data().readReceipts || false : false
  )

  const toggleIsAnnouncement = useCallback(() => {
    setIsAnnouncement(!isAnnouncement)
  }, [isAnnouncement])

  const [loading, setLoading] = useState(false)

  const [initialFiles, setInitialFiles] = useState<Set<FileInfo> | undefined>(
    postSnapToEdit?.data().files
      ? new Set(postSnapToEdit.data().files)
      : undefined
  )
  const [initialImages, setInitialImages] = useState<
    Set<ProgressiveImage> | undefined
  >(
    postSnapToEdit?.data().images
      ? new Set(postSnapToEdit.data().images)
      : undefined
  )

  const handleDropdownClick = useCallback(() => {
    if (state === 'selectFeed') {
      setState('open')
    } else if (!initialFeedId) {
      setState('selectFeed')
    }
  }, [initialFeedId, state])

  const selectedFeed = useMemo(() => {
    return feedResource?.read().data()
  }, [feedResource])

  const personal = selectedFeedId?.startsWith('personal_')
  const hasSelectedFeed = personal || !!selectedFeed

  const currentUser = useCurrentUser()
  const avatarUri = personal ? currentUser?.avatar : selectedFeed?.avatar
  const feedName = personal ? t('Your feed') : selectedFeed?.name

  const avatar = hasSelectedFeed ? (
    <Container vAlign="center">
      <RoundAvatar size={50} avatar={avatarUri} name={feedName} />
    </Container>
  ) : (
    <div style={{ height: 50 }} />
  )

  const [
    { finishedUploads: finishedImageUploads, uploads: imageUploadsInProgress },
    setImageUploads,
  ] = useImageUploads()

  const imageRef = useFeedImageRef(selectedFeedId ?? 'empty', postId)

  const addImageUpload = useCallback(
    (image: File) => addImage(imageRef, image, setImageUploads),
    [imageRef, setImageUploads]
  )

  const removeImageUpload = useCallback(
    (id: string) => removeImage(id, setImageUploads),
    [setImageUploads]
  )

  const [
    { uploads: fileUploadsInProgress, finishedUploads: finishedFileUploads },
    setFileUploads,
  ] = useFileUploads()

  const fileRef = useFeedFileRef(selectedFeedId ?? 'empty', postId)

  const addFileUpload = useCallback(
    (file: File) => addFile(fileRef, file, setFileUploads),
    [fileRef, setFileUploads]
  )

  const removeFileUpload = useCallback(
    (id: string) => removeFile(id, setFileUploads),
    [setFileUploads]
  )

  useEffect(() => {
    // This hook is only meant to run on unmount.
    deleteImages(setImageUploads)
    deleteFiles(setFileUploads)
  }, [setImageUploads, setFileUploads])

  const handleEditCancel = useCallback(() => {
    remount()
    onEditCancel && onEditCancel()
  }, [onEditCancel, remount])

  const uploadsFinished = useMemo(
    () => !!finishedFileUploads && !!finishedImageUploads,
    [finishedFileUploads, finishedImageUploads]
  )

  const initialPoll = useMemo(
    () => postSnapToEdit?.data().poll,
    [postSnapToEdit]
  )
  const [poll, setPoll, rebasePoll] = useStateHistory<Poll | undefined>(
    initialPoll
  )

  useEffect(() => {
    rebasePoll(initialPoll)
  }, [initialPoll, rebasePoll])

  function* pollOptions(poll: Poll) {
    for (const optionId of poll.ordering) {
      const option = poll.options[optionId]
      if (!option) throw new Error('invalid poll')
      yield option
    }
  }

  const pollValid = useMemo(() => {
    const norm = normalizePoll(poll)
    if (!norm) return true
    for (const option of pollOptions(norm)) {
      if (!option || !option.title.trim()) return false
    }
    return true
  }, [poll])

  const editMode = !!postSnapToEdit

  const [event, setEvent] = useState<FeedEvent>(
    postSnapToEdit?.data().event || defaultEvent
  )

  const removeEvent = useCallback(async () => {
    const postRef = organizationDoc
      .collection('feeds')
      .doc(selectedFeedId)
      .collection('posts')
      .doc(postId)

    setEvent(defaultEvent)
    try {
      await postRef.update({ event: firebase.firestore.FieldValue.delete() })
      setEventEditorOpen(false)
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
    }
  }, [organizationDoc, postId, selectedFeedId, t])

  const postRef = useMemo(() => {
    return selectedFeedId
      ? organizationDoc
          .collection('feeds')
          .doc(selectedFeedId)
          .collection('posts')
          .doc(postId)
      : undefined
  }, [organizationDoc, postId, selectedFeedId])

  const combinedFeedRef = useStabilized(
    organizationDoc.collection('combinedFeeds').doc(currentUserId)
  )

  const combinedFeed = useFirestoreDoc(combinedFeedRef).read().data()
  const subscribed = combinedFeed?.subscriptions?.[selectedFeedId || ''] == true

  const [mentions, setMentions] = useState<Mention[]>([])
  const [inputValue, setInputValue] = useState(() =>
    mentionsInputString(
      postSnapToEdit?.data().body,
      postSnapToEdit?.data().mentions
    )
  )

  const hasAnyContent = useMemo(() => {
    return (
      !!text.trim() ||
      [...(initialImages || []), ...(finishedImageUploads || [])].length !==
        0 ||
      [...(initialFiles || []), ...(finishedFileUploads || [])].length !== 0 ||
      !!poll ||
      event.title.trim().length > 0 ||
      false
    )
  }, [
    event.title,
    finishedFileUploads,
    finishedImageUploads,
    initialFiles,
    initialImages,
    poll,
    text,
  ])

  const disableSubmit: boolean =
    !uploadsFinished || loading || !hasSelectedFeed || !pollValid

  const orgDoc = useOrganizationDoc()
  const feedRef = selectedFeedId
    ? orgDoc.collection('feeds').doc(selectedFeedId)
    : emptyDoc
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )
  const followerCount = followers.read().data()?.ids.length || 0

  const submit = useCallback(async () => {
    if (disableSubmit || !hasAnyContent) return
    if (!selectedFeedId) return

    const postRef = organizationDoc
      .collection('feeds')
      .doc(selectedFeedId)
      .collection('posts')
      .doc(postId)

    setLoading(true)

    const combinedFeedPostRef = combinedFeedRef
      .collection('posts')
      .doc(postRef.id)

    const images = [...(initialImages || []), ...(finishedImageUploads || [])]

    const files = [...(initialFiles || []), ...(finishedFileUploads || [])]

    function inc(i: number): number {
      return firebase.firestore.FieldValue.increment(i) as any
    }

    function del(): undefined {
      return firebase.firestore.FieldValue.delete() as any
    }

    const postData: PostDocument = {
      readReceipts: isAnnouncement,
      author:
        editMode && postSnapToEdit
          ? postSnapToEdit?.data().author
          : currentUserId,
      body: text.trim(),
      createdDate: firebase.firestore.Timestamp.now(),
      images: images,
      files,
      canConfirmCount: (followerCount || 0) - (subscribed ? 1 : 0),
      mentions,
      personal: selectedFeedId.startsWith('personal_'),
    }

    if (eventEditorOpen) {
      postData.event = event
    }

    if (editMode) {
      postData.editedBy = currentUserId
    }

    const batch = firestore().batch()

    const combinedData: SubscriberPost = {
      ...postData,
      originalCollectionPath: postRef.parent.path,
      pinned: isAnnouncement,
    }

    const postUserDoc = postRef.collection('users').doc(currentUserId)
    const postUserData: PostUserDocument = {
      subscribed: true,
    }

    batch.set(postRef, postData, { merge: true })
    batch.set(postUserDoc, postUserData, { merge: true })

    if (subscribed) {
      batch.set(combinedFeedPostRef, combinedData, { merge: true })
    }

    const pollResultSnap = pollResultResource
      ? await asyncSuspend(pollResultResource)
      : undefined

    if (!pollDisabled(pollResultSnap?.data() as PollResult)) {
      const normalized = normalizePoll(poll)
      batch.update(postRef, { poll: normalized ?? del() })

      if (normalized) {
        // Use increment and no `pollVotes` field here, because we don't want to
        // overwrite the document that potentially already exists.
        // This occurs when editing a post with a poll that already has votes.
        const defaultPollResultData: PollResult = { totalVoters: inc(0) }

        batch.set(
          postRef.collection('extra').doc('pollResult'),
          defaultPollResultData,
          { merge: true }
        )
      }
    }

    try {
      await batch.commit()
      const successMessage = editMode
        ? t('Post successfully edited')
        : t('Post successfully created')
      showSuccessToast(successMessage)
      if (editMode) {
        onEditCancel?.()
      } else {
        emitTutorialEvent('feed_post_created')
        setState('closed')
      }
      if (selectedFeedId) {
        localStorage.setItem('latestFeedPosted', selectedFeedId)
      }
      remount()
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
      setLoading(false)
    }
  }, [
    disableSubmit,
    hasAnyContent,
    selectedFeedId,
    organizationDoc,
    postId,
    combinedFeedRef,
    initialImages,
    finishedImageUploads,
    initialFiles,
    finishedFileUploads,
    isAnnouncement,
    editMode,
    postSnapToEdit,
    currentUserId,
    text,
    followerCount,
    subscribed,
    mentions,
    eventEditorOpen,
    pollResultResource,
    event,
    poll,
    t,
    remount,
    onEditCancel,
  ])

  const header = (
    <ClickableOpacity
      onMouseLeave={() => setDropdownHovered(false)}
      onMouseOver={() => setDropdownHovered(true)}
      onClick={editMode ? undefined : handleDropdownClick}
      style={{
        cursor: editMode ? 'default' : 'pointer',
        display: 'flex',
        backgroundColor: colors.border,
        padding: hasSelectedFeed ? 20 : '20px 20px 20px 0',
        alignItems: 'center',
      }}
    >
      {avatar}
      <Spacer size={16} />
      <Container
        vAlign="center"
        flex={1}
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          textAlign: 'start',
        }}
      >
        {hasSelectedFeed ? (
          <Container
            flex={1}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textAlign: 'start',
            }}
          >
            <CaptionText
              style={{
                fontWeight: 600,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                lineHeight: 1.4,
              }}
            >
              {feedName}
            </CaptionText>
            <BodyText style={{ color: colors.labelStrong }}>
              {t('%{count} Followers', {
                count: followerCount || 0,
              })}{' '}
            </BodyText>
          </Container>
        ) : (
          <BodyText
            style={{
              fontWeight: 600,
            }}
          >
            {t('Select a feed')}
          </BodyText>
        )}
      </Container>
      <Spacer size={20} />
      {!editMode && !initialFeedId && (
        <DropdownIcon
          style={{
            transform: 'rotate(90deg)',
          }}
          fill={dropdownHovered ? colors.active : colors.pastEvent}
          width={24}
          height={24}
        />
      )}
    </ClickableOpacity>
  )

  const onRemoveInitialFile = useCallback((file: FileInfo) => {
    setInitialFiles((files) => {
      const newFiles = new Set(files)
      newFiles.delete(file)
      return newFiles
    })
  }, [])

  const onRemoveInitialImage = useCallback((image: ProgressiveImage) => {
    setInitialImages((images) => {
      const newImages = new Set(images)
      newImages.delete(image)
      return newImages
    })
  }, [])

  const handleCloseEventEditor = useCallback(() => {
    setEvent(defaultEvent)
    setEventEditorOpen(false)
  }, [])

  const organizationRef = useOrganizationDoc()
  const followersRef = selectedFeedId
    ? organizationRef
        .collection('feeds')
        .doc(selectedFeedId)
        .collection('extra')
        .doc('followers')
    : undefined

  return (
    <Container
      vAlign="center"
      style={{
        borderRadius: 5,
        backgroundColor: colors.foreground,
        boxShadow: `0 1px 3px ${colors.webChatBoxShadow}`,
        ...style,
      }}
    >
      {state === 'closed' && (
        <PostCreateCardClosedState setState={setState} avatar={avatar} />
      )}
      {state === 'open' && (
        <PostCreateCardOpenState
          mentionsFilter={followersRef}
          editingEvent={!!postSnapToEdit?.data().event}
          onRemoveInitialFile={onRemoveInitialFile}
          onRemoveInitialImage={onRemoveInitialImage}
          initialImages={initialImages}
          initialFiles={initialFiles}
          onIsAnnouncementToggle={toggleIsAnnouncement}
          isAnnouncement={isAnnouncement}
          removeFile={removeFileUpload}
          removeImage={removeImageUpload}
          fileUploads={fileUploadsInProgress}
          imageUploads={imageUploadsInProgress}
          onSubmit={submit}
          onAddFile={addFileUpload}
          onAddImage={addImageUpload}
          setPoll={setPoll}
          poll={poll}
          // TODO: Ugly cast here but types aren't fully working atm.
          pollResultResource={
            pollResultResource as unknown as Resource<
              firebase.firestore.DocumentSnapshot<PollResult>
            >
          }
          initialPoll={initialPoll}
          inputValue={inputValue}
          onInputValueChange={setInputValue}
          onMentionsChange={setMentions}
          onTextChange={setText}
          header={header}
          setState={setState}
          disableSubmit={disableSubmit || !hasAnyContent}
          onEditCancel={handleEditCancel}
          onAddEvent={handleToggleEvent}
          eventEditorOpen={eventEditorOpen}
          onEventEditorClose={handleCloseEventEditor}
          onEventEditorOpen={() => setEventEditorOpen(true)}
          postEvent={event}
          setPostEvent={setEvent}
          onEventDelete={removeEvent}
          postRef={postRef}
        />
      )}
      {state === 'selectFeed' && (
        <Suspense
          fallback={
            <Container style={{ height: 450 }}>
              {header}
              <FullscreenSpinner />
            </Container>
          }
        >
          <PostCreateCardSelectFeedState
            header={header}
            setState={setState}
            onSelectFeed={setSelectedFeedId}
          />
        </Suspense>
      )}
    </Container>
  )
}

export default function FeedPostEditCardWrapper(props: FeedPostEditCardProps) {
  const [key, remount] = useReducer(Math.random, 0)
  return <FeedPostEditCard remount={remount} {...props} key={key} />
}
