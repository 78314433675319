import { useState } from 'react'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { ReactComponent as CommentIcon } from '../../icons/ico-comment.svg'
import HoverableContainer from '../../HoverableContainer'
import IconHoverWrapper from '../../IconHoverWrapper'
import { useTranslate } from '../../hooks/Internationalization'

type FeedPostCommentButtonProps = {
  onClick?: () => void
}

export default function FeedPostCommentButton({
  onClick,
}: FeedPostCommentButtonProps) {
  const colors = useColors()
  const t = useTranslate()

  const [hover, setHover] = useState(false)

  return (
    <IconHoverWrapper style={{ flex: 1 }}>
      <HoverableContainer
        hoverColor={colors.selected}
        style={{
          flex: 1,
          cursor: 'pointer',
          flexDirection: 'row',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ClickableOpacity
          style={{
            borderTop: `1px solid ${colors.background}`,
            paddingTop: 25,
            paddingBottom: 25,
            flex: 1,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: hover ? colors.menuUnselected : colors.inactiveLikeButton,
            fontSize: 14,
            fontWeight: 'bold',
          }}
        >
          <CommentIcon style={{ marginRight: 7 }} width={20} height={20} />
          {t('Comment')}
        </ClickableOpacity>
      </HoverableContainer>
    </IconHoverWrapper>
  )
}
