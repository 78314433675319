import React, { Dispatch, useCallback } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import { Container, Spacer } from '../layout/Layout'
import { initialCrop, Upload } from '../crop-utils'
import 'react-image-crop/dist/ReactCrop.css'
import BlockButton from '../BlockButton'
import ClickableOpacity from '../ClickableOpacity'
import { useTranslate } from '../hooks/Internationalization'

type CreateNewFeedModalCropAvatarProps = {
  avatar: Upload
  crop: Crop
  setCrop: Dispatch<React.SetStateAction<ReactCrop.Crop>>
  imageRef: React.MutableRefObject<HTMLImageElement | undefined>
  onSubmit: () => void
  onCancel: () => void
}

export default function CreateNewFeedModalCropAvatar({
  avatar,
  crop,
  setCrop,
  imageRef,
  onSubmit,
  onCancel,
}: CreateNewFeedModalCropAvatarProps) {
  const t = useTranslate()

  const targetWidth = 300
  const targetHeight = 300

  const handleImageLoaded = useCallback(
    (image: HTMLImageElement) => {
      imageRef.current = image
      setTimeout(() => {
        setCrop(initialCrop(image, targetWidth, targetHeight))
      }, 0)
    },
    [imageRef, setCrop]
  )

  return (
    <Container style={{ overflowY: 'auto' }} flex="1">
      <ReactCrop
        src={avatar.url}
        style={{ alignSelf: 'center' }}
        imageStyle={{ maxHeight: 600 }}
        onImageLoaded={handleImageLoaded}
        circularCrop
        crop={crop}
        onChange={setCrop}
      />
      <Spacer size={20} />
      <Container horizontal hAlign="right">
        <ClickableOpacity onClick={onCancel}>{t('Cancel')}</ClickableOpacity>
        <Spacer size={20} />
        <BlockButton onClick={onSubmit}>{t('Crop image')}</BlockButton>
      </Container>
    </Container>
  )
}
