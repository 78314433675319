import { CSSProperties, HTMLAttributes, ReactNode } from 'react'
import PageHeader, { MenuButtonProps } from '../PageHeader'
import { Container, Spacer } from './Layout'

export type RightButtonProps = {
  text: string
  onClick: () => void
}

type PageLayoutProps = {
  wide?: boolean
  flex?: CSSProperties['flex']
  Header?: ReactNode
  text?: string
  backButton?: boolean
  rightButton?: RightButtonProps
  menuButtons?: MenuButtonProps[]
  backButtonOnClick?: (e: React.MouseEvent<HTMLDivElement>) => void
} & HTMLAttributes<HTMLDivElement>

export default function PageLayoutLegacy({
  wide,
  flex = '0 0 auto',
  style,
  children,
  text,
  backButtonOnClick,
  backButton,
  rightButton,
  menuButtons,
  ...divProps
}: PageLayoutProps) {
  return (
    <Container
      flex={flex}
      style={{
        width: wide ? '100%' : 660 + 80 + 80,
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 40,
        paddingBottom: 40,
        ...style,
      }}
      {...divProps}
    >
      <PageHeader
        backButtonOnClick={backButtonOnClick}
        menuButtons={menuButtons}
        backButton={backButton}
        text={text}
        rightButton={rightButton}
      />
      <Spacer size={60} />
      <Container
        style={{
          width: wide ? '100%' : 660 + 80 + 80,
        }}
      >
        {children}
      </Container>
    </Container>
  )
}
