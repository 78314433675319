import {
  ChangeEvent,
  CSSProperties,
  HTMLAttributes,
  useCallback,
  useState,
} from 'react'
import { useColors } from './Colors'
import { useTranslate } from './hooks/Internationalization'

type FileDropzoneProps = {
  flex?: CSSProperties['flex']
  onFiles?: (files: FileList | null) => void
} & HTMLAttributes<HTMLLabelElement>

export default function FileDropzone({
  flex = '0 0 auto',
  style,
  onFiles,
  ...labelProps
}: FileDropzoneProps) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onFiles?.(e.currentTarget.files)
      e.target.value = ''
    },
    [onFiles]
  )
  const colors = useColors()
  const [dragging, setDragging] = useState(false)
  const t = useTranslate()

  const handleDragEnter = useCallback(() => {
    setDragging(true)
  }, [])
  const handleDragLeave = useCallback(() => {
    setDragging(false)
  }, [])

  return (
    <label
      style={{
        flex,
        backgroundColor: colors.background,
        opacity: dragging ? 0.4 : 1,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: colors.textDimHard,
        borderRadius: 5,
        padding: 50,
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      {...labelProps}
    >
      {(() => {
        const [drop, browse] = t('Drop file here, or |browse').split('|')
        return (
          <span>
            {drop}
            <strong style={{ color: colors.active }}>{browse}</strong>
          </span>
        )
      })()}

      <input
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDragLeave}
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          bottom: 0,
          opacity: 0,
          cursor: 'pointer',
        }}
        type="file"
        onChange={handleChange}
        accept="application/xml"
        multiple={false}
      />
    </label>
  )
}
