import { Tooltip } from '@mui/material'
import React, { ComponentType, useCallback, useState } from 'react'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import { IconProps } from './IconButton'

type RoundActionButtonProps = {
  onClick?: () => void
  Icon: ComponentType<IconProps & any>
  tooltip?: string
  disabled?: boolean
  size?: number
}

export default function RoundActionButton({
  disabled = false,
  onClick,
  Icon,
  tooltip,
  size = 36,
}: RoundActionButtonProps) {
  const [hovered, setHovered] = useState(false)

  const onHover = useCallback(() => {
    setHovered(true)
  }, [])

  const onUnHover = useCallback(() => {
    setHovered(false)
  }, [])

  const colors = useColors()

  const fill = disabled
    ? colors.labelDimHard
    : hovered
    ? colors.active
    : colors.labelDim

  const body = (
    <ClickableOpacity
      onClick={!disabled ? onClick : undefined}
      onMouseEnter={onHover}
      onMouseLeave={onUnHover}
      hover={!disabled}
      hoverColor={colors.selected}
      style={{
        display: 'flex',
        height: size,
        width: size,
        borderRadius: size / 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon
        style={{ opacity: disabled ? 0.4 : 1.0 }}
        width={size - 12}
        height={size - 12}
        fill={fill}
      />
    </ClickableOpacity>
  )

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip}>
        <div>{body}</div>
      </Tooltip>
    )
  } else {
    return body
  }
}
