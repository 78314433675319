import { useFirestoreDoc } from '@iwikal/reactfire'
import { useCallback, useState } from 'react'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { useCurrentUserId } from '../../hooks/auth'
import { usePostUsers } from '../../hooks/feeds'
import { ReactComponent as LikeIcon } from '../../icons/ico-like.svg'
import HoverableContainer from '../../HoverableContainer'
import IconHoverWrapper from '../../IconHoverWrapper'
import { useTranslate } from '../../hooks/Internationalization'

type FeedPostLikeButtonProps = {
  feedId: string
  postId: string
}

export default function FeedPostLikeButton({
  feedId,
  postId,
}: FeedPostLikeButtonProps) {
  const colors = useColors()
  const t = useTranslate()
  const currentUserId = useCurrentUserId()
  const postUsers = usePostUsers(feedId, postId).doc(currentUserId)
  const postUserDoc = useFirestoreDoc(postUsers).read().data()

  const likedByCurrentUser = postUserDoc?.likes

  const [hover, setHover] = useState(false)

  const handleLike = useCallback(() => {
    return postUsers.set(
      { likes: likedByCurrentUser ? 0 : 1, subscribed: true },
      { merge: true }
    )
  }, [likedByCurrentUser, postUsers])

  return (
    <IconHoverWrapper style={{ flex: 1 }}>
      <HoverableContainer
        hoverColor={colors.selected}
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ClickableOpacity
          style={{
            borderTop: `1px solid ${colors.background}`,
            paddingTop: 25,
            paddingBottom: 25,
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            color: likedByCurrentUser
              ? colors.activeLikeButton
              : hover
              ? colors.menuUnselected
              : colors.inactiveLikeButton,
            fontSize: 14,
            fontWeight: 'bold',
          }}
          onClick={handleLike}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <LikeIcon
            style={{ marginRight: 7 }}
            filled={Boolean(likedByCurrentUser)}
            fill={colors.none}
            width={20}
            height={20}
          />
          {t('Like')}
        </ClickableOpacity>
      </HoverableContainer>
    </IconHoverWrapper>
  )
}
