import { DocumentSnapshot } from 'connect-shared/firestore'
import { FeedDocument } from 'connect-shared/types'
import React from 'react'
import { useColors } from '../../Colors'
import { useTranslate } from '../../hooks/Internationalization'
import { Container, Spacer } from '../../layout/Layout'
import { BodyText, CaptionText } from '../../Text'

function TextBlock({
  title,
  text,
  isAbout,
}: {
  title: string
  text: string
  isAbout?: boolean
}) {
  const colors = useColors()
  return (
    <Container>
      <CaptionText style={{ fontWeight: 600, marginBottom: isAbout ? 10 : 0 }}>
        {title}
      </CaptionText>
      <BodyText style={{ fontSize: 14, color: colors.labelStrong }}>
        {text}
      </BodyText>
    </Container>
  )
}

export default function FeedAbout({
  feedSnap,
}: {
  feedSnap: DocumentSnapshot<FeedDocument>
}) {
  const colors = useColors()
  const t = useTranslate()
  const feed = feedSnap.data()

  const { anyoneMayPost = false, inviteOnly, disableInteractions } = feed ?? {}

  const publicText = inviteOnly
    ? t('Closed - only those invited can join')
    : t('Open - everyone can join')

  const rightsText = anyoneMayPost
    ? t('Everyone can post')
    : t('Only admins can post')

  const interactionsText = disableInteractions
    ? t('No one can like or comment in this feed')
    : t('Likes and comments are enabled for this feed')

  return (
    <Container
      style={{
        padding: 20,
        borderRadius: 5,
        boxShadow: `0 1px 3px ${colors.announcementShadow}`,
        marginTop: 30,
        backgroundColor: colors.foreground,
      }}
    >
      <TextBlock
        isAbout
        title={t('About this feed')}
        text={feed?.description || t('No description has been added')}
      />
      <Spacer size={30} />
      <TextBlock title={t('Public')} text={publicText} />
      <Spacer size={20} />
      <TextBlock title={t('Rights')} text={rightsText} />
      <Spacer size={20} />
      <TextBlock title={t('Interactions')} text={interactionsText} />
    </Container>
  )
}
