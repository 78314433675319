import styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { privateRoomId } from 'connect-shared/new-chat'
import { FeedDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import useCurrentUser from '../../hooks/useCurrentUser'
import useUser from '../../hooks/useUser'
import { Container, Spacer } from '../../layout/Layout'
import PopoutMenu, { PopoutMenuOption } from '../../PopoutMenu'
import { usePopoverHover } from '../../PopoverWrapper'
import StatusAvatar from '../../StatusAvatar'
import { showErrorToast, showSuccessToast } from '../../Toast'
import { UserCardPopover } from '../../user-profile/UserCard'
import TableInfoRow from '../../wiki/TableInfoRow'

const HoverableContainer = styled.div`
  background-color: transparent;

  &:hover {
    background-color: #ededed;
  }
`

type FeedFollowerListItemProps = {
  userId: string
  feedSnap: firebase.firestore.DocumentSnapshot<FeedDocument>
}

export default function FeedFollowerListItem({
  userId,
  feedSnap,
}: FeedFollowerListItemProps) {
  const [confirmRemoveModalOpen, setConfirmRemoveUserModalOpen] =
    useState(false)

  const closeConfirmRemove = useCallback(() => {
    setConfirmRemoveUserModalOpen(false)
  }, [])

  const openConfirmRemove = useCallback(() => {
    setConfirmRemoveUserModalOpen(true)
  }, [])

  const colors = useColors()
  const t = useTranslate()

  const history = useHistory()
  const navigateToProfile = useCallback(() => {
    history.push(`/users/${userId}`)
  }, [history, userId])

  const user = useUser(userId)

  const currentUserId = useCurrentUserId()
  const startChat = useCallback(() => {
    const roomId = privateRoomId(currentUserId, userId)
    history.push(`/chat/rooms/${roomId}`)
  }, [currentUserId, history, userId])

  const admins = feedSnap.data()?.admins
  const currentUser = useCurrentUser()
  const userIsAdmin = admins?.[userId] === true
  const currentUserIsAdmin =
    admins?.[currentUserId] === true || currentUser?.organizationAdmin

  const promoteToAdmin = useCallback(async () => {
    const newAdmins = Object.keys(admins || {})
    newAdmins.push(userId)

    const updateData: Partial<FeedDocument> = {
      admins: Object.fromEntries(newAdmins.map((id) => [id, true])),
    }

    await feedSnap.ref.update(updateData).catch((e) => {
      showErrorToast(t('Something went wrong, please try again later'))
      throw e
    })
    showSuccessToast(
      t('%{fullName} promoted to admin!', { fullName: user?.fullName || '' })
    )
  }, [admins, feedSnap.ref, t, user?.fullName, userId])

  const demoteAdmin = useCallback(async () => {
    await feedSnap.ref
      .update(
        ['admins', userId].join('.'),
        firebase.firestore.FieldValue.delete()
      )
      .catch((e) => {
        showErrorToast(t('Something went wrong, please try again later'))
        throw e
      })
    showSuccessToast(
      t('%{fullName} demoted', { fullName: user?.fullName || '' })
    )
  }, [feedSnap.ref, t, user?.fullName, userId])

  const organizationDoc = useOrganizationDoc()
  const removeFromFeed = useCallback(async () => {
    const batch = firebase.firestore().batch()
    batch.update(
      feedSnap.ref,
      ['admins', userId].join('.'),
      firebase.firestore.FieldValue.delete()
    )

    const combinedFeedRef = organizationDoc
      .collection('combinedFeeds')
      .doc(userId)
    batch.update(
      combinedFeedRef,
      ['subscriptions', feedSnap.id].join('.'),
      firebase.firestore.FieldValue.delete()
    )

    await batch.commit().catch((e) => {
      console.error(e)
      showErrorToast(t('Something went wrong, please try again later'))
      throw e
    })

    showSuccessToast(
      t('%{fullName} successfully removed from feed', {
        fullName: user?.fullName || '',
      })
    )
  }, [feedSnap.id, feedSnap.ref, organizationDoc, t, user?.fullName, userId])

  const options: PopoutMenuOption[] = [
    {
      text: t('View Profile'),
      onClick: navigateToProfile,
    },
  ]

  const chatEnabled =
    useFirestoreDoc(useOrganizationDoc()).read().data()?.chatEnabled ?? true

  if (chatEnabled) {
    options.push({
      text: t('Start chat'),
      onClick: startChat,
    })
  }

  if (currentUserIsAdmin) {
    if (!userIsAdmin) {
      options.push({
        text: t('Promote to admin'),
        onClick: promoteToAdmin,
      })
    } else {
      options.push({
        text: t('Demote admin'),
        onClick: demoteAdmin,
      })
    }
    options.push({
      text: t('Remove from feed'),
      onClick: openConfirmRemove,
      danger: true,
    })
  }
  const hasWorkplaces = !useFirestoreCollection(
    useOrganizationDoc().collection('workplaces').limit(1)
  ).read().empty

  const { anchorProps, popOverProps } = usePopoverHover()

  return (
    <HoverableContainer>
      <Container
        horizontal
        style={{
          padding: '11px 0',
          width: '100%',
          borderTop: `1px solid ${colors.inputBorder}`,
        }}
      >
        <UserCardPopover userId={userId} {...popOverProps} />
        <Container vAlign="center" {...anchorProps}>
          <ClickableOpacity onClick={navigateToProfile}>
            <StatusAvatar
              style={{ marginLeft: 10 }}
              userId={userId}
              size={46}
            />
          </ClickableOpacity>
        </Container>
        <Spacer size={25} />
        <Container
          flex={1}
          vAlign="center"
          onClick={navigateToProfile}
          horizontal
          style={{
            padding: '10px 0',
            cursor: 'pointer',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <TableInfoRow text={user?.fullName || ''} />
          <TableInfoRow text={user?.jobTitle || '-'} />
          {hasWorkplaces && <TableInfoRow text={user?.workplace || ''} />}
        </Container>
        <Spacer size={10} />
        <Container vAlign="center">
          <PopoutMenu options={options} />
        </Container>
      </Container>
      {confirmRemoveModalOpen && (
        <ConfirmDeleteModal
          buttonText={t('Remove')}
          onRequestClose={closeConfirmRemove}
          text={t(
            'Are you sure you want to remove %{user?.fullName} from the feed?',
            { fullName: user?.fullName || '' }
          )}
          title={t('Remove %{fullName}?', { fullName: user?.fullName })}
          onConfirm={removeFromFeed}
        />
      )}
    </HoverableContainer>
  )
}
