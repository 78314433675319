import { css } from '@emotion/css'
import { Modal } from '@mui/material'
import Color from 'color'
import { UserDocument } from 'connect-shared/types'
import { useCallback, useReducer } from 'react'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { firestore } from '../../firestore'
import { useCurrentUserId } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import useUser from '../../hooks/useUser'
import IconHoverWrapper from '../../IconHoverWrapper'
import { ReactComponent as CheckIcon } from '../../icons/ico-check.svg'
import { ReactComponent as AcceptedIcon } from '../../icons/ico-going.svg'
import { Container } from '../../layout/Layout'
import ModalCloseButton from '../../ModalCloseButton'
import { BodyText } from '../../Text'
import AnnouncementIntro from './AnnouncementIntro'

export default function AnnouncementWrapperConfirmButton({
  canConfirm,
  confirmCount,
  canConfirmCount,
  markRead,
  openSeenBy,
  introAsModal,
  style,
  confirmed,
}: {
  canConfirm: boolean
  confirmCount: number
  canConfirmCount: number
  markRead: () => void
  openSeenBy: () => void
  style?: React.CSSProperties
  introAsModal?: boolean
  confirmed: boolean
}) {
  const [introOpen, toggleIntroOpen] = useReducer((state) => !state, false)
  const colors = useColors()
  const t = useTranslate()

  const currentUserId = useCurrentUserId()
  const currentUser = useUser(currentUserId)

  const setReadReceiptsTouched = useCallback(() => {
    const currentUserRef = firestore().collection('users').doc(currentUserId)
    const userData: Partial<UserDocument> = {
      readReceiptTouched: true,
    }
    currentUserRef.update(userData)
  }, [currentUserId])

  const handleClose = useCallback(() => {
    setReadReceiptsTouched()
    toggleIntroOpen()
  }, [setReadReceiptsTouched])

  const handleMarkRead = useCallback(() => {
    if (!currentUser?.readReceiptTouched) {
      toggleIntroOpen()
    }
    markRead()
  }, [currentUser, markRead])

  return (
    <Container
      style={{
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '9px 0',
        margin: 0,
        backgroundColor: canConfirm
          ? colors.beeworkGold
          : colors.beeworkBlueLight,
        ...style,
      }}
    >
      {canConfirm ? (
        <IconHoverWrapper>
          <ClickableOpacity
            css={css`
              background-color: ${colors.beeworkGoldDark};

              &:hover {
                background-color: ${Color(colors.beeworkGoldDark)
                  .lighten(0.15)
                  .hex()};
              }
            `}
            style={{
              boxShadow: `0 3px 3px ${colors.shadow}`,
              display: 'flex',
              padding: '4px 10px',
              flexDirection: 'row',
              cursor: 'pointer',
              borderRadius: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleMarkRead}
          >
            <CheckIcon
              style={{ marginRight: 7 }}
              width={24}
              height={24}
              fillColor={colors.textInverted}
            />
            <BodyText
              style={{
                color: colors.textInverted,
              }}
            >
              {t('Confirm as read')}
            </BodyText>
          </ClickableOpacity>
        </IconHoverWrapper>
      ) : (
        <IconHoverWrapper>
          <ClickableOpacity
            style={{
              flexDirection: 'row',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={openSeenBy}
          >
            <AcceptedIcon
              style={{ marginRight: 7 }}
              width={20}
              height={20}
              fill={colors.beeworkBlueText}
            />
            <BodyText
              css={css`
                color: ${colors.beeworkBlueText};

                &:hover {
                  color: ${Color(colors.beeworkBlueText).lighten(0.7).hex()};
                }
              `}
              style={{
                color: undefined,
                fontWeight: 600,
                textDecoration: 'underline',
              }}
            >
              {confirmed
                ? t(
                    '%{confirmCount}/%{canConfirmCount} Others have read this too',
                    { confirmCount, canConfirmCount }
                  )
                : t('%{confirmCount}/%{canConfirmCount} have read this', {
                    confirmCount,
                    canConfirmCount,
                  })}{' '}
            </BodyText>
          </ClickableOpacity>
        </IconHoverWrapper>
      )}
      {introAsModal && introOpen && (
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open
          onClose={handleClose}
        >
          <Container style={{ position: 'relative' }}>
            <AnnouncementIntro close={handleClose} />
            <ModalCloseButton onClick={handleClose} />
          </Container>
        </Modal>
      )}
    </Container>
  )
}
