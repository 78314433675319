import { useFirestoreCollection } from '@iwikal/reactfire'
import { Autocomplete, SxProps, TextField, Typography } from '@mui/material'
import { useAllUsers } from 'connect-shared/AllUsersContext'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { groupName, UserGroupDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import { HTMLAttributes, useMemo, useState } from 'react'
import { useOrganizationUserDoc } from './hooks/auth'
import { ReactComponent as GroupsIcon } from './icons/ico-groups.svg'
import assertNever from 'assert-never'

function UserOption({
  userId,
  ...props
}: { userId: string } & HTMLAttributes<HTMLLIElement>) {
  const { allUsers } = useAllUsers()
  const user = allUsers.get(userId)

  return (
    <li {...props}>
      <Typography variant="body1">{user?.fullName}</Typography>
    </li>
  )
}

function GroupOption({
  snap,
  ...props
}: {
  snap: QueryDocumentSnapshot<UserGroupDocument>
} & HTMLAttributes<HTMLLIElement>) {
  return (
    <li {...props}>
      <GroupsIcon width={20} height={20} style={{ marginRight: 5 }} />
      <Typography variant="body1">{groupName(snap)}</Typography>
    </li>
  )
}

type GroupItem = {
  type: 'group'
  snap: firebase.firestore.QueryDocumentSnapshot<UserGroupDocument>
  label: string
  key: string
}

type UserItem = {
  type: 'user'
  id: string
  label: string
  key: string
}

export default function UserGroupSearch({
  disabled,
  filteredUsers,
  filteredGroups,
  onSelectUser,
  onSelectGroup,
  onSelectNone,
  placeholder,
  sx,
  groups,
  value,
}: {
  disabled?: boolean
  filteredUsers?: Set<string>
  filteredGroups?: Set<string>
  onSelectUser?: (id: string) => void
  onSelectGroup?: (id: string) => void
  onSelectNone?: () => void
  placeholder?: string
  groups?: boolean
  sx?: SxProps
  value?: { type: 'group' | 'user'; id?: string }
}) {
  const { allUsers } = useAllUsers()
  const userOrgRef = useOrganizationUserDoc()
  const userGroups = useFirestoreCollection(
    userOrgRef.collection('groups')
  ).read()

  const items = useMemo(() => {
    const groupItems: GroupItem[] =
      groups === false
        ? []
        : userGroups.docs
            .filter(({ id }) => !filteredGroups?.has(id))
            .sort((a, b) => {
              return groupName(a).localeCompare(groupName(b))
            })
            .map((snap) => {
              return {
                type: 'group',
                snap: snap,
                label: groupName(snap),
                key: 'group/' + snap.id,
              }
            })

    const userItems: UserItem[] = [...allUsers.values()]
      .filter(({ id }) => !filteredUsers?.has(id))
      .filter(({ hidden }) => !hidden)
      .map((user) => {
        return {
          type: 'user',
          id: user.id,
          label: user.fullName,
          key: 'user/' + user.id,
        }
      })

    const items = [...groupItems, ...userItems]

    return items
  }, [allUsers, filteredGroups, filteredUsers, groups, userGroups.docs])

  const [inputValue, setInputValue] = useState('')
  const options = items.map((item) => ({
    label: item.label,
    item,
  }))

  const valueOption =
    options.find(({ item }) => {
      if (item.type !== value?.type) return false
      if (item.type === 'group') return item.snap.id === value.id
      if (item.type === 'user') return item.id === value.id
      assertNever(item, true)
    }) || null

  return (
    <Autocomplete
      sx={sx}
      onChange={(_e, value) => {
        setInputValue('')
        if (!value?.item) return onSelectNone?.()
        if (value.item.type === 'group') {
          onSelectGroup?.(value.item.snap.id)
        } else {
          onSelectUser?.(value.item.id)
        }
      }}
      options={options}
      value={valueOption}
      isOptionEqualToValue={(a, b) => {
        return a.item.key === b.item.key
      }}
      inputValue={inputValue}
      onInputChange={(_, value) => {
        setInputValue(value)
      }}
      renderOption={(props, { item }) => {
        if (item.type === 'group') {
          return <GroupOption snap={item.snap} {...props} key={item.key} />
        } else if (item.type === 'user') {
          return <UserOption userId={item.id} {...props} key={item.key} />
        }
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
          }}
          placeholder={placeholder}
        />
      )}
    />
  )
}
