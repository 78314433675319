import { useFirestoreDoc } from '@iwikal/reactfire'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AttentionBar, { AttentionBarStep } from './AttentionBar'
import { firestore } from './firestore'
import { useCurrentUserId, useOrganizationUserDoc } from './hooks/auth'
import { ReactComponent as ArrowRightIcon } from './icons/ico-chevron-right.svg'
import { showSuccessToast } from './Toast'

const eventListeners: ((event: string) => void)[] = []
export function emitTutorialEvent(event: string) {
  for (const listener of eventListeners) {
    listener(event)
  }
}

function useTutorialEvents(listner: (event: string) => void) {
  useEffect(() => {
    eventListeners.push(listner)

    return () => {
      eventListeners.splice(eventListeners.indexOf(listner), 1)
    }
  }, [listner])
}

function useCommonSteps() {
  const history = useHistory()
  const currentUserId = useCurrentUserId()

  const intro = {
    id: 'intro',
    headline: "Hey there! I'm a tutorial.",
    body: 'I will guide you through the basics of Beework. Click on me to to continue to the next step.',
    clickText: 'Get started!',
    completionEvent: 'tutorial_intro_click',
    onClick: () => emitTutorialEvent('tutorial_intro_click'),
  }

  const profile: TutorialStep = {
    id: 'user_profile_with_avatar_saved',
    headline: 'Update your profile',
    body: 'Make Beework more personal - add a profile picture and your contact information',
    completionEvent: 'user_profile_with_avatar_saved',
    onClick: useCallback(
      () => history.push(`/users/${currentUserId}`),
      [currentUserId, history]
    ),
  }

  const checkIn: TutorialStep = {
    id: 'check_in',
    headline: 'Time to check in',
    body: "Let your colleagues know you're at work by checking in.",
    completionEvent: 'status_available',
    onClick: useCallback(() => history.push('/'), [history]),
  }

  const sendChatMessage: TutorialStep = {
    id: 'chat_send_message',
    completionEvent: 'chat_message_sent',
    headline: 'Start chatting',
    body: 'The chat is a powerful tool - begin the conversation right now.',
    onClick: useCallback(() => history.push(`/chat`), [history]),
  }

  const wikiViewIndex: TutorialStep = {
    id: 'wiki_view_index',
    completionEvent: 'wiki_view_index',
    headline: 'Get an intro to pages',
    body: 'Pages is your own library - view the main page',
    onClick: useCallback(() => history.push(`/wiki`), [history]),
  }

  const viewFeeds: TutorialStep = {
    id: 'view_feeds',
    completionEvent: 'feed_viewed',
    headline: 'Look at your feeds',
    body: 'View your feed.',
    onClick: useCallback(() => history.push(`/feeds`), [history]),
  }

  return {
    intro,
    profile,
    wikiViewIndex,
    sendChatMessage,
    viewFeeds,
    checkIn,
  }
}

const UserOnboarding = React.memo(function UserOnboarding() {
  const { intro, profile, wikiViewIndex, sendChatMessage, checkIn, viewFeeds } =
    useCommonSteps()

  return (
    <Tutorial
      tutorialName="Getting started"
      id="user_onboarding"
      steps={[
        intro,
        profile,
        checkIn,
        sendChatMessage,
        wikiViewIndex,
        viewFeeds,
      ]}
    />
  )
})

const OrgOnboarding = React.memo(function UserOnboarding() {
  const { intro, profile, sendChatMessage, checkIn } = useCommonSteps()
  const history = useHistory()

  return (
    <Tutorial
      tutorialName="Getting started"
      id="org_onboarding"
      steps={[
        intro,
        {
          id: 'save_org_with_logo',
          completionEvent: 'org_with_logo_saved',
          headline: 'Complete your organization profile',
          body: 'Upload a logo and edit your company color theme.',
          onClick: useCallback(() => {
            history.push('/settings/organization')
          }, [history]),
          clickIcon: ArrowRightIcon,
          clickText: "Let's go!",
        },
        profile,
        checkIn,
        {
          id: 'wiki_article_edit',
          completionEvent: 'wiki_article_updated',
          headline: 'Pages',
          body: 'Edit a page',
          onClick: useCallback(() => {
            history.push('/wiki/article/index/edit')
          }, [history]),
        },

        {
          id: 'invite_user',
          completionEvent: 'invite_user',
          headline: 'Invite some colleagues',
          body: 'Get beework up and running and invite a few of you colleagues.',
          onClick: useCallback(
            () => history.push('/settings/invitations'),
            [history]
          ),
        },
        sendChatMessage,
        {
          id: 'feed_post',
          completionEvent: 'feed_post_created',
          headline: 'Feed post',
          body: 'Create a feed post',
          onClick: useCallback(() => {
            history.push('/feeds')
          }, [history]),
        },
      ]}
    />
  )
})

type TutorialStep = {
  headline: string
  body: string
  id: string
  onClick?: () => void
  clickText?: string
  clickIcon?: SvgComponent
  completionEvent?: string
}

type TutorialProps = {
  steps: TutorialStep[]
  id: string
  tutorialName: string
}

const Tutorial = React.memo(function Tutorial({
  id,
  steps,
  tutorialName,
}: TutorialProps) {
  const orgUserDoc = useOrganizationUserDoc()
  const doc = orgUserDoc.collection('tutorialProgress').doc(id)
  const data = useFirestoreDoc(doc).read().data()

  const [currentStep, setCurrentStep] = useState(
    (steps.findIndex((step) => !data?.completedSteps?.includes(step.id)) + 1 ||
      1) - 1
  )

  useTutorialEvents(
    useCallback(
      (event: string) => {
        if (event === steps[currentStep]?.completionEvent) {
          doc.update({
            completedSteps: firestore.FieldValue.arrayUnion(
              steps[currentStep].id
            ),
          })
        }

        if (
          event === 'tutorial_intro_click' &&
          steps[currentStep]?.id === 'intro'
        ) {
          setCurrentStep((x) => x + 1)
        }
      },
      [currentStep, doc, steps]
    )
  )

  const doClose = useCallback(() => {
    doc.update({ state: 'closed' })
  }, [doc])
  const doOpen = useCallback(() => {
    doc.update({ state: 'open' })
  }, [doc])
  const doDismiss = useCallback(() => {
    doc.update({ state: 'dismissed' })
  }, [doc])
  const doComplete = useCallback(() => {
    doc.update({ state: 'completed' })
  }, [doc])

  const attentionBarSteps: AttentionBarStep[] = useMemo(() => {
    return steps.map((step) => ({
      headline: step.headline,
      body: step.body,
      completed: data?.completedSteps?.includes(step.id),
      onClick: step.onClick,
      clickText: step.clickText,
      clickIcon: step.clickIcon,
    }))
  }, [data?.completedSteps, steps])

  const allComplete = !attentionBarSteps.some((step) => !step.completed)
  const prevAllComplete = useRef(allComplete)
  useEffect(() => {
    if (!prevAllComplete.current && allComplete && data?.state === 'open') {
      doc.update({
        state: 'dismissed',
      })
      showSuccessToast(`${tutorialName} guide completed 🎉`)
    }
    prevAllComplete.current = allComplete
  }, [allComplete, data?.state, doc, tutorialName])

  if (!data) return null
  if (data.state === 'dismissed') return null

  return (
    <AttentionBar
      onRequestClose={doClose}
      onRequestOpen={doOpen}
      onRequestDismiss={doDismiss}
      onRequestComplete={doComplete}
      open={data.state === 'open'}
      steps={attentionBarSteps}
      currentStep={currentStep}
      onRequestStepChange={setCurrentStep}
      tutorialName={tutorialName}
    />
  )
})

export function Tutorials() {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'right',
        pointerEvents: 'none',
        alignItems: 'flex-end',
        zIndex: 1000,
      }}
    >
      <UserOnboarding />
      <OrgOnboarding />
    </div>
  )
}
