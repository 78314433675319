import { ReactComponent as AppstoreIcon } from './icons/Appstore.svg'
import { Container } from './layout/Layout'

export default function IOSAppstoreButton() {
  return (
    <Container hAlign="center">
      <a href="https://apps.apple.com/us/app/playipp/id1446448587">
        <AppstoreIcon width={400} height={150} />
      </a>
    </Container>
  )
}
