import { UserDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import { useOrganizationDoc } from './auth'
import { firestore } from '../firestore'
import { useFirestoreCollection } from '@iwikal/reactfire'
import { useCurrentUserId } from '../hooks/auth'
import { useMemo } from 'react'

export function nextString(s: string): string {
  return s.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1))
}

function capitalizeWord(word: string): string {
  if (!word) return word
  const [initial, ...tail] = word
  return initial.toUpperCase() + tail.join('')
}

function titleCase(input: string): string {
  return input
    .split(' ')
    .map(capitalizeWord)
    .join(' ')
    .split('-')
    .map(capitalizeWord)
    .join('-')
}

export default function useUserSearch(
  searchTerm: string,
  usersToFilter?: Set<string>
) {
  const organizationDoc = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  function fetchUsers(
    fieldName: keyof UserDocument,
    searchString: string = searchTerm
  ) {
    return firestore()
      .collection('users')
      .where('organization', '==', organizationDoc)
      .orderBy(fieldName)
      .startAt(searchString)
      .endBefore(nextString(searchString))
      .limit(10)
  }

  const fullNameResults = useFirestoreCollection(fetchUsers('lowerFullName'))
  const lastNameResults = useFirestoreCollection(
    fetchUsers('lastName', titleCase(searchTerm))
  )

  return useMemo(() => {
    const result = new Map<
      string,
      firebase.firestore.QueryDocumentSnapshot<UserDocument>
    >()
    for (const snap of fullNameResults.read().docs) {
      result.set(snap.id, snap)
    }

    for (const snap of lastNameResults.read().docs) {
      result.set(snap.id, snap)
    }

    result.delete(currentUserId)

    if (usersToFilter) {
      for (const id of usersToFilter) {
        result.delete(id)
      }
    }

    return [...result.values()]
      .filter((snap) => {
        return !snap.data().hidden
      })
      .sort((a, b) => {
        const aName = a.get('lowerFullName')
        const bName = b.get('lowerFullName')
        return aName.localeCompare(bName)
      })
  }, [fullNameResults, lastNameResults, currentUserId, usersToFilter])
}
