import { useFirestoreCollection } from '@iwikal/reactfire'
import firebase from 'firebase/app'
import { FeedDocument } from 'connect-shared/types'
import React, { useCallback, useMemo, useState } from 'react'
import { useColors } from '../../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import useCurrentUser from '../../hooks/useCurrentUser'
import { Container, Spacer } from '../../layout/Layout'
import OutlineButton from '../../OutlineButton'
import FeedMembersTable from './FeedMembersTable'
import InviteUsersModal from './InviteFeedUsersModal'
import InviteAdminsModal from './InviteAdminsModal'
import { useTranslate } from '../../hooks/Internationalization'

export default function FeedMembers({
  feedSnap,
}: {
  feedSnap: firebase.firestore.DocumentSnapshot<FeedDocument>
}) {
  const [inviteFollowerModalOpen, setInviteFollowerModalOpen] = useState(false)
  const closeInviteModal = useCallback(() => {
    setInviteFollowerModalOpen(false)
  }, [])
  const openInviteModal = useCallback(() => {
    setInviteFollowerModalOpen(true)
  }, [])

  const [inviteAdminModalOpen, setInviteAdminModalOpen] = useState(false)
  const closeInviteAdminModal = useCallback(() => {
    setInviteAdminModalOpen(false)
  }, [])
  const openInviteAdminModal = useCallback(() => {
    setInviteAdminModalOpen(true)
  }, [])

  const colors = useColors()
  const t = useTranslate()

  const organizationDoc = useOrganizationDoc()
  const followersRef = organizationDoc
    .collection('combinedFeeds')
    .where(`subscriptions.${feedSnap.id}`, '==', true)

  const subscribersResource = useFirestoreCollection(followersRef)

  const sortedSubscribers = useMemo(() => {
    return subscribersResource.read().docs.sort((a, b) => {
      const aName = a.data().lowerFullName
      const bName = b.data().lowerFullName
      if (!aName) return 1
      if (!bName) return -1
      return aName.localeCompare(bName)
    })
  }, [subscribersResource])

  const followerIds = useMemo(() => {
    return sortedSubscribers.map((subscriber) => subscriber.id)
  }, [sortedSubscribers])
  const adminIds = Object.keys(feedSnap.data()?.admins || {})
  const followerIdsWithoutAdmins = useMemo(() => {
    return followerIds.filter((followerId) => {
      return !adminIds.includes(followerId)
    })
  }, [adminIds, followerIds])

  const currentUser = useCurrentUser()
  const currentUserId = useCurrentUserId()
  const currentUserIsAdmin =
    adminIds?.includes(currentUserId) || currentUser?.organizationAdmin

  return (
    <Container style={{ marginTop: 30 }} hAlign="right">
      {currentUserIsAdmin && (
        <Container
          hAlign={'right'}
          flex={1}
          horizontal
          style={{ width: '100%', marginBottom: 10 }}
        >
          <OutlineButton
            onClick={openInviteModal}
            style={{ display: 'inline-block' }}
          >
            {t('Add Followers')}
          </OutlineButton>
          <Spacer size={10} />
          <OutlineButton
            onClick={openInviteAdminModal}
            style={{ display: 'inline-block' }}
          >
            {t('Add Admins')}
          </OutlineButton>
        </Container>
      )}
      <Container
        style={{
          width: '100%',
          boxShadow: `0 1px 3px ${colors.announcementShadow}`,
          borderRadius: 5,
          backgroundColor: colors.foreground,
          padding: 20,
        }}
      >
        <FeedMembersTable
          title={t('Admins')}
          admins
          feedSnap={feedSnap}
          followerIds={adminIds}
        />
        <Spacer size={30} />
        <FeedMembersTable
          title={t('Followers')}
          feedSnap={feedSnap}
          followerIds={followerIdsWithoutAdmins}
        />
      </Container>
      {currentUserIsAdmin && (
        <>
          <InviteUsersModal
            followers={subscribersResource.read().docs.map((doc) => doc.id)}
            feedSnap={feedSnap}
            open={inviteFollowerModalOpen}
            onRequestClose={closeInviteModal}
          />
          <InviteAdminsModal
            open={inviteAdminModalOpen}
            feedSnap={feedSnap}
            onRequestClose={closeInviteAdminModal}
            admins={adminIds}
          />
        </>
      )}
    </Container>
  )
}
