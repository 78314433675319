import styled from '@emotion/styled'

const HoverButton = styled.button<{
  backgroundColor: string
  hoverColor: string
  textColor?: string
  textHoverColor?: string
}>`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 20;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.textColor};
  outline: none;

  &:hover,
  &:focus-within {
    background-color: ${(props) => props.hoverColor};
    color: ${(props) => props.textHoverColor};
    outline: none;
  }
`

export default HoverButton
