import firebase from 'firebase/app'
import 'firebase/storage'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Resizer from 'react-image-file-resizer'
import styled from '@emotion/styled'
import uuid from 'uuid'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import {
  ReactComponent as AttachmentFilledIcon,
  ReactComponent as AttachmentIcon,
} from '../../icons/ico-add-image.svg'
import CreateFeedPreview from './CreateFeedPreview'
import ToggleArea from '../../ToggleArea'
import { useColors } from '../../Colors'
import Spinner from '../../playippSpinner/PlayippSpinner'
import { useTranslate } from '../../hooks/Internationalization'

const StyledCreateFeed = styled.div`
  background: #fff;
  padding: 20px;

  .createFeedWrapper {
    h2 {
      width: 100%;
      color: #222;
      font-size: 20px;
    }
    input[type='text'] {
      width: 100%;
      border: none;
      border-bottom: 1px solid #d1d1d1;
      line-height: 34px;
      margin-bottom: 10px;
      padding: 0 15px;
      &:focus {
        outline: none;
      }
    }
    .inputWrapper {
      position: relative;
      div#error {
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }
    .acceptedFiles {
      display: flex;
      flex-flow: row;
      div {
        font-size: 12px;
      }
    }
  }
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  font-family: 'Open sans', sans-serif;
  width: calc(100% - 60px);
  background: #4b9fcc;
  color: #fff;
  line-height: 34px;
  margin: 0 30px 30px;
  border-radius: 6px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const CreateFeed = (props: {
  user: string
  handleShowCreateFeed: (value: boolean) => void
  close: () => void
}) => {
  const colors = useColors()
  const t = useTranslate()
  const [nameValue, setNameValue] = useState('')
  const [descValue, setDescValue] = useState('')
  const [inviteOnly, setInviteOnly] = useState<boolean>(false)
  const [everyoneCanPost, setEveryoneCanPost] = useState<boolean>(false)

  const validated = Boolean(nameValue && nameValue.length > 0)

  const [files, setFiles] = useState<Blob[]>([])
  const userId = useCurrentUserId()
  const organizationDoc = useOrganizationDoc()

  const maxSize = 10000000
  const onDrop = useCallback((acceptedFiles) => {
    let imageType = ''
    if (acceptedFiles[0].type === 'image/png') {
      imageType = 'PNG'
    } else if (acceptedFiles[0].type !== 'image/png') {
      imageType = 'JPEG'
    }
    Resizer.imageFileResizer(
      acceptedFiles[0],
      600,
      600,
      imageType,
      75,
      0,
      (blob: Blob) => {
        setFiles((files) => [...files, blob])
      },
      'blob'
    )
  }, [])

  const { isDragActive, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      onDrop,
      accept: ['image/png', 'image/jpeg', 'image/gif'],
      minSize: 0,
      maxSize,
    })

  const uploadFile = (feed: firebase.firestore.DocumentReference) => {
    const fileRef = firebase
      .storage()
      .ref(
        `organizations/${organizationDoc.id}/feeds/${
          feed.id
        }/avatars/${uuid.v4()}`
      )

    const uploadTask = fileRef.put(files[0], {
      cacheControl: 'private, max-age=604800, immutable',
    })

    uploadTask.on(
      'state_changed',
      () => {},
      (err) => {
        console.error('Error on upload: ', err)
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((uri: string) => {
          feed.update({
            avatar: uri,
          })
        })
      }
    )
  }

  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    if (validated) {
      setLoading(true)
      organizationDoc
        .collection('feeds')
        .add({
          name: nameValue,
          description: descValue,
          inviteOnly: inviteOnly,
          anyoneMayPost: everyoneCanPost,
          admins: {
            [props.user]: true,
          },
        })
        .then(async (docRef) => {
          // Always set the creator as subscriber
          const subscribe = firebase
            .app()
            .functions('europe-west1')
            .httpsCallable('addCombinedFeedSubscriptions')
          await subscribe({
            combinedFeedIds: [userId],
            feedIds: [docRef.id],
          })

          setNameValue('')
          setDescValue('')
          props.handleShowCreateFeed(false)
          props.close()
          if (files && files.length > 0) {
            uploadFile(docRef)
          }
          setLoading(false)
        })
    }
  }

  return (
    <StyledCreateFeed>
      <div className="createFeedWrapper">
        <h3>{t('Create feed')}</h3>
        <div className="inputWrapper">
          <input
            id="name"
            type="text"
            value={nameValue}
            placeholder={t('Feed name')}
            onChange={(e) => setNameValue(e.target.value)}
          />
        </div>
        <div className="inputWrapper">
          <input
            type="text"
            value={descValue}
            placeholder={t('Feed description')}
            onChange={(e) => setDescValue(e.target.value)}
          />
        </div>
        <button
          style={{
            margin: '20px 0',
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
            background: colors.none,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {!isDragActive && <AttachmentIcon />}
          {isDragActive && !isDragReject && <AttachmentFilledIcon />}
          {isDragReject && t('File type not accepted, sorry!')}
        </button>

        <ToggleArea
          label={t('Invite Only')}
          description={t(
            'No one can start following your feed without an invite.'
          )}
          onChange={useCallback(() => {
            setInviteOnly(!inviteOnly)
          }, [inviteOnly])}
        />
        <div style={{ height: 40 }} />
        <ToggleArea
          label={t('Anyone may post')}
          description={t(
            'Allow all followers to post to the feed, not just admins.'
          )}
          onChange={useCallback(() => {
            setEveryoneCanPost(!everyoneCanPost)
          }, [everyoneCanPost])}
        />

        <CreateFeedPreview
          acceptedFiles={files}
          removeFile={() => setFiles([])}
          name={nameValue}
          desc={descValue}
        />

        <Button
          style={{
            opacity: loading ? 0.4 : 1,
          }}
          type="button"
          className="create-feed"
          disabled={!validated}
          onClick={() => {
            if (!loading) {
              handleSubmit()
            }
          }}
        >
          <div style={{ display: 'flex', flex: 1 }} />
          <p>{t('Create feed')}</p>
          <div
            style={{
              flex: 1,
              fontSize: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
            }}
          >
            {loading && <Spinner size={'20px'} color={colors.textInverted} />}
          </div>
        </Button>
      </div>
    </StyledCreateFeed>
  )
}

export default CreateFeed
