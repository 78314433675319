import { useRef } from 'react'
import { useColors } from '../../../Colors'
import { Container } from '../../../layout/Layout'
import EventCardUserPill from './EventCardUserPill'
import { ReactComponent as AcceptIcon } from '../../../icons/ico-going.svg'
import ClickableOpacity from '../../../ClickableOpacity'
import { css } from '@emotion/css'
import { ModelCollection } from '../../../firestore'
import { PostUserNode } from 'connect-shared/datamodel'
import { useFirestoreCollection } from '@iwikal/reactfire'
import { useTranslate } from '../../../hooks/Internationalization'

type AttendeesProps = {
  onMoreClick: () => void
  eventUsersRef: ModelCollection<PostUserNode>
  yesCount: number
}

export function Attendees({
  eventUsersRef,
  onMoreClick,
  yesCount,
}: AttendeesProps) {
  const MAX_VISIBLE_USERPILLS = 7
  const colors = useColors()
  const t = useTranslate()

  const users = useFirestoreCollection(
    eventUsersRef.where('attending', '==', 'yes').limit(MAX_VISIBLE_USERPILLS)
  )

  const ref = useRef<HTMLDivElement>(null)

  const more = yesCount - MAX_VISIBLE_USERPILLS

  return (
    <Container horizontal>
      <div
        ref={ref}
        style={{
          display: 'flex',
          overflowY: 'hidden',
          overflowX: 'clip',
          flexWrap: 'wrap',
        }}
      >
        {yesCount > 0 ? (
          users
            .read()
            .docs.map((snap) => (
              <EventCardUserPill key={snap.id} userId={snap.id} />
            ))
        ) : (
          <p
            style={{
              color: colors.text,
              fontSize: 14,
              fontWeight: 'normal',
              fontStyle: 'italic',
            }}
          >
            {t('No attendees yet')}
          </p>
        )}
        {more > 0 && (
          <ClickableOpacity onClick={onMoreClick}>
            <Container
              css={css`
                background-color: ${colors.border};
                border: 1px solid ${colors.inputBorder};

                &:hover {
                  background-color: ${colors.selected};
                }
              `}
              vAlign="center"
              hAlign="center"
              horizontal
              style={{
                paddingLeft: 4,
                paddingRight: 4,
                display: 'inline-flex',
                flex: '0 0 auto',
                height: 20,
                borderRadius: 12,
                alignItems: 'center',
                maxWidth: 120,
                marginRight: 8,
                marginBottom: 4,
              }}
            >
              <p style={{ fontSize: 12 }}>
                {t('And %{count} more...', { count: more })}
              </p>
              <AcceptIcon style={{ marginLeft: 4 }} width={15} height={15} />
            </Container>
          </ClickableOpacity>
        )}
      </div>
    </Container>
  )
}
