import { useCallback } from 'react'
import { useOrganizationDoc } from '../hooks/auth'
import { useFirestoreCollection } from '@iwikal/reactfire'
import firebase from 'firebase/app'
import { WorkplaceDocument } from 'connect-shared/types'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import PageLayoutLegacy from '../layout/PageLayoutLegacy'
import { Container, Pusher, Spacer } from '../layout/Layout'
import Card from '../Card'
import { BodyText } from '../Text'
import RoundAvatar from '../RoundAvatar'
import WorkplaceSettings from './WorkplaceSettings'
import { ReactComponent as MailIcon } from '../icons/ico-send-email.svg'
import { useColors } from '../Colors'
import ClickableOpacity from '../ClickableOpacity'
import { firestore } from '../firestore'
import { useWorkplacesWhereAdmin } from '../hooks/useIsWorkplaceAdmin'
import useCurrentUser from '../hooks/useCurrentUser'
import { useTranslate } from '../hooks/Internationalization'

type WorkplaceListItemProps = {
  snap: firebase.firestore.DocumentSnapshot<WorkplaceDocument>
}
function WorkplaceListItem({ snap }: WorkplaceListItemProps) {
  const history = useHistory()
  const workplace = snap.data()
  const { url } = useRouteMatch()
  const hasSlash = url.endsWith('/')
  const to = hasSlash ? url + snap.id : url + '/' + snap.id
  const colors = useColors()
  const t = useTranslate()

  const email = snap.data()?.email

  const workplaceMembersCollection = firestore()
    .collection('users')
    .where('organization', '==', useOrganizationDoc())
    .where('workplaceId', '==', snap.id)

  const numOfMembers = useFirestoreCollection(workplaceMembersCollection).read()
    .docs.length
  return (
    <Card
      style={{
        width: 260,
        height: 267,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: 0,
        marginBottom: 20,
        marginRight: 20,
      }}
    >
      <ClickableOpacity
        onClick={useCallback(() => {
          history.push(to)
        }, [history, to])}
        style={{
          flex: 1,
          width: '100%',
        }}
      >
        <Container style={{ width: '100%', height: '100%' }} hAlign="center">
          <Pusher />
          <RoundAvatar
            size={80}
            name={workplace?.name}
            avatar={workplace?.avatar}
          />
          <Spacer size={15} />
          <BodyText
            style={{
              padding: '0 30px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: 20,
              maxWidth: '100%',
            }}
          >
            {workplace?.name}
          </BodyText>
          <Spacer size={5} />
          <BodyText style={{ color: colors.unavailable }}>
            {t('%{smart_count} Member |||| %{smart_count} Members', {
              smart_count: numOfMembers,
            })}
          </BodyText>{' '}
          <Pusher />
          <a
            href={`mailto:${email}`}
            style={{
              display: 'flex',
              height: 40,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: colors.selected,
              pointerEvents: email ? undefined : 'none',
            }}
          >
            {!!email && (
              <MailIcon width={20} height={15} fill={colors.textDimHard} />
            )}
          </a>
        </Container>
      </ClickableOpacity>
    </Card>
  )
}

export default function Workplaces() {
  const t = useTranslate()
  const organizationDoc = useOrganizationDoc()
  const workplacesWhereAdmin = useWorkplacesWhereAdmin()
    .read()
    .docs.map((snap) => snap.id)
  const workplacesCollection = organizationDoc.collection('workplaces')
  const workplaces = useFirestoreCollection(workplacesCollection)
  const { path } = useRouteMatch()
  const history = useHistory()
  const goBack = useCallback(() => {
    history.push('/settings')
  }, [history])

  const currentUser = useCurrentUser()

  const filteredWorkplaces = workplaces.read().docs.filter((snap) => {
    return (
      workplacesWhereAdmin.includes(snap.id) || currentUser?.organizationAdmin
    )
  })

  const addWorkplace = useCallback(async () => {
    const defaultWorkplace: WorkplaceDocument = {
      name: t('New workplace'),
    }
    const workplaceRef = organizationDoc.collection('workplaces').doc()
    history.push(`workplaces/${workplaceRef.id}`)
    await workplaceRef.set(defaultWorkplace, { merge: true })
  }, [history, organizationDoc, t])

  return (
    <Switch>
      <Route
        path={path + '/:workplaceId'}
        render={useCallback(({ match }) => {
          return <WorkplaceSettings id={match.params.workplaceId} />
        }, [])}
      />
      <Route>
        <PageLayoutLegacy
          backButtonOnClick={goBack}
          wide
          text={t('Workplaces')}
          flex={1}
          backButton
          rightButton={
            currentUser?.organizationAdmin
              ? {
                  text: t('Add workplace'),
                  onClick: () => addWorkplace(),
                }
              : undefined
          }
        >
          <Container
            hAlign="center"
            horizontal
            flex={1}
            style={{
              flexWrap: 'wrap',
            }}
          >
            {filteredWorkplaces.map((snap) => {
              return <WorkplaceListItem key={snap.id} snap={snap} />
            })}
          </Container>
        </PageLayoutLegacy>
      </Route>
    </Switch>
  )
}
