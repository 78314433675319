import { css } from '@emotion/css'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { colors } from 'connect-shared/constants'
import { Timestamp } from 'connect-shared/firestore'
import { PostEventUserStatus } from 'connect-shared/types'
import useStabilized from 'connect-shared/useStabilized'
import { isAfter } from 'date-fns'
import firebase from 'firebase/app'
import { ReactNode, Suspense, useCallback, useMemo, useState } from 'react'
import ClickableOpacity from '../../../ClickableOpacity'
import { firestore } from '../../../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../../../hooks/auth'
import { usePostSnap } from '../../../hooks/feeds'
import { useDateFns, useTranslate } from '../../../hooks/Internationalization'
import { Container, Pusher, Spacer } from '../../../layout/Layout'
import { Attendees } from './Attendees'
import AttendeesModal from './AttendeesModal'
import OptionButton from './OptionButton'

const PostEventTitle = ({ children }: { children: ReactNode }) => {
  return (
    <p
      style={{
        color: colors.text,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '27px',
      }}
    >
      {children}
    </p>
  )
}

const PostEventDescription = ({ children }: { children: ReactNode }) => {
  return (
    <p
      style={{
        color: colors.text,
        fontSize: 16,
        lineHeight: '27px',
      }}
    >
      {children}
    </p>
  )
}

const PostEventLocation = ({ children }) => {
  return <p style={{ color: colors.text, fontSize: 14 }}>{children}</p>
}

type PostEventDateTimeProps = {
  fromDate: Timestamp
  toDate?: Timestamp
  allDay?: boolean
}

function PostEventDateTime({
  fromDate,
  toDate,
  allDay,
}: PostEventDateTimeProps) {
  const { format } = useDateFns()
  const timestampFormat = allDay ? 'eeee dd MMM' : 'eeee dd MMM · HH:mm'
  const from = format(fromDate.toDate(), timestampFormat)
  const to = toDate && format(toDate.toDate(), timestampFormat)

  return (
    <p style={{ color: colors.danger, fontSize: 18, fontWeight: 600 }}>
      {from} {!!to && ` - ${to}`}
    </p>
  )
}

type EventCardProps = {
  feedId: string
  postId: string
}

export default function EventCard({ feedId, postId }: EventCardProps) {
  const t = useTranslate()
  const [attendeesModalOpen, setAttendeesModalOpen] = useState(false)

  const postSnap = usePostSnap(feedId, postId).read()
  const event = postSnap.data()?.event

  const organizationRef = useOrganizationDoc()

  const eventUsersRef = organizationRef
    .collection('feeds')
    .doc(feedId)
    .collection('posts')
    .doc(postSnap.id)
    .collection('users')

  const eventUserRef = useStabilized(eventUsersRef.doc(useCurrentUserId()))
  const currentEventUserSnap = useFirestoreDoc(eventUserRef).read()

  const attendStatus = currentEventUserSnap.data()?.attending

  const postRef = useStabilized(postSnap.ref)

  const setAttending = useCallback(
    async (attendingStatus: PostEventUserStatus) => {
      const deleteField =
        firebase.firestore.FieldValue.delete() as unknown as undefined
      const increment = firebase.firestore.FieldValue.increment(
        1
      ) as unknown as number
      const decrement = firebase.firestore.FieldValue.increment(
        -1
      ) as unknown as number

      const batch = firestore().batch()
      const prevAttending = attendStatus
      const nextAttending =
        prevAttending === attendingStatus ? undefined : attendingStatus

      batch.set(
        eventUserRef,
        { attending: nextAttending || deleteField },
        { merge: true }
      )

      if (prevAttending) {
        batch.update(postRef, {
          ['event.attendingCounters.' + prevAttending]: decrement,
        })
      }

      if (nextAttending) {
        batch.update(postRef, {
          ['event.attendingCounters.' + attendingStatus]: increment,
        })
      }

      await batch.commit()
    },
    [attendStatus, eventUserRef, postRef]
  )

  const eventPassed = useMemo(() => {
    if (!event?.fromDate) return false
    return isAfter(new Date(), event?.fromDate?.toDate())
  }, [event?.fromDate])

  const openAttendeesModal = useCallback(() => {
    setAttendeesModalOpen(true)
  }, [])
  if (!event) return null

  const eventDisabled = eventPassed || event.canceled

  return (
    <Container
      style={{
        backgroundColor: colors.background,
        borderRadius: 5,
        padding: 20,
        pointerEvents: eventDisabled ? 'none' : undefined,
      }}
    >
      <Container style={{ opacity: eventDisabled ? 0.4 : 1.0 }}>
        <Container horizontal vAlign="center">
          {event.fromDate && (
            <PostEventDateTime
              fromDate={event.fromDate}
              toDate={event.toDateEnabled ? event.toDate : undefined}
              allDay={event.allDay}
            />
          )}
          <Pusher />
        </Container>
        <PostEventTitle>{event.title}</PostEventTitle>
        {Boolean(event.description) && (
          <PostEventDescription>{event.description}</PostEventDescription>
        )}
        <PostEventLocation>{event.location}</PostEventLocation>
        <Spacer size={16} />
        <ClickableOpacity
          onClick={openAttendeesModal}
          css={css(`
            text-align: start;
            width: fit-content;
            :hover {
              color: ${colors.active};
            }
          `)}
        >
          <p style={{ fontSize: 14 }}>
            {event?.attendingCounters?.yes || 0} {t('yes')},{' '}
            {event?.attendingCounters?.no || 0} {t('no')},{' '}
            {event?.attendingCounters?.maybe || 0} {t('maybe')}
          </p>
        </ClickableOpacity>
        <Spacer size={11} />
        <Attendees
          onMoreClick={openAttendeesModal}
          eventUsersRef={eventUsersRef}
          yesCount={event?.attendingCounters?.yes || 0}
        />

        <Spacer size={18} />
      </Container>
      <Container
        style={{
          borderTop: `1px solid ${colors.inputBorder}`,
          height: 44,
          color: colors.text,
          fontSize: 14,
        }}
        horizontal
        vAlign="center"
      >
        {eventDisabled ? (
          <>
            {event.canceled
              ? t('This event is canceled')
              : t('This event has passed')}
          </>
        ) : (
          <>
            {t('Are you attending?')}
            <Pusher />
            <OptionButton
              selected={attendStatus === 'yes'}
              onClick={() => setAttending('yes')}
            >
              {t('Yes')}
            </OptionButton>
            <Spacer size={16} />
            <OptionButton
              selected={attendStatus === 'no'}
              onClick={() => setAttending('no')}
            >
              {t('No')}
            </OptionButton>
            <Spacer size={16} />
            <OptionButton
              selected={attendStatus === 'maybe'}
              onClick={() => setAttending('maybe')}
            >
              {t('Maybe')}
            </OptionButton>
          </>
        )}
      </Container>
      {attendeesModalOpen && (
        <Suspense fallback={null}>
          <AttendeesModal
            setAttending={setAttending}
            postSnap={postSnap}
            onRequestClose={() => setAttendeesModalOpen(false)}
          />
        </Suspense>
      )}
    </Container>
  )
}
