import { colors } from 'connect-shared/constants'
import { userFullName } from 'connect-shared/User'
import useUser from '../../../hooks/useUser'
import { Spacer } from '../../../layout/Layout'
import RoundUserAvatar from '../../../RoundUserAvatar'
import { ReactComponent as AcceptIcon } from '../../../icons/ico-check.svg'

type EventCardUserPillProps = {
  userId: string
}

export default function EventCardUserPill({ userId }: EventCardUserPillProps) {
  const user = useUser(userId)
  if (!user) return null

  return (
    <div
      style={{
        display: 'inline-flex',
        flex: '0 0 auto',
        height: 20,
        backgroundColor: colors.border,
        border: `1px solid ${colors.inputBorder}`,
        borderRadius: 12,
        alignItems: 'center',
        maxWidth: 120,
        marginRight: 8,
        marginBottom: 4,
      }}
    >
      <RoundUserAvatar userId={userId} size={20} />
      <Spacer size={4} />
      <p
        style={{
          color: colors.text,
          fontSize: 12,
          width: 80,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {userFullName(user)}
      </p>
      <Spacer size={4} />
      <AcceptIcon width={15} height={15} />
      <Spacer size={4} />
    </div>
  )
}
