import { FirebaseAppProvider } from '@iwikal/reactfire'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import 'firebase/auth'
import 'focus-visible'
import { enableMapSet } from 'immer'
import 'normalize.css'
import { Suspense, useEffect, useRef } from 'react'
import './App.css'
import ErrorBoundary from './chat/ErrorBoundary'
import { ColorsProvider, useColors } from './Colors'
import firebase from './firebase-init'
import FullscreenSpinner from './FullscreenSpinner'
import GlobalStyle from './GlobalStyle'
import materialTheme from './materialTheme'
import Router from './Router'
import Toast from './Toast'
import DevPanel from './DevPanel'
import svLocale from 'date-fns/locale/sv'

function Loading() {
  const colors = useColors()

  return (
    <div
      style={{
        backgroundColor: colors.background,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
      }}
    >
      <FullscreenSpinner />
    </div>
  )
}

function App() {
  const prevUser = useRef<firebase.User | null>(null)

  enableMapSet()

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (prevUser.current && user) {
        // Reload if user changes since all firestore listeners will go haywire
        document.location.href = '/'
      }
      prevUser.current = user
    })
  }, [])

  return (
    <>
      <GlobalStyle />
      <FirebaseAppProvider firebaseApp={firebase.app()}>
        <ColorsProvider>
          <Suspense fallback={<Loading />}>
            <ErrorBoundary fallback={'error'}>
              <ThemeProvider theme={materialTheme}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={svLocale}
                >
                  <Toast />
                  <DevPanel />
                  <Router />
                </LocalizationProvider>
              </ThemeProvider>
            </ErrorBoundary>
          </Suspense>
        </ColorsProvider>
      </FirebaseAppProvider>
    </>
  )
}

export default App
