import { useFirestoreDoc } from '@iwikal/reactfire'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { privateRoomId } from 'connect-shared/new-chat'
import User from 'connect-shared/User'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useColors } from '../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import useMayEditProfile from '../hooks/useMayEditProfile'
import { ReactComponent as EditIcon } from '../icons/ico-edit.svg'
import { ReactComponent as MailIcon } from '../icons/ico-send-email.svg'
import { ReactComponent as ChatIcon } from '../icons/ico-chat.svg'
import { Spacer } from '../layout/Layout'
import IconHoverWrapper from '../IconHoverWrapper'
import { useTranslate } from '../hooks/Internationalization'

export const LowTooltip = withStyles({
  tooltip: {
    margin: 0,
  },
})(Tooltip)

function ProfileActionButton({
  onClick,
  label,
  children,
  href,
  ...props
}: {
  onClick?: () => void
  label?: string
  children?: React.ReactNode
  href?: string
} & IconButtonProps) {
  return (
    <div style={{ alignSelf: 'center' }}>
      <IconHoverWrapper>
        <LowTooltip placement="top" title={label || ''}>
          <IconButton
            tabIndex={-1}
            style={{ margin: -10 }}
            onClick={onClick}
            {...({ href } as any)}
            {...props}
            size="large"
          >
            {children}
          </IconButton>
        </LowTooltip>
      </IconHoverWrapper>
    </div>
  )
}

export default function ProfileActionButtons({
  onRequestEdit,
  onRequestView,
  user,
}: {
  onRequestEdit?: () => void
  onRequestView?: () => void
  user: User
}) {
  const colors = useColors()
  const t = useTranslate()
  const currentUserId = useCurrentUserId()
  const history = useHistory()

  const organization = useFirestoreDoc(useOrganizationDoc()).read().data()

  const navigateChat = useCallback(() => {
    const roomId = privateRoomId(currentUserId, user.id)
    history.push(`/chat/rooms/${roomId}`)
  }, [currentUserId, history, user.id])
  const mayEdit = useMayEditProfile(user.id)

  const chatEnabled = organization?.chatEnabled ?? true
  return (
    <>
      {chatEnabled && (
        <>
          <ProfileActionButton label={t('Chat')} onClick={navigateChat}>
            <ChatIcon fill={colors.label} height={24} width={24} />
          </ProfileActionButton>
          <Spacer size={17} />
        </>
      )}
      <ProfileActionButton label={t('E-mail')} href={'mailto:' + user.email}>
        <MailIcon fill={colors.label} height={23} />
      </ProfileActionButton>
      {mayEdit && (
        <>
          {<Spacer size={17} />}
          {onRequestView && (
            <ProfileActionButton
              label={t('View profile')}
              onClick={onRequestView}
            >
              <EditIcon fill={colors.label} height={20} />
            </ProfileActionButton>
          )}
          {onRequestEdit && (
            <ProfileActionButton
              label={t('Edit profile')}
              onClick={onRequestEdit}
            >
              <EditIcon fill={colors.label} height={20} />
            </ProfileActionButton>
          )}
        </>
      )}
    </>
  )
}
