import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  GetInvitationInfoResponse,
  InvitationEmailDocument,
} from 'connect-shared/types'
import firebase from 'firebase/app'
import useIsMounted from 'ismounted'
import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { claimInvitationEmail, getInvitationInfo } from './cloud-functions'
import { firestore } from './firestore'
import { ReactComponent as Logo } from './icons/beework-logo-blue-yellow.svg'
import InlineBackButton from './InlineBackButton'
import materialTheme from './materialTheme'
import Spinner from './Spinner'
import { showErrorToast, showSuccessToast } from './Toast'

export default function InvitationEmail() {
  const history = useHistory()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const inviteCode = query.get('inviteCode')
  const invitationEmailToken = query.get('invitationEmailToken')
  const [loading, setLoading] = useState(true)
  const [invitationInfo, setInvitationInfo] =
    useState<GetInvitationInfoResponse>()
  const [invitationEmail, setInvitationEmail] =
    useState<InvitationEmailDocument>()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitStep, setSubmitStep] = useState(0)
  const isMounted = useIsMounted()

  const submittingTexts = [
    'Vänligen vänta, skapar konto...',
    'Konfiguerar parametrar...',
    'Gör mer saker, en sekund...',
    'Nästan färdigt! Loggar in...',
    'Nu kör vi!',
  ]

  useEffect(() => {
    async function effect() {
      const currentUser = await new Promise((resolve) => {
        firebase.auth().onAuthStateChanged((user) => {
          resolve(user)
        })
      })
      if (currentUser !== null || !invitationEmailToken) {
        history.replace(`/invitation/${inviteCode}`)
        return
      }

      const invitationEmailRef = firestore()
        .collection('invitationEmails')
        .doc(invitationEmailToken)

      const invitationEmailSnap = await invitationEmailRef.get()
      const data = invitationEmailSnap.data()
      setInvitationEmail(data)

      if (!data || data.claimed) {
        showSuccessToast(
          'Invitation has already been accepted. Sign in to get started!'
        )
        history.replace('/')
        return
      }

      const info = await getInvitationInfo(data?.inviteCode)
      setInvitationInfo(info)

      // Wait for <img> to load to avoid jank
      if (!info.organizationLogo) {
        setLoading(false)
      }
    }

    effect().catch((err) => {
      console.error(err)
      showErrorToast('Unknown error')
      setLoading(false)
    })
  }, [history, invitationEmailToken, inviteCode])

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setSubmitStep(0)
    setSubmitting(true)
    try {
      if (!invitationEmailToken || !inviteCode) return

      if (invitationEmail?.claimed !== false) {
        showErrorToast(
          'Invitation already used, try signing in contact ask your administrator',
          10000
        )
        history.replace('/')
        return
      }

      const step1Timeout = setTimeout(() => {
        setSubmitStep(1)
      }, 2000)

      const step2Timeout = setTimeout(() => {
        setSubmitStep(2)
      }, 4000)
      const res = await claimInvitationEmail({
        password,
        token: invitationEmailToken,
      })

      clearTimeout(step1Timeout)
      clearTimeout(step2Timeout)
      setSubmitStep(3)
      setTimeout(() => {
        if (isMounted.current) {
          setSubmitStep(4)
        }
      }, 2000)

      if (res.status === 'success') {
        await firebase.auth().signInWithCustomToken(res.signInToken)
        history.replace('/')
      } else if (res.status === 'error') {
        console.error(res.code)
      }
    } catch (err) {
      console.error(err)
      showErrorToast('Unknown error')
      history.replace('/')
      return
    }

    setSubmitting(false)
  }

  const loadingCard = (
    <Box display="flex" alignItems="center" flex={1}>
      <Spinner />
    </Box>
  )
  const submittingCard = (
    <>
      <Box flex={1} />
      <Box flex={0} display="flex">
        <Spinner />
      </Box>
      <Box flex={1} display="flex" alignItems="center">
        <Typography variant="h6">{submittingTexts[submitStep]}</Typography>
      </Box>
    </>
  )
  const mainCard = (
    <>
      <Box mb={3} mt={2}>
        {invitationInfo?.organizationLogo && 1 ? (
          <img
            style={{ width: 256, height: 64 }}
            src={invitationInfo?.organizationLogo}
          />
        ) : (
          <Typography variant="h2">
            {invitationInfo?.organizationName}
          </Typography>
        )}
      </Box>
      <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Typography>
            Du är inbjuden att gå med i{' '}
            <strong>{invitationInfo?.organizationName}</strong> på Beework
          </Typography>
          <Typography>Välj ett lösenord för att komma igång!</Typography>
          <TextField
            sx={{
              position: 'absolute',
              width: 0,
              height: 0,
              overflow: 'hidden',
            }}
            label="E-post"
            value={invitationEmail?.email || ''}
            disabled
          />
          <TextField
            label="Välj lösenord"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(true)}
                    onMouseDown={() => setShowPassword(false)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            size="large"
            variant="contained"
            type="submit"
            disabled={password.length < 6 || submitting}
          >
            Skapa konto
          </Button>
        </Stack>
      </form>
    </>
  )

  const cardContent = loading
    ? loadingCard
    : submitting
    ? submittingCard
    : mainCard

  return (
    <Box sx={{ backgroundColor: 'info.light' }} height="100%">
      <img
        // Pre-load image
        style={{ display: 'none' }}
        src={invitationInfo?.organizationLogo || ''}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
      <Container
        maxWidth="sm"
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            flexBasis: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <InlineBackButton
            text
            style={{ marginBottom: materialTheme.spacing(1) }}
            onClick={() => history.push('/')}
          />
        </Box>
        <Box
          maxWidth="sm"
          sx={{
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <Card raised>
            <CardContent
              sx={{
                minHeight: materialTheme.spacing(40),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {cardContent}
            </CardContent>
          </Card>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          flexGrow={1}
          flexShrink={0}
          flexBasis={0}
          sx={{ opacity: 0.6 }}
        >
          <Logo height={50} style={{ margin: materialTheme.spacing(2) }} />
        </Box>
      </Container>
    </Box>
  )
}
