import { CSSProperties } from 'react'
import { useColors } from '../Colors'

export default function SidebarSeparator({ style }: { style?: CSSProperties }) {
  const colors = useColors()
  return (
    <div
      style={{
        margin: '0 10px',
        height: 1,
        backgroundColor: colors.inputBorder,
        width: '100%',
        ...style,
      }}
    />
  )
}
