import firebase from 'firebase/app'
import { Claims } from 'connect-shared/types'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { useObservable } from '@iwikal/reactfire'

export enum AccountInfoState {
  Loading,
  SignedOut,

  // Has signed in but has no account ID (legacy playipp cloud user, sso or
  // (im)possibly unknown state)
  SignedInWithoutAccount,

  // Has signed in with firebase auth but not switched to a user yet
  SignedInAccount,

  // Has signed in with firebase auth and switched to a user with switchUser()
  SignedInUserByAccount,
}

type AccountInfoSignedInAccount = {
  state: AccountInfoState.SignedInAccount
  emailVerified: boolean
  accountId: string
  email: string
}

type AccountInfoLoading = {
  state: AccountInfoState.Loading
}

type AccountInfoSignedOut = {
  state: AccountInfoState.SignedOut
}

type AccountInfoSignedInByAccount = {
  state: AccountInfoState.SignedInUserByAccount
  accountId: string
  emailVerified: true // Email must be verified to switchUser()
  email: string
}
type AccountInfoSignedInWithoutAccount = {
  state: AccountInfoState.SignedInWithoutAccount
}

type AccountInfo =
  | AccountInfoSignedOut
  | AccountInfoSignedInByAccount
  | AccountInfoSignedInAccount
  | AccountInfoSignedInWithoutAccount
  | AccountInfoLoading

export function isAccount(
  info: AccountInfo
): info is AccountInfoSignedInAccount | AccountInfoSignedInByAccount {
  if (
    info.state !== AccountInfoState.SignedInAccount &&
    info.state !== AccountInfoState.SignedInUserByAccount
  ) {
    return false
  } else {
    return true
  }
}

export function useUser() {
  const observable = new Observable<firebase.User | null>((subscriber) =>
    firebase.auth().onIdTokenChanged(subscriber)
  ).pipe(
    // switchMap discards any currently pending promise when the source
    // observable emits a new value. This prevents a slow response from
    // getIdTokenResult from superceding a fresher, faster result
    // (such as null).
    switchMap(
      async (user) =>
        user && {
          user,
          idTokenResult: await user.getIdTokenResult(),
        }
    )
  )

  return useObservable(observable, 'useAccountInfo-idTokenChanged')
}

export default function useAccountInfo(): AccountInfo {
  const result = useUser().read()

  if (!result) {
    return {
      state: AccountInfoState.SignedOut,
    }
  } else {
    const { idTokenResult, user } = result
    const claims = idTokenResult.claims as (
      | Claims
      | { userType: undefined }
    ) & { email_verified?: boolean }
    if (idTokenResult.signInProvider === 'password' && user.email) {
      return {
        state: AccountInfoState.SignedInAccount,
        emailVerified: Boolean(claims.email_verified),
        accountId: user.uid,
        email: user.email,
      }
    } else if (claims.userType === 'human' && claims.accountId) {
      return {
        state: AccountInfoState.SignedInUserByAccount,
        accountId: claims.accountId,
        emailVerified: true, // Email must be verified to switchUser()
        email: claims.email,
      }
    } else {
      return {
        state: AccountInfoState.SignedInWithoutAccount,
      }
    }
  }
}

export function useAccountInfoAssertAccount():
  | AccountInfoSignedInAccount
  | AccountInfoSignedInByAccount {
  const info = useAccountInfo()
  if (isAccount(info)) {
    return info
  } else {
    throw new Error('Not signed in with account')
  }
}
