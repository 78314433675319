import firebaseConfig from './firebase-config.json'
import User from 'connect-shared/User'

const base = `https://europe-west1-${firebaseConfig.projectId}.cloudfunctions.net`

export function textPlaceholderAvatar(
  text: string,
  backgroundColor: string,
  textColor: string
): URL {
  const url = new URL('textThumbnail/thumbnail.png', base)
  url.searchParams.set('text', text)
  url.searchParams.set('textColor', textColor)
  url.searchParams.set('backgroundColor', backgroundColor)
  url.searchParams.set('size', '300')
  return url
}

export function placeholderAvatar(
  fullName: string,
  backgroundColor: string,
  textColor: string
): URL {
  return textPlaceholderAvatar(
    User.initials(fullName).slice(0, 5),
    backgroundColor,
    textColor
  )
}
