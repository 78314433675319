import { ReactComponent as Celebrating } from '../../assets/illustrations/celebrating.svg'
import BlockButton from '../../BlockButton'
import { useColors } from '../../Colors'
import { useTranslate } from '../../hooks/Internationalization'

interface AnnouncementIntroProps {
  close: () => void
}

function Spacer() {
  return <div style={{ height: 16 }} />
}

export default function AnnouncementIntro({ close }: AnnouncementIntroProps) {
  const colors = useColors()
  const t = useTranslate()

  return (
    <>
      <div
        style={{
          maxWidth: 450,
          borderRadius: 5,
          backgroundColor: colors.foreground,
          padding: 30,
          display: 'flex',
          flexDirection: 'column',
          fontSize: 16,
          color: colors.text,
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Celebrating />
        <p style={{ margin: '30px 0 10px 0', fontSize: 20, fontWeight: 600 }}>
          {t('Good job!')}
        </p>
        <p>{t('You confirmed your first reading confirmation post!')}</p>
        <Spacer />
        <p>
          {t(
            'This is a post that the author marked as "Require reading confirmation", which pins the post to the top of the feed and makes it stand out more until you confirm that you have read the post.'
          )}
        </p>
        <Spacer />
        <p>
          {t(
            'You can read more about reading confirmation in our help center.'
          )}
        </p>
        <BlockButton
          onClick={close}
          style={{
            marginTop: 30,
            alignSelf: 'stretch',
          }}
        >
          {t('Got it!')}
        </BlockButton>
      </div>
    </>
  )
}
