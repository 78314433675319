import { Resource, useFirestoreDoc, useObservable } from '@iwikal/reactfire'
import { DocumentSnapshot } from 'connect-shared/firestore'
import { FeedDocument, PostUserDocument } from 'connect-shared/types'
import useStabilized from 'connect-shared/useStabilized'
import { useMemo } from 'react'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { useCurrentUserId, useOrganizationDoc } from './auth'

export function useFeeds() {
  return useStabilized(useOrganizationDoc().collection('feeds'))
}

function useFeedRef(feedId: string) {
  return useStabilized(useOrganizationDoc().collection('feeds').doc(feedId))
}

export function useFeedSnap(feedId: string) {
  return useFirestoreDoc(useFeedRef(feedId))
}

export function usePosts(feedId: string) {
  return useStabilized(
    useOrganizationDoc().collection('feeds').doc(feedId).collection('posts')
  )
}

function usePostRef(feedId: string, postId: string) {
  return useStabilized(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
  )
}

export function usePostSnap(feedId: string, postId: string) {
  return useFirestoreDoc(usePostRef(feedId, postId))
}

export function usePostUsers(feedId: string, postId: string) {
  return useStabilized(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('users')
  )
}

export function usePostCurrentUserSnap(feedId: string, postId: string) {
  return useFirestoreDoc(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('users')
      .doc(useCurrentUserId())
  )
}

export function usePostPollVotesRef(feedId: string, postId: string) {
  const currentUserId = useCurrentUserId()

  return useStabilized(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('pollVotes')
      .doc(currentUserId)
  )
}

export function usePostPollVotesSnap(feedId: string, postId: string) {
  const currentUserId = useCurrentUserId()

  return useFirestoreDoc(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('pollVotes')
      .doc(currentUserId)
  )
}

export function usePostPollResultRef(feedId: string, postId: string) {
  return useStabilized(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('extra')
      .doc('pollResult')
  )
}

export function usePostPollResultSnap(feedId: string, postId: string) {
  return useFirestoreDoc(
    useOrganizationDoc()
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)
      .collection('extra')
      .doc('pollResult')
  )
}

export function useForcedSubscriptions(): Resource<Set<string>> {
  const organization = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const combinedFeedRef = organization
    .collection('combinedFeeds')
    .doc(currentUserId)

  const combinedFeed = useFirestoreDoc(combinedFeedRef)

  return useMemo(
    () => ({
      read: () => {
        return new Set(
          Object.entries(combinedFeed.read().data()?.force ?? {})
            .filter(([, val]) => val)
            .map(([id]) => id)
        )
      },
    }),
    [combinedFeed]
  )
}

export function useSubscriptions(): Resource<Set<string>> {
  const organization = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const combinedFeedRef = organization
    .collection('combinedFeeds')
    .doc(currentUserId)

  const combinedFeed = useFirestoreDoc(combinedFeedRef)

  return useMemo(
    () => ({
      read: () => {
        return new Set(
          Object.entries(combinedFeed.read().data()?.subscriptions ?? {})
            .filter(([, val]) => val)
            .map(([id]) => id)
        )
      },
    }),
    [combinedFeed]
  )
}

function useFeedsObservable(feedIds: string[]) {
  const organizationDoc = useOrganizationDoc()

  if (feedIds.length === 0) return new BehaviorSubject([])

  return combineLatest(
    [...feedIds].map((id) => {
      return new Observable<DocumentSnapshot<FeedDocument>>((subscriber) =>
        organizationDoc
          .collection('feeds')
          .doc(id)
          .onSnapshot({
            next: (value) => subscriber.next(value),
            error: (value) => subscriber.error(value),
            complete: () => subscriber.complete(),
          })
      )
    })
  )
}

function useSubscribedFeeds() {
  const feedIds = [...useSubscriptions().read()]
  return useObservable(
    useFeedsObservable(feedIds),
    'useSubscribedFeeds-' + feedIds.sort().join('/')
  )
}

export function useWritableFeeds(): Resource<DocumentSnapshot<FeedDocument>[]> {
  const currentUserId = useCurrentUserId()
  const subs = useSubscribedFeeds()
  return useMemo(
    () => ({
      read: () =>
        subs.read().filter((snap) => {
          const feed = snap.data()
          if (!feed) return false
          if (feed.anyoneMayPost) return true
          if (feed.admins?.[currentUserId]) return true
          return false
        }),
    }),
    [subs, currentUserId]
  )
}

export function usePostUser(
  feedId: string,
  postId: string,
  userId: string
): Resource<DocumentSnapshot<PostUserDocument>> {
  return useFirestoreDoc(usePostUsers(feedId, postId).doc(userId))
}
