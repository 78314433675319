import React, { ReactNode } from 'react'
import ClickableOpacity, { ClickableOpacityProps } from './ClickableOpacity'
import { useColors } from './Colors'
import { Container } from './layout/Layout'
import { BodyText } from './Text'

type FeedSettingsButtonProps = {
  children: ReactNode
  onClick?: () => void
  active?: boolean
} & ClickableOpacityProps

export default function FeedSettingsButton({
  children,
  onClick,
  active,
  ...props
}: FeedSettingsButtonProps) {
  const colors = useColors()
  return (
    <ClickableOpacity
      style={{
        borderRadius: 5,
      }}
      hover
      onClick={onClick}
      {...props}
    >
      <Container
        style={{
          padding: 15,
          borderRadius: 5,
          backgroundColor: active ? colors.inputBorder : colors.none,
        }}
      >
        <BodyText
          style={{
            fontWeight: 600,
            fontSize: 14,
            color: colors.settingsBottom,
          }}
        >
          {children}
        </BodyText>
      </Container>
    </ClickableOpacity>
  )
}
