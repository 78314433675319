import { useFirestoreCollection } from '@iwikal/reactfire'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useColors } from '../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { Container, Spacer } from '../layout/Layout'
import PageLayout from '../layout/PageLayout'
import PlayippSpinner from '../playippSpinner/PlayippSpinner'
import DiscoverFeedsItem from './DiscoverFeedsItem'
import FeedMenu from './FeedMenu'
import { useCombined } from './Feeds'
import DiscoverFeedsWelcomeBlock from './DiscoverFeedsWelcomeBlock'
import useCurrentUser from '../hooks/useCurrentUser'
import { ReactComponent as EmptyIllustration } from '../icons/Empty.svg'
import { BodyText } from '../Text'
import OutlineButton from '../OutlineButton'
import CreateNewFeedModal from './CreateNewFeedModal'
import { useTranslate } from '../hooks/Internationalization'

export default function DiscoverFeeds() {
  const [createNewFeedModalOpen, setCreateFeedModalOpen] = useState(false)
  const openNewFeedModal = useCallback(() => {
    setCreateFeedModalOpen(true)
  }, [])

  const closeNewFeedModal = useCallback(() => {
    setCreateFeedModalOpen(false)
  }, [])

  const [addedFeedIds, setAddedFeedIds] = useState<Set<string>>(new Set())
  const organizationDoc = useOrganizationDoc()
  const combined = useCombined()
  const subscriptions = useMemo(() => {
    return Object.keys(combined.read().data()?.subscriptions || {})
  }, [combined])

  const colors = useColors()
  const t = useTranslate()

  const currentUser = useCurrentUser()
  const feedsRef = useMemo(() => {
    if (currentUser?.organizationAdmin) {
      return organizationDoc.collection('feeds')
    } else {
      return organizationDoc
        .collection('feeds')
        .where('inviteOnly', '==', false)
    }
  }, [currentUser?.organizationAdmin, organizationDoc])

  const currentUserId = useCurrentUserId()

  const feedSnaps = useFirestoreCollection(feedsRef)
    .read()
    .docs.filter((feedSnap) => {
      const admins = Object.keys(feedSnap.data().admins || {})
      return (
        addedFeedIds.has(feedSnap.id) ||
        (!subscriptions.includes(feedSnap.id) &&
          !admins.includes(currentUserId))
      )
    })
    .sort((a, b) => {
      const aName = a.data().name?.toLocaleLowerCase() || ''
      const bName = b.data().name?.toLocaleLowerCase() || ''
      if (!aName) return 1
      if (!bName) return -1
      return aName.localeCompare(bName)
    })

  const history = useHistory()

  const selectFeed = useCallback(
    (feedId: string) => history.push('/feeds/' + feedId),
    [history]
  )

  const handleOnFeedAdd = useCallback((id: string) => {
    setAddedFeedIds((addedFeeds) => {
      const newAddedFeeds = new Set(addedFeeds)
      newAddedFeeds.add(id)
      return newAddedFeeds
    })
  }, [])

  return (
    <PageLayout
      leftSideContent={
        <FeedMenu selectFeed={selectFeed} combinedFeedRes={combined} />
      }
    >
      <React.Suspense
        fallback={
          <Container vAlign="center" hAlign="center" style={{ height: '100%' }}>
            <PlayippSpinner size={60} color={colors.active} />
          </Container>
        }
      >
        <Container style={{ marginTop: 30 }}>
          <DiscoverFeedsWelcomeBlock />
          {feedSnaps.length ? (
            feedSnaps.map((feedDocSnap) => {
              return (
                <DiscoverFeedsItem
                  onFeedAdded={handleOnFeedAdd}
                  style={{ marginBottom: 20 }}
                  feedSnap={feedDocSnap}
                  key={feedDocSnap.id}
                />
              )
            })
          ) : (
            <Container style={{ marginTop: 70 }} flex={1} hAlign="center">
              <EmptyIllustration width={247} height={247} />
              <BodyText style={{ fontSize: 14, color: colors.chatRead }}>
                {t('There are no more feeds to find')}
              </BodyText>
              <Spacer size={20} />
              <BodyText
                style={{
                  fontSize: 14,
                  fontWeight: 300,
                  color: colors.chatRead,
                }}
              >
                {t('Does the feed you are looking for not exist?')}
              </BodyText>
              <Spacer size={10} />
              <OutlineButton onClick={openNewFeedModal}>
                {t('Create a new feed')}
              </OutlineButton>
            </Container>
          )}
        </Container>
      </React.Suspense>
      <CreateNewFeedModal
        close={closeNewFeedModal}
        open={createNewFeedModalOpen}
      />
    </PageLayout>
  )
}
