import { CSSProperties, HTMLAttributes, useMemo } from 'react'
import { useColors } from './Colors'

type CaptionTextProps = {
  flex?: CSSProperties['flex']
  strong?: boolean
} & HTMLAttributes<HTMLSpanElement>

/**
 * Text lager than BodyText, smaller than HeadingText
 */
export function CaptionText({
  flex = '0 0 auto',
  style,
  strong,
  ...spanProps
}: CaptionTextProps) {
  const colors = useColors()
  return (
    <span
      style={{
        fontWeight: strong ? 600 : 'normal',
        color: colors.text,
        fontSize: 16,
        flex,
        ...style,
      }}
      {...spanProps}
    />
  )
}

type BodyTextProps = {
  flex?: CSSProperties['flex']
} & HTMLAttributes<HTMLSpanElement>

/**
 * Standard Text for main text
 */
export function BodyText({
  flex = '0 0 auto',
  style,
  ...spanProps
}: BodyTextProps) {
  const colors = useColors()
  return (
    <span
      style={{
        lineHeight: 1.5,
        color: colors.text,
        flex,
        fontSize: 14,
        fontWeight: 'normal',
        ...style,
      }}
      {...spanProps}
    />
  )
}

type HeadingTextProps = {
  flex?: CSSProperties['flex']
} & HTMLAttributes<HTMLSpanElement>

/**
 * Large text for title/heading
 */
export function HeadingText({
  flex = '0 0 auto',
  style,
  ...spanProps
}: HeadingTextProps) {
  const colors = useColors()
  return (
    <span
      style={{
        color: colors.text,
        flex,
        fontSize: 21,
        fontWeight: 'normal',
        ...style,
      }}
      {...spanProps}
    />
  )
}

type NoteTextProps = {
  flex?: CSSProperties['flex']
  danger?: boolean
  success?: boolean
  faint?: boolean
} & HTMLAttributes<HTMLSpanElement>

/**
 * Tiny text for e.g. footnotes
 */
export function NoteText({
  flex = '0 0 auto',
  style,
  danger,
  success,
  faint,
  ...spanProps
}: NoteTextProps) {
  const colors = useColors()
  const color = useMemo(() => {
    if (danger) {
      return colors.danger
    } else if (success) {
      return colors.success
    } else if (faint) {
      return colors.textDim
    } else {
      return colors.text
    }
  }, [
    colors.danger,
    colors.success,
    colors.text,
    colors.textDim,
    danger,
    faint,
    success,
  ])
  return (
    <span
      style={{
        color: color,
        flex,
        fontSize: 10,
        fontWeight: 600,
        ...style,
      }}
      {...spanProps}
    />
  )
}
