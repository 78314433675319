import IconButton from '../../IconButton'
import styled from '@emotion/styled'
import { useState } from 'react'

interface StatusButtonProps {
  disabled?: boolean
  Icon: any
  iconSize: number
  hoverText: string
  onClick: () => void
  color: string
  iconColor?: string
  opacity?: number
  size?: number
  className?: string
}

const StyledLabel = styled.label`
  div.hoverText {
    visibility: hidden;
  }

  &:hover,
  &:focus-within {
    div.hoverText {
      visibility: visible;
    }
  }
`

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export default function StatusButton({
  disabled,
  Icon,
  iconSize,
  hoverText,
  onClick,
  color,
  iconColor,
  opacity = 1,
  size = 50,
  className,
}: StatusButtonProps) {
  const [hovered, setHovered] = useState(false)

  const rgbColor = hexToRgb(color)
  return (
    <StyledLabel>
      <div
        className="hoverText"
        style={{
          height: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ fontSize: 10, color: color }}>{hoverText}</p>
      </div>

      <IconButton
        className={className}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          borderRadius: size / 2,
          cursor: disabled ? 'auto' : 'pointer',
          border: `2px solid ${color}`,
          margin: '0 6px',
          backgroundColor: `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${
            rgbColor?.b
          }, ${hovered ? 0.3 : 0})`,
          opacity: opacity,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        Icon={Icon}
        size={iconSize}
        onClick={disabled ? () => {} : onClick}
        fill={iconColor}
      />
    </StyledLabel>
  )
}
