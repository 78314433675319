import { Button } from '@mui/material'
import { useCallback } from 'react'
import { Container, Spacer } from './layout/Layout'
import PlayippSpinner from './playippSpinner/PlayippSpinner'
import SignInLayout from './SignInLayout'
import { HeadingText } from './Text'
import 'firebase/auth'
import firebase from 'firebase/app'
import { useTranslate } from './hooks/Internationalization'

export default function SsoSpinner() {
  const t = useTranslate()
  const handleClick = useCallback(() => {
    firebase.auth().signOut()
  }, [])

  return (
    <SignInLayout>
      <Container hAlign="center">
        <HeadingText>{t('Signing in')}</HeadingText>
        <Spacer size={30} />
        <PlayippSpinner size={40} />
        <Spacer size={30} />
        <Button variant="contained" onClick={handleClick}>
          {t('Cancel')}
        </Button>
      </Container>
    </SignInLayout>
  )
}
