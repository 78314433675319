import { PostDocument, ProgressiveImage } from 'connect-shared/types'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { Container, ContainerProps } from '../../../layout/Layout'

type FeedPostGalleryImageProps = {
  image: ProgressiveImage
} & ContainerProps

function FeedPostGalleryImage({
  image,
  style,
  ...rest
}: FeedPostGalleryImageProps) {
  return (
    <Container
      style={{
        ...style,
      }}
      {...rest}
    >
      <img
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          maxHeight: 600,
        }}
        alt=""
        src={image.fullImage.uri}
      />
    </Container>
  )
}

type FeedPostGalleryProps = {
  postSnap: QueryDocumentSnapshot<PostDocument>
  onImageClick?: (index: number) => void
} & ContainerProps

export default function FeedPostGallery({
  postSnap,
  style,
  onImageClick,
  ...rest
}: FeedPostGalleryProps) {
  const post = postSnap.data()
  if (!post.images) return null

  const imageCount = post.images.length
  const images = post.images.slice(0, Math.min(3, imageCount))

  return (
    <Container
      style={{
        height: 600,
        flex: '0 1 100%',
        flexDirection: 'column',
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        ...style,
      }}
      {...rest}
    >
      {images.map((image, index) => {
        return (
          <div
            style={{
              position: 'relative',
              flex:
                (imageCount >= 3 && index === 0) || imageCount == 1
                  ? '0 1 100%'
                  : '0 1 50%',
            }}
            key={image.fullImage.uri}
          >
            <FeedPostGalleryImage
              onClick={() => onImageClick?.(index)}
              style={{
                padding: 5,
                zIndex: 1,
              }}
              image={image}
            />
            {index === images.length - 1 && imageCount > 3 && (
              <div
                onClick={() => onImageClick?.(index)}
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5,
                  width: 'auto',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'rgba(0, 0, 0, 0.2)',
                  color: '#fff',
                  fontSize: 34,
                  fontWeight: 700,
                  zIndex: 1,
                }}
              >
                {`+${imageCount - (images?.length || 0)}`}
              </div>
            )}
          </div>
        )
      })}
    </Container>
  )
}
