import bytes from 'bytes'
import { CSSProperties, useCallback } from 'react'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import { useTranslate } from './hooks/Internationalization'
import IconButton from './IconButton'
import { ReactComponent as DownloadIcon } from './icons/download.svg'
import { ReactComponent as AttachIcon } from './icons/ico-add-file.svg'
import { ReactComponent as CloseIcon } from './icons/ico-delete.svg'
import { Container } from './layout/Layout'
import { BodyText } from './Text'

export function FileIcon({ size }: { size: number }) {
  const colors = useColors()

  return (
    <Container
      hAlign="center"
      vAlign="center"
      style={{
        width: size,
        height: size,
        backgroundColor: colors.active,
        borderRadius: size / 2,
      }}
    >
      <AttachIcon
        width={size * 0.65}
        height={size * 0.65}
        fillColor={colors.textInverted}
      />
    </Container>
  )
}

export default function FilePreview({
  name,
  size,
  url,
  progress,
  onRemove,
  style,
}: {
  name: string
  size: number
  url: string
  progress?: number
  onRemove?: () => void
  style?: CSSProperties
}) {
  const colors = useColors()
  const t = useTranslate()

  const progressPercentage = progress ? progress * 100 : undefined

  const download = useCallback(() => {
    window.open(url)
  }, [url])

  return (
    <Container
      vAlign="center"
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.webChatModalBackground,
        border: `1px solid ${colors.inputBorder}`,
        borderRadius: 5,
        ...style,
      }}
    >
      <Container flex={1} horizontal>
        <ClickableOpacity
          onClick={download}
          style={{
            textAlign: 'start',
            flex: 1,
            backgroundColor: colors.none,
            border: 0,
            display: 'flex',
            flexDirection: 'row',
            padding: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 20,
          }}
        >
          <FileIcon size={24} />
          <BodyText
            style={{
              flex: 1,
              fontWeight: 600,
              fontSize: 13,
              marginLeft: 10,
              alignSelf: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {name}
            <BodyText
              style={{
                marginLeft: 11,
                alignSelf: 'center',
                color: colors.labelDim,
              }}
            >
              {`${bytes(size)}`}
              {progress === 1 && t(' - Upload complete')}
            </BodyText>
          </BodyText>
        </ClickableOpacity>
        {onRemove ? (
          <IconButton
            style={{ paddingRight: 12 }}
            onClick={onRemove}
            Icon={CloseIcon}
            size={20}
          />
        ) : (
          <Container
            onClick={download}
            style={{ cursor: 'pointer' }}
            horizontal
            vAlign="center"
          >
            <BodyText
              style={{
                fontSize: 14,
                color: colors.active,
              }}
            >
              {t('Download')}
            </BodyText>
            <DownloadIcon
              style={{ marginLeft: 16, marginRight: 20 }}
              fill={colors.active}
              width={17}
              height={17}
            />
          </Container>
        )}
      </Container>
      {progress !== undefined && progress !== 1 && (
        <div
          style={{
            backgroundColor: colors.label,
            borderRadius: 12,
            margin: '5px 10px',
          }}
        >
          <div
            style={{
              height: 5,
              backgroundColor: colors.active,
              width: progress ? `${progressPercentage}%` : 0,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 12,
            }}
          />
        </div>
      )}
    </Container>
  )
}
