import { useUserCounts } from '../settings/ManageUsers'
import { usePlanMetadata } from './usePlanMetadata'

export function useUserlimit(orgId?: string): {
  userCount: number
  userLimit: number | undefined
  exceeded: boolean
  reached: boolean
} {
  const plan = usePlanMetadata(orgId)
  const userCount = useUserCounts(orgId).read().data()?.totalUsers || 0
  const userLimit = plan.maxUsers
  const exceeded = userLimit !== undefined && userCount > userLimit
  const reached = userLimit !== undefined && userCount >= userLimit

  return {
    userCount,
    userLimit,
    exceeded,
    reached,
  }
}
