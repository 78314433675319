import { Switch } from '@mui/material'
import React from 'react'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import { Container, Spacer } from './layout/Layout'
import { BodyText, CaptionText } from './Text'
import { css } from '@emotion/css'

type ToggleBlockProps = {
  title?: string
  text?: string
  value?: boolean
  onChange?: () => void
  disabled?: boolean
}

export default function ToggleBlock({
  title,
  text,
  value,
  onChange,
  disabled,
}: ToggleBlockProps) {
  const colors = useColors()
  return (
    <ClickableOpacity
      disabled={disabled}
      onClick={onChange}
      style={{ textAlign: 'start' }}
    >
      <Container
        css={css`
          border: 1px solid ${colors.inputBorder};
          border-radius: 5px;

          &:hover {
            border: 1px solid ${disabled ? 'none' : colors.active};
          }
        `}
        vAlign="center"
        horizontal
        style={{
          padding: 20,
        }}
      >
        <Container>
          <Switch disabled={disabled} checked={value} color="primary" />
        </Container>
        <Spacer size={20} />
        <Container flex={1}>
          <CaptionText style={{ fontWeight: 600 }}>{title}</CaptionText>
          <BodyText style={{ fontSize: 14, color: colors.labelStrong }}>
            {text}
          </BodyText>
        </Container>
      </Container>
    </ClickableOpacity>
  )
}
