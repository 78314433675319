import { useColors } from './Colors'
import ClickableOpacity from './ClickableOpacity'
import { ReactComponent as CheckIcon } from './icons/ico-check.svg'
import { Container, Spacer } from './layout/Layout'
import { css } from '@emotion/react'

export default function FilterPill({
  text,
  selected,
  onClick,
}: {
  text: string
  selected: boolean
  onClick: () => void
}) {
  const colors = useColors()
  return (
    <Container
      css={css`
        border: ${selected ? 0 : `1px solid ${colors.label}`};
        color: ${selected ? colors.textInverted : colors.text};

        p {
          color: ${selected ? colors.textInverted : colors.text};
        }

        &:hover {
          border: ${selected ? 0 : `1px solid ${colors.active}`};
          p {
            color: ${selected ? colors.textInverted : colors.active};
          }
        }
      `}
      style={{
        borderRadius: 10,

        backgroundColor: selected ? colors.active : colors.none,
      }}
    >
      <ClickableOpacity
        style={{
          borderRadius: 10,
          padding: '1px 10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onClick={onClick}
      >
        {selected && (
          <>
            <CheckIcon width={20} height={20} fillColor={colors.background} />
            <Spacer size={5} />
          </>
        )}
        <p
          style={{
            lineHeight: 1.5,
            flex: '0 0 auto',
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {text}
        </p>
      </ClickableOpacity>
    </Container>
  )
}
