import { useMemo } from 'react'
import { useFirestoreDoc } from '@iwikal/reactfire'
import User from 'connect-shared/User'
import { resource } from 'react-suspense-img'
import { firestore } from '../firestore'
import { UserDocument } from 'connect-shared/types'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import usePlaceholderAvatar from './usePlaceholderAvatar'

function useUserSnap(userId?: string) {
  const userDoc = userId
    ? firestore().collection('users').doc(userId)
    : firestore().collection('empty').doc('empty')
  const resource = useFirestoreDoc(userDoc)

  try {
    const userSnap = resource.read()
    if (userSnap.exists) {
      return userSnap as QueryDocumentSnapshot<UserDocument>
    } else {
      return undefined
    }
  } catch (err) {
    if (err.name === 'FirebaseError' && err.code === 'permission-denied') {
      return undefined
    }
    throw err
  }
}

export default function useUser(userId?: string) {
  const userSnap = useUserSnap(userId)

  const user = useMemo(() => userSnap && new User(userSnap), [userSnap])
  const placeholderAvatar = usePlaceholderAvatar(user?.fullName)
  if (user && !user.avatar) {
    user.avatar = placeholderAvatar.toString()
  }

  if (user?.avatar) {
    resource.preloadImage(user.avatar)
  }

  return user
}
