import styled from '@emotion/styled'
import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { CommentNode, PostNode } from 'connect-shared/datamodel'
import { feedIdFromPostSnap, Snap } from 'connect-shared/feed'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { CommentDocument, CommentUserDocument } from 'connect-shared/types'
import { memo, useCallback, useMemo, useState } from 'react'
import { ModelDocument } from '../../firestore'
import HighlightText from '../../HighlightText'
import { useCurrentUserId } from '../../hooks/auth'
import { useFeedSnap } from '../../hooks/feeds'
import { useTranslate } from '../../hooks/Internationalization'
import useUser from '../../hooks/useUser'
import { Container } from '../../layout/Layout'
import { Resource } from '../../resource'
import CommentItemHeader from './CommentItemHeader'
import CommentReplies from './CommentReplies'
import EditCommentItem from './EditCommentItem'
import RemoveCommentItem from './RemoveCommentItem'
import ReplyCommentItem from './ReplyCommentItem'

export function useIsEnergized(
  commentSnap: QueryDocumentSnapshot<CommentDocument>
): Resource<boolean> {
  const userId = useCurrentUserId()
  const commentRef = commentSnap.ref as ModelDocument<CommentNode>
  const commentUserResource = useFirestoreDoc(
    commentRef.collection('users').doc(userId)
  )

  return useMemo(
    () => ({
      read: () => {
        const commentUser = commentUserResource.read().data()
        if (!commentUser) return false
        return !!commentUser.likes
      },
    }),
    [commentUserResource]
  )
}

const StyledCommentsList = styled.li`
  width: 100%;
  list-style: none;
  padding: 0 10px;
  font-size: 13px;
  margin-bottom: 30px;
  .replyIcon {
    cursor: pointer;
  }
  .commentBody {
    word-break: break-word;
    overflow: hidden;
  }
  .commentResponseButtons {
    width: 100px;
    flex: 2;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
  ul.commentChildren {
    margin-top: 15px;
    padding-left: 10px;
  }
  .toggleReplies {
    font-size: 12px;
    font-weight: 600;
    color: #4b9fcc;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
  }
`

// TODO: this needs to scroll in div, not window. Not used atm.
// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
type CommentItemProps = {
  commentSnap: QueryDocumentSnapshot<CommentDocument>
  postRef: ModelDocument<PostNode>
}
const CommentItem = ({ commentSnap, postRef }: CommentItemProps) => {
  const t = useTranslate()
  const [replyToComment, setReplyToComment] = useState(false)
  const [showReplies, setShowReplies] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)

  const comment = commentSnap.data()
  const currentUserId = useCurrentUserId()
  const repliesRef = postRef
    .collection('comments')
    .where('parent', '==', commentSnap.id)
    .orderBy('createdDate', 'desc')

  const repliesResource = useFirestoreCollection(repliesRef)

  const postSnap = useFirestoreDoc(postRef).read()
  const feedSnap = useFeedSnap(feedIdFromPostSnap(postSnap as Snap))
  const feed = feedSnap.read().data()
  const author = useUser(comment.author)
  const isAuthor = author && author.id === currentUserId
  const isAdmin = (feed && feed.admins && feed.admins[currentUserId]) || false
  const allowEdit = isAuthor || isAdmin
  const energized = useIsEnergized(commentSnap)

  const energizeComment = useCallback(async () => {
    const updateData: CommentUserDocument = {
      likes: energized.read() ? 0 : 1,
    }
    await commentSnap.ref
      .collection('users')
      .doc(currentUserId)
      .set(updateData, { merge: true })
  }, [commentSnap.ref, energized, currentUserId])

  const removeComment = useCallback(() => {
    commentSnap.ref.delete()
  }, [commentSnap.ref])

  const reply = useCallback(() => {
    setReplyToComment(!replyToComment)
  }, [replyToComment])

  const enableEditMode = useCallback(() => {
    setEditMode(true)
  }, [])

  const disableEditMode = useCallback(() => {
    setEditMode(false)
  }, [])

  const enableDeleteMode = useCallback(() => {
    setDeleteMode(true)
  }, [])

  const disableDeleteMode = useCallback(() => {
    setDeleteMode(false)
  }, [])

  if (deleteMode) {
    return (
      <RemoveCommentItem
        onCancel={disableDeleteMode}
        onAccept={removeComment}
      />
    )
  }

  if (editMode) {
    return (
      <>
        <EditCommentItem
          commentSnap={commentSnap}
          onEditCancel={disableEditMode}
        />
        {showReplies && (
          <ul className="commentChildren">
            {repliesResource.read().docs.map((child) => {
              return (
                <CommentReplies
                  key={child.id}
                  commentSnap={child}
                  postRef={postRef}
                />
              )
            })}
          </ul>
        )}
      </>
    )
  }

  return (
    <StyledCommentsList>
      {!comment.parent && (
        <>
          <CommentItemHeader
            onEditClick={enableEditMode}
            onRemoveClick={allowEdit ? enableDeleteMode : undefined}
            onReply={reply}
            energized={energized.read()}
            onEnergize={energizeComment}
            authorId={comment.author}
            commentSnap={commentSnap}
            editMode={editMode}
            isAdmin={isAdmin}
          />
          <Container
            className="commentBody"
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 1.4,
            }}
          >
            <HighlightText mentions={comment.mentions} text={comment.body} />
          </Container>
        </>
      )}
      {repliesResource.read().size > 0 && (
        <>
          <Container
            className="toggleReplies"
            onClick={() => setShowReplies((show) => !show)}
          >
            {showReplies
              ? t('Hide all replies')
              : t('Show %{count} replies', {
                  count: repliesResource.read().size,
                })}{' '}
          </Container>

          {showReplies && (
            <ul className="commentChildren">
              {repliesResource.read().docs.map((child) => {
                return (
                  <CommentReplies
                    key={child.id}
                    commentSnap={child}
                    postRef={postRef}
                  />
                )
              })}
            </ul>
          )}
        </>
      )}
      {replyToComment && (
        <Container>
          <ReplyCommentItem commentSnap={commentSnap} post={postRef} />
        </Container>
      )}
    </StyledCommentsList>
  )
}

export default memo(CommentItem)
