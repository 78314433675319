import { ComponentType, CSSProperties, ReactNode } from 'react'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { IconProps } from '../IconButton'
import IconHoverWrapper from '../IconHoverWrapper'
import { Container, Spacer } from '../layout/Layout'
import { BodyText } from '../Text'

type SidebarListItemProps = {
  active?: boolean
  text: string
  onClick?: () => void
  AvatarComponent?: ReactNode
  subText?: string
  Icon?: ComponentType<IconProps & any>
  style?: CSSProperties
}

export default function SidebarListItem({
  text,
  active,
  onClick,
  AvatarComponent,
  subText,
  Icon,
  style,
}: SidebarListItemProps) {
  const colors = useColors()
  return (
    <IconHoverWrapper style={{ flex: 1 }}>
      <ClickableOpacity
        hover
        onClick={onClick}
        style={{
          width: '100%',
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Container
          style={{
            backgroundColor: active ? colors.inputBorder : undefined,
            padding: 6,
            borderRadius: 5,
            overflow: 'hidden',

            ...style,
          }}
          flex={1}
          horizontal
          vAlign="center"
        >
          {Icon && <Icon width={20} height={20} fill={colors.labelDim} />}
          <Spacer size={10} />
          {AvatarComponent && (
            <>
              <Container>{AvatarComponent}</Container>
              <Spacer size={10} />
            </>
          )}
          <Container
            style={{
              flex: 1,
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <BodyText
              style={{
                flex: 1,
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </BodyText>
            {subText && (
              <BodyText
                style={{
                  fontSize: 14,
                  marginTop: -4,
                  fontWeight: 300,
                  color: colors.labelDim,
                }}
              >
                {subText}
              </BodyText>
            )}
          </Container>
        </Container>
      </ClickableOpacity>
    </IconHoverWrapper>
  )
}
