import { colors } from 'connect-shared/constants'
import { feedIdFromPostSnap } from 'connect-shared/feed'
import { PostDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import React from 'react'
import ListUserItem from '../../addUsers/ListUserItem'
import { useOrganizationDoc } from '../../hooks/auth'
import HoverButton from '../../HoverButton'
import { useScrollLimitQuery } from '../../chat/useScrollLimitQuery'
import Spinner from '../../Spinner'
import { Container } from '../../layout/Layout'
import { useTranslate } from '../../hooks/Internationalization'

enum SelectedList {
  read = 'read',
  unread = 'unread',
}

export default function AnnouncementSeenBy({
  postSnap,
  style,
  isModal,
}: {
  postSnap: firebase.firestore.QueryDocumentSnapshot<PostDocument>
  style?: React.CSSProperties
  isModal?: boolean
}) {
  const [selectedList, setSelectedList] = React.useState<SelectedList>(
    SelectedList.read
  )

  const postId = postSnap.id
  const feedId = feedIdFromPostSnap(postSnap)

  const organizationDoc = useOrganizationDoc()

  const query = organizationDoc
    .collection('feeds')
    .doc(feedId)
    .collection('posts')
    .doc(postId)
    .collection('users')
    .where('confirmedSeen', '==', selectedList === SelectedList.read)
    .orderBy('lowerFullName')

  const confirmCount = postSnap.data().confirmCount || 0
  const canConfirmCount = postSnap.data().canConfirmCount || 0

  const buttonStyle: React.CSSProperties = {
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    borderRadius: '100px 0 0 100px',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 600,
    padding: '7px 0',
  }

  const t = useTranslate()

  const setUserListRead = React.useCallback(() => {
    setSelectedList(SelectedList.read)
  }, [])

  const setUserListUnread = React.useCallback(() => {
    setSelectedList(SelectedList.unread)
  }, [])

  const listIsUnread = selectedList === SelectedList.unread

  const emptyMessage: string = listIsUnread
    ? t('Everyone has read the announcement')
    : t('No one has read the announcement')

  const {
    snap: usersSnap,
    scrollRef,
    hasMore,
  } = useScrollLimitQuery(query, {
    initialLimit: 10,
  })

  const users = usersSnap.docs

  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 20px 20px 0',
        }}
      >
        <HoverButton
          onClick={setUserListRead}
          textColor={listIsUnread ? colors.text : colors.textInverted}
          textHoverColor={
            listIsUnread ? colors.textDimHard : colors.textInverted
          }
          backgroundColor={listIsUnread ? colors.border : colors.active}
          hoverColor={listIsUnread ? colors.selected : colors.active}
          style={{
            ...buttonStyle,
            borderRadius: '100px 0 0 100px',
          }}
        >
          {t('Read')}
        </HoverButton>
        <HoverButton
          onClick={setUserListUnread}
          textColor={listIsUnread ? colors.textInverted : colors.text}
          textHoverColor={
            listIsUnread ? colors.textInverted : colors.textDimHard
          }
          backgroundColor={listIsUnread ? colors.active : colors.border}
          hoverColor={listIsUnread ? colors.active : colors.selected}
          style={{
            ...buttonStyle,
            borderRadius: '0 100px 100px 0',
          }}
        >
          {t('Unread')}
        </HoverButton>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          borderBottom: `1px solid ${colors.faint}`,
          marginRight: 30,
        }}
      >
        <p style={{ fontSize: 16 }}>{t('Total')}</p>
        <p style={{ fontSize: 16 }}>
          {selectedList === 'unread'
            ? canConfirmCount - confirmCount
            : confirmCount}
        </p>
      </div>
      <div
        ref={scrollRef}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: isModal ? 500 : 'auto',
        }}
      >
        {users.length <= 0 ? (
          <p
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 10,
              paddingRight: 20,
            }}
          >
            {emptyMessage}
          </p>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {[...users].map((user) => {
              return <ListUserItem key={user.id} userId={user.id} />
            })}
            {hasMore && (
              <Container hAlign="center" style={{ marginTop: 20 }}>
                <Spinner />
              </Container>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
