import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/database'
import firebase from 'firebase/app'
import firebaseConfig from './firebase-config.json'
import version from './version.json'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

try {
  const gtag = function gtag(..._args: any) {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    const dataLayer = (window as any).dataLayer

    //eslint-disable-next-line
    dataLayer.push(arguments)
  }

  // Disable _ga cookies
  gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  })

  firebase.analytics().setAnalyticsCollectionEnabled(true)
  firebase.analytics().setUserProperties({
    client: 'web',
    git_version: version,
  })

  // Clear old _ga cookies
  document.cookie
    .split(';')
    .filter((pair) => pair.startsWith('_ga'))
    .map((pair) => pair.split('=')[0])
    .forEach(
      (key) =>
        (document.cookie = `${key}=; Max-Age=0; path=/; domain=${location.hostname}`)
    )
} catch (err) {
  console.error(err)
}

// firebase.firestore().enablePersistence()

export default firebase
