import { FileInfo, ProgressiveImage } from 'connect-shared/types'
import { CSSProperties, useCallback, useMemo } from 'react'
import { useColors } from './Colors'
import FilePreview from './FilePreview'
import { FileUpload } from './hooks/useFileUploads'
import { ImageUpload } from './hooks/useImageUploads'
import IconButton from './IconButton'
import { ReactComponent as RemoveIcon } from './icons/remove.svg'
import { Container } from './layout/Layout'
import Spinner from './Spinner'

type UploadedImageProps = {
  style?: CSSProperties
  src: string
  onRemove?: () => void
}

function UploadedImage({ style, src, onRemove }: UploadedImageProps) {
  const handleOnRemove = useCallback(() => {
    onRemove?.()
  }, [onRemove])
  return (
    <Container
      style={{
        position: 'relative',
        ...style,
      }}
    >
      <img
        style={{
          objectFit: 'cover',
          height: 190,
          width: 190,
          maxWidth: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          borderRadius: 6,
        }}
        alt=""
        src={src}
      />
      <IconButton
        style={{
          top: 0,
          right: 0,
          position: 'absolute',
          height: 20,
          margin: 5,
        }}
        size={30}
        Icon={RemoveIcon}
        onClick={handleOnRemove}
      />
    </Container>
  )
}

type ImagePreviewProps = {
  upload: ImageUpload
  remove: (id: string) => void
}

function ImagePreview({ upload, remove }: ImagePreviewProps) {
  const src = useMemo(() => {
    return URL.createObjectURL(upload.file)
  }, [upload.file])

  const colors = useColors()
  const isFinished = upload.progress === 1

  return useMemo(
    () => (
      <Container
        style={{
          position: 'relative',
          margin: 4,
        }}
      >
        <img
          style={{
            objectFit: 'cover',
            height: 190,
            width: 190,
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            borderRadius: 6,
          }}
          alt=""
          src={src}
        />
        <Container
          style={{
            opacity: isFinished ? 0 : 1,
            backgroundColor: '#FFFA',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={colors.foreground} size={50} />
        </Container>
        <IconButton
          style={{
            top: 5,
            right: 0,
            position: 'absolute',
            height: 20,
            margin: 5,
          }}
          size={30}
          Icon={RemoveIcon}
          onClick={() => remove(upload.id)}
        />
      </Container>
    ),
    [upload.id, remove, colors.foreground, isFinished, src]
  )
}

type UploadsPreviewProps = {
  removeImage: (id: string) => void
  imageUploadsInProgress: ImageUpload[]
  removeFile: (id: string) => void
  fileUploadsInProgress: FileUpload[]
  initialImages?: Set<ProgressiveImage>
  initialFiles?: Set<FileInfo>
  onRemoveInitialFile?: (file: FileInfo) => void
  onRemoveInitialImage?: (image: ProgressiveImage) => void
}

export default function UploadsPreview({
  removeImage,
  imageUploadsInProgress,
  removeFile,
  fileUploadsInProgress,
  initialFiles,
  initialImages,
  onRemoveInitialFile,
  onRemoveInitialImage,
}: UploadsPreviewProps) {
  const handleRemoveInitialImage = useCallback(
    (image: ProgressiveImage) => {
      onRemoveInitialImage?.(image)
    },
    [onRemoveInitialImage]
  )
  const handleRemoveInitialFile = useCallback(
    (image: FileInfo) => {
      onRemoveInitialFile?.(image)
    },
    [onRemoveInitialFile]
  )

  return (
    <Container>
      <Container>
        {initialFiles &&
          [...initialFiles].map((fileUpload) => {
            return (
              <FilePreview
                style={{ marginBottom: 11 }}
                key={fileUpload.uri}
                name={fileUpload.fileName}
                size={fileUpload.size}
                url={fileUpload.uri}
                progress={1}
                onRemove={() => handleRemoveInitialFile(fileUpload)}
              />
            )
          }, [])}

        {fileUploadsInProgress.map((fileUpload) => {
          return (
            <FilePreview
              style={{ marginBottom: 11 }}
              key={fileUpload.id}
              name={fileUpload.file.name}
              size={fileUpload.file.size}
              url={URL.createObjectURL(fileUpload.file)}
              progress={fileUpload.progress}
              onRemove={() => removeFile(fileUpload.id)}
            />
          )
        })}
      </Container>
      <Container horizontal style={{ flexWrap: 'wrap' }}>
        {initialImages &&
          [...initialImages].map((image) => {
            return (
              <UploadedImage
                style={{ margin: 4 }}
                onRemove={() => handleRemoveInitialImage(image)}
                key={image.fullImage.uri}
                src={(image.thumbnail ?? image.fullImage).uri}
              />
            )
          })}
        {imageUploadsInProgress.map((imageUpload) => {
          return (
            <ImagePreview
              key={imageUpload.id}
              upload={imageUpload}
              remove={() => removeImage(imageUpload.id)}
            />
          )
        })}
      </Container>
    </Container>
  )
}
