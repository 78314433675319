import { useFirestoreCollection } from '@iwikal/reactfire'
import { useCurrentUserId, useOrganizationDoc } from './auth'

export function useWorkplacesWhereAdmin() {
  const currentUserId = useCurrentUserId()
  const orgDoc = useOrganizationDoc()
  const adminQuery = orgDoc
    .collection('workplaces')
    .where('admins', 'array-contains', currentUserId)
  return useFirestoreCollection(adminQuery)
}

export function useIsWorkplaceAdmin() {
  const workplaces = useWorkplacesWhereAdmin()
  return workplaces.read().docs.map((snap) => snap).length > 0
}
