import React from 'react'
import { colors } from 'connect-shared/constants'
import { feedIdFromPostSnap } from 'connect-shared/feed'
import { PostDocument, PostUserDocument } from 'connect-shared/types'
import firebase from 'firebase/app'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { usePostUser } from '../../hooks/feeds'
import IconButton from '../../IconButton'
import { ReactComponent as CloseIcon } from '../../icons/closeModal.svg'
import { Container, ContainerProps } from '../../layout/Layout'
import AnnouncementSeenBy from './AnnouncementSeenBy'
import AnnouncementWrapperConfirmButton from './AnnouncementWrapperConfirmButton'
import { Box, Modal } from '@mui/material'
import { useTranslate } from '../../hooks/Internationalization'

type AnnouncementWrapperProps = {
  children: React.ReactNode
  shouldRender?: boolean
  postSnap: firebase.firestore.QueryDocumentSnapshot<PostDocument>
  introAsModal?: boolean
} & ContainerProps

export default function AnnouncementWrapper({
  children,
  shouldRender,
  postSnap,
  introAsModal,
  style,
  ...rest
}: AnnouncementWrapperProps) {
  const t = useTranslate()
  const [seenByOpen, toggleSeenByOpen] = React.useReducer(
    (state) => !state,
    false
  )

  const currentUserId = useCurrentUserId()
  const organizationRef = useOrganizationDoc()
  const feedId = feedIdFromPostSnap(postSnap)
  const postId = postSnap.id
  const postUserResource = usePostUser(feedId, postSnap.id, currentUserId)

  const markRead = React.useCallback(async () => {
    const batch = firebase.firestore().batch()

    const postDoc = organizationRef
      .collection('feeds')
      .doc(feedId)
      .collection('posts')
      .doc(postId)

    const postUserDoc = postDoc.collection('users').doc(currentUserId)

    const combinedPostRef = organizationRef
      .collection('combinedFeeds')
      .doc(currentUserId)
      .collection('posts')
      .doc(postDoc.id)

    const postData: Partial<PostDocument> = {
      confirmCount: firebase.firestore.FieldValue.increment(
        1
      ) as unknown as number,
    }

    const userData: PostUserDocument = {
      confirmedSeen: true,
    }

    batch.update(postDoc, postData)
    batch.update(combinedPostRef, postData)
    batch.set(postUserDoc, userData, { merge: true })

    await batch.commit()
  }, [feedId, postId, organizationRef, currentUserId])

  const post = postSnap.data() as PostDocument

  const confirmCount = post.confirmCount || 0
  const canConfirmCount = post.canConfirmCount || 0

  if (!shouldRender) {
    return (
      <Container
        style={{
          borderRadius: 5,
          ...style,
        }}
        {...rest}
      >
        {children}
      </Container>
    )
  }

  const ICON_SIZE = 40

  const postUser = postUserResource.read().data()
  const confirmed = postUser?.confirmedSeen === true
  const canConfirm = postUser?.confirmedSeen === false

  return (
    <Container
      style={{
        borderRadius: 10,
        borderColor: canConfirm ? colors.beeworkGold : undefined,
        borderWidth: canConfirm ? '3px 3px 1px 3px' : 0,
        borderStyle: 'solid',
        boxShadow: `0px 1px 3px ${colors.announcementShadow}`,
        overflow: 'hidden',
        ...style,
      }}
      {...rest}
    >
      {children}
      <AnnouncementWrapperConfirmButton
        introAsModal={introAsModal}
        canConfirm={canConfirm}
        markRead={markRead}
        confirmCount={confirmCount}
        canConfirmCount={canConfirmCount}
        confirmed={confirmed}
        openSeenBy={toggleSeenByOpen}
      />
      {seenByOpen && (
        <Modal
          open={seenByOpen}
          style={{
            zIndex: 3,
            backgroundColor: colors.webChatTransparentBackground,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          aria-label=" "
          onClose={toggleSeenByOpen}
        >
          <Box
            aria-label=" "
            style={{
              backgroundColor: colors.foreground,
              borderRadius: 12,
              width: 350,
              position: 'relative',
              padding: '20px 0 0 20px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: -ICON_SIZE,
                top: -ICON_SIZE,
              }}
            >
              <IconButton
                Icon={CloseIcon}
                onClick={toggleSeenByOpen}
                size={ICON_SIZE}
              />
            </div>
            <h1
              style={{
                fontSize: 20,
                color: colors.text,
                fontWeight: 600,
                margin: '10px 0 20px 0',
              }}
            >
              {t('Who has read')}
            </h1>
            <AnnouncementSeenBy isModal postSnap={postSnap} />
          </Box>
        </Modal>
      )}
    </Container>
  )
}
