import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import firebase from 'firebase/app'
import { FeedDocument } from 'connect-shared/types'
import React, { Suspense } from 'react'
import { useColors } from '../../Colors'
import { useOrganizationDoc } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import { Container, Spacer } from '../../layout/Layout'
import { BodyText, CaptionText } from '../../Text'
import TableInfoRow from '../../wiki/TableInfoRow'
import FeedFollowerListItem from './FeedFollowerListItem'

type FeedFollowersTableProps = {
  followerIds: string[]
  feedSnap: firebase.firestore.DocumentSnapshot<FeedDocument>
  title?: string
  admins?: boolean
}
export default function FeedFollowersTable({
  followerIds,
  feedSnap,
  title,
  admins,
}: FeedFollowersTableProps) {
  const colors = useColors()
  const t = useTranslate()
  const adminCount = Object.keys(feedSnap.data()?.admins || {}).length
  const feedRef = useOrganizationDoc().collection('feeds').doc(feedSnap.id)
  const followers = useFirestoreDoc(
    feedRef.collection('extra').doc('followers')
  )
  const subscriberCount = followers.read().data()?.ids.length
  const followerCount = subscriberCount ? subscriberCount - adminCount : 0
  const userCount = admins ? adminCount : followerCount
  const hasWorkplaces = !useFirestoreCollection(
    useOrganizationDoc().collection('workplaces').limit(1)
  ).read().empty
  return (
    <Container>
      {title && (
        <CaptionText style={{ fontWeight: 600 }}>
          {title + ' '}
          <span
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: colors.labelInfoText,
            }}
          >
            {userCount}
          </span>
        </CaptionText>
      )}

      <Spacer size={10} />
      <Container flex={1} horizontal>
        <Spacer size={81} />
        <Container flex={1} horizontal>
          <BodyText
            style={{
              flex: 1,
              color: colors.labelInfoText,
              fontWeight: 600,
              textAlign: 'start',
            }}
          >
            {t('Name')}
          </BodyText>
        </Container>
        <TableInfoRow text={t('Job title')} strong />
        {hasWorkplaces && <TableInfoRow text={t('Workplace')} strong />}
        <Spacer size={56} />
      </Container>
      {followerIds.map((userId) => {
        return (
          <Suspense key={userId} fallback={null}>
            <FeedFollowerListItem
              feedSnap={feedSnap}
              key={userId}
              userId={userId}
            />
          </Suspense>
        )
      })}
    </Container>
  )
}
