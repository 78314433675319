import {
  useAllUsers,
  useAllUsersFiltered,
} from 'connect-shared/AllUsersContext'
import emojis from 'connect-shared/emojis.json'
import { DocumentReference } from 'connect-shared/firestore'
import { Mention, UserIdsDocument, UserMention } from 'connect-shared/types'
import { userFullName } from 'connect-shared/User'
import deepmerge from 'deepmerge'
import { UIEvent } from 'react'
import {
  Mention as ReactMention,
  MentionItem,
  MentionProps,
  MentionsInput as ReactMentionsInput,
  SuggestionDataItem,
} from 'react-mentions'
import { useColors } from './Colors'
import { Container } from './layout/Layout'
import RoundAvatar from './RoundAvatar'
import { BodyText } from './Text'

const uniqueEmojisMap = new Map<string, string>()
for (const [emoji, colons] of Object.entries(emojis)) {
  uniqueEmojisMap.set(colons, emoji)
}
const uniqueEmojis = [...uniqueEmojisMap.entries()]

type MentionSuggestionItemProps = {
  suggestion: SuggestionDataItem
}
function MentionSuggestionItem({ suggestion }: MentionSuggestionItemProps) {
  const { allUsers } = useAllUsers()
  const userId = suggestion.id.toString()
  const user = allUsers.get(userId)
  return (
    <Container horizontal vAlign="center" flex={1}>
      <RoundAvatar
        avatar={user?.avatar}
        name={userFullName(user)}
        size={20}
        style={{ marginRight: 4 }}
      />
      <BodyText
        style={{
          maxWidth: 150,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {userFullName(user)}
      </BodyText>
    </Container>
  )
}

const renderMentionSuggestionItem: MentionProps['renderSuggestion'] = (
  suggestion
) => {
  return <MentionSuggestionItem suggestion={suggestion} />
}

function renderEmojiSuggestionItem({ id, display }: any) {
  return (
    <Container horizontal vAlign="center" flex={1}>
      <span style={{ marginRight: 10 }}>{display}</span>
      <BodyText
        style={{
          maxWidth: 150,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {id}
      </BodyText>
    </Container>
  )
}

export function mentionsInputString(text = '', mentions?: Mention[]) {
  let inputString = text
  const sortedMentions = [...(mentions || [])].sort(
    (a, b) => (b?.index || 0) - (a?.index || 0)
  )
  for (const { index, text: mentionText, userId } of sortedMentions || []) {
    if (index === undefined) continue
    inputString =
      inputString.slice(0, index) +
      `#B1#[[${mentionText}]]#B2#{{${userId}}}#B3#` +
      inputString.slice(index + mentionText.length)
  }

  return inputString
}

export default function MentionsInput({
  value,
  onChange,
  style,
  filter,
  inputRef,
  onSelect,
  ...inputProps
}: {
  value: string
  onChange: (data: { mentions: Mention[]; value: string; text: string }) => void
  style?: any
  filter?: DocumentReference<UserIdsDocument>
  inputRef?: React.Ref<HTMLTextAreaElement>
  onSelect?: (e: UIEvent<Element, globalThis.UIEvent>) => void
} & Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  | 'onChange'
  | 'onBlur'
  | 'onKeyDown'
  | 'onSelect'
  | 'defaultValue'
  | 'aria-relevant'
>) {
  const allUsers = useAllUsersFiltered(filter)
  const colors = useColors()

  const mentionStyle = {
    color: colors.linkText,
    zIndex: 1,
    position: 'relative',
  }

  return (
    <ReactMentionsInput
      inputRef={inputRef}
      onSelect={onSelect}
      allowSuggestionsAboveCursor
      value={value}
      onChange={(_, value, text, rawMentions) => {
        const mentions: UserMention[] = rawMentions.map((_mention) => {
          const mention = _mention as MentionItem & { plainTextIndex: number }
          return {
            userId: mention.id,
            text: mention.display,
            index: mention.plainTextIndex,
          }
        })
        onChange({
          value,
          text,
          mentions,
        })
      }}
      style={deepmerge(
        {
          width: '100%',
          fontSize: 13,
          overflowWrap: 'anywhere',
          control: {
            overflowWrap: 'anywhere',
            width: '100%',
            fontWeight: 'normal',
          },
          highlighter: {
            overflowWrap: 'anywhere',
            padding: 9,
            border: 'none',
          },
          input: {
            overflowWrap: 'anywhere',
            overflow: 'auto',
            padding: 9,
            border: 'none',
            outline: 'none',
          },
          suggestions: {
            width: 220,
            borderRadius: 12,
            backgroundColor: colors.none,
            border: `1px solid ${colors.border}`,
            boxShadow: `1px 1px 3px ${colors.shadow}`,
            list: {
              overflowY: 'auto',
              maxHeight: 200,
              backgroundColor: colors.foreground,
              outline: 'none',
            },
            item: {
              padding: 10,
              borderBottom: `1px solid ${colors.border}`,
              outline: 'none',
              '&focused': {
                backgroundColor: colors.selected,
              },
            },
          },
        },
        style || {}
      )}
      {...inputProps}
    >
      <ReactMention
        markup="#A1#[[__display__]]#A2#{{__id__}}#A3#"
        trigger="@"
        style={mentionStyle}
        data={(query) => {
          return allUsers.search(query).map((user) => {
            return {
              id: user.id,
              display: '@' + user.fullName,
            }
          })
        }}
        renderSuggestion={renderMentionSuggestionItem}
      />
      <ReactMention
        markup="#B1#[[__display__]]#B2#{{__id__}}#B3#"
        trigger={/^(([^-._ \n]{3,}))$/}
        style={mentionStyle}
        data={(query) => {
          return allUsers
            .search(query, { caseInsensitive: false })
            .map((user) => {
              return {
                id: user.id,
                display: '@' + user.fullName,
              }
            })
        }}
        renderSuggestion={renderMentionSuggestionItem}
      />
      <ReactMention
        markup="#C1#[[__display__]]#C2#{{__id__}}#C3#"
        trigger={/.*[ \n](([^-._ \n]{3,}))$/}
        style={mentionStyle}
        data={(query) => {
          return allUsers
            .search(query, { caseInsensitive: false })
            .map((user) => {
              return {
                id: user.id,
                display: '@' + user.fullName,
              }
            })
        }}
        renderSuggestion={renderMentionSuggestionItem}
      />
      <ReactMention
        markup="__display__"
        regex={/($a)/}
        trigger={/(:(\w\w+))$/}
        data={(query) => {
          if (query.length < 2) return
          return uniqueEmojis
            .filter(([, colons]) => colons.includes(query))
            .sort(([, a], [, b]) => a.localeCompare(b))
            .sort(
              ([, a], [, b]) =>
                Number(b.startsWith(query)) - Number(a.startsWith(query))
            )
            .sort(([, a], [, b]) => Number(b === query) - Number(a === query))
            .slice(0, 30)
            .map(([emoji, colons]) => ({ id: colons, display: emoji }))
        }}
        renderSuggestion={renderEmojiSuggestionItem}
      />
    </ReactMentionsInput>
  )
}
