import { getStatus, Status } from 'connect-shared/statuses'
import React, { HTMLAttributes, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import { language } from './hooks/Internationalization'
import useAvailabilityEnabled from './hooks/useAvailabilityEnabled'
import useUser from './hooks/useUser'
import RoundUserAvatar from './RoundUserAvatar'

function StatusIndicator(props: {
  status: Status
  size: number
  big?: boolean
}) {
  const { size, status, big } = props
  const colors = useColors()

  if (big) {
    const altWidth = 70
    const altHeight = 19
    const altBottom = altHeight - 10
    const altLeft = (size - 66) / 2
    const altBorderRadius = 6

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          width: altWidth,
          height: altHeight,
          bottom: -altBottom,
          left: altLeft,
          borderRadius: altBorderRadius,
          backgroundColor: status.color,
          fontSize: 11,
          color: colors.textInverted,
        }}
      >
        {status.name[language]}
      </div>
    )
  }

  const statusDotSize = size * 0.3

  return (
    <div
      style={{
        position: 'absolute',
        width: statusDotSize,
        height: statusDotSize,
        bottom: 0,
        left: size - statusDotSize,
        borderRadius: statusDotSize / 2,
        border: `1px solid ${colors.background}`,
        backgroundColor: status.color,
      }}
    />
  )
}

interface StatusAvatarProps extends HTMLAttributes<HTMLDivElement> {
  userId?: string
  size: number
  style?: React.CSSProperties
  alternateIndicator?: boolean
  requestClose?: () => void
  clickable?: boolean
}

const StatusAvatar = React.memo(function StatusAvatar({
  userId,
  size,
  style,
  alternateIndicator,
  requestClose,
  clickable,
  ...props
}: StatusAvatarProps) {
  const availabilityEnabled = useAvailabilityEnabled().read()
  const user = useUser(userId)
  const status = user && getStatus(user)
  const history = useHistory()
  const navigateProfile = useCallback(() => {
    requestClose?.()
    history.push(`/users/${userId}`)
  }, [history, requestClose, userId])

  const content = (
    <div
      style={{
        position: 'relative',
        height: size,
        width: size,
        ...style,
      }}
      {...props}
    >
      <RoundUserAvatar userId={userId} size={size} />
      {status && availabilityEnabled && (
        <StatusIndicator big={alternateIndicator} size={size} status={status} />
      )}
    </div>
  )

  if (clickable) {
    return (
      <ClickableOpacity onClick={navigateProfile}>{content}</ClickableOpacity>
    )
  }

  return content
})
export default StatusAvatar
