import { DialogContent, DialogOverlay } from '@reach/dialog'
import { CalendarDocument, FeedDocument } from 'connect-shared/types'
import {
  WorkplaceCalendarSettings,
  WorkplaceFeedSettings,
  workplaceFeedSettingsId,
} from 'connect-shared/workplaces'
import { useCallback } from 'react'
import { useColors } from '../Colors'
import IconButton from '../IconButton'
import { Container, Pusher, Spacer } from '../layout/Layout'
import RoundAvatar from '../RoundAvatar'
import { BodyText, CaptionText } from '../Text'
import { ReactComponent as AddIcon } from '../icons/ico-not-going.svg'
import { QuerySnapshot } from 'connect-shared/firestore'
import { useOrganizationDoc } from '../hooks/auth'
import { ReactComponent as SelectedIcon } from '../icons/ico-going.svg'
import ModalCloseButton from '../ModalCloseButton'

type Item = FeedDocument | CalendarDocument

function ListItem({
  item,
  id,
  isFeed,
  workplaceId,
  selected,
}: {
  item: Item
  id: string
  isFeed: boolean
  workplaceId: string
  selected: boolean
}) {
  const colors = useColors()

  const organizationDoc = useOrganizationDoc()
  const addItem = useCallback(async () => {
    const workplaceFeedSettings: WorkplaceFeedSettings = {
      autoSubscribe: true,
      forceSubscribe: false,
      workplaceId,
      feedId: id,
    }

    const workplaceCalendarSettings: WorkplaceCalendarSettings = {
      autoSubscribe: true,
      forceSubscribe: false,
      workplaceId,
      calendarId: id,
    }
    await organizationDoc
      .collection(
        isFeed ? 'workplaceFeedSettings' : 'workplaceCalendarSettings'
      )
      .doc(workplaceFeedSettingsId(workplaceId, id))
      .set(isFeed ? workplaceFeedSettings : workplaceCalendarSettings, {
        merge: true,
      })
      .catch((e) => console.error(e))
  }, [id, isFeed, organizationDoc, workplaceId])
  return (
    <>
      <Container
        horizontal
        vAlign="center"
        style={{
          backgroundColor: colors.foreground,
          borderRadius: 6,
          boxShadow: `0 0 3px ${colors.announcementShadow}`,
          padding: '10px 15px',
        }}
      >
        <RoundAvatar size={50} name={item.name} avatar={item.avatar} />
        <Spacer size={10} />
        <BodyText
          style={{
            maxWidth: 270,
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {item.name}
        </BodyText>
        <Pusher />
        {selected ? (
          <IconButton Icon={SelectedIcon} size={30} />
        ) : (
          <IconButton
            onClick={addItem}
            Icon={AddIcon}
            size={30}
            style={{ transform: 'rotate(45deg)' }}
          />
        )}
      </Container>
      <Spacer size={15} />
    </>
  )
}

type AddChannelModalProps = {
  close: () => void
  snaps: QuerySnapshot<Item>
  title: string
  workplaceId: string
  selectedIds: string[]
}

export default function AddChannelModal({
  snaps,
  close,
  title,
  workplaceId,
  selectedIds,
}: AddChannelModalProps) {
  const colors = useColors()
  return (
    <DialogOverlay style={{ zIndex: 20 }} aria-label=" " onDismiss={close}>
      <DialogContent
        aria-label=" "
        style={{
          position: 'relative',
          width: 490,
          backgroundColor: colors.webChatModalBackground,
          borderRadius: 10,
          padding: 0,
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <CaptionText
          style={{
            marginBottom: 30,
            marginLeft: 30,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title}
        </CaptionText>
        <Container
          style={{
            fontSize: 20,
            fontWeight: 600,
            overflowY: 'auto',
            maxHeight: '70vh',
            padding: '20px 30px',
          }}
        >
          {snaps.docs.map((snap) => {
            const item = snap.data()
            const isFeed = snap.ref.path.split('/')[2] === 'feeds'
            return (
              <ListItem
                selected={selectedIds.includes(snap.id)}
                workplaceId={workplaceId}
                isFeed={isFeed}
                item={item}
                id={snap.id}
                key={snap.id}
              />
            )
          })}
        </Container>
        <ModalCloseButton onClick={close} style={{ zIndex: 10 }} />
      </DialogContent>
    </DialogOverlay>
  )
}
