import { css } from '@emotion/react'
import { Tooltip } from '@mui/material'
import { CSSProperties } from 'react'
import { useColors } from '../Colors'
import IconButton from '../IconButton'
import { ReactComponent as NewIcon } from '../icons/ico-create-new-feed.svg'

type SidebarAddButtonProps = {
  tooltip?: string
  onClick?: () => void
  style?: CSSProperties
}

export default function SidebarAddButton({
  onClick,
  tooltip,
  style,
}: SidebarAddButtonProps) {
  const colors = useColors()
  return (
    <Tooltip title={tooltip} placement="top">
      <div
        style={style}
        css={css`
          button {
            border-style: solid;
            border-width: 1px;
            border-color: ${colors.labelDim};
            border-radius: 4px;
          }

          &:hover {
            button {
              border-color: ${colors.active};
            }
            svg {
              fill: ${colors.active};
              color: ${colors.active};
            }
          }
        `}
      >
        <IconButton onClick={onClick} size={20} Icon={NewIcon} />
      </div>
    </Tooltip>
  )
}
