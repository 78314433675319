import { useOrganizationDoc } from './auth'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { Resource } from '../resource'

export default function useAvailabilityEnabled(): Resource<boolean> {
  const organizationDoc = useOrganizationDoc()
  const orgResource = useFirestoreDoc(organizationDoc)

  return {
    read: () => {
      return orgResource.read().data()?.availabilityEnabled ?? true
    },
  }
}
