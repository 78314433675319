import { useFirestoreCollection, useFirestoreDoc } from '@iwikal/reactfire'
import { Box, Button, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid'
import parseCsv from '@shamus03/csv-parser'
import { Timestamp } from 'connect-shared/firestore'
import {
  emailValid,
  InvitationDocument,
  UserDocument,
} from 'connect-shared/types'
import { isDate, parse } from 'date-fns'
import firebase from 'firebase/app'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { firestore } from '../firestore'
import { useCurrentUserId, useOrganizationDoc } from '../hooks/auth'
import { useDateFns, useTranslate } from '../hooks/Internationalization'
import { Container, Pusher, Spacer } from '../layout/Layout'
import PageLayoutLegacy from '../layout/PageLayoutLegacy'
import ModalCloseButton from '../ModalCloseButton'
import { showErrorToast, showSuccessToast } from '../Toast'
import { CaptionText } from '../Text'
import { useUserlimit } from '../hooks/useUserlimit'
import { useColors } from '../Colors'

function EmailCell({
  value = '',
  row,
  emailCounts,
}: GridCellParams & { emailCounts: Map<string, number> }) {
  const t = useTranslate()
  const valid = typeof value === 'string' && emailValid(value)
  const nonEmptyRow = Object.entries(row).some(([key, val]) => {
    return key !== 'id' && val
  })

  const usersSnap = useFirestoreCollection(
    firestore()
      .collection('users')
      .where('organization', '==', useOrganizationDoc())
      .where('email', '==', value || ''),
    { skipCache: true }
  )

  if (typeof value !== 'string') {
    return <div />
  }

  const tooltipText = (() => {
    if (!nonEmptyRow) return ''
    if (!valid) return t('Invalid e-mail')
    if ((emailCounts.get(value) || 0) > 1) return t('Duplicate e-mail')
    if (usersSnap.read().size > 0) return t('User already exists')
    return ''
  })()

  const error =
    (!valid && nonEmptyRow) ||
    (emailCounts.get(value) || 0) > 1 ||
    (usersSnap.read().size > 0 && !usersSnap.read().metadata.hasPendingWrites)

  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          fontWeight: error ? 600 : undefined,
          color: error ? '#d96060' : '',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {!nonEmptyRow || value || 'None'}
      </div>
    </Tooltip>
  )
}

function DateCell({ value, formattedValue }: GridCellParams) {
  const error = isDate(value) && isNaN(value?.getDate())

  return (
    <div
      style={{
        fontWeight: error ? 600 : undefined,
        color: error ? '#d96060' : '',
      }}
    >
      {formattedValue as any}
    </div>
  )
}

function WorkplacePicker({ value, id, field }: GridCellParams) {
  const t = useTranslate()
  const apiRef = useGridApiContext()
  const organizationRef = useOrganizationDoc()
  const workplaces = useFirestoreCollection(
    organizationRef.collection('workplaces')
  ).read().docs
  const workplaceState = value as WorkplaceState | undefined
  const [workplaceId, setWorkplaceId] = useState(
    workplaceState?.valid ? workplaceState.id : ''
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setWorkplaceId(e.target.value)

      apiRef.current.unstable_setEditCellProps({
        id,
        field,
        props: {
          value: e.target.value
            ? { valid: true, id: e.target.value }
            : undefined,
        },
      })
    },
    [apiRef, field, id]
  )
  return (
    <select value={workplaceId} onChange={handleChange}>
      <option value="/" disabled>
        {t('Workplace')}
      </option>
      <option value="">{t('None')}</option>
      {workplaces.map((snap) => {
        const workplace = snap.data()
        return (
          <option key={snap.id} value={snap.id}>
            {workplace.name}
          </option>
        )
      })}
    </select>
  )
}

function WorkplaceCell({ value, row }: GridCellParams) {
  const t = useTranslate()
  const nonEmptyRow = Object.entries(row).some(([key, val]) => {
    return key !== 'id' && val
  })
  const workplaceState = value as WorkplaceState | undefined
  const organizationRef = useOrganizationDoc()
  const workplaceSnap = useFirestoreDoc(
    workplaceState?.valid && workplaceState.id
      ? organizationRef.collection('workplaces').doc(workplaceState.id)
      : firestore().collection('empty').doc('empty')
  ).read()

  if (!workplaceState) {
    return <div>{nonEmptyRow && 'None'}</div>
  }

  const data = workplaceSnap.data()

  if (!data) {
    return (
      <div
        style={{
          fontWeight: 600,
          color: '#d96060',
        }}
      >
        {t('Invalid')}
      </div>
    )
  }

  return <div>{data.name}</div>
}

function DeleteCell({
  row,
  setRows,
}: GridCellParams & {
  setRows: Dispatch<SetStateAction<Row[]>>
}) {
  function handleClick() {
    setRows((rows) => {
      return rows.filter(({ id }) => id !== row.id)
    })
  }

  const nonEmptyRow = Object.entries(row).some(([key, val]) => {
    return key !== 'id' && val
  })

  if (!nonEmptyRow) return null
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        zIndex: 10,
      }}
    >
      <ModalCloseButton onClick={handleClick} style={{ zIndex: 20 }} />
    </div>
  )
}

type WorkplaceState = { valid: true; id: string } | { valid: false }

type Row = {
  id: number
  email: string
  workplace?: WorkplaceState
  dateOfEmployment?: Date
  birthday?: Date
  firstName: string
  lastName: string
  jobTitle: string
  phone: string
}

function createEmptyRow(): Row {
  return {
    id: Math.random(),
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phone: '',
  }
}

export default function BulkInvite() {
  const organizationRef = useOrganizationDoc()
  const workplacesSnap = useFirestoreCollection(
    organizationRef.collection('workplaces')
  ).read()

  const t = useTranslate()

  const [rows, setRows] = useState<Row[]>([createEmptyRow()])

  const emailCounts = useMemo(() => {
    const counts = new Map<string, number>()
    for (const row of rows) {
      if (!row.email) continue
      const count = counts.get(row.email) || 0
      counts.set(row.email, count + 1)
    }
    return counts
  }, [rows])

  const handleChange: GridEventListener<'cellEditCommit'> = ({
    id,
    field,
    value,
  }) => {
    setRows((rows) => {
      return rows
        .map((row): Row => {
          if (row.id === id) {
            return {
              ...row,
              [field]: value,
            }
          } else {
            return row
          }
        })
        .filter((row) => {
          return Object.entries(row).find(([key, val]) => {
            return key !== 'id' && val
          })
        })
        .concat(createEmptyRow())
    })
  }

  const { format } = useDateFns()
  const [submitting, setSubmitting] = useState(false)
  const organizationDoc = useOrganizationDoc()
  const currentUserId = useCurrentUserId()

  const nonEmptyRows = rows.filter((row) => {
    return Object.entries(row).some(([key, val]) => {
      return key !== 'id' && val
    })
  })

  const submit = useCallback(async () => {
    setSubmitting(true)
    const usersSnap = await firestore()
      .collection('users')
      .where('organization', '==', organizationRef)
      .get()
    const userEmails = new Set(usersSnap.docs.map((snap) => snap.data().email))

    for (const row of nonEmptyRows) {
      if (!row.email || !emailValid(row.email)) {
        showErrorToast(
          t('Invalid E-Mail: %{email}', {
            email: row.email || 'None',
          })
        )
        setSubmitting(false)
        return
      }

      if (userEmails.has(row.email)) {
        showErrorToast(
          t('User already exists: %{email}', {
            email: row.email,
          })
        )
        setSubmitting(false)
        return
      }

      if ((emailCounts.get(row.email) || 0) > 1) {
        showErrorToast(
          t('Duplicate E-mail: %{email}', {
            email: row.email,
          })
        )
        setSubmitting(false)
        return
      }

      if (
        isDate(row.dateOfEmployment) &&
        isNaN(row.dateOfEmployment?.getDay() || NaN)
      ) {
        showErrorToast(t(`Invalid Employment date`))
        setSubmitting(false)
        return
      }

      if (isDate(row.birthday) && isNaN(row.birthday?.getDay() || NaN)) {
        showErrorToast(t(`Invalid birthday`))
        setSubmitting(false)
        return
      }
    }

    const batches: { batch: firebase.firestore.WriteBatch; rows: Row[] }[] = [
      { batch: firestore().batch(), rows: [] },
    ]
    for (const row of nonEmptyRows) {
      const { batch, rows } = batches[batches.length - 1]
      if (rows.length === 250) {
        batches.push({ batch: firestore().batch(), rows: [] })
      }
      rows.push(row)

      if (!row.email) {
        throw new Error('Empty email') // Just for typescript
      }

      const userData: UserDocument = {
        organization: organizationDoc,
        email: row.email.toLowerCase(),
        invitePending: true,
        avatar: '',
      }

      if (row.phone) userData.phone = row.phone
      if (row.birthday) userData.birthday = format(row.birthday, 'MM-dd')
      if (row.jobTitle) userData.jobTitle = row.jobTitle
      if (row.firstName) userData.firstName = row.firstName
      if (row.lastName) userData.lastName = row.lastName
      if (row.workplace?.valid) userData.workplaceId = row.workplace.id
      if (row.dateOfEmployment) {
        userData.dateOfEmployment = firebase.firestore.Timestamp.fromDate(
          row.dateOfEmployment
        )
      }

      const userDoc = firestore().collection('users').doc()
      batch.set(userDoc, userData)
      const codeDoc = firestore().collection('invitations').doc(userDoc.id)
      const randomInts = crypto.getRandomValues(new Uint32Array(10))
      const chars = 'ABCDEFGHJKMNPQRTWXYZ2346789'
      const code = [...randomInts]
        .map((randomInt) => chars[randomInt % chars.length])
        .join('')

      const codeData: InvitationDocument = {
        code,
        inviter: currentUserId,
        invitee: currentUserId,
        timestamp:
          firebase.firestore.FieldValue.serverTimestamp() as any as Timestamp,
      }

      batch.set(codeDoc, codeData)
    }

    let hasErrors = false
    for (const [promise, rows] of batches.map(
      ({ batch, rows }) => [batch.commit(), rows] as const
    )) {
      try {
        await promise
        setRows((prevRows) => {
          return prevRows.filter((row) => !rows.includes(row))
        })
      } catch (err) {
        console.error(err)
        hasErrors = true
      }
    }
    if (hasErrors) {
      showErrorToast(
        t('Some invitations could not be sent, please try again later.')
      )
    } else {
      showSuccessToast(
        t('%{count} invitations sent', {
          count: nonEmptyRows.length,
        })
      )
    }

    setSubmitting(false)
  }, [
    currentUserId,
    emailCounts,
    format,
    nonEmptyRows,
    organizationDoc,
    organizationRef,
    t,
  ])

  const [inputKey, setInputKey] = useState(Math.random())
  const handleUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const workplaces = new Map(
        workplacesSnap.docs.map((snap) => [snap.data().name, snap])
      )
      setInputKey(Math.random())
      const file = e.target.files?.[0]
      if (!file) return
      setRows(
        parseCsv(await file.text())
          .slice(1)
          .map((csvRow) => {
            const [
              email = '',
              firstName = '',
              lastName = '',
              jobTitle = '',
              phone = '',
              birthday,
              dateOfEmployment,
              workplaceName,
            ] = csvRow
            const row: Row = {
              id: Math.random(),
              email: email.trim().toLowerCase(),
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              jobTitle: jobTitle.trim(),
              phone: phone.trim(),
            }

            if (workplaceName) {
              const workplace = workplaces.get(workplaceName)
              if (workplace) {
                row.workplace = { valid: true, id: workplace.id }
              } else {
                row.workplace = { valid: false }
              }
            }

            if (birthday) {
              row.birthday = parse(birthday, 'MM-dd', new Date())
            }

            if (dateOfEmployment) {
              row.dateOfEmployment = parse(
                dateOfEmployment,
                'yyyy-MM-dd',
                new Date()
              )
            }

            return row
          })
          .concat(createEmptyRow())
      )
      setSubmitting(false)
    },
    [workplacesSnap.docs]
  )

  const downloadCsvTemplate = useCallback(() => {
    const content =
      'E-mail,First name,Last name,Job title,Phone number,Birthday,Employment date,Workplace\n' +
      'alex@example.com,Alex,Doe,Accountant,123456,01-30,2021-01-01,Stockholm office'
    const element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
    )
    element.setAttribute('download', 'template.csv')

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }, [])

  const colors = useColors()

  const { reached, userLimit, userCount } = useUserlimit()

  const invitesLeft = userLimit ? userLimit - userCount : undefined
  return (
    <PageLayoutLegacy
      flex={1}
      wide
      text={t('Invite in bulk')}
      style={{ minHeight: 600 }}
    >
      <Container horizontal vAlign="bottom" style={{}}>
        {invitesLeft && (
          <Tooltip
            placement="top"
            title={t(
              'The number of invites you are about to send compared to the user limit set by your user-limit in your current plan'
            )}
          >
            <div>
              <CaptionText>
                Invites used{' '}
                <span
                  style={{
                    marginBottom: 10,
                    color:
                      nonEmptyRows.length === invitesLeft
                        ? colors.warning
                        : nonEmptyRows.length > invitesLeft
                        ? colors.danger
                        : colors.label,
                  }}
                >
                  {nonEmptyRows.length} / {invitesLeft}
                </span>
              </CaptionText>
            </div>
          </Tooltip>
        )}
        <Pusher />
        <Button variant="contained" onClick={downloadCsvTemplate}>
          {t('Download CSV template')}
        </Button>
        <Spacer size={10} />
        <input
          type="file"
          hidden
          accept=".csv"
          key={inputKey}
          onChange={handleUpload}
          id="csv_input"
        />
        <label htmlFor="csv_input">
          <Button color="primary" variant="contained" component="span">
            {t('Upload csv')}
          </Button>
        </label>
      </Container>
      <Box mt={3} height={600} style={{ backgroundColor: '#fff' }}>
        <DataGrid
          rows={rows}
          showCellRightBorder
          showColumnRightBorder
          columns={[
            {
              field: 'email',
              sortable: false,
              headerName: t('E-mail'),
              width: 200,
              editable: true,
              renderCell(props) {
                return <EmailCell emailCounts={emailCounts} {...props} />
              },
            },
            {
              field: 'firstName',
              sortable: false,
              headerName: t('First name'),
              width: 200,
              editable: true,
            },
            {
              field: 'lastName',
              sortable: false,
              headerName: t('Last name'),
              width: 200,
              editable: true,
            },
            {
              field: 'jobTitle',
              sortable: false,
              headerName: t('Job title'),
              width: 200,
              editable: true,
            },
            {
              field: 'phone',
              sortable: false,
              headerName: t('Phone number'),
              width: 200,
              editable: true,
            },
            {
              field: 'birthday',
              sortable: false,
              headerName: t('Birthday'),
              width: 200,
              editable: true,
              type: 'date',
              renderCell: DateCell,
              valueFormatter: ({ value }) => {
                try {
                  if (isDate(value)) {
                    return format(value, 'MM-dd')
                  }
                } catch {
                  return 'Invalid date'
                }
              },
            },
            {
              field: 'dateOfEmployment',
              sortable: false,
              headerName: t('Employment date'),
              width: 200,
              editable: true,
              type: 'date',
              renderCell: DateCell,
              valueFormatter: ({ value }) => {
                try {
                  if (isDate(value)) {
                    return format(value, 'yyyy-MM-dd')
                  }
                } catch {
                  return 'Invalid date'
                }
              },
            },
            {
              field: 'workplace',
              headerName: t('Workplace'),
              sortable: false,
              width: 200,
              editable: true,
              renderEditCell: function RenderEditCell(props) {
                return <WorkplacePicker {...props} />
              },
              renderCell(props) {
                return <WorkplaceCell {...props} />
              },
            },
            {
              field: 'delete',
              headerName: '',
              sortable: false,
              width: 50,
              renderCell(props) {
                return <DeleteCell {...props} setRows={setRows} />
              },
            },
          ]}
          rowsPerPageOptions={[100]}
          pageSize={100}
          disableSelectionOnClick
          density="compact"
          onCellEditCommit={handleChange}
        />
      </Box>
      <Spacer size={20} />
      <Container horizontal>
        <Pusher />
        <Tooltip
          placement="top"
          title={
            invitesLeft && nonEmptyRows.length > invitesLeft
              ? t(
                  "You can't send more then %{invitesLeft} invites, remove some or upgrade your plan",
                  {
                    invitesLeft,
                  }
                )
              : undefined
          }
        >
          <div>
            <Button
              disabled={
                submitting ||
                reached ||
                (invitesLeft !== undefined && nonEmptyRows.length > invitesLeft)
              }
              color="primary"
              variant="contained"
              onClick={submit}
            >
              {t('Send invitations')}
            </Button>
          </div>
        </Tooltip>
      </Container>
    </PageLayoutLegacy>
  )
}
