import { WorkplaceDocument } from 'connect-shared/types'
import { useState, useCallback, useEffect } from 'react'
import { useColors } from '../Colors'
import IconButton from '../IconButton'
import { Container, Pusher, Spacer } from '../layout/Layout'
import { BodyText } from '../Text'
import { ReactComponent as RemoveIcon } from '../icons/remove.svg'
import { firestore } from '../firestore'
import RoundUserAvatar from '../RoundUserAvatar'
import User from 'connect-shared/User'

export default function UserListItem({
  id,
  noBorder,
  onMouseEnter,
  onMouseLeave,
  workplace,
  onRemoveUser,
}: {
  id: string
  noBorder: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  workplace: WorkplaceDocument
  onRemoveUser: (workplace: WorkplaceDocument) => void
}) {
  const [user, setUser] = useState<User>()
  const userRef = firestore().collection('users').doc(id)

  useEffect(() => {
    userRef.onSnapshot(
      (snap) => {
        if (snap.exists) {
          setUser(new User(snap))
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.warn('Failed to fetch admin user', userRef.id, err.message)
      }
    )
  })
  const colors = useColors()
  const [hovered, setHovered] = useState(false)

  const handleRemoveUser = useCallback(() => {
    const newAdmins = new Set(workplace.admins)
    newAdmins.delete(id)
    const newWorkplace: WorkplaceDocument = {
      ...workplace,
      ...{ admins: [...newAdmins] },
    }
    onRemoveUser(newWorkplace)
  }, [id, workplace, onRemoveUser])

  const handleMouseEnter = useCallback(() => {
    onMouseEnter()
    setHovered(true)
  }, [onMouseEnter])

  const handleMouseLeave = useCallback(() => {
    onMouseLeave()
    setHovered(false)
  }, [onMouseLeave])

  if (!user) {
    return null
  }

  return (
    <Container
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        padding: '20px 30px',
        backgroundColor: hovered ? colors.selected : colors.none,
        borderBottom: noBorder ? '' : `1px solid ${colors.selected}`,
      }}
      horizontal
      hAlign="left"
      vAlign="center"
    >
      <RoundUserAvatar userId={id} size={40} />
      <Spacer size={15} />
      <Container>
        <BodyText>{user.fullName}</BodyText>
        {user.jobTitle && (
          <BodyText style={{ marginTop: 5, fontWeight: 300 }}>
            {user.jobTitle}
          </BodyText>
        )}
      </Container>
      <Pusher />
      <IconButton onClick={handleRemoveUser} Icon={RemoveIcon} size={24} />
    </Container>
  )
}
