/**
 * An interface that represents some data or other resource that may or may not
 * be ready to use yet.
 * In that sense it's analogous to a `Promise<T>`, but adapted to use in React
 * with Suspense instead of async functions.
 * A `Resource<T>` should be immutable in that it always returns the same `T`
 * on successive calls to the `read` method.
 */
export interface Resource<T> {
  /**
   * May suspend (throw `Promise<any>`), throw an error, or return `T`.
   * The error can be of any type, as long as it doesn't look like a promise.
   */
  read(): T
}

/**
 * This is, at the time of writing, the same logic that React uses internally
 * to detect a PromiseLike.
 */
function isPromiseLike(x: any): x is PromiseLike<unknown> {
  return x !== null && typeof x === 'object' && typeof x.then === 'function'
}

/**
 * If you have a `Resource<T>`, you can await it with this function.
 */
export async function asyncSuspend<T>(resource: Resource<T>): Promise<T> {
  try {
    return resource.read()
  } catch (err) {
    if (isPromiseLike(err)) {
      await err
      // Trust that once the promise resolves, the resource is also resolved.
      return resource.read()
    }
    throw err
  }
}

/**
 * If you have a `Promise<T>`, you can convert it to a `Resource<T>` with this
 * function.
 */
export function createResource<T>(promise: PromiseLike<T>): Resource<T> {
  let result: { value: T } | undefined

  promise.then(
    (value) => void (result = { value }),
    () => {
      // Suppress unhandled promise rejection warning
    }
  )

  return {
    read() {
      if (result) {
        return result.value
      }
      throw promise
    },
  }
}
