import React, { useCallback, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { ReactComponent as SendIcon } from '../../icons/ico-send.svg'
import { useCurrentUserId } from '../../hooks/auth'
import { QueryDocumentSnapshot } from 'connect-shared/firestore'
import { CommentDocument, Mention } from 'connect-shared/types'
import { ModelDocument } from '../../firestore'
import { PostNode } from 'connect-shared/datamodel'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { Container } from '../../layout/Layout'
import MentionsInput from '../../MentionsInput'
import { showErrorToast } from '../../Toast'
import { useTranslate } from '../../hooks/Internationalization'

export default function ReplyCommentItem(props: {
  commentSnap: QueryDocumentSnapshot<CommentDocument>
  post: ModelDocument<PostNode>
}) {
  const [body, setBody] = useState('')
  const userId = useCurrentUserId()

  const mentionsRef = useRef<Mention[]>([])
  const textRef = useRef('')

  const colors = useColors()
  const t = useTranslate()

  const postNewCommentReply = useCallback(async () => {
    const data: CommentDocument = {
      body: textRef.current,
      author: userId,
      parent: props.commentSnap.id,
      createdDate:
        firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      mentions: mentionsRef.current,
    }
    try {
      await props.post.collection('comments').add(data)
      await props.post
        .collection('users')
        .doc(userId)
        .set(
          {
            latestSeenCommentIndex: firebase.firestore.FieldValue.increment(
              1
            ) as any as number,
          },
          { merge: true }
        )
      setBody('')
      mentionsRef.current = []
      textRef.current = ''
    } catch (err) {
      console.error(err)
      showErrorToast(t('Something went wrong, please try again later'))
    }
  }, [props.commentSnap.id, props.post, t, userId])

  const handleKeyPress = useCallback(
    (event) => {
      if (!event.shiftKey && event.key === 'Enter') {
        if (body.trim().length > 0) {
          postNewCommentReply()
          event.preventDefault()
        } else {
          event.preventDefault()
        }
      }
    },
    [body, postNewCommentReply]
  )

  const [sendHovered, setSendHovered] = useState(false)

  return (
    <div
      style={{
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        background: colors.background,
        marginLeft: '-20px',
        marginRight: '-20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          margin: '0 20px',
          background: '#fff',
          borderRadius: '18px',
          border: '1px solid #E4E4E4',
          padding: '5px 0 5px 8px',
        }}
      >
        <MentionsInput
          onKeyPress={handleKeyPress}
          value={body}
          style={{ flex: 1 }}
          onChange={({ value, mentions, text }) => {
            setBody(value)
            mentionsRef.current = mentions
            textRef.current = text
          }}
        />
        <Container
          horizontal
          vAlign="bottom"
          hAlign="right"
          style={{
            marginRight: 10,
          }}
        >
          <ClickableOpacity
            onMouseEnter={() => setSendHovered(true)}
            onMouseLeave={() => setSendHovered(false)}
            style={{
              marginBottom: 2,
            }}
            onClick={body.trim() ? () => postNewCommentReply() : undefined}
          >
            <SendIcon
              width={26}
              height={20}
              fillColor={
                sendHovered && body.length > 0
                  ? colors.beeworkBlueLight
                  : colors.beeworkBlue
              }
            />
          </ClickableOpacity>
        </Container>
      </div>
    </div>
  )
}
