import { CSSProperties } from 'react'
import { useFirestoreDoc } from '@iwikal/reactfire'
import {
  colors,
  defaultOrganizationPrimaryColor,
  defaultOrganizationPrimaryTextColor,
} from 'connect-shared/constants'
import { firestore } from '../firestore'
import { useAuthData } from './auth'
import useAccountInfo, { AccountInfoState } from './useAccountInfo'
import useFeatureFlag from './useFeatureFlag'

export default function useOrganizationTheme() {
  const accountInfo = useAccountInfo()
  const signedIn =
    accountInfo.state !== AccountInfoState.SignedOut &&
    accountInfo.state !== AccountInfoState.Loading &&
    accountInfo.state !== AccountInfoState.SignedInAccount

  const { organizationId } = useAuthData(false)
  const organizationDoc = signedIn
    ? firestore().collection('organizations').doc(organizationId)
    : firestore().collection('empty').doc('empty')

  const organizationSnap = useFirestoreDoc(organizationDoc)
  const organization = organizationSnap.read().data()
  const enabled = organization?.enableCustomTheme

  const defaultPlayipp = useFeatureFlag('defaultThemePlayipp', true)

  const defaultPrimary =
    '#' + defaultPlayipp ? colors.label : defaultOrganizationPrimaryColor
  const defaultText =
    '#' + defaultPlayipp
      ? colors.textInverted
      : defaultOrganizationPrimaryTextColor

  const orgPrimary = '#' + organization?.primaryColor
  const orgText = '#' + organization?.primaryTextColor

  const logo = enabled ? organization?.logo || null : null

  const primary = enabled ? orgPrimary || defaultPrimary : defaultPrimary
  const primaryText = enabled ? orgText || defaultText : defaultText

  const organizationStyle: CSSProperties = {
    backgroundColor: primary,
    color: primaryText,
  }
  return {
    primary,
    primaryText,
    organizationStyle,
    logo,
  }
}
