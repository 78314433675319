import { CSSProperties } from 'react'
import { useColors } from '../Colors'
import { Container } from '../layout/Layout'
import { BodyText } from '../Text'

export default function TableInfoRow({
  text,
  strong,
  style,
  children,
}: {
  text?: string | number
  strong?: boolean
  style?: CSSProperties
  children?: React.ReactNode
}) {
  const colors = useColors()
  return (
    <Container
      style={{
        minWidth: 0,
        ...style,
      }}
      flex={1}
      horizontal
      vAlign="center"
    >
      <BodyText
        style={{
          color: colors.labelInfoText,
          fontWeight: strong ? 600 : 500,
          textAlign: 'start',
          flex: 1,
          minWidth: 0,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
        {text}
      </BodyText>
    </Container>
  )
}
