import { ComponentType, CSSProperties } from 'react'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import HoverableContainer from '../HoverableContainer'
import { IconProps } from '../IconButton'
import IconHoverWrapper from '../IconHoverWrapper'
import { Container, Spacer } from '../layout/Layout'
import { CaptionText } from '../Text'

type SidebarBigListItemProps = {
  text: string
  onClick?: () => void
  active?: boolean
  Icon?: ComponentType<IconProps>
  style?: CSSProperties
}
export default function SidebarBigListItem({
  text,
  onClick,
  active,
  Icon,
  style,
}: SidebarBigListItemProps) {
  const colors = useColors()
  return (
    <IconHoverWrapper>
      <HoverableContainer
        hoverColor={colors.selected}
        style={{
          borderRadius: 5,
          width: '100%',
        }}
        onClick={onClick}
      >
        <Container
          vAlign="center"
          horizontal
          flex={1}
          style={{
            padding: '18px 6px',
            borderRadius: 5,
            backgroundColor: active ? colors.inputBorder : undefined,
            ...style,
          }}
        >
          {Icon && (
            <>
              <ClickableOpacity
                style={{
                  borderRadius: 4,
                }}
              >
                {<Icon height={30} width={30} fill={colors.labelDim} />}
              </ClickableOpacity>
              <Spacer size={10} />
            </>
          )}
          <CaptionText
            style={{ color: colors.settingsBottom, fontWeight: 600 }}
          >
            {text}
          </CaptionText>
        </Container>
      </HoverableContainer>
    </IconHoverWrapper>
  )
}
