/// <reference types="react/experimental" />
/// <reference types="react-dom/experimental" />

import './fetchHack'
import { Root, createRoot } from 'react-dom/client'
import App from './App'
import '@reach/dialog/styles.css'
import { BrowserRouter } from 'react-router-dom'

declare global {
  interface Window {
    reactRoot: Root
  }
}

if (!window.reactRoot) {
  const rootElement = document.getElementById('root')
  if (!rootElement) {
    throw new Error('root not found')
  }
  window.reactRoot = createRoot(rootElement)
}

// Reload on code splitting errors
window.onerror = function (_message, _source, _lineno, _colno, error) {
  if (error && error.name === 'SyntaxError') {
    ;(window.location.reload as any)(true)
  }
}

window.reactRoot.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
