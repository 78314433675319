import { CSSProperties } from 'react'
import { isMobile } from 'react-device-detect'
import { useColors } from './Colors'
import { useTranslate } from './hooks/Internationalization'

export default function OrSeparator() {
  const colors = useColors()
  const t = useTranslate()

  const lineStyle: CSSProperties = {
    backgroundColor: colors.faint,
    height: 1,
    flex: 10,
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '30px 0',
      }}
    >
      <div style={lineStyle} />
      <p
        style={{
          margin: '0 10px',
          fontSize: isMobile ? 32 : 16,
          color: colors.label,
        }}
      >
        {t('Or')}
      </p>
      <div style={lineStyle} />
    </div>
  )
}
