import React, { Suspense, useCallback, useDeferredValue, useRef } from 'react'
import { useColors } from '../Colors'
import FoundUsersList from './FoundUsersList'
import SelectedUserPill from './SelectedUserPill'
import styled from '@emotion/styled'

interface UserSelectInputProps {
  placeholder?: string
  style?: React.CSSProperties
  text: string
  onChangeText: (text: string) => void
  users: Set<string>
  usersToFilter?: string[]
  onChangeUsers: (users: Set<string>) => void
}

const Div = styled.div<{ unfocusedColor: string; focusedColor: string }>`
  border: 0;
  border-bottom: 2px solid ${(props) => props.unfocusedColor};

  :focus-within {
    border-bottom: 2px solid ${(props) => props.focusedColor};
  }
`

export default function UserSelectInput({
  style,
  placeholder,
  users,
  onChangeUsers,
  text,
  onChangeText,
  usersToFilter,
}: UserSelectInputProps) {
  const searchTerm = useDeferredValue(text)
  const colors = useColors()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = (key: string) => {
    if (key === 'Backspace') {
      if (text === '') {
        removeLatestAddedUser()
      }
    }
  }

  const handleInputChange = useCallback(
    (value: string) => {
      onChangeText(value)
    },
    [onChangeText]
  )

  const handleAddUser = useCallback(
    (userId: string) => {
      const newUsers = new Set(users)
      newUsers.add(userId)
      onChangeUsers(newUsers)
      if (inputRef.current) inputRef.current.focus()
    },
    [onChangeUsers, users]
  )

  const removeUser = (userId: string) => {
    const newUsers = new Set(users)
    newUsers.delete(userId)
    onChangeUsers(newUsers)
  }

  const removeLatestAddedUser = () => {
    if (users.size <= 0) {
      return users
    }

    const newUsers = new Set(users)
    const latestAddedUser = [...users][users.size - 1]
    newUsers.delete(latestAddedUser)
    onChangeUsers(newUsers)
  }

  return (
    <>
      <Div
        unfocusedColor={colors.faint}
        focusedColor={colors.active}
        onKeyDown={(e) => handleKeyDown(e.key)}
        style={{
          maxHeight: 600,
          overflowY: 'auto',
          position: 'relative',
          fontSize: 18,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          paddingBottom: 8,
          ...style,
        }}
      >
        {users.size > 0 &&
          [...users].map((id) => (
            <SelectedUserPill
              key={id}
              userId={id}
              clicked={(userId) => removeUser(userId)}
            />
          ))}
        <input
          autoFocus
          value={text}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder={users.size === 0 ? placeholder : ''}
          style={{
            outline: 'none',
            minWidth: 0,
            flex: 1,
            fontSize: 18,
            color: colors.label,
            border: 0,
            marginTop: 12,
            backgroundColor: colors.webChatModalBackground,
          }}
          ref={inputRef}
        />
      </Div>
      <Suspense fallback={null}>
        <FoundUsersList
          usersToFilter={usersToFilter}
          selectedUsers={users}
          searchTerm={searchTerm}
          addUser={(userId) => handleAddUser(userId)}
        />
      </Suspense>
    </>
  )
}
