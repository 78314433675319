import { SvgIcon, SvgIconProps } from '@mui/material'

function iconComponent(svg: SvgComponent) {
  function Component({
    light,
    sx,
    ...props
  }: Omit<SvgIconProps<SvgComponent>, 'component'> & { light?: boolean }) {
    return (
      <SvgIcon
        color="primary"
        inheritViewBox
        fillColor=" "
        component={svg}
        {...props}
        sx={{ color: light ? '#fff' : undefined, ...sx }}
      />
    )
  }
  Component.displayName = svg.displayName
  Component.hasFilled = svg.hasFilled
  return Component
}

export type IconComponent = ReturnType<typeof iconComponent>

import { ReactComponent as AddFavoriteIconSvg } from './icons/ico-add-favorite.svg'
export const AddFavoriteIcon = iconComponent(AddFavoriteIconSvg)

import { ReactComponent as AddFileIconSvg } from './icons/ico-add-file.svg'
export const AddFileIcon = iconComponent(AddFileIconSvg)

import { ReactComponent as AddImageIconSvg } from './icons/ico-add-image.svg'
export const AddImageIcon = iconComponent(AddImageIconSvg)

import { ReactComponent as AddMemberIconSvg } from './icons/ico-add-member.svg'
export const AddMemberIcon = iconComponent(AddMemberIconSvg)

import { ReactComponent as AlertIconSvg } from './icons/ico-alert.svg'
export const AlertIcon = iconComponent(AlertIconSvg)

import { ReactComponent as AnswerIconSvg } from './icons/ico-answer.svg'
export const AnswerIcon = iconComponent(AnswerIconSvg)

import { ReactComponent as ArrowLeftIconSvg } from './icons/ico-arrow-left.svg'
export const ArrowLeftIcon = iconComponent(ArrowLeftIconSvg)

import { ReactComponent as ArrowRightIconSvg } from './icons/ico-arrow-right.svg'
export const ArrowRightIcon = iconComponent(ArrowRightIconSvg)

//import { ReactComponent as BirthdayIconSvg } from './icons/ico-birthday.svg'
//export const BirthdayIcon = iconComponent(BirthdayIconSvg)

import { ReactComponent as BoldIconSvg } from './icons/ico-bold.svg'
export const BoldIcon = iconComponent(BoldIconSvg)

import { ReactComponent as CalendarIconSvg } from './icons/ico-calendar.svg'
export const CalendarIcon = iconComponent(CalendarIconSvg)

import { ReactComponent as CallIconSvg } from './icons/ico-call.svg'
export const CallIcon = iconComponent(CallIconSvg)

import { ReactComponent as ChatIconSvg } from './icons/ico-chat.svg'
export const ChatIcon = iconComponent(ChatIconSvg)

import { ReactComponent as CheckOutIconSvg } from './icons/ico-check-out.svg'
export const CheckOutIcon = iconComponent(CheckOutIconSvg)

import { ReactComponent as CheckIconSvg } from './icons/ico-check.svg'
export const CheckIcon = iconComponent(CheckIconSvg)

import { ReactComponent as ChevronDownIconSvg } from './icons/ico-chevron-down.svg'
export const ChevronDownIcon = iconComponent(ChevronDownIconSvg)

import { ReactComponent as ChevronLeftIconSvg } from './icons/ico-chevron-left.svg'
export const ChevronLeftIcon = iconComponent(ChevronLeftIconSvg)

import { ReactComponent as ChevronRightIconSvg } from './icons/ico-chevron-right.svg'
export const ChevronRightIcon = iconComponent(ChevronRightIconSvg)

import { ReactComponent as ChevronUpIconSvg } from './icons/ico-chevron-up.svg'
export const ChevronUpIcon = iconComponent(ChevronUpIconSvg)

import { ReactComponent as CloseSidebarIconSvg } from './icons/ico-close-sidebar.svg'
export const CloseSidebarIcon = iconComponent(CloseSidebarIconSvg)

import { ReactComponent as CloseIconSvg } from './icons/ico-close.svg'
export const CloseIcon = iconComponent(CloseIconSvg)

import { ReactComponent as CommentIconSvg } from './icons/ico-comment.svg'
export const CommentIcon = iconComponent(CommentIconSvg)

import { ReactComponent as ContactsIconSvg } from './icons/ico-contacts.svg'
export const ContactsIcon = iconComponent(ContactsIconSvg)

import { ReactComponent as CopyIconSvg } from './icons/ico-copy.svg'
export const CopyIcon = iconComponent(CopyIconSvg)

import { ReactComponent as CreateNewFeedIconSvg } from './icons/ico-create-new-feed.svg'
export const CreateNewFeedIcon = iconComponent(CreateNewFeedIconSvg)

import { ReactComponent as CreateNewIconSvg } from './icons/ico-create-new.svg'
export const CreateNewIcon = iconComponent(CreateNewIconSvg)

import { ReactComponent as CrossLargeIconSvg } from './icons/ico-cross-large.svg'
export const CrossLargeIcon = iconComponent(CrossLargeIconSvg)

import { ReactComponent as DeleteIconSvg } from './icons/ico-delete.svg'
export const DeleteIcon = iconComponent(DeleteIconSvg)

import { ReactComponent as DownloadIconSvg } from './icons/ico-download.svg'
export const DownloadIcon = iconComponent(DownloadIconSvg)

import { ReactComponent as EditIconSvg } from './icons/ico-edit.svg'
export const EditIcon = iconComponent(EditIconSvg)

import { ReactComponent as EmojisIconSvg } from './icons/ico-emojis.svg'
export const EmojisIcon = iconComponent(EmojisIconSvg)

import { ReactComponent as EventIconSvg } from './icons/ico-event.svg'
export const EventIcon = iconComponent(EventIconSvg)

import { ReactComponent as FabSmallIconSvg } from './icons/ico-FAB-small.svg'
export const FabSmallIcon = iconComponent(FabSmallIconSvg)

import { ReactComponent as FavoriteIconSvg } from './icons/ico-favorite.svg'
export const FavoriteIcon = iconComponent(FavoriteIconSvg)

import { ReactComponent as FeedsIconSvg } from './icons/ico-feeds.svg'
export const FeedsIcon = iconComponent(FeedsIconSvg)

import { ReactComponent as GoingIconSvg } from './icons/ico-going.svg'
export const GoingIcon = iconComponent(GoingIconSvg)

import { ReactComponent as GroupsIconSvg } from './icons/ico-groups.svg'
export const GroupsIcon = iconComponent(GroupsIconSvg)

import { ReactComponent as HeadingsIconSvg } from './icons/ico-headings.svg'
export const HeadingsIcon = iconComponent(HeadingsIconSvg)

import { ReactComponent as HomeIconSvg } from './icons/ico-home.svg'
export const HomeIcon = iconComponent(HomeIconSvg)

import { ReactComponent as HrIconSvg } from './icons/ico-hr.svg'
export const HrIcon = iconComponent(HrIconSvg)

import { ReactComponent as IndentIconSvg } from './icons/ico-indent.svg'
export const IndentIcon = iconComponent(IndentIconSvg)

import { ReactComponent as InformationIconSvg } from './icons/ico-information.svg'
export const InformationIcon = iconComponent(InformationIconSvg)

import { ReactComponent as ItalicIconSvg } from './icons/ico-italic.svg'
export const ItalicIcon = iconComponent(ItalicIconSvg)

import { ReactComponent as InvitationIconSvg } from './icons/ico-invitation.svg'
export const InvitationIcon = iconComponent(InvitationIconSvg)

import { ReactComponent as LikeIconSvg } from './icons/ico-like.svg'
export const LikeIcon = iconComponent(LikeIconSvg)

import { ReactComponent as LinkIconSvg } from './icons/ico-link.svg'
export const LinkIcon = iconComponent(LinkIconSvg)

import { ReactComponent as LogoutIconSvg } from './icons/ico-logout.svg'
export const LogoutIcon = iconComponent(LogoutIconSvg)

import { ReactComponent as ManageUserIconSvg } from './icons/ico-manage-user.svg'
export const ManageUserIcon = iconComponent(ManageUserIconSvg)

import { ReactComponent as MentionIconSvg } from './icons/ico-mention.svg'
export const MentionIcon = iconComponent(MentionIconSvg)

import { ReactComponent as MoveArticleIconSvg } from './icons/ico-move-article.svg'
export const MoveArticleIcon = iconComponent(MoveArticleIconSvg)

import { ReactComponent as NotGoingIconSvg } from './icons/ico-not-going.svg'
export const NotGoingIcon = iconComponent(NotGoingIconSvg)

import { ReactComponent as NotificationsOffIconSvg } from './icons/ico-notifications-off.svg'
export const NotificationsOffIcon = iconComponent(NotificationsOffIconSvg)

import { ReactComponent as NotificationsIconSvg } from './icons/ico-notifications.svg'
export const NotificationsIcon = iconComponent(NotificationsIconSvg)

import { ReactComponent as OlIconSvg } from './icons/ico-ol.svg'
export const OlIcon = iconComponent(OlIconSvg)

import { ReactComponent as OnSiteIconSvg } from './icons/ico-on-site.svg'
export const OnSiteIcon = iconComponent(OnSiteIconSvg)

import { ReactComponent as OpenSidebarIconSvg } from './icons/ico-open-sidebar.svg'
export const OpenSidebarIcon = iconComponent(OpenSidebarIconSvg)

import { ReactComponent as OutdentIconSvg } from './icons/ico-outdent.svg'
export const OutdentIcon = iconComponent(OutdentIconSvg)

import { ReactComponent as PasswordIconSvg } from './icons/ico-password.svg'
export const PasswordIcon = iconComponent(PasswordIconSvg)

import { ReactComponent as PinnedIconSvg } from './icons/ico-pinned.svg'
export const PinnedIcon = iconComponent(PinnedIconSvg)

import { ReactComponent as PlusLargeIconSvg } from './icons/ico-plus-large.svg'
export const PlusLargeIcon = iconComponent(PlusLargeIconSvg)

import { ReactComponent as PollsIconSvg } from './icons/ico-polls.svg'
export const PollsIcon = iconComponent(PollsIconSvg)

import { ReactComponent as PrintIconSvg } from './icons/ico-print.svg'
export const PrintIcon = iconComponent(PrintIconSvg)

import { ReactComponent as ProfileIconSvg } from './icons/ico-profile.svg'
export const ProfileIcon = iconComponent(ProfileIconSvg)

import { ReactComponent as QuoteIconSvg } from './icons/ico-quote.svg'
export const QuoteIcon = iconComponent(QuoteIconSvg)

import { ReactComponent as RecentlyUpdatedIconSvg } from './icons/ico-recently-updated.svg'
export const RecentlyUpdatedIcon = iconComponent(RecentlyUpdatedIconSvg)

import { ReactComponent as RemoteIconSvg } from './icons/ico-remote.svg'
export const RemoteIcon = iconComponent(RemoteIconSvg)

import { ReactComponent as RemoveFavoriteIconSvg } from './icons/ico-remove-favorite.svg'
export const RemoveFavoriteIcon = iconComponent(RemoveFavoriteIconSvg)

import { ReactComponent as SearchIconSvg } from './icons/ico-search.svg'
export const SearchIcon = iconComponent(SearchIconSvg)

import { ReactComponent as SendEmailIconSvg } from './icons/ico-send-email.svg'
export const SendEmailIcon = iconComponent(SendEmailIconSvg)

import { ReactComponent as SendIconSvg } from './icons/ico-send.svg'
export const SendIcon = iconComponent(SendIconSvg)

import { ReactComponent as SettingsIconSvg } from './icons/ico-settings.svg'
export const SettingsIcon = iconComponent(SettingsIconSvg)

import { ReactComponent as SickIconSvg } from './icons/ico-sick.svg'
export const SickIcon = iconComponent(SickIconSvg)

import { ReactComponent as SsoIconSvg } from './icons/ico-sso.svg'
export const SsoIcon = iconComponent(SsoIconSvg)

import { ReactComponent as StrikeThroughIconSvg } from './icons/ico-strike-through.svg'
export const StrikeThroughIcon = iconComponent(StrikeThroughIconSvg)

import { ReactComponent as SupportIconSvg } from './icons/ico-support.svg'
export const SupportIcon = iconComponent(SupportIconSvg)

import { ReactComponent as TableIconSvg } from './icons/ico-table.svg'
export const TableIcon = iconComponent(TableIconSvg)

import { ReactComponent as TakePhotoIconSvg } from './icons/ico-take-photo.svg'
export const TakePhotoIcon = iconComponent(TakePhotoIconSvg)

import { ReactComponent as TaskIconSvg } from './icons/ico-task.svg'
export const TaskIcon = iconComponent(TaskIconSvg)

import { ReactComponent as UlIconSvg } from './icons/ico-ul.svg'
export const UlIcon = iconComponent(UlIconSvg)

import { ReactComponent as UncertainIconSvg } from './icons/ico-uncertain.svg'
export const UncertainIcon = iconComponent(UncertainIconSvg)

import { ReactComponent as VacationIconSvg } from './icons/ico-vacation.svg'
export const VacationIcon = iconComponent(VacationIconSvg)

import { ReactComponent as WikiLinkIconSvg } from './icons/ico-wiki-link.svg'
export const WikiLinkIcon = iconComponent(WikiLinkIconSvg)

import { ReactComponent as WikiIconSvg } from './icons/ico-wiki.svg'
export const WikiIcon = iconComponent(WikiIconSvg)

import { ReactComponent as WorkplaceIconSvg } from './icons/ico-workplace.svg'
export const WorkplaceIcon = iconComponent(WorkplaceIconSvg)
