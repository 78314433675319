import firebase from 'firebase/app'
import { firestore } from '../firestore'

let promise: Promise<void> | null = null
let result: LoginResult | undefined

interface LoginResult {
  readonly organizationId: string
  readonly userId: string
}

function getLoginData() {
  async function myLoginData() {
    const user = firebase.auth().currentUser
    if (!user) {
      throw new Error('Connect login failed')
    }
    const { claims } = await user.getIdTokenResult()
    result = {
      organizationId: claims.organization,
      userId: user.uid,
    }
  }

  if (result) return result
  if (!promise) {
    promise = myLoginData().catch((err) => {
      console.error(err)
      throw err
    })
  }
  throw promise
}

export function readCurrentUserId(): string {
  return getLoginData().userId
}

export function readOrganizationId(): string {
  return getLoginData().organizationId
}

export function readOrganization() {
  const id = readOrganizationId()
  return firestore().collection('organizations').doc(id)
}
