import { ComponentType, CSSProperties, ReactNode } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'
import { IconProps } from '../IconButton'
import { Container } from '../layout/Layout'
import SidebarBigListItem from './SidebarBigListItem'
import SidebarListItem from './SidebarListItem'

type SidebarLinkProps = {
  text: string
  to: string
  toOrActiveList?: string[]
  big?: boolean
  Icon?: ComponentType<IconProps & any>
  AvatarComponent?: ReactNode
  subText?: string
  style?: CSSProperties
} & LinkProps

export default function SidebarLink({
  text,
  to,
  toOrActiveList,
  big,
  Icon,
  AvatarComponent,
  subText,
  style,
  ...props
}: SidebarLinkProps) {
  const { pathname } = useLocation()
  const active = pathname.startsWith(to) || toOrActiveList?.includes(pathname)

  if (big) {
    return (
      <Link to={to} style={{ textDecoration: 'none' }} {...props}>
        <SidebarBigListItem Icon={Icon} text={text} active={active} />
      </Link>
    )
  }

  return (
    <Link to={to} style={{ textDecoration: 'none' }} {...props}>
      <Container horizontal>
        <SidebarListItem
          Icon={Icon}
          subText={subText}
          AvatarComponent={AvatarComponent}
          text={text}
          active={active}
          style={style}
        />
      </Container>
    </Link>
  )
}
