import styled from '@emotion/styled'
import AddFeedSearchItem from './AddFeedSearchItem'
import { useOrganizationDoc } from '../../hooks/auth'
import { useFirestoreCollection } from '@iwikal/reactfire'
import { useTranslate } from '../../hooks/Internationalization'

const StyledAddFeedSearch = styled.div`
  .addFeedSearchWrapper {
    max-height: 888px;
    .header {
      width: 100%;
      background: #222222;
      color: #fff;
      line-height: 68px;
      font-size: 20px;
      text-align: center;
    }
    input.searchInput {
      background: #f5f5f5;
      margin-bottom: 30px;
      color: #828282;
      border: none;
      border-radius: 100px;
      height: 34px;
      line-height: 34px;
      padding: 0 20px;
      width: 100%;
      font-size: 14px;
    }
    .createFeedButton {
      background: #4b9fcc;
      color: #fff;
      width: 100%;
      line-height: 40px;
      text-align: center;
      border-radius: 6px;
      user-select: none;
      cursor: pointer;
    }
    .searchResultList {
      padding: 0;
      height: 350px;
      overflow: auto;
    }
  }
`

export default function AddFeedSearch(props: {
  handleShowCreateFeed: () => void
  user: string
  selectFeed: (feedId: string) => void
  close: () => void
}) {
  const t = useTranslate()
  const query = useOrganizationDoc()
    .collection('feeds')
    .where('inviteOnly', '==', false)

  const openFeeds = useFirestoreCollection(query)

  return (
    <StyledAddFeedSearch>
      <div className="addFeedSearchWrapper">
        {/* <input type='text' className='searchInput' placeholder='Search' value={searchValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)} /> */}
        <div
          className="createFeedButton"
          onClick={() => {
            props.handleShowCreateFeed()
          }}
        >
          {t('Create new information feed')}
        </div>

        <ul className="searchResultList">
          {openFeeds.read().docs.map((snap) => (
            <AddFeedSearchItem
              key={snap.id}
              feedId={snap.id}
              feed={snap.data()}
              user={props.user}
              selectFeed={props.selectFeed}
              close={props.close}
            />
          ))}
        </ul>
      </div>
    </StyledAddFeedSearch>
  )
}
