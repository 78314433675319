import { useFirestoreDoc } from '@iwikal/reactfire'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { useEffect, useState } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useRouteMatch } from 'react-router-dom'
import { useScrollLimitQuery } from '../../chat/useScrollLimitQuery'
import { useColors } from '../../Colors'
import { useCurrentUserId, useOrganizationDoc } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import FeedPostEditCard from '../FeedPostEditCard'
import FeedPost from '../post/FeedPost'
import PostDetailsInline from '../PostDetailsInline'

export default function CombinedPersonalFeeds() {
  const colors = useColors()
  const t = useTranslate()

  const { loadMore, snap: posts } = useScrollLimitQuery(
    useOrganizationDoc()
      .collection('combinedFeeds')
      .doc('personal')
      .collection('posts')
      .orderBy('createdDate', 'desc')
  )

  useBottomScrollListener<HTMLDivElement>(loadMore)

  const match = useRouteMatch<{ feedId: string; postId: string }>(
    '/feeds/all/:feedId/:postId'
  )
  const feedId = match?.params.feedId
  const postId = match?.params.postId

  const combinedFeedRef = useOrganizationDoc()
    .collection('combinedFeeds')
    .doc(useCurrentUserId())

  const [unseenPost] = useState(
    useFirestoreDoc(
      useOrganizationDoc().collection('combinedFeeds').doc(useCurrentUserId())
    )
      .read()
      .data()?.firstUnseenPost
  )

  useEffect(() => {
    if (unseenPost) {
      combinedFeedRef.update({
        firstUnseenPost: firebase.firestore.FieldValue.delete(),
      })
    }
  }, [combinedFeedRef, unseenPost])

  const currentUserId = useCurrentUserId()

  if (feedId && postId) {
    return <PostDetailsInline feedId={feedId} postId={postId} />
  }

  return (
    <div
      style={{
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
      }}
    >
      <FeedPostEditCard
        initialFeedId={'personal_' + currentUserId}
        style={{ marginBottom: 30 }}
      />

      {posts.size === 0 ? (
        <div
          style={{
            flex: 1,
            textAlign: 'center',
            color: colors.labelDim,
            fontSize: 14,
          }}
        >
          {t('Nothing here...')}
        </div>
      ) : (
        posts.docs.map((snap) => <FeedPost key={snap.id} postSnap={snap} />)
      )}
    </div>
  )
}
