import { useCallback, useState } from 'react'
import { useColors } from './Colors'
import { useSpring, animated } from 'react-spring'

interface ToggleAreaProps {
  onChange: (value: boolean) => void
  label: string
  description?: string
}

export default function ToggleArea({
  onChange,
  label,
  description,
}: ToggleAreaProps) {
  const [toggled, setToggled] = useState<boolean>(false)

  const colors = useColors()

  const animation = useSpring({
    config: { friction: 100, tension: 1000 },
    position: 'relative',
    left: toggled ? 23 : 2,
  })

  const dotStyle: React.CSSProperties = {
    ...animation,
    display: 'flex',
    width: 26,
    height: 26,
    borderRadius: 26 / 2,
    backgroundColor: colors.foreground,
  }

  const handleChange = useCallback(() => {
    setToggled((toggled) => {
      onChange(!toggled)
      return !toggled
    })
  }, [onChange])

  return (
    <div
      style={{
        display: 'flex',
        alignSelf: 'stretch',
        padding: '24px 20px',
        boxShadow: `0 1px 3px ${colors.announcementShadow}`,
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          marginBottom: description ? 20 : 0,
        }}
      >
        <p style={{ fontSize: 16 }}>{label}</p>
        <div
          onClick={handleChange}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 30 / 2,
            width: 50,
            height: 30,
            backgroundColor: toggled ? colors.brand : colors.faint,
          }}
        >
          <animated.div style={dotStyle} />
        </div>
      </div>
      {description && (
        <p style={{ color: colors.label, fontSize: 14 }}>{description}</p>
      )}
    </div>
  )
}
