import { useState, useTransition, useCallback, useDeferredValue } from 'react'
import firebase from 'firebase/app'
import { useFirestoreCollection } from '@iwikal/reactfire'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

interface ScrollLimitOptions {
  initialLimit?: number
  maxLimit?: number
}

export function useScrollLimitQuery<
  T,
  R extends HTMLDivElement = HTMLDivElement
>(query: firebase.firestore.Query<T>, options?: ScrollLimitOptions) {
  const { initialLimit = 13, maxLimit = Infinity } = options || {}

  const deferredMaxLimit = useDeferredValue(maxLimit)

  const [limit, setLimit] = useState(initialLimit)
  const deferredLimit = Math.min(deferredMaxLimit, limit)

  const [, startTransition] = useTransition()
  const snap = useFirestoreCollection(query.limit(deferredLimit), {
    skipCache: true,
  }).read()

  const hasMore = snap.size === deferredLimit && deferredLimit < maxLimit

  const loadMore = useCallback(() => {
    if (!hasMore) return

    startTransition(() => {
      setLimit(Math.min(deferredMaxLimit, snap.size + initialLimit))
    })
  }, [initialLimit, hasMore, deferredMaxLimit, snap.size, startTransition])

  const scrollRef = useBottomScrollListener<R>(loadMore, 200)

  return {
    snap,
    scrollRef,
    loadMore,
    hasMore,
    setLimit,
  }
}
