import { useState, useEffect, useContext, createContext } from 'react'
import { colors } from 'connect-shared/constants'
import { darkTheme } from './theme'
import { ThemeProvider as StyledThemeProvider } from '@emotion/react'

const colorsContext = createContext(colors)

function Providers(props: { children: React.ReactNode; value: typeof colors }) {
  return (
    <StyledThemeProvider theme={props.value}>
      <colorsContext.Provider {...props} />
    </StyledThemeProvider>
  )
}

export function ColorsProvider(props: React.PropsWithChildren<{}>) {
  const [theme, setTheme] = useState<'default' | 'dark'>('default')
  useEffect(() => {
    try {
      if (window.localStorage.theme === 'dark') {
        setTheme('dark')
      }
    } catch (err) {
      console.error(err)
    }
    const codeKeys = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
    ]
    let nextKeyIndex = 0
    const handler = (e: KeyboardEvent) => {
      if (e.key === codeKeys[nextKeyIndex]) {
        nextKeyIndex = nextKeyIndex + 1
        if (nextKeyIndex === codeKeys.length) {
          nextKeyIndex = 0
          const newTheme = theme === 'dark' ? 'default' : 'dark'
          try {
            window.localStorage.theme = newTheme
          } catch (err) {
            console.error(err)
          }
          setTheme(newTheme)
        }
      } else {
        nextKeyIndex = 0
      }
    }
    document.addEventListener('keydown', handler)
    return () => document.removeEventListener('keydown', handler)
  }, [theme])

  const value = theme === 'dark' ? (darkTheme(colors) as any) : colors

  return <Providers value={value}>{props.children}</Providers>
}

export function useColors() {
  return useContext(colorsContext)
}

type Theme = (theme: any) => any

export function ThemeProvider(props: {
  theme?: Theme | false | null | 0 | ''
  children?: React.ReactNode
}) {
  const { theme, children } = props

  const colors = useColors()

  return (
    <Providers value={theme ? theme(colors) : colors}>{children}</Providers>
  )
}

export type Colors = { [key: string]: string }
