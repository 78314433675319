import { css } from '@emotion/react'
import { Switch } from '@mui/material'
import {
  FeedEvent,
  FileInfo,
  Mention,
  Poll,
  PollResult,
  ProgressiveImage,
  UserIdsDocument,
} from 'connect-shared/types'
import firebase from 'firebase/app'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { useDropzone } from 'react-dropzone'
import uuid from 'uuid'
import { PostNode } from 'connect-shared/datamodel'
import { DocumentReference } from 'connect-shared/firestore'
import ClickableOpacity from '../ClickableOpacity'
import { useColors } from '../Colors'
import { ModelDocument } from '../firestore'
import HelpHover from '../HelpHover'
import useFeatureFlag from '../hooks/useFeatureFlag'
import { FileUpload } from '../hooks/useFileUploads'
import { ImageUpload } from '../hooks/useImageUploads'
import { ReactComponent as AddFileIcon } from '../icons/ico-add-file.svg'
import { ReactComponent as AddEventIcon } from '../icons/ico-event.svg'
import { ReactComponent as AddPhotoIcon } from '../icons/ico-add-image.svg'
import { ReactComponent as PollIcon } from '../icons/ico-polls.svg'
import { Container, Pusher, Spacer } from '../layout/Layout'
import MentionsInput from '../MentionsInput'
import OutlineButton from '../OutlineButton'
import { Resource } from '../resource'
import RoundActionButton from '../RoundActionButton'
import { BodyText } from '../Text'
import { showErrorToast } from '../Toast'
import UploadsPreview from '../UploadsPreview'
import { FeedPostEditCardState } from './FeedPostEditCard'
import PollEditor, { Recipe } from './PollEditor'
import EditEventCard from './post/event/EditEventCard'
import { useTranslate } from '../hooks/Internationalization'

export default function PostCreateCardOpenState({
  setState,
  header,
  onTextChange,
  onInputValueChange,
  inputValue,
  onMentionsChange,
  onAddFile,
  onAddImage,
  onSubmit,
  disableSubmit,
  fileUploads,
  imageUploads,
  removeFile,
  removeImage,
  isAnnouncement,
  onIsAnnouncementToggle,
  onEditCancel,
  initialImages,
  initialFiles,
  onRemoveInitialFile,
  onRemoveInitialImage,
  poll,
  initialPoll,
  setPoll,
  pollResultResource,
  eventEditorOpen,
  onEventEditorClose,
  onEventEditorOpen,
  postEvent,
  setPostEvent,
  editingEvent,

  mentionsFilter,
}: {
  setState: Dispatch<SetStateAction<FeedPostEditCardState>>
  header: ReactNode
  fileUploads: FileUpload[]
  imageUploads: ImageUpload[]
  isAnnouncement: boolean
  disableSubmit?: boolean
  onTextChange: (text: string) => void
  onInputValueChange: (text: string) => void
  inputValue: string
  onMentionsChange: (mentions: Mention[]) => void
  onAddFile: (file: File) => void
  onAddImage: (file: File) => void
  onSubmit: () => void
  removeFile: (id: string) => void
  removeImage: (id: string) => void
  onIsAnnouncementToggle: () => void
  onEditCancel?: () => void
  initialImages?: Set<ProgressiveImage>
  initialFiles?: Set<FileInfo>
  onRemoveInitialFile?: (file: FileInfo) => void
  onRemoveInitialImage?: (image: ProgressiveImage) => void
  poll: Poll | undefined
  initialPoll: Poll | undefined
  setPoll: Dispatch<Recipe<Poll | undefined>>
  pollResultResource:
    | Resource<firebase.firestore.DocumentSnapshot<PollResult>>
    | undefined
  onAddEvent: () => void
  eventEditorOpen: boolean
  onEventEditorClose: () => void
  onEventEditorOpen: () => void
  postEvent: FeedEvent
  setPostEvent: (data: FeedEvent) => void
  onEventDelete: () => void
  editingEvent: boolean
  postRef?: ModelDocument<PostNode, string>
  mentionsFilter?: DocumentReference<UserIdsDocument>
}) {
  const colors = useColors()
  const t = useTranslate()

  const cancel = useCallback(() => {
    onEditCancel ? onEditCancel() : setState('closed')
  }, [onEditCancel, setState])

  const fileInputRef = useRef<HTMLInputElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)

  const handleFileClick = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const handleImageClick = useCallback(() => {
    imageInputRef.current?.click()
  }, [])

  const handlePollClick = useCallback(() => {
    if (eventEditorOpen) {
      showErrorToast(t('A post can have either a poll or an event, not both.'))
      return
    }

    const id = uuid.v4()
    const id2 = uuid.v4()
    setPoll(() => ({
      allowMultiple: false,
      options: { [id]: { title: '' }, [id2]: { title: '' } },
      ordering: [id, id2],
    }))
  }, [eventEditorOpen, setPoll, t])

  const handleAddFile = useCallback(
    (event) => {
      const target = event.target as EventTarget & { files: FileList }
      const files = [...target.files]

      for (const file of files) {
        // Upload everything as files except images that are not gifs.
        if (!file.type.startsWith('image/') || file.type === 'image/gif') {
          onAddFile(file)
        } else {
          onAddImage(file)
        }
      }
      remountFile()
    },
    [onAddFile, onAddImage]
  )

  const handleAddImage = useCallback(
    (event) => {
      const target = event.target as EventTarget & { files: FileList }
      const files = [...target.files]

      const validTypes = ['jpg', 'jpeg', 'gif', 'png']

      for (const file of files) {
        const isValid = validTypes.some((validType) => {
          return file.type === `image/${validType}`
        })
        if (!isValid) {
          console.error('Invalid file-type')
          showErrorToast(t('Unsupported file type'))
          throw 'Invalid file-type'
        }
        onAddImage(file)
      }
      remountImage()
    },
    [onAddImage, t]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validImageTypes = ['jpg', 'jpeg', 'gif', 'png']

      for (const file of acceptedFiles) {
        const isValidImage = validImageTypes.some((validType) => {
          return file.type === `image/${validType}`
        })
        if (isValidImage) {
          onAddImage(file)
        } else {
          onAddFile(file)
        }
      }
    },
    [onAddFile, onAddImage]
  )

  const handlePaste = useCallback(
    (e: Event) => {
      const validImageTypes = ['jpg', 'jpeg', 'gif', 'png']
      const clipboardEvent = e as ClipboardEvent
      const fileArray = [...(clipboardEvent.clipboardData?.files || [])]
      for (const file of fileArray) {
        const isImage = validImageTypes.some((validType) => {
          return file.type === `image/${validType}`
        })
        if (isImage) {
          onAddImage(file)
        } else {
          onAddFile(file)
        }
      }
    },
    [onAddFile, onAddImage]
  )

  useEffect(() => {
    window.addEventListener('paste', handlePaste)
    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  const pollsEnabled = useFeatureFlag('pollsEnabled', false)
  const eventsInFeedsFlag = useFeatureFlag('eventsInFeeds', false)

  const [fileKey, remountFile] = useReducer(Math.random, Math.random())
  const [imageKey, remountImage] = useReducer(Math.random, Math.random())

  return (
    <div {...getRootProps()}>
      <Container vAlign="center" style={{ position: 'relative' }}>
        {isDragActive && (
          <Container
            hAlign="center"
            vAlign="center"
            style={{
              zIndex: 2,
              backgroundColor: colors.webChatTransparentBackground,
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: 5,
            }}
          >
            <BodyText
              style={{
                fontSize: 30,
                fontWeight: 600,
                color: colors.textInverted,
              }}
            >
              {t('Drop images or files here')}
            </BodyText>
          </Container>
        )}
        {header}
        <Container style={{ padding: 20 }}>
          <Spacer size={20} />
          <MentionsInput
            filter={mentionsFilter}
            autoFocus
            value={inputValue}
            onChange={({ text, value, mentions }) => {
              onTextChange(text)
              onInputValueChange(value)
              onMentionsChange(mentions)
            }}
            rows={9}
            placeholder={t("What's on your mind?")}
            style={{
              input: {
                fontSize: 16,
                overflow: 'auto',
                height: 170,
                flex: 1,
                resize: 'none',
                border: 'none',
                outline: 'none',
                '::placeholder': {
                  color: colors.labelDim,
                },
              },
              highlighter: {
                fontSize: 16,
                boxSizing: 'border-box',
                overflow: 'hidden',
                height: 170,
              },
            }}
            id="feed_input"
          />
          <Spacer size={16} />

          {eventEditorOpen ? (
            <EditEventCard
              postEvent={postEvent}
              onChange={setPostEvent}
              onRequestClose={onEventEditorClose}
            />
          ) : (
            poll && (
              <PollEditor
                poll={poll}
                setPoll={setPoll}
                initialPoll={initialPoll}
                pollResultResource={pollResultResource}
              />
            )
          )}
          <Spacer size={16} />
          <UploadsPreview
            onRemoveInitialFile={onRemoveInitialFile}
            onRemoveInitialImage={onRemoveInitialImage}
            initialImages={initialImages}
            initialFiles={initialFiles}
            fileUploadsInProgress={fileUploads}
            removeFile={removeFile}
            imageUploadsInProgress={imageUploads}
            removeImage={removeImage}
          />
          <Container horizontal vAlign="center">
            <HelpHover
              style={{ maxWidth: 600 }}
              text={t(
                "Your post will be pinned to the top of the feed and will stand out more to the recipient. It's easy for the recipient to confirm the post as read and everyone in the feed can see who has read or not."
              )}
            />

            <Spacer size={10} />
            <BodyText>{t('Require reading confirmation on post')}</BodyText>
            <Switch
              checked={isAnnouncement}
              onChange={onIsAnnouncementToggle}
              color="primary"
              inputProps={{
                'aria-label': t('checkbox for reading confirmation'),
              }}
            />
          </Container>
          <Spacer size={10} />
          <Container horizontal vAlign="center">
            <RoundActionButton
              onClick={handleImageClick}
              Icon={AddPhotoIcon}
              tooltip={t('Attach image to post')}
            />
            <Spacer size={10} />
            <RoundActionButton
              onClick={handleFileClick}
              Icon={AddFileIcon}
              tooltip={t('Attach file to post')}
            />
            <Spacer size={10} />
            {pollsEnabled && (
              <RoundActionButton
                onClick={handlePollClick}
                Icon={PollIcon}
                disabled={!!poll || eventEditorOpen}
                tooltip={t('Attach poll to post')}
              />
            )}
            {eventsInFeedsFlag && (
              <>
                <Spacer size={10} />
                <RoundActionButton
                  onClick={onEventEditorOpen}
                  Icon={AddEventIcon}
                  tooltip={t('Attach event to post')}
                  disabled={!!poll || eventEditorOpen}
                />
              </>
            )}
            <Pusher />
            <ClickableOpacity
              onClick={cancel}
              css={css`
                color: ${colors.label};

                &:hover {
                  color: ${colors.cancelHover};
                }
              `}
              style={{
                fontWeight: 600,
                padding: '0 20px',
              }}
            >
              {t('Cancel')}
            </ClickableOpacity>
            <OutlineButton
              id="feed_submit"
              disabled={disableSubmit}
              onClick={onSubmit}
            >
              {editingEvent ? t('Publish changes') : t('Publish post')}
            </OutlineButton>
          </Container>
          <input
            key={fileKey}
            onChange={handleAddFile}
            multiple
            type="file"
            accept="*"
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <input
            key={imageKey}
            onChange={handleAddImage}
            multiple
            type="file"
            accept=".png, .jpg, .jpeg"
            style={{ display: 'none' }}
            ref={imageInputRef}
          />
        </Container>
      </Container>
    </div>
  )
}
