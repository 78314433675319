import { Container, Pusher } from './layout/Layout'
import SignUpLayout from './SignUpLayout'
import TextInput from './TextInput'
import { isMobile } from 'react-device-detect'
import BlockButton from './BlockButton'
import OrSeparator from './OrSeparator'
import { useColors } from './Colors'
import React, { FormEvent, useCallback, useState } from 'react'
import firebase from './firebase-init'
import useFeatureFlag from './hooks/useFeatureFlag'
import { BodyText } from './Text'
import LinkText from './LinkText'
import PlayippSpinner from './playippSpinner/PlayippSpinner'
import { useTranslate } from './hooks/Internationalization'

export default function SignIn() {
  const colors = useColors()
  const t = useTranslate()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<boolean | string>(false)
  const [rememberMe, setRememberMe] = useState(true)

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (loading) return
      setLoading(true)

      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        return
      } catch (error) {
        setError(error.message || t('Unknown error'))
        setLoading(false)
        console.error(error)
      }

      await firebase
        .auth()
        .setPersistence(
          rememberMe
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        )
    },
    [email, loading, password, rememberMe, t]
  )

  const handleEmailChange = useCallback((text: string) => {
    setEmail(text.trim().toLowerCase())
  }, [])

  const ssoEnabled = useFeatureFlag('sso', false)

  const fontSize = isMobile ? 32 : 16
  return (
    <SignUpLayout
      backgroundColor={colors.beeworkGoldLight}
      caption={t('Sign in')}
      footer={
        <Container style={{ marginTop: 24 }} hAlign="center">
          <BodyText
            style={{
              fontSize,
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {t("Don't have an account?")}
          </BodyText>
          <LinkText
            style={{
              fontSize,
              fontFamily: 'Work sans, sans-serif',
            }}
            to="/sign-up"
          >
            {t('Create one here')}
          </LinkText>
        </Container>
      }
    >
      <Container>
        <form onSubmit={handleSubmit} className="login">
          <TextInput
            size={isMobile ? 40 : undefined}
            error={!!error}
            onChangeText={handleEmailChange}
            value={email}
            type="email"
            id="email"
            placeholder={t('Email address')}
          />
          <TextInput
            style={{ marginBottom: 10 }}
            size={isMobile ? 40 : undefined}
            error={error}
            onChangeText={setPassword}
            value={password}
            type="password"
            id="password"
            placeholder={t('Password')}
          />
          <Container
            style={{
              marginTop: isMobile ? 40 : 0,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile ? 'flex-start' : 'center',
              alignItems: isMobile ? 'flex-start' : 'center',
              flexWrap: 'wrap',
            }}
          >
            <Container
              vAlign="center"
              horizontal
              flex={1}
              style={{
                justifyContent: 'space-between',
                marginBottom: 30,
                width: '100%',
              }}
            >
              <Container vAlign="center" horizontal>
                <input
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  style={{
                    height: isMobile ? 48 : 16,
                    width: isMobile ? 48 : 16,
                    marginRight: isMobile ? 10 : undefined,
                  }}
                  type="checkbox"
                />
                <p
                  style={{
                    margin: '0 30px 0 5px',
                    fontSize: isMobile ? 32 : 14,
                    fontFamily: 'Work sans, sans-serif',
                    color: colors.beeworkBlue,
                  }}
                >
                  {t('Remember me')}
                </p>
              </Container>
              <Pusher />
              <LinkText
                to="/forgot"
                style={{
                  color: colors.beeworkBlue,
                  alignSelf: 'center',
                  fontSize: isMobile ? 32 : 14,
                  fontFamily: 'Work sans, sans-serif',
                }}
              >
                {t('Forgot your password?')}
              </LinkText>
            </Container>
          </Container>
          <Container hAlign="center">
            <BlockButton
              disabled={loading || !email.trim() || !password.trim()}
              type="submit"
              id="login"
              style={{
                display: 'flex',
                backgroundColor: colors.beeworkGold,
                color: colors.beeworkBlue,
                borderRadius: 27,
                marginTop: isMobile ? 40 : 0,
                padding: isMobile ? '40px 80px' : '9px 50px',
                alignItems: 'center',
                justifyContent: 'center',
                height: isMobile ? 117 : 37,
                width: isMobile ? 320 : 142,
              }}
            >
              {!loading && (
                <p
                  style={{
                    fontSize: isMobile ? 32 : 16,
                  }}
                >
                  {t('Login')}
                </p>
              )}
              {loading && (
                <PlayippSpinner
                  size={isMobile ? 40 : 18}
                  color={colors.beeworkBlue}
                />
              )}
            </BlockButton>
          </Container>
        </form>
        {ssoEnabled && (
          <>
            <OrSeparator />
            <LinkText
              style={{
                marginTop: isMobile ? 20 : undefined,
                marginBottom: isMobile ? 20 : undefined,
                fontSize: isMobile ? 32 : 16,
                textAlign: 'center',
                color: colors.beeworkBlue,
                fontFamily: 'Work sans, sans-serif',
              }}
              to="/sso/"
            >
              {t('Use single sign on instead')}
            </LinkText>
          </>
        )}
      </Container>
    </SignUpLayout>
  )
}
