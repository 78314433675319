import React, { CSSProperties, ReactNode, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import ClickableOpacity from './ClickableOpacity'
import { useColors } from './Colors'
import { ReactComponent as Logo } from './icons/beework-logo-blue-yellow.svg'
import { Container, Spacer } from './layout/Layout'
import { BodyText, CaptionText } from './Text'

type SignUpLayoutProps = {
  children?: ReactNode
  caption?: string
  footer?: ReactNode
  containerStyle?: CSSProperties
  backgroundColor?: string
  subText?: string | ReactNode
}

export default function SignUpLayout({
  children,
  caption,
  footer,
  containerStyle,
  backgroundColor,
  subText,
}: SignUpLayoutProps) {
  const colors = useColors()

  const logoHeight = isMobile ? 85 : 57

  const history = useHistory()

  const navigateHome = useCallback(() => {
    history.push('/sign-in')
  }, [history])

  return (
    <Container
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : colors.beeworkBabyBlue,
        height: '100%',
        overflowY: 'auto',
      }}
      hAlign="center"
    >
      <ClickableOpacity onClick={navigateHome}>
        <Logo
          style={{ marginTop: 40 }}
          height={logoHeight}
          width={isMobile ? 330 : 215}
        />
      </ClickableOpacity>
      <Container style={{ width: '100%' }} flex={1} hAlign="center">
        {CaptionText && (
          <CaptionText
            style={{
              textAlign: 'center',
              marginTop: 91,
              fontSize: isMobile ? 80 : 40,
              fontWeight: 'bold',
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {caption}
          </CaptionText>
        )}
        {subText && !isMobile && (
          <BodyText
            style={{
              marginTop: 24,
              textAlign: 'center',
              maxWidth: 566,
              fontSize: isMobile ? 36 : 18,
              color: colors.beeworkBlue,
              fontFamily: 'Work sans, sans-serif',
            }}
          >
            {subText}
          </BodyText>
        )}
        <Spacer size={24} />
        <Container
          style={{
            padding: 48,
            borderRadius: 8,
            backgroundColor: colors.foreground,
            width: isMobile ? 'calc(100% - 96px)' : 512,
            boxShadow: `5px 20px 40px ${colors.logInDropshadow}`,
            ...containerStyle,
          }}
        >
          {children}
        </Container>
        {footer}
      </Container>
    </Container>
  )
}
