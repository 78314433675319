import {
  Box,
  ButtonBase,
  Slide,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as DownChevron } from './icons/ico-chevron-down.svg'
import { ReactComponent as LeftChevron } from './icons/ico-chevron-left.svg'
import { ReactComponent as RightChevron } from './icons/ico-chevron-right.svg'
import { ReactComponent as UpChevron } from './icons/ico-chevron-up.svg'
import { ReactComponent as CloseIcon } from './icons/ico-close.svg'
import { ReactComponent as ArrowRight } from './icons/ico-arrow-right.svg'
import materialTheme from './materialTheme'

export type AttentionBarStep = {
  headline: string
  body: string
  completed?: boolean
  onClick?: () => void
  clickIcon?: SvgComponent
  clickText?: string
}

function Top({
  onRequestClose,
  sx,
  tutorialName,
}: {
  onRequestClose?: () => void
  sx?: SxProps<Theme>
  tutorialName: string
}) {
  const iconSize = 24

  return (
    <Box
      sx={{
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        ...sx,
      }}
      display="flex"
      justifyContent="space-between"
    >
      <Typography
        variant="caption"
        color="info.light"
        textTransform="uppercase"
      >
        Guide: {tutorialName}
      </Typography>
      <ButtonBase
        onClick={onRequestClose}
        style={{
          marginLeft: -4,
        }}
      >
        <DownChevron
          width={iconSize}
          height={iconSize}
          fillColor={materialTheme.palette.primary.contrastText}
        />
        <Typography color="background.paper" fontSize={14}>
          Hide
        </Typography>
      </ButtonBase>
    </Box>
  )
}

type StepContentProps = {
  step: AttentionBarStep
  slideRight: boolean
  show: boolean
}

const StepContent = React.forwardRef<HTMLDivElement, StepContentProps>(
  function StepContent({ step, slideRight, show }: StepContentProps, ref) {
    const containerRef = useRef<HTMLDivElement>(null)

    const ClickIcon = step.clickIcon || ArrowRight
    const clickText = step.clickText || "Let's go"

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: -50,
          right: -50,
          paddingLeft: 50,
          paddingRight: 50,
          bottom: 0,
          overflow: 'hidden',
          pointerEvents: show ? undefined : 'none',
        }}
        ref={containerRef}
      >
        <Slide
          container={(e) => containerRef.current || e}
          easing={{
            enter: materialTheme.transitions.easing.easeInOut,
            exit: materialTheme.transitions.easing.easeInOut,
          }}
          timeout={400}
          in={show}
          appear
          direction={slideRight === show ? 'left' : 'right'}
          unmountOnExit
        >
          <Box
            ref={ref}
            alignItems="center"
            display="flex"
            flexDirection="row"
            flex="1 0 0px"
          >
            <Box flex={1}>
              <Typography
                sx={{
                  userSelect: 'none',
                }}
                color="background.paper"
                fontWeight={600}
              >
                {step.headline}
              </Typography>
              <Typography
                sx={{
                  userSelect: 'none',
                }}
                color="background.paper"
              >
                {step.body}
              </Typography>
            </Box>
            {step.onClick && (
              <ButtonBase
                onClick={step.onClick}
                color="primary.light"
                sx={{
                  alignItems: 'center',
                  height: 9 * 8,
                  ml: 8,
                  flexDirection: 'column',
                  backgroundColor: 'primary.light',
                  borderRadius: 2,
                  width: 14 * 8,
                }}
              >
                <ClickIcon
                  style={{ marginBottom: -8, marginTop: -8 }}
                  height={50}
                  width={50}
                  fillColor="#fff"
                />
                <Typography variant="button" color="info.light">
                  {clickText}
                </Typography>
              </ButtonBase>
            )}
          </Box>
        </Slide>
      </div>
    )
  }
)

function Bottom({
  currentStep,
  steps,
  onRequestStep,
}: {
  currentStep: number
  steps: boolean[]
  onRequestStep: (step: number) => void
}) {
  return (
    <Box>
      <Box
        sx={{ mt: 2 }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{
          marginLeft: -10,
          marginRight: -10,
        }}
      >
        <ButtonBase
          onClick={() => onRequestStep(currentStep - 1)}
          disabled={currentStep === 0}
        >
          <LeftChevron
            width={32}
            height={32}
            fillColor={
              currentStep === 0
                ? materialTheme.palette.text.disabled
                : materialTheme.palette.primary.contrastText
            }
          />
        </ButtonBase>
        {steps.map((done, index) => {
          return (
            <ButtonBase
              sx={{ flex: 1, py: 1 }}
              onClick={() => onRequestStep(index)}
              key={index}
            >
              <Box
                sx={{
                  backgroundColor:
                    index === currentStep
                      ? 'warning.main'
                      : done
                      ? 'background.paper'
                      : 'primary.light',
                  mr: 1,
                  flex: 1,
                  height: 4,
                }}
              />
            </ButtonBase>
          )
        })}
        <ButtonBase
          onClick={() => onRequestStep(currentStep + 1)}
          disabled={currentStep === steps.length - 1}
        >
          <RightChevron
            width={32}
            height={32}
            fillColor={
              currentStep === steps.length - 1
                ? materialTheme.palette.text.disabled
                : materialTheme.palette.primary.contrastText
            }
          />
        </ButtonBase>
      </Box>
      <Typography
        textAlign="center"
        display="block"
        color="info.light"
        variant="caption"
        textTransform="uppercase"
      >
        Step {currentStep + 1} / {steps.length}
      </Typography>
    </Box>
  )
}

type AttentionBarProps = {
  steps: AttentionBarStep[]
  onRequestDismiss?: () => void
  open: boolean
  onRequestOpen?: () => void
  onRequestClose?: () => void
  onRequestComplete?: () => void
  onRequestStepChange?: (step: number) => void
  currentStep: number
  tutorialName: string
}

export default function AttentionBar({
  steps,
  onRequestDismiss,
  onRequestOpen,
  onRequestClose,
  onRequestComplete,
  open,
  currentStep,
  onRequestStepChange,
  tutorialName,
}: AttentionBarProps) {
  return (
    <Box sx={{ position: 'relative', mr: 4 }}>
      <Slide in={open} direction="up">
        <div style={{ pointerEvents: 'all' }}>
          <AttentionBarOpen
            onRequestStepChange={onRequestStepChange}
            currentStep={currentStep}
            steps={steps}
            onRequestClose={onRequestClose}
            onRequestComplete={onRequestComplete}
            tutorialName={tutorialName}
          />
        </div>
      </Slide>
      <Slide in={!open} direction="up">
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 32,
            pointerEvents: 'all',
          }}
        >
          <AttentionBarClosed
            onRequestOpen={onRequestOpen}
            onRequestDismiss={onRequestDismiss}
          />
        </div>
      </Slide>
    </Box>
  )
}

export function AttentionBarClosed({
  onRequestOpen,
  onRequestDismiss,
}: {
  onRequestOpen?: () => void
  onRequestDismiss?: () => void
}) {
  const iconSize = 24

  return (
    <Box
      sx={{
        width: 155,
        borderRadius: 4,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: 'primary.main',
        paddingLeft: 1,
      }}
    >
      <ButtonBase onClick={onRequestOpen}>
        <UpChevron
          width={iconSize}
          height={iconSize}
          fillColor={materialTheme.palette.primary.contrastText}
        />
        <Typography color="background.paper" fontSize={14}>
          Show guide
        </Typography>
      </ButtonBase>
      <ButtonBase
        onClick={onRequestDismiss}
        sx={{
          borderLeft: `1px solid ${materialTheme.palette.primary.light}`,
          py: 1,
          ml: 1,
        }}
      >
        <CloseIcon
          style={{ marginLeft: 4 }}
          height={iconSize}
          width={iconSize}
          fillColor={materialTheme.palette.background.paper}
        />
      </ButtonBase>
    </Box>
  )
}

export function AttentionBarOpen({
  onRequestClose,
  onRequestComplete,
  steps,
  currentStep,
  onRequestStepChange,
  tutorialName,
}: {
  onRequestClose?: () => void
  onRequestComplete?: () => void
  steps: AttentionBarStep[]
  currentStep: number
  onRequestStepChange?: (step: number) => void
  tutorialName: string
}) {
  const slideRight = useRef(true)

  const [stepContentHeights, setStepContentHeights] = useState<{
    [index: number]: number
  }>({})

  onRequestComplete // TODO implement dis

  const currentCompleted = steps[currentStep].completed ?? false

  const prevCompleted = useRef(currentCompleted)
  const prevStep = useRef(currentStep)

  useEffect(() => {
    if (
      !prevCompleted.current &&
      currentCompleted &&
      currentStep === prevStep.current &&
      currentStep !== steps.length - 1
    ) {
      onRequestStepChange?.(currentStep + 1)
    }

    prevCompleted.current = currentCompleted
    prevStep.current = currentStep
  }, [currentCompleted, currentStep, onRequestStepChange, steps.length])

  return (
    <Box
      color="main"
      display="flex"
      alignItems="center"
      flexDirection="column"
      style={{
        filter: 'drop-shadow(5px 30px 50px rgba(0,0,0,0.1))',
      }}
      sx={{
        mb: 9,
        borderRadius: 4,
        p: 2,
        backgroundColor: 'primary.main',
        width: 416,
      }}
    >
      <Box height="100%" width="100%">
        <Top
          sx={{ mb: 1 }}
          onRequestClose={onRequestClose}
          tutorialName={tutorialName}
        />
        <Box
          width="100%"
          sx={{
            height: stepContentHeights[currentStep],
            transition: 'height 200ms',
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          {steps.map((step, i) => {
            return (
              <StepContent
                key={i}
                ref={(el) => {
                  if (el?.clientHeight) {
                    if (stepContentHeights[i] !== el.clientHeight) {
                      setStepContentHeights({
                        ...stepContentHeights,
                        [i]: el.clientHeight,
                      })
                    }
                  }
                }}
                slideRight={slideRight.current}
                step={step}
                show={currentStep === i}
              />
            )
          })}
        </Box>
        <Bottom
          currentStep={currentStep}
          steps={steps.map((a) => !!a.completed)}
          onRequestStep={(nextStep: number) => {
            slideRight.current = nextStep > currentStep
            onRequestStepChange?.(
              Math.min(Math.max(nextStep, 0), steps.length - 1)
            )
          }}
        />
      </Box>
    </Box>
  )
}
