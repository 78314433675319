import { colors } from 'connect-shared/constants'
import { UserDocument } from 'connect-shared/types'
import { useCallback, useEffect, useState } from 'react'
import { useCurrentUserDoc } from '../../hooks/auth'
import { useTranslate } from '../../hooks/Internationalization'
import useCurrentUser from '../../hooks/useCurrentUser'
import HoverableUnderlineWrapper from '../../HoverableUnderlineWrapper'

export default function StatusTextInput() {
  const t = useTranslate()
  const currentUserDoc = useCurrentUserDoc()
  const currentUser = useCurrentUser()

  const statusText = currentUser?.statusText || ''

  const [text, setText] = useState(currentUser?.statusText || '')

  useEffect(() => setText(statusText), [statusText])

  const MAX_LENGTH = 30

  const save = useCallback(() => {
    const user: Partial<UserDocument> = {
      statusText: text.trim(),
    }
    currentUserDoc.update(user)
  }, [currentUserDoc, text])

  const handleInputBlur = useCallback(() => {
    save()
  }, [save])

  const handleTextInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value
      if (text.length <= MAX_LENGTH) {
        setText(text)
      }
    },
    []
  )

  return (
    <div
      style={{
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
      }}
    >
      <p style={{ fontSize: 10, alignSelf: 'flex-end' }}>
        {text.length}/{MAX_LENGTH}
      </p>
      <HoverableUnderlineWrapper
        style={{ paddingBottom: 5, display: 'flex', alignSelf: 'stretch' }}
        unfocusedColor={colors.faint}
        focusedColor={colors.active}
      >
        <input
          value={text}
          onBlur={handleInputBlur}
          onChange={handleTextInput}
          style={{
            width: '100%',
            alignSelf: 'stretch',
            flex: 1,
            fontSize: 14,
            border: 'none',
            backgroundColor: colors.none,
            outline: 'none',
          }}
          placeholder={t("What's going on?")}
        />
      </HoverableUnderlineWrapper>
    </div>
  )
}
