import { Drawer } from '@mui/material'
import { ReactNode } from 'react'

export function DevPanelItem({ children }: { children: ReactNode }) {
  return <div>{children}</div>
}

export default function DevPanel() {
  return (
    <Drawer>
      <h1>aaaoiajerogij</h1>
    </Drawer>
  )
}
