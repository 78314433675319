import { colors } from 'connect-shared/constants'
import HoverableScrollContainer from '../HoverableScrollContainer'

type SidebarProps = {
  children: React.ReactNode
  style?: React.CSSProperties
}

export default function Sidebar({ children, style }: SidebarProps) {
  return (
    <HoverableScrollContainer
      style={{
        backgroundColor: colors.selected,
        position: 'sticky',
        top: 0,
        width: 300,
        flex: '0 0 300px',
        maxHeight: '100%',
        ...style,
      }}
    >
      {children}
    </HoverableScrollContainer>
  )
}
