import { Container, Spacer } from '../layout/Layout'
import { BodyText, CaptionText } from '../Text'
import { useCurrentUserDoc } from '../hooks/auth'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { useColors } from '../Colors'
import OutlineButton from '../OutlineButton'
import { useCallback } from 'react'
import HighlightText from '../HighlightText'
import { useTranslate } from '../hooks/Internationalization'

export default function DiscoverFeedsWelcomeBlock() {
  const colors = useColors()
  const t = useTranslate()
  const currentUserDoc = useCurrentUserDoc()

  const hasSeen = useFirestoreDoc(currentUserDoc)
    .read()
    .data()?.discoverFeedsTutorialSeen

  const setSeen = useCallback(async () => {
    await currentUserDoc.update({ discoverFeedsTutorialSeen: true })
  }, [currentUserDoc])

  if (hasSeen) {
    return null
  }

  return (
    <Container
      style={{
        backgroundColor: colors.welcomeBlockBackground,
        padding: 30,
        borderRadius: 10,
        marginBottom: 40,
      }}
    >
      <Container horizontal vAlign="center">
        <CaptionText style={{ fontSize: 35, fontWeight: 300 }}>
          {t('Find new feeds with ease!')}
        </CaptionText>
        <Spacer size={10} />
        <HighlightText text={'🔎'} />
      </Container>
      <Spacer size={10} />
      {(() => {
        const [bodyTop, linkLabel, bodyEnd] = t(
          'Following others can be a great way of knowledge and enjoyment. Here you see all the feeds you dont follow right now, but maybe you should? Click on a feed to see the entries, hit follow to take advantage and you can always find more information regarding feeds in |our helpdesk|. Best of follow!'
        ).split('|')

        return (
          <BodyText style={{ color: colors.labelDim, fontSize: 15 }}>
            {bodyTop}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: colors.active }}
              href={t(
                'https://intercom.help/beeworkapp/sv/articles/5587957-informationsfloden'
              )}
            >
              {linkLabel}
            </a>
            {bodyEnd}
          </BodyText>
        )
      })()}{' '}
      <Spacer size={20} />
      <Container style={{ alignItems: 'flex-end' }}>
        <OutlineButton
          onClick={setSeen}
          style={{ display: 'inline-block', padding: '9px 76px' }}
        >
          {t('Got it!')}
        </OutlineButton>
      </Container>
    </Container>
  )
}
