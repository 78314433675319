import { useFirestoreCollection } from '@iwikal/reactfire'
import ClickableOpacity from '../../ClickableOpacity'
import { useColors } from '../../Colors'
import { usePostCurrentUserSnap, usePostSnap } from '../../hooks/feeds'
import { useTranslate } from '../../hooks/Internationalization'
import { Container, ContainerProps } from '../../layout/Layout'

function useNewCommentsCount(feedId: string, postId: string) {
  const postRes = usePostSnap(feedId, postId)
  const postUserRes = usePostCurrentUserSnap(feedId, postId)

  const post = postRes.read().data()
  const postUser = postUserRes.read().data()

  return (
    (post?.latestCommentIndex || 0) - (postUser?.latestSeenCommentIndex || 0)
  )
}

type FeedPostCommentCounterProps = {
  feedId: string
  postId: string
  onClick?: () => void
} & ContainerProps

export default function FeedPostCommentCounter({
  feedId,
  postId,
  style,
  onClick,
  ...rest
}: FeedPostCommentCounterProps) {
  const colors = useColors()
  const postResource = usePostSnap(feedId, postId)

  const newCommentsCount = useNewCommentsCount(feedId, postId)

  const commentsCount = useFirestoreCollection(
    postResource.read().ref.collection('comments')
  ).read().size

  const t = useTranslate()

  return (
    <Container
      style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        ...style,
      }}
      {...rest}
    >
      <ClickableOpacity onClick={onClick}>
        {t('%{smart_count} comment |||| %{smart_count} comments', {
          smart_count: commentsCount || 0,
        })}
        {newCommentsCount > 0 && (
          <div style={{ color: colors.success, marginLeft: 5 }}>
            {t('%{smart_count} new |||| %{smart_count} new', {
              smart_count: newCommentsCount || 0,
            })}
          </div>
        )}
      </ClickableOpacity>
    </Container>
  )
}
