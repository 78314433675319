import { Button, FormGroup, Stack, TextField } from '@mui/material'
import 'firebase/auth'
import { useCallback, useState } from 'react'
import BackLabelButton from './BackLabelButton'
import SignInLayout from './SignInLayout'
import { showSuccessToast } from './Toast'
import { setPassword } from './cloud-functions'
import { useTranslate } from './hooks/Internationalization'
import useAccountInfo, { AccountInfoState } from './hooks/useAccountInfo'
import { Container, ContainerProps, Spacer } from './layout/Layout'

type AccountSettingsPageProps = ContainerProps & {}

export default function AccountSettingsPage({
  flex = '0 0 auto',
  style,
  ...containerProps
}: AccountSettingsPageProps) {
  const { state } = useAccountInfo()
  const showChangePassword =
    state === AccountInfoState.SignedInAccount ||
    state === AccountInfoState.SignedInUserByAccount

  return (
    <SignInLayout
      topChildren={
        <>
          <BackLabelButton sx={{ alignSelf: 'center' }} />
          <Spacer size={10} />
        </>
      }
    >
      <Container
        style={{
          flex,
          ...style,
        }}
        {...containerProps}
      >
        {showChangePassword && <ChangePasswordForm />}
      </Container>
    </SignInLayout>
  )
}

function ChangePasswordForm() {
  const [newPasswordError, setNewPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const t = useTranslate()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)
      setRepeatPasswordError('')
      setNewPasswordError('')

      if (newPassword !== repeatPassword) {
        setRepeatPasswordError(t('Passwords do not match'))
        setLoading(false)
        return
      }

      try {
        await setPassword(newPassword)
      } catch (err) {
        setNewPasswordError(err.message)
        setLoading(false)
        return
      }

      setNewPassword('')
      setRepeatPassword('')
      setLoading(false)
      showSuccessToast(t('Password successfully changed'))
    },
    [newPassword, repeatPassword, t]
  )

  const handleNewPasswordInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNewPassword(e.target.value)
  }

  const handleRepeatPasswordInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRepeatPassword(e.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Stack spacing={2}>
          <TextField
            disabled={loading}
            value={newPassword}
            onChange={handleNewPasswordInput}
            type="password"
            label={t('New password')}
            helperText={newPasswordError}
            error={!!newPasswordError}
            autoComplete="new-password"
          />
          <TextField
            disabled={loading}
            helperText={repeatPasswordError}
            error={!!repeatPasswordError}
            value={repeatPassword}
            onChange={handleRepeatPasswordInput}
            type="password"
            label={t('Confirm new password')}
            autoComplete="new-password"
          />
          <Button disabled={loading} type="submit" variant="contained">
            {t('Change password')}
          </Button>
        </Stack>
      </FormGroup>
    </form>
  )
}
