import { css } from '@emotion/react'
import { useFirestoreDoc, useUser } from '@iwikal/reactfire'
import { Alert } from '@mui/material'
import { AllUsersProvider } from 'connect-shared/AllUsersContext'
import { DocumentReference } from 'connect-shared/firestore'
import { organizationIsTimeLimited } from 'connect-shared/organization'
import { AllUsersDocument } from 'connect-shared/types'
import 'firebase/auth'
import {
  ComponentType,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { isMobile } from 'react-device-detect'
import DocumentTitle from 'react-document-title'
import { Redirect, Route, Switch } from 'react-router-dom'
import AccountActions from './AccountActions'
import AccountSettingsPage from './AccountSettingsPage'
import AndroidPlaystoreButton from './AndroidPlaystoreButton'
import BlockButton from './BlockButton'
import ErrorBoundary from './chat/ErrorBoundary'
import { useColors } from './Colors'
import EnsureTokenRefreshAfterEmailVerification from './EnsureTokenRefreshAfterEmailVerification'
import DiscoverFeeds from './feeds/DiscoverFeeds'
import { firestore } from './firestore'
import FullscreenSpinner from './FullscreenSpinner'
import {
  useAuthData,
  useCurrentUserId,
  useHasMultipleUsers,
  useOrganizationDoc,
} from './hooks/auth'
import { usePranks } from './hooks/pranks'
import { useTranslate } from './hooks/Internationalization'
import { useAccountInfoAssertAccount } from './hooks/useAccountInfo'
import useAccountSnap from './hooks/useAccountSnap'
import useClaimUpdater from './hooks/useClaimUpdater'
import useCurrentUser from './hooks/useCurrentUser'
import useFeatureFlag, { useIdTokenResult } from './hooks/useFeatureFlag'
import useUsageUpdater from './hooks/useUsageUpdater'
import { useUserlimit } from './hooks/useUserlimit'
import InvitationEmail from './InvitationEmail'
import IOSAppstoreButton from './IOSAppstoreButton'
import { Container, Spacer } from './layout/Layout'
import Login from './Login'
import Navigation from './Navigation'
import { useAllowSettingsPage } from './settings/Settings'
import SignInLayout from './SignInLayout'
import SignOut from './SignOut'
import SsoSpinner from './SsoSpinner'
import { BodyText, CaptionText } from './Text'
import { Tutorials } from './Tutorials'
import UserLimitReached from './UserLimitReached'

function withOopsie(Component: ComponentType<{}>) {
  return function Oopsie() {
    return (
      <ErrorBoundary fallback={<h2>error</h2>}>
        <Component />
      </ErrorBoundary>
    )
  }
}

const Home = withOopsie(lazy(() => import('./Home')))
const Chat = withOopsie(lazy(() => import('./chat/ChatPage')))
const Contacts = withOopsie(lazy(() => import('./Contacts')))
const Feeds = withOopsie(lazy(() => import('./feeds/Feeds')))
const Events = withOopsie(lazy(() => import('./events/Events')))
const Wiki = withOopsie(lazy(() => import('./wiki/Wiki')))
const GroupsTesting = withOopsie(lazy(() => import('./GroupsTesting')))
const NotFound = withOopsie(lazy(() => import('./NotFound')))
const VerifyEmail = withOopsie(lazy(() => import('./VerifyEmail')))
const SwitchUser = withOopsie(lazy(() => import('./SwitchUser')))
const UserProfile = withOopsie(lazy(() => import('./user-profile/UserProfile')))
const Oauth2Authorize = withOopsie(lazy(() => import('./Oauth2Authorize')))
const BackOfficePage = withOopsie(
  lazy(() => import('./back-office/BackOfficePage'))
)
const Settings = withOopsie(lazy(() => import('./settings/Settings')))
const Invitation = withOopsie(lazy(() => import('./Invitation')))
const FeedEvents = withOopsie(lazy(() => import('./feeds/FeedEvents')))

function UserRouter() {
  const wikiEnabled = useFeatureFlag('wiki', false)
  const currentUser = useCurrentUser()
  const multipleUsers = useHasMultipleUsers()
  const organization = useFirestoreDoc(useOrganizationDoc())

  useUsageUpdater()

  const organizationName = organization.read().data()?.name
  const hasMultipleUsers = multipleUsers.read()
  const title =
    hasMultipleUsers && organizationName
      ? `${organizationName} | Beework`
      : null

  const showSettings = useAllowSettingsPage()
  const { exceeded } = useUserlimit()
  const allUsersRef = currentUser?.organization
    ?.collection('extra')
    .doc('allUsers') as DocumentReference<AllUsersDocument> | undefined
  if (exceeded) {
    return (
      <AllUsersProvider allUsersRef={allUsersRef}>
        <LockedOutRoutes />
      </AllUsersProvider>
    )
  }

  return (
    <>
      {title && <DocumentTitle title={title} />}
      <Navigation />
      <Suspense fallback={<FullscreenSpinner />}>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Redirect exact from="/home/posts" to="/home" />
          <Redirect exact from="/sign-in" to="/home" />
          <Redirect from="/sso/:providerId" to="/home" />
          <Redirect from="/sso/" to="/home" />
          <Route path="/home" component={Home} />
          <Route path="/chat" component={Chat} />
          <Redirect exact from="/people" to="/people/all" />
          <Route path="/people" component={Contacts} />
          <Route path="/users/:userid" component={UserProfile} />
          <Route exact path="/feeds/discover" component={DiscoverFeeds} />
          <Redirect exact from="/feeds" to="/feeds/all" />
          <Redirect exact from="/feeds/all" to="/feeds/all/posts" />
          <Route path="/feeds/all/posts" component={Feeds} />
          <Route path="/feeds/all/events" component={FeedEvents} />
          <Redirect exact path="/feeds/:feedId" to="/feeds/:feedId/posts" />
          <Route path="/feeds/:feedId/:postId" component={Feeds} />
          <Route path="/events" component={Events} />
          {wikiEnabled && <Route path="/wiki" component={Wiki} />}
          {showSettings && <Route path="/settings" component={Settings} />}
          <Route path="/groups-testing" component={GroupsTesting} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <Tutorials />
    </>
  )
}

function LockedOutRoutes() {
  const currentUser = useCurrentUser()
  const allUsersRef = currentUser?.organization
    ?.collection('extra')
    .doc('allUsers') as DocumentReference<AllUsersDocument> | undefined
  if (currentUser?.organizationAdmin) {
    return (
      <>
        <AllUsersProvider allUsersRef={allUsersRef}>
          <Navigation />
          <Route path="/settings">
            <Settings />
          </Route>
        </AllUsersProvider>
      </>
    )
  } else {
    return (
      <>
        <Navigation />
        <UserLimitReached />
      </>
    )
  }
}

function LoggedInRoutes() {
  const colors = useColors()
  const t = useTranslate()
  const currentUser = useCurrentUser()
  useClaimUpdater()

  const accept = useCallback(() => {
    sessionStorage.setItem('acceptNarrowLayout', 'true')
    window.location.reload()
  }, [])

  const hasAccepted = useMemo(() => {
    return sessionStorage.getItem('acceptNarrowLayout') === 'true'
  }, [])

  const pranks = usePranks()
  const organization = useFirestoreDoc(useOrganizationDoc()).read().data()
  const currentUserId = useCurrentUserId()
  const isSupportUser =
    organization && currentUserId === organization.supportUserId

  if (isMobile && !hasAccepted) {
    return (
      <SignInLayout>
        <CaptionText
          style={{ fontSize: 42, fontWeight: 600, marginBottom: 24 }}
        >
          {t('Welcome!')}
        </CaptionText>
        <BodyText style={{ fontSize: 32 }}>
          {t(
            'Use our app for the best experience on smaller devices if not already installed you can download it here.'
          )}
        </BodyText>
        <Container>
          <Spacer size={60} />
          <IOSAppstoreButton />
          <Spacer size={40} />
          <AndroidPlaystoreButton />
          <Spacer size={40} />
        </Container>
        <BlockButton
          style={{ fontSize: 32, padding: 40, marginTop: 40 }}
          onClick={accept}
        >
          {t('Continue without app')}
        </BlockButton>
        <Spacer size={40} />
      </SignInLayout>
    )
  }

  const allUsersRef = currentUser?.organization
    ?.collection('extra')
    .doc('allUsers') as DocumentReference<AllUsersDocument> | undefined

  return (
    <AllUsersProvider allUsersRef={allUsersRef}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.background,
          minHeight: '100%',
          flex: 0,
          opacity: pranks.windowOpacity,
          transform: pranks.windowRotation
            ? `rotate(${pranks.windowRotation}deg)`
            : undefined,
          transition: `transform ${pranks.transitionDuration || 1}s, opacity ${
            pranks.transitionDuration || 1
          }s`,
        }}
        css={css`
          ${pranks.textTransform
            ? `* { text-transform: ${pranks.textTransform} !important; }`
            : ''}
          ${pranks.textDecoration
            ? `* { text-decoration: ${pranks.textDecoration} !important; }`
            : ''}
        `}
      >
        {isSupportUser && (
          <Alert severity="warning">
            You are logged in as a support user on this organization
          </Alert>
        )}
        <Switch>
          <Route exact path="/oauth2/authorize" component={Oauth2Authorize} />
          <Route path="/switch-user" component={SwitchUserRouter} />
          <Route component={UserRouter} />
        </Switch>
      </div>
    </AllUsersProvider>
  )
}

function SwitchUserRouter() {
  const { accountId } = useAccountInfoAssertAccount()
  const accountDoc = useMemo(() => {
    return firestore().collection('accounts').doc(accountId)
  }, [accountId])
  const account = useFirestoreDoc(accountDoc).read().data() || {}

  const { email } = useAccountInfoAssertAccount()

  useEffect(() => {
    if (email && accountId && account?.email === undefined) {
      accountDoc.set({ email }, { merge: true })
    }
  }, [account?.email, accountDoc, accountId, email])

  let savedInviteCode = ''
  try {
    savedInviteCode =
      window.localStorage.getItem('Invitation-signup-invitation-code') || ''
  } catch (err) {
    console.error(err)
  }

  return (
    <EnsureTokenRefreshAfterEmailVerification>
      <Switch>
        {/* <Route path="/create-organization" component={CreateOrganization} /> */}
        {!!savedInviteCode && (
          <Redirect to={'/invitation/' + savedInviteCode} />
        )}
        <Route component={SwitchUser} />
      </Switch>
    </EnsureTokenRefreshAfterEmailVerification>
  )
}

function AppRouter() {
  const user = useUser().read()
  const idTokenResult = useIdTokenResult().read()
  const isSsoUser = idTokenResult?.signInProvider?.startsWith('saml.')
  const accountSnap = useAccountSnap()
  const isAccountAuth = idTokenResult?.signInProvider === 'password'

  const { organizationId, userId } = useAuthData(false)
  const organizationRef = organizationId
    ? firestore().collection('organizations').doc(organizationId)
    : firestore().collection('empty').doc('empty')
  const organizationSnap = useFirestoreDoc(organizationRef).read()

  const currentUserRef = userId
    ? firestore().collection('users').doc(userId)
    : firestore().collection('empty').doc('empty')
  const currentUserSnap = useFirestoreDoc(currentUserRef).read()

  const disabled =
    !currentUserSnap.data()?.isSupportUser &&
    (organizationSnap.data()?.disabled ||
      organizationIsTimeLimited(organizationSnap.data()))

  if (!user) {
    return (
      <Switch>
        <Redirect exact from="/sign-out" to="/sign-in" />
        <Route component={Login} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        {accountSnap.read().data()?.superUser && (
          <Route path="/back-office" component={BackOfficePage} />
        )}
        <Route path="/account-settings" component={AccountSettingsPage} />
        <Route path="/forgot" component={SignOut} />
        <Route path="/sign-out" component={SignOut} />
        {idTokenResult?.claims.userType === 'human' &&
          (disabled ? (
            <Route component={SwitchUserRouter} />
          ) : (
            <Route component={LoggedInRoutes} />
          ))}
        {isSsoUser && <Route component={SsoSpinner} />}
        {isAccountAuth &&
          (idTokenResult?.claims.email_verified ? (
            <Route component={SwitchUserRouter} />
          ) : (
            <Route component={VerifyEmail} />
          ))}
        <Route component={SignOut} />
      </Switch>
    )
  }
}

export default function Router() {
  return (
    <Switch>
      <Route path="/invitation/:code" component={Invitation} />
      <Route path="/account-action">
        <AccountActions />
      </Route>
      <Route path="/invitation-email" component={InvitationEmail} />
      <Route>
        <AppRouter />
      </Route>
    </Switch>
  )
}
