import { css } from '@emotion/css'
import { CSSProperties, ReactNode } from 'react'

export const hoverAnimation = css`
  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.5;
    }
  }

  &:hover {
    svg {
      animation-duration: 0.4s;
      opacity: 0.5;
      animation-name: fadeOut;
    }
  }
`

export default function IconHoverWrapper({
  children,
  style,
}: {
  children: ReactNode
  style?: CSSProperties
}) {
  return (
    <div style={style} css={hoverAnimation}>
      {children}
    </div>
  )
}
