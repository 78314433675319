import { DialogContent, DialogOverlay } from '@reach/dialog'
import { useCallback } from 'react'
import { useColors } from '../Colors'
import { BodyText, CaptionText } from '../Text'
import { ModelDocument } from '../firestore'
import { useFirestoreDoc } from '@iwikal/reactfire'
import { QueryDocumentSnapshot, QuerySnapshot } from 'connect-shared/firestore'
import { UserDocument } from 'connect-shared/types'
import User from 'connect-shared/User'
import IconButton from '../IconButton'
import { Container, Pusher, Spacer } from '../layout/Layout'
import RoundAvatar from '../RoundAvatar'
import { ReactComponent as SelectedIcon } from '../icons/ico-going.svg'
import { ReactComponent as AddIcon } from '../icons/ico-not-going.svg'
import { WorkplacesNode } from 'connect-shared/datamodel'
import { Resource } from '../resource'
import ModalCloseButton from '../ModalCloseButton'

function ListItem({
  item,
  selected,
  workplaceDoc,
}: {
  item: QueryDocumentSnapshot<UserDocument>
  selected: boolean
  workplaceDoc: ModelDocument<WorkplacesNode>
}) {
  const colors = useColors()
  const user = new User(item)
  const workplaceRef = useFirestoreDoc(workplaceDoc)
  const workplace = workplaceRef.read().data()
  const addItem = useCallback(async () => {
    if (!workplace) return
    const workplaceAdmins = workplace?.admins || []
    const newAdmins = new Set(workplaceAdmins)
    newAdmins.add(item.id)
    await workplaceDoc.set({ admins: [...newAdmins] }, { merge: true })
  }, [workplaceDoc, workplace, item.id])

  return (
    <>
      <Container
        horizontal
        vAlign="center"
        style={{
          backgroundColor: colors.foreground,
          borderRadius: 6,
          boxShadow: `0 0 3px ${colors.announcementShadow}`,
          padding: '10px 15px',
        }}
      >
        <RoundAvatar size={50} name={user.fullName} avatar={user.avatar} />
        <Spacer size={10} />
        <BodyText
          style={{
            maxWidth: 270,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user.fullName}
        </BodyText>
        <Pusher />
        {selected ? (
          <IconButton Icon={SelectedIcon} size={30} />
        ) : (
          <IconButton
            onClick={addItem}
            Icon={AddIcon}
            style={{ transform: 'rotate(45deg)' }}
            size={30}
          />
        )}
      </Container>
      <Spacer size={15} />
    </>
  )
}

type WorkplaceAddUserModalProps = {
  close: () => void
  selectedUsers: string[]
  title: string
  workplaceDoc: ModelDocument<WorkplacesNode>
  usersResource: Resource<QuerySnapshot<UserDocument>>
}
export default function WorkplaceAddUserModal({
  close,
  selectedUsers,
  title,
  workplaceDoc,
  usersResource,
}: WorkplaceAddUserModalProps) {
  const colors = useColors()

  const userSnap = usersResource.read().docs

  return (
    <DialogOverlay
      style={{
        zIndex: 10,
      }}
      onDismiss={close}
      aria-label=" "
    >
      <DialogContent
        aria-label=" "
        style={{
          position: 'relative',
          width: 490,
          backgroundColor: colors.webChatModalBackground,
          borderRadius: 10,
          padding: 0,
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <CaptionText
          style={{
            marginBottom: 30,
            marginLeft: 30,
            fontSize: 20,
            fontWeight: 600,
          }}
          strong
        >
          {title}
        </CaptionText>
        <Container
          style={{
            fontSize: 20,
            fontWeight: 600,
            overflowY: 'auto',
            maxHeight: '70vh',
            padding: '20px 30px',
          }}
        >
          {userSnap.map((snap) => {
            return (
              <ListItem
                workplaceDoc={workplaceDoc}
                selected={selectedUsers.includes(snap.id)}
                item={snap}
                key={snap.id}
              />
            )
          })}
        </Container>
        <ModalCloseButton onClick={close} />
      </DialogContent>
    </DialogOverlay>
  )
}
