import styled from '@emotion/styled'
import Color from 'color'
import { ButtonHTMLAttributes, CSSProperties, useMemo } from 'react'
import { useColors } from './Colors'
import { Container } from './layout/Layout'
import PlayippSpinner from './playippSpinner/PlayippSpinner'
import { isMobile } from 'react-device-detect'

type StyledButtonProps = {
  background?: string
  hoverBackground?: string
  foreground?: string
}

const StyledButton = styled.button<StyledButtonProps>`
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.background};

  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.foreground};

  padding: 9px 20px 9px 20px;

  &:hover,
  &:focus-within {
    background-color: ${(props) =>
      props.disabled ? props.background : props.hoverBackground};
  }
`

export type BlockButtonProps = {
  foreground?: string
  danger?: boolean
  cancel?: boolean
  transparent?: boolean
  wide?: boolean
  hoverColor?: string
  flex?: CSSProperties['flex']
  loading?: boolean
  background?: string
  rounded?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement> &
  StyledButtonProps

export default function BlockButton({
  foreground,
  onClick,
  type,
  style,
  danger,
  transparent,
  flex = '0 0 auto',
  wide,
  hoverColor,
  disabled: disabledProp,
  cancel,
  loading,
  children,
  background,
  rounded,
  ...buttonProps
}: BlockButtonProps) {
  const disabled = loading || disabledProp
  const colors = useColors()

  const foregroundColor =
    foreground ?? (transparent ? colors.label : colors.textInverted)

  const backgroundColor = useMemo(() => {
    if (background) {
      return background
    } else if (transparent) {
      return colors.none
    } else if (danger) {
      return colors.danger
    } else if (cancel) {
      return colors.faint
    } else {
      return colors.beeworkBlue
    }
  }, [
    background,
    cancel,
    colors.beeworkBlue,
    colors.danger,
    colors.faint,
    colors.none,
    danger,
    transparent,
  ])

  const hoverBackgroundColor = hoverColor
    ? hoverColor
    : Color(backgroundColor).lighten(0.15).rgb().string()

  return (
    <StyledButton
      background={backgroundColor}
      hoverBackground={hoverBackgroundColor}
      foreground={foregroundColor}
      onClick={onClick}
      style={{
        borderRadius: rounded ? 27 : 6,
        flex,
        width: wide ? '100%' : 'auto',
        cursor: disabled ? '' : 'pointer',
        minWidth: isMobile ? 320 : 142,
        ...style,
      }}
      disabled={disabled}
      type={type}
      {...buttonProps}
    >
      {loading ? (
        <Container hAlign="center" vAlign="center">
          <PlayippSpinner
            size={isMobile ? 40 : 20}
            color={colors.beeworkBlue}
          />
        </Container>
      ) : (
        <Container style={{ opacity: loading ? 0 : 1 }} horizontal>
          {children}
        </Container>
      )}
    </StyledButton>
  )
}
